export interface IFormArgs {
  $mappings?: { [key: string]: string };
  $markAsDirty?: boolean;
  [key: string]: any;
}

export enum KnownFormQueryParams {
  TabIndex = 'tabIndex',
  Args = 'args',
}
