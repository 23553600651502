import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { CookiesService } from '@shared/cookies/cookies.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export const ENGINE_THEME_COOKIE: string = 'ENGINE_THEME';

export enum EngineTheme {
  Light = 'Light',
  Dark = 'Dark',
  System = 'System',
}

@Injectable()
export class ThemeService {
  private _renderer: Renderer2;
  private _theme$: BehaviorSubject<EngineTheme> = new BehaviorSubject(EngineTheme.System);
  private _mediaQueryList: MediaQueryList = window.matchMedia('(prefers-color-scheme: dark)');

  constructor(
    private _cookieService: CookiesService,
    @Inject(DOCUMENT) private _document: Document,
    rendererFactory: RendererFactory2,
  ) {
    this._renderer = rendererFactory.createRenderer(null, null);
    this.initializeTheme();
    this._mediaQueryList.addEventListener('change', () => this.setTheme(this._theme$.value));
  }

  setTheme(theme: EngineTheme): void {
    this._theme$.next(theme);
    this.setUpThemeClassOnHostElement();
    this._cookieService.set(ENGINE_THEME_COOKIE, theme.toString());
  }

  getThemeAsync(): Observable<EngineTheme> {
    return this._theme$;
  }

  getTheme(): EngineTheme {
    return this._theme$.value;
  }

  isDarkThemeAsync(): Observable<boolean> {
    return this._theme$.asObservable().pipe(map((x) => this.isDarkThemeInternal(x)));
  }

  isDarkTheme(): boolean {
    return this.isDarkThemeInternal(this._theme$.value);
  }

  private initializeTheme() {
    let theme = EngineTheme.System;
    if (this._cookieService.exists(ENGINE_THEME_COOKIE)) {
      const themeCookie = this._cookieService.get(ENGINE_THEME_COOKIE);
      theme = EngineTheme[themeCookie];
    }
    this.setTheme(theme);
  }

  private isDarkThemeInternal(theme: EngineTheme): boolean {
    if (!theme) return false;
    return theme == EngineTheme.Dark || (theme == EngineTheme.System && this.isSystemThemeDark());
  }

  private isSystemThemeDark(): boolean {
    return window.matchMedia('(prefers-color-scheme: dark)').matches;
  }

  private setUpThemeClassOnHostElement() {
    const hostClass = this.isDarkTheme() ? 'dark-theme' : 'light-theme';
    this._renderer.setAttribute(this._document.body, 'class', hostClass);
  }
}
