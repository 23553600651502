import { ActionReducerMap } from '@ngrx/store';
import { viewReducer } from './reducers';
import { IViewState } from './state';
import * as ViewSelectors from './selectors';
import * as ViewActions from './actions';

export { ViewActions, ViewSelectors };

export interface ViewFeatureState {
  view: IViewState;
}

export const reducers: ActionReducerMap<ViewFeatureState> = {
  view: viewReducer,
};
