import { ModuleWithProviders, NgModule } from '@angular/core';
import { EngineMetadataProvider } from './services/engine-metadata-provider.service';
import { ENGINE_METADATA_PROVIDER } from 'src/engine-sdk/contract/metadata';

@NgModule({
  imports: [],
  exports: [],
  declarations: [],
})
export class EngineMetadataModule {
  static forRoot(): ModuleWithProviders<EngineMetadataModule> {
    return {
      ngModule: EngineMetadataModule,
      providers: [EngineMetadataProvider, { provide: ENGINE_METADATA_PROVIDER, useExisting: EngineMetadataProvider }],
    };
  }
}
