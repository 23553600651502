<ng-container *ngIf="controlGroup">
  <app-icon-header
    class="control-group-header"
    *ngIf="controlGroup.icon || controlGroup.title || controlGroup.subtitle"
    [icon]="controlGroup.icon"
    [title]="controlGroup.title"
    [subtitle]="controlGroup.subtitle">
  </app-icon-header>
  <div class="control-group-controls" [ngStyle]="{ 'flex-direction': getLayoutType(controlGroup) }">
    <ng-container *ngFor="let control of controlGroup.controls" [ngSwitch]="control.type">
      <app-yes-no-control
        *ngSwitchCase="'yesno'"
        [formControlName]="control.id"
        engineYesNoFormControl
        [engineYesNoControlDefinition]="control"></app-yes-no-control>
      <app-text-control
        *ngSwitchCase="'text'"
        [formControlName]="control.id"
        engineTextFormControl
        [engineTextControlDefinition]="control"></app-text-control>
      <app-text-area-control
        *ngSwitchCase="'textarea'"
        [formControlName]="control.id"
        engineTextAreaFormControl
        [engineTextAreaControlDefinition]="control"></app-text-area-control>
      <app-number-control
        *ngSwitchCase="'number'"
        [formControlName]="control.id"
        engineNumberFormControl
        [engineNumberControlDefinition]="control"></app-number-control>
      <app-datetime-control
        *ngSwitchCase="'datetime'"
        [formControlName]="control.id"
        engineDatetimeFormControl
        [engineDatetimeControlDefinition]="control"></app-datetime-control>
      <app-date-control
        *ngSwitchCase="'date'"
        [formControlName]="control.id"
        engineDateFormControl
        [engineDateControlDefinition]="control"></app-date-control>
      <app-time-control
        *ngSwitchCase="'time'"
        [formControlName]="control.id"
        engineTimeFormControl
        [engineTimeControlDefinition]="control"></app-time-control>
      <app-select-control
        *ngSwitchCase="'optionset'"
        [formControlName]="control.id"
        engineSelectFormControl
        [engineSelectControlDefinition]="control">
      </app-select-control>
      <app-multiselect-control
        *ngSwitchCase="'multiselectoptionset'"
        [formControlName]="control.id"
        engineMultiselectFormControl
        [engineMultiselectControlDefinition]="control">
      </app-multiselect-control>
      <app-autocomplete-control
        *ngSwitchCase="'lookup'"
        [formControlName]="control.id"
        engineLookupFormControl
        [engineLookupControlDefinition]="control"></app-autocomplete-control>
      <app-button-control
        *ngSwitchCase="'button'"
        [formControlName]="control.id"
        engineButtonFormControl
        [engineButtonControlDefinition]="control"></app-button-control>
      <app-hyperlink-control
        *ngSwitchCase="'hyperlink'"
        [formControlName]="control.id"
        engineHyperlinkFormControl
        [engineHyperlinkControlDefinition]="control"></app-hyperlink-control>
      <app-label-control
        *ngSwitchCase="'label'"
        engineLabelFormControl
        [engineLabelFormControlDefinition]="control"></app-label-control>
      <app-description-control
        *ngSwitchCase="'description'"
        engineDescriptionFormControl
        [engineDescriptionFormControlDefinition]="control"></app-description-control>
      <app-icon-control
        *ngSwitchCase="'icon'"
        [formControlName]="control.id"
        engineIconFormControl
        [engineIconFormControlDefinition]="control"></app-icon-control>
      <app-chips-control
        *ngSwitchCase="'inlinerelation'"
        [formControlName]="control.id"
        engineInlineRelationFormControl
        [engineInlineRelationControlDefinition]="control"></app-chips-control>
      <app-html-editor-control
        *ngSwitchCase="'htmleditor'"
        [formControlName]="control.id"
        engineHtmlEditorFormControl
        [engineHtmlEditorControlDefinition]="control"></app-html-editor-control>

      <engine-chart-control *ngSwitchCase="'chart'" [chartControl]="control"></engine-chart-control>
      <engine-query-builder-control *ngSwitchCase="'querybuilder'" [queryBuilderControl]="control"></engine-query-builder-control>

      <engine-subgrid-control *ngSwitchCase="'subgrid'" [subgridControl]="control"></engine-subgrid-control>
      <app-grouped-autocomplete-control
        *ngSwitchCase="'combinedlookups'"
        engineCombinedLookupsFormControl
        [engineCombinedLookupsControlDefinition]="control"></app-grouped-autocomplete-control>
    </ng-container>
  </div>
</ng-container>
