<button
  type="button"
  mat-button
  #trigger="matMenuTrigger"
  class="breadcrumb-toolbar-button"
  [matMenuTriggerFor]="menu"
  (menuOpened)="isMenuOpened = trigger.menuOpen"
  (menuClosed)="isMenuOpened = trigger.menuOpen">
  <mat-icon class="breadcrumb-menu" [ngClass]="{ 'breadcrumb-menu-rotated': isMenuOpened }"> navigation </mat-icon>
</button>
<mat-menu #menu="matMenu">
  <span *ngFor="let breadcrumb of breadcrumbs">
    <button type="button" mat-menu-item matTooltipPosition="after" (click)="breadcrumbClicked.emit(breadcrumb)">
      <span>{{ breadcrumb.label }}</span>
    </button>
  </span>

  <span *ngIf="forms.length > 0">
    <button type="button" mat-menu-item [matMenuTriggerFor]="subMenu" matTooltipPosition="below">
      <span>{{ selectedForm.name }}</span>
    </button>
    <mat-menu #subMenu="matMenu" class="breadcrumb-form-menu">
      <button type="button" *ngFor="let form of forms" mat-menu-item (click)="onOptionSelected(form)" matTooltipPosition="after">
        <span>{{ form.name }}</span>
      </button>
    </mat-menu>
  </span>
</mat-menu>
