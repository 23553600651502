import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { IUserInteractionContext } from '../models/iuser-interaction-context.model';
import { UserInteractionContexts } from '../models/user-interaction-contexts.model';

export const USER_INTERACTION_SERVICE = new InjectionToken<IUserInteractionService>('IUserInteractionService');

export interface IUserInteractionService {
  readonly uiContexts: UserInteractionContexts;
  startListen(contexts: IUserInteractionContext[]): Observable<any[]>;
}
