import { IErrorProvider } from '@core/errors/services/error-event.service';
import { ErrorHandlerProvider } from '@core/errors/services/error-handler-provider.service';
import { Observable } from 'rxjs';
import { IErrorContext, IError, IExecutionContext, ErrorHandlerLifetime } from 'src/engine-sdk';

export class ErrorContext implements IErrorContext {
  constructor(private _handlerProvider: ErrorHandlerProvider, private _errorProvider: IErrorProvider) {}

  error(): Observable<IError> {
    return this._errorProvider.errorEvent$;
  }

  addErrorHandler(
    handler: (ctx: IExecutionContext, error: IError) => Observable<IError>,
    order: number,
    exceptionName?: string,
    lifetime?: ErrorHandlerLifetime,
  ) {
    this._handlerProvider.addHandler({
      handler,
      order,
      exceptionName,
      lifetime,
    });
  }
}
