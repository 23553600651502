import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MenuAreaDto, MenuConfigurationDto } from '@core/services/api-clients';

@Component({
  selector: 'app-main-menu-logo',
  templateUrl: './main-menu-logo.component.html',
  styleUrls: ['./main-menu-logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MainMenuLogoComponent {
  @Input() selectedMenuArea: MenuAreaDto;
  @Input() isWide: boolean = false;
  @Input() menuConfiguration: MenuConfigurationDto;
}
