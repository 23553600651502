import { createSelector, createFeatureSelector } from '@ngrx/store';
import { LayoutFeatureState } from '.';
import { MD_SCREEN_BREAKPOINT, ScreenSize, XS_SCREEN_BREAKPOINT } from '../models/screen-size.enum';
import { FEATURE_NAME } from './state';

export const layoutFeatureSelector = createFeatureSelector<LayoutFeatureState>(FEATURE_NAME);
export const layoutSelector = createSelector(layoutFeatureSelector, (state: LayoutFeatureState) => state.layout);

export const getScreenWidth = createSelector(layoutSelector, (state) => state.screenWidth);
export const getScreenSize = createSelector(getScreenWidth, (screenWidth) => {
  if (!screenWidth) return undefined;
  if (screenWidth <= XS_SCREEN_BREAKPOINT) {
    return ScreenSize.XS;
  } else {
    if (screenWidth <= MD_SCREEN_BREAKPOINT) {
      return ScreenSize.MD;
    }
  }
  return ScreenSize.XL;
});

export const getIsRightPanelVisible = createSelector(layoutSelector, (state) => state.isRightPanelVisible);

export const getIsLeftPanelVisible = createSelector(layoutSelector, (state) => state.isLeftPanelVisible);
export const getIsLeftPanelExpanded = createSelector(layoutSelector, getScreenSize, (state, screenSize) => {
  return screenSize < ScreenSize.MD ? true : state.isLeftPanelExpanded;
});
