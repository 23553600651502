import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ProcessStateDto } from '@core/services/api-clients';

@Component({
  selector: 'engine-process-state',
  templateUrl: './engine-process-state.component.html',
  styleUrls: ['./engine-process-state.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EngineProcessStateComponent {

  private greyColor: string = "#B3B3BF";
  private blueColor: string = "#00A3D7";
  @Input() processState: ProcessStateDto;
  @Input() isHistoryState: boolean;
  @Output() navigateToRelatedRecord = new EventEmitter<ProcessStateDto>();

  getStateBorder(processState: ProcessStateDto){
    if (this.isHistoryState)
      return `2px solid ${this.greyColor};`;
    else
      return `2px solid ${processState?.htmlColor ? processState.htmlColor : this.blueColor};`;
  }

  getStateColor(processState: ProcessStateDto){
    if (this.isHistoryState)
      return `${this.greyColor};`;
    else
      return `${processState?.htmlColor ? processState.htmlColor : this.blueColor};`;
  }
}
