import { Component, OnInit } from '@angular/core';
import { LayoutActions } from '@core/layout/store';
import { Store } from '@ngrx/store';
import { UserSelectors } from '../store';

@Component({
  selector: 'app-user-panel',
  templateUrl: './user-panel.component.html',
  styleUrls: ['./user-panel.component.scss'],
})
export class UserPanelComponent implements OnInit {
  currentUser$ = this._store.select(UserSelectors.getCurrentUser);

  constructor(private _store: Store) {}

  ngOnInit(): void {}

  closeUserSidenav() {
    this._store.dispatch(new LayoutActions.CloseRightPanel());
  }
}
