import { Component, ChangeDetectionStrategy, Inject, Input, Optional, SkipSelf, OnDestroy } from '@angular/core';
import { IButtonContext } from '@core/execution-context';
import { ContextService } from '@core/services/context.service';
import { WidgetDirective } from '@core/widgets/directives/widget.directive';
import { SCRIPT_RUNNER_SERVICE, IScriptRunnerService } from '@core/widgets/models/iscript-runner.service';
import { WidgetType } from '@core/widgets/models/widget-type';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IExecutionContext, IExecutionEvent } from 'src/engine-sdk';
import { ENGINE_DATA_CONTEXT_PROVIDER, IEngineDataContextProvider } from 'src/engine-sdk/contract/engine-data-context';
import { ContextMenuService } from '../context-menu.service';
import { IContextMenuButtonDefinition } from '../models/context-menu-definition.model';
import { IContextMenuButtonState, IContextMenuState } from '../models/context-menu-state.model';
import { IIcon } from '@shared/icon/icon.model';

@Component({
  selector: 'app-context-menu-button',
  templateUrl: './context-menu-button.component.html',
  styleUrls: ['./context-menu-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContextMenuButtonComponent extends WidgetDirective implements IButtonContext, OnDestroy {
  private _isInitiated$ = new BehaviorSubject<boolean>(false);
  private _buttonDefinition: IContextMenuButtonDefinition;

  @Input() contextMenuState: IContextMenuState;
  @Input()
  set buttonDefinition(b: IContextMenuButtonDefinition) {
    this._buttonDefinition = b;
    this.tryToInit();
  }
  @Input() isLabelVisible: boolean = false;

  get buttonDefinition(): IContextMenuButtonDefinition {
    return this._buttonDefinition;
  }
  get buttonState(): IContextMenuButtonState {
    return this.contextMenuState.buttons[this.buttonDefinition.id];
  }
  get hasLabel(): boolean {
    return !!this.buttonState.label?.length;
  }

  override onWidgetClick(): void {
    if (this.buttonState.isDisabled) return;
    super.onWidgetClick();
  }

  constructor(
    @Optional() @SkipSelf() parentWidget: WidgetDirective,
    @Inject(SCRIPT_RUNNER_SERVICE) scriptRunnerService: IScriptRunnerService,
    @Optional()
    @SkipSelf()
    @Inject(ENGINE_DATA_CONTEXT_PROVIDER)
    private _engineDataContextProvider: IEngineDataContextProvider,
    private _contextService: ContextService,
    private _contextMenuService: ContextMenuService,
  ) {
    super(parentWidget, scriptRunnerService);
    this._isOnClickEventSupported = true;
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  getIcon(button: IContextMenuButtonState): IIcon {
    return {
      fontIconName: button.fontIconName,
      iconRelativePath: button.iconRelativePath,
    };
  }

  private tryToInit() {
    if (!this._isInitiated$.value && this.buttonDefinition) {
      this._isInitiated$.next(true);
    }
  }

  //#region IButtonContext
  setFontIcon(fontIconName: string): void {
    this._contextMenuService.setButtonIcon(this.buttonDefinition.id, fontIconName, undefined);
  }

  setResourceIcon(iconRelativePath: string): void {
    this._contextMenuService.setButtonIcon(this.buttonDefinition.id, undefined, iconRelativePath);
  }

  setLabel(value: string): void {
    this._contextMenuService.setButtonLabel(this.buttonDefinition.id, value);
  }

  setDisabled(value: boolean): void {
    this._contextMenuService.setButtonIsDisabled(this.buttonDefinition.id, value);
  }

  getVisibility(): boolean {
    return this.buttonState?.isVisible;
  }

  setVisibility(value: boolean): void {
    this._contextMenuService.setButtonIsVisible(this.buttonDefinition.id, value);
  }
  //#endregion

  //#region WidgetDirective
  getWidgetType(): WidgetType {
    return WidgetType.ToolbarButton;
  }

  getExecutionContext(executionEvent: IExecutionEvent): Observable<IExecutionContext> {
    return this._contextService.createMenuContextButtonExecutionContext(
      this,
      this._events$,
      this._engineDataContextProvider?.getDataContext() ?? {},
      executionEvent,
    );
  }

  protected override isWidgetInitiated(): Observable<boolean> {
    return this._isInitiated$.asObservable().pipe(map(() => true));
  }
  //#endregion
}
