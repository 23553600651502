import { FormActions, FormActionTypes } from './actions';
import { IFormState, initialFormState } from './state';

export function formReducer(state = initialFormState, action: FormActions): IFormState {
  switch (action.type) {
    case FormActionTypes.LoadEntityFormDtoSuccess: {
      let formId = action.payload.form.id;
      return {
        ...state,
        forms: { ...state.forms, [formId]: action.payload.form },
      };
    }

    default:
      return state;
  }
}
