import { ModuleWithProviders, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { blobReducer } from './store/reducers';
import { FEATURE_NAME } from './store/state';
import { BlobEffects } from './store/effects';
import { UploadDialogComponent } from './upload-dialog/upload-dialog.component';
import { SharedModule } from '@shared/shared.module';

@NgModule({
  declarations: [UploadDialogComponent],
  imports: [StoreModule.forFeature(FEATURE_NAME, blobReducer), EffectsModule.forFeature([BlobEffects]), SharedModule],
  exports: [UploadDialogComponent],
})
export class BlobModule {
  static forRoot(): ModuleWithProviders<BlobModule> {
    return {
      ngModule: BlobModule,
      providers: [],
    };
  }
}
