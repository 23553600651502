import { Directive, Inject, Injector, Input, Optional, SkipSelf } from '@angular/core';
import { HtmlEditorControlDto } from '@core/services/api-clients';
import { WidgetDirective } from '@core/widgets/directives/widget.directive';
import { SCRIPT_RUNNER_SERVICE, IScriptRunnerService } from '@core/widgets/models/iscript-runner.service';
import { EngineFormControlDirective } from './engine-form-control.directive';
import { HtmlEditorControlComponent } from '@shared/reactive-controls/components/html-editor/html-editor-control.component';

@Directive({
  selector: 'app-html-editor-control[engineHtmlEditorFormControl]',
  providers: [{ provide: EngineFormControlDirective, useExisting: EngineHtmlEditorFormControlDirective }],
})
export class EngineHtmlEditorFormControlDirective extends EngineFormControlDirective {
  protected get _htmlEditorBaseControl(): HtmlEditorControlComponent {
    return this._baseControl as HtmlEditorControlComponent;
  }

  @Input() set engineHtmlEditorControlDefinition(definition: HtmlEditorControlDto) {
    this.engineControlDefinition = definition;
  }

  constructor(
    @Optional() @SkipSelf() parentWidget: WidgetDirective,
    @Inject(SCRIPT_RUNNER_SERVICE) scriptRunnerService: IScriptRunnerService,
    injector: Injector,
  ) {
    super(parentWidget, scriptRunnerService, injector);
  }
}
