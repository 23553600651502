import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { KanbanColumnAggregatorDto, KanbanColumnDto } from '@core/services/api-clients';

@Component({
  selector: 'app-kanban-column-header',
  templateUrl: './kanban-column-header.component.html',
  styleUrls: ['./kanban-column-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KanbanColumnHeaderComponent {
  @Input() kanbanColumn: KanbanColumnDto;
  @Input() aggregatorsData: { [aggregatorId: string]: number };
  @Input() isExpanded: boolean;

  @Output() isExpandedChange = new EventEmitter<boolean>();
  @Output() aggregatorSelect = new EventEmitter<KanbanColumnAggregatorDto>();
}
