import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';
import { ViewTab } from '../../../models/view.model';
import { GridSettings } from '../services/engine-view-settings.service';

@Component({
  selector: 'app-engine-view-toolbar',
  templateUrl: './engine-view-toolbar.component.html',
  styleUrls: ['./engine-view-toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EngineViewToolbarComponent implements OnInit, OnDestroy {
  private _destroy$: Subject<boolean> = new Subject<boolean>();
  globalSearchControl = new UntypedFormControl('');

  @Input() disabled = false;
  @Input() isCollapsed = false;
  @Input() settings: GridSettings;
  @Input() set globalSearch(value: string) {
    if ((value || '') != (this.globalSearchControl.value || '')) {
      this.globalSearchControl.setValue(value);
      if (value) {
        this.changeGlobalSearchVisibility(true);
      }
    }
  }
  @Input() viewTabs: ViewTab[];
  @Input() viewTabsSelectionMode: 'multi' | 'single';

  @Output() globalSearchChange = new EventEmitter<string>();
  @Output() globalSearchVisibilityChange = new EventEmitter<boolean>();
  @Output() filtersVisibilityChange = new EventEmitter<boolean>();
  @Output() viewTabsVisibilityChange = new EventEmitter<ViewTab[]>();
  @Output() refreshData = new EventEmitter<any>();
  @Output() restoreSettings = new EventEmitter<any>();
  @Output() navigateToSettings = new EventEmitter<any>();

  @ViewChild('globalSearch', { static: false }) globalSearchInput: ElementRef;
  ngOnInit() {
    this.globalSearchControl.valueChanges
      .pipe(
        takeUntil(this._destroy$),
        debounceTime(300),
        tap((value) => {
          this.globalSearchChange.emit(value);
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
  }

  changeGlobalSearchVisibility(value: boolean) {
    const globalSearchValue = this.globalSearchControl.value || '';
    if (!value && globalSearchValue != '') {
      this.globalSearchControl.setValue('');
    }
    this.globalSearchVisibilityChange.emit(value);
    if (!this.settings.isGlobalSearchEnabled) {
      setTimeout(() => {
        if (this.globalSearchInput?.nativeElement) {
          this.globalSearchInput.nativeElement.focus();
        }
      });
    }
  }

  changeFiltersVisibility(value: boolean) {
    const globalSearchValue = this.globalSearchControl.value || '';
    if (value && globalSearchValue != '') {
      this.globalSearchControl.setValue('');
    }

    this.filtersVisibilityChange.emit(value);
  }

  onRestoreSettingsClick() {
    this.globalSearchChange.emit(undefined);
    this.restoreSettings.emit();
  }

  // to prevent mat-tab focus
  stopInputPropagation(event: UIEvent): void {
    event.stopPropagation();
  }
}
