import { ChangeDetectionStrategy, Component, Input, Output, EventEmitter, InjectionToken, Inject } from '@angular/core';
import { IQueryBuilderMetadataProvider } from './query-builder.model';
import { IEngineEntity } from 'src/engine-sdk/contract/metadata';
import { Observable, of } from 'rxjs';
import { ICompositeFilterDescriptor, IEngineODataState } from 'src/engine-sdk/contract/odata/iodata-state.model';

export const QUERY_BUILDER_METADATA_PROVIDER = new InjectionToken<IQueryBuilderMetadataProvider>(
  'IQueryBuilderMetadataProvider',
);

@Component({
  selector: 'app-query-builder',
  templateUrl: './query-builder.component.html',
  styleUrls: ['./query-builder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QueryBuilderComponent {
  private _entityId: string;
  private _state: IEngineODataState = { filter: { logic: 'and', filters: [] } };

  @Input() set entityId(v: string) {
    this._entityId = v;
    this.entity$ = this._metadataProvider.getEntityMetadataAsync(this._entityId);
  }
  @Input() set state(state: IEngineODataState) {
    if (!state) return;
    this._state = state;
  }
  @Output() stateChanged = new EventEmitter<IEngineODataState>();

  entity$: Observable<IEngineEntity> = of(undefined);
  get entityId(): string {
    return this._entityId;
  }
  get state(): IEngineODataState {
    return this._state;
  }

  constructor(@Inject(QUERY_BUILDER_METADATA_PROVIDER) private _metadataProvider: IQueryBuilderMetadataProvider) {}

  onFilterChange(filter: ICompositeFilterDescriptor) {
    this._state.filter = filter;
    this.stateChanged.emit(this._state);
  }
}
