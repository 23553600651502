<div *ngIf="formControl" class="control-input-with-buttons">
  <mat-form-field class="control-input">
    <input [formControl]="formControl" (blur)="onBlur()" matInput [id]="id" [name]="name" [placeholder]="label" [ngStyle]="textAlignStyle" />
    <button type="button"
            *ngIf="!formControl.disabled"
            matSuffix
            mat-icon-button
            [appHyperlink]="formControl.value"
            [linkBehavior]="linkBehavior"
            class="action-button visible-on-edit-mode">
      <mat-icon>forward</mat-icon>
    </button>
  </mat-form-field>
  <app-control-error [control]="formControl" [offset]="-16"></app-control-error>
</div>
