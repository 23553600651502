import { Action } from '@ngrx/store';
import { CompositeFilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';
import { Params } from '@angular/router';
import { IRecordInfo } from '@core/models/irecord-info.model';

// Reprezentacja stanu widoku przechowywana w URL
// TODO-MR: Trzeba to usunąć jak całkowicie pozbędziemy się entity-view
export interface UrlState {
  skip?: number;
  take?: number;
  sort?: SortDescriptor[];
  filters?: CompositeFilterDescriptor;
  globalSearch?: string;
}

export enum RouterActionTypes {
  NavigateToHome = '[Router] Navigate To Home',
  NavigateToForm = '[Router] Navigate to form',
  NavigateToEntityView = '[Router] Navigate to entity view',
  NavigateToEntityCreateForm = '[Router] Navigate To Entity Create Form',
  SaveViewQuery = '[Router] Save view query',
  RemoveViewQuery = '[Router] Remove view query',
  AddRouteParams = '[Router] Add Route Params',
  AddQueryParams = '[Router] Add Query Params',
  RemoveQueryParams = '[Router] Remove Query Params',
  SetQueryParams = '[Router] Set Query Params',
  SetRouteParams = '[Router] Set Route Params',
  NavigateToViewSettings = '[Router] Navigate To View Settings',
  NavigateToEntityKanban = '[Router] Navigate to entity kanban',
}

export enum NavigationMode {
  SkipLocationChange,
  AsRoot,
  Append,
}

export interface NavigationConfig {
  mode: NavigationMode;
  baseNavigationPath?: string;
}

export abstract class NavigateActionBase {
  public config: NavigationConfig;
  public queryParams: Params;

  public constructor(config?: NavigationConfig, queryParams?: Params) {
    this.config = config ? config : { mode: NavigationMode.AsRoot };
    this.queryParams = queryParams;
  }
}

export class NavigateToHome implements Action {
  public readonly type = RouterActionTypes.NavigateToHome;
}

export class NavigateToForm extends NavigateActionBase implements Action {
  public readonly type = RouterActionTypes.NavigateToForm;
  public onSuccess: () => void;

  public constructor(
    public payload: { recordInfo: IRecordInfo; formId?: string },
    config?: NavigationConfig,
    queryParams?: Params,
    onSuccess?: () => void,
  ) {
    super(config, queryParams);
    this.onSuccess = onSuccess;
  }
}

export class NavigateToEntityView extends NavigateActionBase implements Action {
  public readonly type = RouterActionTypes.NavigateToEntityView;
  public constructor(
    public payload: { entityId: string; viewId: string },
    config?: NavigationConfig,
    queryParams?: Params,
  ) {
    super(config, queryParams);
  }
}

export class NavigateToEntityKanban extends NavigateActionBase implements Action {
  public readonly type = RouterActionTypes.NavigateToEntityKanban;
  public constructor(
    public payload: { entityId: string; kanbanId: string },
    config?: NavigationConfig,
    queryParams?: Params,
  ) {
    super(config, queryParams);
  }
}

export class SaveViewQuery implements Action {
  public readonly type = RouterActionTypes.SaveViewQuery;
  public constructor(public payload: { viewId: string; stateQuery: UrlState }) {}
}

export class RemoveViewQuery implements Action {
  public readonly type = RouterActionTypes.RemoveViewQuery;
  public constructor(public payload: { viewId: string }) {}
}

export class NavigateToEntityCreateForm extends NavigateActionBase implements Action {
  public readonly type = RouterActionTypes.NavigateToEntityCreateForm;
  public constructor(
    public payload: { entityId: string; formId?: string },
    config?: NavigationConfig,
    queryParams?: Params,
  ) {
    super(config, queryParams);
  }
}

export class AddRouteParams implements Action {
  public readonly type = RouterActionTypes.AddRouteParams;
  public constructor(public payload: { params: Params }) {}
}

// Dodanie query parametru do Url nie powoduje, że zmienia się state w router, ponieważ nie dochodzi do realnej renawigacji
// Aby prawidłwo pobierać taki query params ze stora lepiej skorzystać z statu w subdomenie navigation
// TODO: zastanowić się czy to nie błąd/problem
export class AddQueryParams implements Action {
  public readonly type = RouterActionTypes.AddQueryParams;
  public constructor(public payload: { queryParams: Params }) {}
}

// Jeśli queryParamsKeys jest null lub undefined to wszystki query params zostaną usunięte
export class RemoveQueryParams implements Action {
  public readonly type = RouterActionTypes.RemoveQueryParams;
  public constructor(public payload: { queryParamsKeys?: string[] }) {}
}

export class SetQueryParams implements Action {
  public readonly type = RouterActionTypes.SetQueryParams;
  public constructor(public payload: { queryParams: Params }) {}
}

export class SetRouteParams implements Action {
  public readonly type = RouterActionTypes.SetRouteParams;
  public constructor(public payload: { params: Params }) {}
}

export class NavigateToViewSettings implements Action {
  public readonly type = RouterActionTypes.NavigateToViewSettings;
  public constructor(public payload: { viewId: string }) {}
}

export type RouterActions =
  | NavigateToHome
  | NavigateToForm
  | NavigateToEntityView
  | SaveViewQuery
  | RemoveViewQuery
  | NavigateToEntityCreateForm
  | AddRouteParams
  | AddQueryParams
  | RemoveQueryParams
  | SetQueryParams
  | SetRouteParams
  | NavigateToViewSettings
  | NavigateToEntityKanban;
