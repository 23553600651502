import { Directive, Inject, Injector, Input, Optional, SkipSelf } from '@angular/core';
import { AlignStyle, OptionSetControlDto, OptionSetValueDto } from '@core/services/api-clients';
import { WidgetDirective } from '@core/widgets/directives/widget.directive';
import { IScriptRunnerService, SCRIPT_RUNNER_SERVICE } from '@core/widgets/models/iscript-runner.service';
import { SelectControlComponent } from '@shared/reactive-controls/components/select/select-control.component';
import { IOptionSetControlContext } from '../../../engine-sdk';
import { EngineFormControlDirective } from './engine-form-control.directive';

@Directive({
  selector: 'app-select-control[engineSelectFormControl]',
  providers: [{ provide: EngineFormControlDirective, useExisting: EngineSelectFormControlDirective }],
})
export class EngineSelectFormControlDirective extends EngineFormControlDirective implements IOptionSetControlContext {
  private _isFlags: boolean = false;
  private _options: OptionSetValueDto[] = [];
  protected get _selectBaseControl(): SelectControlComponent {
    return this._baseControl as SelectControlComponent;
  }

  @Input() set engineSelectControlDefinition(definition: OptionSetControlDto) {
    this.options = definition.options;
    this.isFlags = definition.isFlags;
    this.engineControlDefinition = definition;
    this.textAlignStyle = definition.textAlign;
  }
  @Input() set options(v: OptionSetValueDto[]) {
    this._options = this.getFixedOptions(v);
    this._selectBaseControl.options = this._options;
  }
  @Input() set isFlags(v: boolean) {
    this._isFlags = v; // TODO-MP: to nie jest używane
  }

  @Input() set textAlignStyle(textAlign: AlignStyle) {
    this._selectBaseControl.textAlignStyle = this.getTextAlignStyle(textAlign);
  }

  get textAlignStyle(): any {
    return this._selectBaseControl.textAlignStyle;
  }

  get options(): OptionSetValueDto[] {
    return this._selectBaseControl.options as OptionSetValueDto[];
  }
  get isFlags(): boolean {
    return this._isFlags;
  }

  constructor(
    @Optional() @SkipSelf() parentWidget: WidgetDirective,
    @Inject(SCRIPT_RUNNER_SERVICE) scriptRunnerService: IScriptRunnerService,
    injector: Injector,
  ) {
    super(parentWidget, scriptRunnerService, injector);
  }

  filterOptionsByName(names: string[]): void {
    this._selectBaseControl.options = this._options.filter((x) => names.includes(x.name));
  }

  resetOptionsFilter() {
    this.options = this._options;
  }

  // We use name as value - dk why
  private getFixedOptions(options: OptionSetValueDto[]): OptionSetValueDto[] {
    return options.map((x) => {
      return { ...x, value: x.name } as OptionSetValueDto;
    });
  }
}
