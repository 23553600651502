import { Injectable, inject } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { BehaviorSubject, Observable } from 'rxjs';

import localePl from '@angular/common/locales/pl';
import localeEn from '@angular/common/locales/en';
import localeUk from '@angular/common/locales/uk';
import { MomentService } from '@shared/moment/services/moment.service';
import { CookiesService } from '@shared/cookies/cookies.service';

export const ENGINE_LOCALE_ID = 'ENGINE_LOCALE';
export const ENGINE_CULTURE_SETTINGS_COOKIE = 'ENGINE_CULTURE_SETTINGS_COOKIE';

export interface IEngineCultureSettings {
  code: string;
  languageId: string;
  rtl?: boolean;
  currencySymbol?: string;
  currencyPattern?: string;
  numberDecimalPattern?: string;
  numberDecimalSeparator?: string;
  numberGroupSeparator?: string;
  dateTimeShortPattern?: string;
  dateTimeLongPattern?: string;
  dateShortPattern?: string;
  dateLongPattern?: string;
  timeShortPattern?: string;
  timeLongPattern?: string;
  ianaTimeZone?: string;
  dateTimeParsingPattern?: string;
}

@Injectable()
export class EngineCultureService {
  private _momentService = inject(MomentService);
  private _cookieService = inject(CookiesService);
  private _cultureSettingsBs: BehaviorSubject<IEngineCultureSettings> = new BehaviorSubject(null);
  get localeData(): any {
    const localeData = [...this.getDefaultLocaleData()];
    if (this.cultureSettings) {
      localeData[10][0] = this.getFixDateFormat(this.cultureSettings.dateShortPattern) ?? localeData[10][0];
      localeData[10][2] = this.getFixDateFormat(this.cultureSettings.dateLongPattern) ?? localeData[10][2];
      localeData[13][0] = this.getFixDateFormat(this.cultureSettings.numberDecimalSeparator) ?? localeData[13][0];
      localeData[13][1] = this.getFixDateFormat(this.cultureSettings.numberGroupSeparator) ?? localeData[13][1];
      localeData[16] = this.cultureSettings.currencySymbol ?? localeData[16];

      this.cultureSettings.dateTimeParsingPattern = this.cultureSettings.dateTimeShortPattern;
      this.cultureSettings.dateTimeParsingPattern = this.cultureSettings.dateTimeParsingPattern.replace(this.cultureSettings.dateShortPattern, this.cultureSettings.dateShortPattern.toUpperCase());
      this.cultureSettings.dateTimeParsingPattern = this.cultureSettings.dateTimeParsingPattern.replace(this.cultureSettings.timeShortPattern, this.cultureSettings.timeShortPattern.toLowerCase().replace(/h/g, 'H').replace(/tt/g, '').trim());
    }

    return localeData;
  }
  get cultureSettings(): IEngineCultureSettings {
    return this._cultureSettingsBs.value;
  }
  get cultureSettingsAsync(): Observable<IEngineCultureSettings> {
    return this._cultureSettingsBs.asObservable();
  }

  constructor() {
    let settings = this.getCultureSettingsCookie();
    this._cultureSettingsBs.next(settings);
    registerLocaleData(this.localeData, ENGINE_LOCALE_ID);
    this._momentService.setDefaultLocaleId(ENGINE_LOCALE_ID);
    this._momentService.setDefaultTimezone(this.cultureSettings?.ianaTimeZone);
  }

  changeSettings(newSettings: IEngineCultureSettings): void {
    if (this.areSettingsEqual(this.cultureSettings, newSettings)) return;
    this.saveCultureSettingsCookie(newSettings);
    window.location.reload();
  }

  private areSettingsEqual(settingsA: IEngineCultureSettings, settingsB: IEngineCultureSettings) {
    return JSON.stringify(settingsA) == JSON.stringify(settingsB);
  }

  private getFixDateFormat(format: string): string {
    if (!format) return format;

    // backend returns 'D' instead of 'd' as day char
    const searchRegExp = /D/gi;
    return format.replace(searchRegExp, 'd');
  }

  private getDefaultLocaleData(): any {
    const localeId = this.cultureSettings?.code?.length ? this.cultureSettings.code.slice(0, 2) : null;
    switch (localeId) {
      case 'pl':
        return localePl;
      case 'uk':
        return localeUk;
      case 'en':
      default:
        return localeEn;
    }
  }

  private saveCultureSettingsCookie(settings: IEngineCultureSettings): void {
    this._cookieService.set(ENGINE_CULTURE_SETTINGS_COOKIE, JSON.stringify(settings));
  }

  private getCultureSettingsCookie(): IEngineCultureSettings {
    try {
      const settings = JSON.parse(this._cookieService.get(ENGINE_CULTURE_SETTINGS_COOKIE)) as IEngineCultureSettings;
      if (!settings?.code?.length) {
        throw new Error('Culture settings retrived from cookie are invalid!');
      }
      return settings;
    } catch (_) {
      return null;
    }
  }
}
