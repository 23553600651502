import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MomentService } from './services/moment.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [MomentService],
})
export class MomentModule {}
