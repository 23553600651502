import { Pipe, PipeTransform } from '@angular/core';
import { GridColumn, OptionSetValue } from '../models/grid-definition.model';
import { LookupPipe } from './lookup.pipe';

@Pipe({
  name: 'optionSet',
})
export class OptionSetPipe implements PipeTransform {
  private _lookupPipe: LookupPipe = new LookupPipe();

  transform(dataItem: any, column: GridColumn): string {
    const value = this._lookupPipe.transform(dataItem, column);

    if (!value) {
      return '';
    }

    if (column.optionSet.isFlags) {
      let stringValues: string[] = value.split(',').map((e) => e.trim());
      return column.optionSet.values
        .filter((o) => stringValues.indexOf(o.name) >= 0)
        .map((o) => this.getLabel(o))
        .join(', ');
    }

    const option = column.optionSet.values.find((e) => e.name === value);
    return this.getLabel(option);
  }

  private getLabel(option: OptionSetValue) {
    return option ? (!option.label.startsWith('@') ? option.label : option.name) : '';
  }
}
