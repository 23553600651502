import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthenticationType } from './authentication-type.enum';

export const AUTH_SERVICE_TOKEN = new InjectionToken<IAuthService>('IAuthService');

export interface IAuthService {
  init(): void;
  getToken(): Observable<any>;
  logout(): Observable<any>;
  isUserAuthenticated(): Observable<boolean>;
  goToLoginPage(): Observable<any>;
  getAuthenticationType(): AuthenticationType;
}
