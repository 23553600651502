<ng-content select="[topbar]"></ng-content>
<mat-sidenav-container autosize>
  <mat-sidenav mode="side" disableClose="true" [opened]="isLeftPanelVisible$ | async">
    <ng-content select="[left-sidenav]"></ng-content>
  </mat-sidenav>

  <mat-sidenav
    class="right-side"
    position="end"
    fixedInViewport="true"
    mode="over"
    [opened]="isRightPanelVisible$ | async"
    (closedStart)="closeUserSidenav()">
    <ng-content select="[right-sidenav]"></ng-content>
  </mat-sidenav>

  <mat-sidenav-content>
    <ng-content select="[main-content]"></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
