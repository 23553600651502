<div mat-dialog-content>
  <div class="bannerlogo">
    <img src="assets/bannerlogo.png" />
  </div>

  <div class="product-info">
    <span class="engine-span">Engine</span> powered by ARP Ideas with <span class="heart">&#10084;</span>
  </div>

  <table class="versions">
    <tr>
      <td class="version-key">Metadata:</td>
      <td class="version-value">{{ data.metadataVersion }}</td>
    </tr>
    <tr>
      <td class="version-key">Solution:</td>
      <td class="version-value">{{ data.solutionVersion }}</td>
    </tr>
    <tr>
      <td class="version-key">Assembly:</td>
      <td class="version-value">{{ data.assemblyVersion }}</td>
    </tr>
    <tr>
      <td class="version-key">UI:</td>
      <td class="version-value">{{ data.frontendVersion }}</td>
    </tr>
  </table>
</div>

<div mat-dialog-actions align="end">
  <button mat-button (click)="close()">
    {{ 'Dialog.OkButton' | translate }}
  </button>
</div>
