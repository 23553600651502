import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ColumnComponent, FilterService } from "@progress/kendo-angular-grid";
import { FilterOperator } from "@progress/kendo-angular-grid/common/filter-operator.interface";
import { FilterDescriptor } from "@progress/kendo-data-query";
import moment from 'moment';
import { DurationService, Precision } from "../../../../../ui-components/components/duration/duration.service";


@Component({
  selector: 'app-time-filter-menu-input',
  templateUrl: './time-filter-menu-input.component.html',
  styleUrls: ['./time-filter-menu-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeFilterMenuInputComponent implements OnInit {
  placeholder: string = 'd.hh:mm:ss';

  operator: FilterOperator;
  value: string = '';
  isValueDisabled: boolean = false;
  currentFilter: FilterDescriptor;
  precision: Precision = 'hours';

  @Input() column: ColumnComponent;

  @Input() set filter(filter: FilterDescriptor) {
    if (filter && !this.currentFilter) {
      this.value = this.getTimeInputValueFromDurationIsoString(filter.value);
      this.operator = this.operators.find(o => o.value == filter.operator)
      this.disableValueBaseOnOperator();
    }
  }

  @Input() filterService: FilterService;
  @Input() operators: Array<FilterOperator>;
  @Input() set maxPrecision(maxPrecision: Precision) {
    if (maxPrecision)
      this.precision = maxPrecision;
  }

  get maxPrecision() {
    return this.precision;
  }

  @Output() valueChange = new EventEmitter<FilterDescriptor>();

  constructor(private _durationService: DurationService) {
  }

  ngOnInit() {
    if (!this.operator)
      this.operator = this.operators[0];
  }

  onOperatorsValueChange(value: FilterOperator): void {
    this.operator = value;
    this.disableValueBaseOnOperator();
    this.currentFilter = this.getValueFilter();
    this.valueChange.emit(this.currentFilter)
  }

  onValueChange(value: string): void {
    this.value = value;

    this.currentFilter = this.getValueFilter();
    this.valueChange.emit(this.currentFilter)
  }

  validateKey(event: KeyboardEvent) {
    let regex = new RegExp('^[0-9]|:|.');
    return regex.test(event.key);
  }

  private getValueFilter(): FilterDescriptor {
    let filter: FilterDescriptor;

    if (this.operator.value == 'isnull' || this.operator.value == 'isnotnull') {
      filter = {
        field: this.column.field,
        operator: this.operator.value,
      }
    } else {
      filter = {
        field: this.column.field,
        operator: this.operator.value,
        value: `duration'${this.getDurationISOString()}'`,
      }
      filter['baseType'] = 'duration';
    }

    return filter;
  }

  private getDurationISOString(): moment.Duration {
    if (!!this.value) {
      let splitedValue = this.value.split(/[.:]/);
      return moment.duration({
        days: splitedValue.length > 0 ? Number(splitedValue[0]) : 0,
        hours: splitedValue.length > 1 ? Number(splitedValue[1]) : 0,
        minutes: splitedValue.length > 2 ? Number(splitedValue[2]) : 0,
        seconds: splitedValue.length > 3 ? Number(splitedValue[3]) : 0
      });
    } else {
      return null;
    }
  }

  private getTimeInputValueFromDurationIsoString(filterValue: string): string {
    let isoDurationString;

    if (!!filterValue)
      isoDurationString = filterValue.replace(/["']|duration/g, "");

    if (isoDurationString) {
      let filterValueAsDuration = moment.duration(isoDurationString);

      let days = this._durationService.getDays(filterValueAsDuration, this.maxPrecision) ?? 0
      let hours = this._durationService.getHours(filterValueAsDuration, this.maxPrecision) ?? 0
      let minutes = this._durationService.getMinutes(filterValueAsDuration, this.maxPrecision) ?? 0
      let seconds = this._durationService.getSeconds(filterValueAsDuration, this.maxPrecision) ?? 0

      return `${days}.${hours}:${minutes}:${seconds}`;
    } else {
      return '';
    }
  }

  private disableValueBaseOnOperator(): void {
    if (this.operator.value == 'isnull' || this.operator.value == 'isnotnull') {
      this.value = '';
      this.isValueDisabled = true;
    }
    else {
      this.isValueDisabled = false;
    }
  }
}
