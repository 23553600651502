import { ActionReducerMap } from '@ngrx/store';
import { contextMenusReducer } from './reducers';
import { IContextMenuState } from './state';
import * as ContextMenusSelectors from './selectors';
import * as ContextMenusActions from './actions';

export { ContextMenusActions, ContextMenusSelectors };

export interface ContextMenusFeatureState {
  contextMenus: IContextMenuState;
}

export const reducers: ActionReducerMap<ContextMenusFeatureState> = {
  contextMenus: contextMenusReducer,
};
