import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { FilterService, SinglePopupService, PopupCloseEvent, ColumnComponent } from '@progress/kendo-angular-grid';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { closest } from '@progress/kendo-angular-common';
import { EngineCultureService } from '@core/engine-culture/services/culture.service';

@Component({
  selector: 'app-datetime-filter',
  templateUrl: './datetime-filter.component.html',
  styleUrls: ['./datetime-filter.component.scss']
})
export class DateTimeFilterComponent implements OnInit, OnDestroy {
  private _popupSubscription: Subscription;

  @Input() column: ColumnComponent;
  @Input() filter: CompositeFilterDescriptor;
  @Input() filterService: FilterService;
  @Input() useTimeZone: boolean;

  display: string = null;
  range = {
    start: null,
    end: null,
  };

  isAdvancedModeEnabled = false;

  constructor(
    private _element: ElementRef,
    private _popupService: SinglePopupService,
    private _cultureService: EngineCultureService) {
    this._popupSubscription = this._popupService.onClose.subscribe((e: PopupCloseEvent) => {
      if (document.activeElement) {
        const closestElement = closest(document.activeElement, (node: HTMLElement) => node === this._element.nativeElement || String(node.className).indexOf("date-range-filter") >= 0);
        if (closestElement
          || String(e.originalEvent.srcElement.className).indexOf("datetime-filter-button") >= 0
          || String(e.originalEvent.srcElement.parentNode.className).indexOf("datetime-filter-button") >= 0) {
          e.preventDefault();
        }
      }
    });
  }

  ngOnInit(): void {
    if (!this.filter) return;

    const lteFilter: FilterDescriptor = this.filter.filters.find((x: FilterDescriptor) => x.operator && x.operator == 'lte') as FilterDescriptor;
    const gteFilter: FilterDescriptor = this.filter.filters.find((x: FilterDescriptor) => x.operator && x.operator == 'gte') as FilterDescriptor;

    if (lteFilter) this.range.end = lteFilter.value;
    if (gteFilter) this.range.start = gteFilter.value;

    this.updateDisplayValue(this.convertToLocalZone(this.range.start), this.convertToLocalZone(this.range.end));
  }

  ngOnDestroy(): void {
    this._popupSubscription.unsubscribe();
  }

  clearFilter() {
    this.display = null;
    this.range.start = null;
    this.range.end = null;

    this.filterService.filter({
      filters: [],
      logic: 'and',
    });
  }

  selectionChange(value: any): void {
    this.range = value;

    let startDate = moment(this.range.start);
    let endDate = moment(this.range.end);

    if (startDate.isSame(endDate)) {
      this.range.end = null;
      endDate = null;
    }
  }

  onAccept(popup) {
    let start = this.convertToValidTimeZone(this.range.start);
    let end = this.convertToValidTimeZone(this.range.end);

    this.changeFilterValue(start, end);
    this.updateDisplayValue(moment(this.range.start), moment(this.range.end));

    popup.toggle();
  }

  onFilterModeChange(e: UIEvent) {
    this.isAdvancedModeEnabled = !this.isAdvancedModeEnabled;
    this.clearFilter();

    e.preventDefault();
  }

  private updateDisplayValue(startDate: moment.Moment, endDate: moment.Moment): void {
    if (startDate == null || !startDate.isValid()) {
      this.display = null;
      return;
    }

    this.display = `${startDate.format(this._cultureService.cultureSettings.dateShortPattern.toUpperCase())}`;

    if (endDate && endDate.isValid()) {
      let endDateFormat = endDate.format(this._cultureService.cultureSettings.dateShortPattern.toUpperCase());
      if (endDateFormat != this.display) this.display += ` - ${endDateFormat}`;
    }
  }

  private changeFilterValue(startDate: moment.Moment, endDate: moment.Moment): void {
    if (startDate && !startDate.isValid()) {
      this.clearFilter();
      return;
    }

    if (this.filter) {
      const lteFilter = this.filter.filters.find(
        (x: FilterDescriptor) => x && x.field == this.column.field && x.operator == 'lte',
      );
      if (lteFilter) this.filter.filters.splice(this.filter.filters.indexOf(lteFilter), 1);

      const gteFilter = this.filter.filters.find(
        (x: FilterDescriptor) => x && x.field == this.column.field && x.operator == 'gte',
      );
      if (gteFilter) this.filter.filters.splice(this.filter.filters.indexOf(gteFilter), 1);
    }

    const filter =
      this.filter ||
      <CompositeFilterDescriptor>{
        logic: 'and',
        filters: [],
      };

    if (!endDate || !endDate.isValid()) endDate = startDate.clone();

    filter.filters.push({
      field: this.column.field,
      operator: 'gte',
      value: startDate.toDate(),
    });

    filter.filters.push({
      field: this.column.field,
      operator: 'lte',
      value: endDate.add(1, 'd').subtract(1, 'ms').toDate(),
    });

    this.filterService.filter(filter);
  }

  private convertToValidTimeZone(value: Date): moment.Moment {
    // Dates which come from the data picker are in local time zone
    // So we have to convert them to valid timezones
    const valueUtc: moment.Moment = moment(moment(value)
      .add(-1 * value.getTimezoneOffset(), 'minutes')
      .utc()
      .format(moment.HTML5_FMT.DATETIME_LOCAL_MS));

    if (!this.useTimeZone) {
      return valueUtc;
    }

    return moment(moment(valueUtc)
      .add(new Date().getTimezoneOffset(), 'minutes')
      .format(moment.HTML5_FMT.DATETIME_LOCAL_MS));
  }

  private convertToLocalZone(value: Date): moment.Moment {
    return value != null
      ? moment(value).add(this.useTimeZone ? -new Date().getTimezoneOffset() : 0, 'minutes')
      : null;
  }
}
