<div mat-dialog-title>
  <mat-icon class="dialog-icon mat-icon" [ngClass]="fontIcon">{{ fontIcon }}</mat-icon>
  <span class="title-container">{{ data.title }}</span>
</div>
<div mat-dialog-content>
  {{ data.message }}
</div>
<br />
<ng-container *ngIf="data.additionalInfo">
  <div mat-dialog-content class="mat-caption" *ngFor="let info of data.additionalInfo">
    <b>{{ info }}</b>
  </div>
</ng-container>

<div mat-dialog-actions align="end">
  <ng-container *ngIf="data.buttons">
    <button mat-button *ngFor="let button of data.buttons" (click)="onButtonClick(button)" [id]="getButtonId(button.label)">
      {{ button.label }}
    </button>
  </ng-container>
</div>
