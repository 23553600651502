import { ActionReducerMap } from '@ngrx/store';
import { processReducer } from './reducers';
import { IProcessState } from './state';

export interface ProcessFeatureState {
  process: IProcessState;
}

export const reducers: ActionReducerMap<ProcessFeatureState> = {
  process: processReducer,
};
