import { Directive, Input, OnDestroy } from '@angular/core';
import { IControlContext, IFormSectionContext, ITabContext } from '../../../engine-sdk';
import { FormTabsComponent } from '../components/engine-form/form-tabs/form-tabs.component';

@Directive({ selector: '[engineFormTab]' })
export class EngineFormTabDirective implements OnDestroy, ITabContext {
  private _sections: IFormSectionContext[] = [];

  @Input() id: string;
  @Input() name: string;

  get isVisible(): boolean {
    return this._tabsGroup.getTabByName(this.name).isVisible;
  }
  set isVisible(v: boolean) {
    this._tabsGroup.setTabVisibility(this.name, v);
  }

  constructor(private _tabsGroup: FormTabsComponent) {
    this._tabsGroup.registerTab(this);
  }

  ngOnDestroy() {
    this._tabsGroup.unregisterTab(this);
  }

  registerSection(section: IFormSectionContext) {
    this._sections.push(section);
  }

  unregisterSection(section: IFormSectionContext) {
    this._sections = this._sections.filter((s) => s != section);
  }

  getSections(): IFormSectionContext[] {
    return this._sections;
  }

  getSectionByName(name: string): IFormSectionContext {
    return this._sections.find((s) => s.name == name);
  }

  getControls(): IControlContext[] {
    return this.getSections().flatMap((s) => s.getControls());
  }
}
