<div
  class="block"
  [ngClass]="getClasses()"
  (mouseover)="hovered = true"
  (mouseout)="hovered = false"
  (click)="onClick.emit(item)">

  <ng-content select="[breadcrumb-item-prefix]"></ng-content>
  <ng-container *ngIf="item">
    {{ item.label }}
  </ng-container>

</div>
<div
  *ngIf="item || level === 0"
  class="arrow"
  (mouseover)="hovered = true"
  (mouseout)="hovered = false"
  (click)="onClick.emit(item)"
  [ngClass]="getClasses()" >
  <mat-icon>chevron_right</mat-icon>
</div>
