import { Injector } from '@angular/core';
import { CancellationToken } from '@core/services/context.service';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { IFormExecutionContext, IExecutionEvent, ISavingExecutionEvent } from 'src/engine-sdk';
import { EventType } from '../services/api-clients';
import { ExecutionContext } from './execution-context';

export class FormExecutionContext extends ExecutionContext implements IFormExecutionContext {
  private _events: Observable<IExecutionEvent>;

  constructor(
    injector: Injector,
    events: Observable<IExecutionEvent>,
    cancellationToken: CancellationToken,
    executionEvent?: IExecutionEvent,
    dialogId?: string,
  ) {
    super(injector, cancellationToken, executionEvent, dialogId);
    this._events = events;
  }

  saving(): Observable<ISavingExecutionEvent> {
    return this._events.pipe(
      filter((e) => e.eventType === EventType.OnSaving),
      map((e) => e as SavingExecutionEvent),
    );
  }
}

export class SavingExecutionEvent implements ISavingExecutionEvent {
  private _postScriptCallback: () => void;
  isSuppressed: boolean = false;
  eventType: EventType;
  data?: any;

  constructor(postScriptCallback: () => void, data?: any) {
    this.eventType = EventType.OnSaving;
    this._postScriptCallback = postScriptCallback;
    this.data = data;
  }

  suppressSaving() {
    this.isSuppressed = true;
  }

  saveAsync() {
    if (!this.isSuppressed) {
      this._postScriptCallback();
    }
  }
}
