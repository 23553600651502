import { Action } from '@ngrx/store';
import { EntityViewDto, ViewMainSelectorVisibilityDto, ViewTargets } from '@core/services/api-clients';
import { GridSettings } from '../components/engine-view/services/engine-view-settings.service';
import { GridColumnSettings } from '@shared/grid/models/grid-definition.model';

export enum ViewActionTypes {
  ReloadView = '[View] Reload View',
  LoadEntityView = '[View] Load Entity View',
  LoadEntityViewSuccess = '[View] Load Entity View Success',

  // Grid settings manipulation from/with localStorage
  LoadGridSettingsFromLocalStorage = '[View] Load Grid Settings From Local Storage',
  SaveGridSettingsToState = '[View] Save Grid Settings To State',
  SaveGridColumnSettings = '[View] Save Grid Settings',
  SaveGridToolbarSettings = '[View] Save Grid Toolbar Settings',
  RestoreGridSettings = '[View] Restore Grid Settings',

  SetUserViewPageSize = '[View] Set User View Page Size',
  SetUserViewPageSizeSuccess = '[View] Set User View Page Size Success',

  LoadTabsVisibilitySettings = '[View] Load Tabs Visibility Settings',
  SaveTabsVisibilitySettings = '[View] Save Views Visibility Settings',
  SaveTabsVisibilitySettingsToState = '[View] Save Tabs Visibility Settings To State',
}

export class ReloadView implements Action {
  public readonly type = ViewActionTypes.ReloadView;
  constructor(public payload: { viewId: string }) {}
}

export class LoadEntityView implements Action {
  public readonly type = ViewActionTypes.LoadEntityView;
  constructor(public payload: { viewId: string; viewTarget: ViewTargets; overwrittenPageSize?: number }) {}
}

export class LoadEntityViewSuccess implements Action {
  public readonly type = ViewActionTypes.LoadEntityViewSuccess;
  public constructor(public payload: { view: EntityViewDto }) {}
}

export class SetUserViewPageSize implements Action {
  public readonly type = ViewActionTypes.SetUserViewPageSize;
  public constructor(public payload: { viewId: string; pageSize: number }) {}
}

export class SetUserViewPageSizeSuccess implements Action {
  public readonly type = ViewActionTypes.SetUserViewPageSizeSuccess;
  public constructor(public payload: { viewId: string; pageSize: number }) {}
}

export class LoadGridSettingsFromLocalStorage implements Action {
  public readonly type = ViewActionTypes.LoadGridSettingsFromLocalStorage;
  public constructor(public payload: { viewOrSubgridId: string }) {}
}
export class SaveGridSettingsToState implements Action {
  public readonly type = ViewActionTypes.SaveGridSettingsToState;
  public constructor(public payload: { viewOrSubgridId: string; settings: GridSettings }) {}
}
export class SaveGridColumnSettings implements Action {
  public readonly type = ViewActionTypes.SaveGridColumnSettings;
  public constructor(public payload: { viewOrSubgridId: string; settings: GridColumnSettings }) {}
}
export class SaveGridToolbarSettings implements Action {
  public readonly type = ViewActionTypes.SaveGridToolbarSettings;
  public constructor(
    public payload: {
      viewOrSubgridId: string;
      settings: { areFiltersEnabled?: boolean; isGlobalSearchEnabled?: boolean };
    },
  ) {}
}
export class RestoreGridSettings implements Action {
  public readonly type = ViewActionTypes.RestoreGridSettings;
  public constructor(public payload: { viewOrSubgridId: string }) {}
}

export class LoadTabsVisibilitySettings implements Action {
  public readonly type = ViewActionTypes.LoadTabsVisibilitySettings;
  public constructor(public payload: { entityId: string }) {}
}
export class SaveTabsVisibilitySettings implements Action {
  public readonly type = ViewActionTypes.SaveTabsVisibilitySettings;
  public constructor(
    public payload: {
      entityId: string;
      tabsVisibilitySettings: ViewMainSelectorVisibilityDto[];
    },
  ) {}
}
export class SaveTabsVisibilitySettingsToState implements Action {
  public readonly type = ViewActionTypes.SaveTabsVisibilitySettingsToState;
  public constructor(
    public payload: {
      entityId: string;
      tabsVisibilitySettings: ViewMainSelectorVisibilityDto[];
    },
  ) {}
}

export type ViewActions =
  | ReloadView
  | LoadEntityView
  | LoadEntityViewSuccess
  | SetUserViewPageSize
  | SetUserViewPageSizeSuccess
  | SaveGridColumnSettings
  | RestoreGridSettings
  | SaveGridToolbarSettings
  | LoadGridSettingsFromLocalStorage
  | SaveGridSettingsToState
  | LoadTabsVisibilitySettings
  | SaveTabsVisibilitySettings
  | SaveTabsVisibilitySettingsToState;
