<mat-toolbar>
  <span>{{ 'UserMenu.Header' | translate }}</span>
  <button type="button" mat-icon-button color="primary" (click)="closeUserSidenav()">
    <mat-icon>close</mat-icon>
  </button>
</mat-toolbar>

<div class="display-flex" *ngIf="currentUser$ | async as currentUser">
  <div class="photo-container">
    <app-user-avatar [user]="currentUser" [size]="75"> </app-user-avatar>
  </div>
  <h3 class="user-title">{{ currentUser?.displayName }}</h3>
  <h5 class="user-email">{{ currentUser?.email }}</h5>
</div>
<ng-content select="[additional-content]"></ng-content>
