import { Injectable, InjectionToken } from '@angular/core';
import { ColumnResizeArgs } from '@progress/kendo-angular-grid';
import { GridComponent } from '../grid.component';
import { GridColumn } from '../models/grid-definition.model';
import { ColumnResizeEvent } from '../models/grid-events.model';

export const GRID_RESIZING_SERVICE = new InjectionToken<IGridResizingService>('IGridResizingService');

export interface IGridResizingService {
  columnWidths: { [columnId: string]: number };
  isFreezable: boolean;

  registerGrid(gridComponent: GridComponent);

  onColumnResize(columnResizeArgs: ColumnResizeArgs[]);
  syncColumnWidths();
}

@Injectable()
export class GridResizingService implements IGridResizingService {
  static readonly MIN_COLUMN_WIDTH = 1;

  private _grid: GridComponent;

  private get gridDefinition() {
    return this._grid.gridDefinition;
  }

  private get kendoGrid() {
    return this._grid.kendoGrid;
  }

  columnWidths: { [columnId: string]: number } = {};
  isFreezable: boolean = false;

  constructor() {}

  registerGrid(grid: GridComponent) {
    this._grid = grid;
  }

  onColumnResize(columnResizeArgs: ColumnResizeArgs[]) {
    for (const columnResize of columnResizeArgs) {
      if (columnResize.oldWidth !== columnResize.newWidth) {
        this.columnWidths[<string>columnResize.column.cssClass] = columnResize.newWidth;

        this._grid.columnResize.emit(<ColumnResizeEvent>{
          column: this.gridDefinition.columns.find((x) => x.columnId == columnResize.column.cssClass),
          oldWidth: columnResize.oldWidth,
          newWidth: columnResize.newWidth,
        });
      }
    }
  }

  syncColumnWidths() {
    if (this._grid == null || this.kendoGrid == null) return;

    this.setCheckboxColumnWidth();
    this.syncCustomColumnWidth();
    this.resizeColumnsAdjustedToText();
    this.columnWidths['mobile-column'] = this.kendoGrid.header.nativeElement.clientWidth - 40;

    for (let column of this.gridDefinition.columns) {
      this.columnWidths[column.columnId] = this.getColumnWidth(column);

      const kendoColumn = this.getKendoColumn(column.columnId);
      if (kendoColumn != null && kendoColumn.width !== this.columnWidths[column.columnId]) {
        kendoColumn.width = this.columnWidths[column.columnId];
      }
    }

    this.isFreezable =
      this.kendoGrid != null &&
      this.kendoGrid.columns.toArray().every((x) => Number.isInteger(x.width) && x.width != 0);
  }

  private setCheckboxColumnWidth() {
    const checkboxColumn = this.getKendoColumn('checkbox-column');
    if (checkboxColumn) {
      checkboxColumn.width = this.gridDefinition?.selection?.settings?.enabled ? 40 : 1;
    }
  }

  private syncCustomColumnWidth() {
    const customColumn = this.getKendoColumn('custom-column');
    if (customColumn != null) {
      const customColumnCells: NodeList = this.kendoGrid.wrapper.nativeElement.querySelectorAll('td.custom-column');

      let maxWidth = 0;
      customColumnCells.forEach((cell) => {
        const cellWidth = (cell.firstChild.firstChild as HTMLElement).clientWidth;
        if (cellWidth > maxWidth) maxWidth = cellWidth;
      });

      this.columnWidths['custom-column'] = maxWidth ? maxWidth + 10 : 1;
      if (this.columnWidths['custom-column'] !== customColumn.width) {
        customColumn.width = this.columnWidths['custom-column'];
        this._grid.isCustomColumnTemplateHidden = customColumn.width == 1 ? true : false;
      }
    }
  }

  private getColumnWidth(column: GridColumn) {
    const columnSetting = this.gridDefinition.columnsSettings?.[column.columnId];
    if (columnSetting != null && columnSetting.width != null) {
      return columnSetting.width;
    }

    return this.calculateColumnWidth(column);
  }

  private calculateColumnWidth(column: GridColumn) {
    const widthAdjuster = (width) => Math.max(GridResizingService.MIN_COLUMN_WIDTH, Math.floor(width));
    const containerWidth = this.kendoGrid.header.nativeElement.clientWidth;

    switch (column.widthType) {
      case 'pixel':
        return widthAdjuster(column.width);
      case 'percentage':
        return widthAdjuster((column.width * containerWidth) / 100);
      case 'adjustToText':
        const kendoColumn = this.getKendoColumn(column.columnId);
        return kendoColumn != null && Number.isInteger(kendoColumn.width)
          ? kendoColumn.width
          : GridResizingService.MIN_COLUMN_WIDTH;
      case 'adjustToSpace':
      default:
        const usedSize = this.getUsedSize();
        const freeSize = Math.max(containerWidth - usedSize, 0);

        const columnsAdjustedToSpace = this.getColumnsNotResizedByUser().filter(
          (x) => x.widthType == 'adjustToSpace' || x.widthType == null,
        );
        if (columnsAdjustedToSpace.length == 0) return GridResizingService.MIN_COLUMN_WIDTH;

        return widthAdjuster(freeSize / columnsAdjustedToSpace.length);
    }
  }

  private getKendoColumns(columnIds: string[]) {
    return this.kendoGrid != null
      ? this.kendoGrid.columns.filter((x) => columnIds.indexOf(x.cssClass?.toString()) > -1)
      : [];
  }

  private getKendoColumn(columnId: string) {
    return this.kendoGrid != null ? this.kendoGrid.columns.find((x) => x.cssClass?.toString() == columnId) : null;
  }

  private getUsedSize() {
    const columnsResizedByUser = this.getColumnIdsResizedByUser();
    const columnsWithKnownSize = this.gridDefinition.columns.filter(
      (x) => columnsResizedByUser.indexOf(x.columnId) >= 0 || (x.widthType != 'adjustToSpace' && x.widthType != null),
    );

    return this.getKendoColumns(columnsWithKnownSize.map((x) => x.columnId))
      .map((x) => x.width)
      .reduce((a, b) => a + b, 0);
  }

  private getColumnIdsResizedByUser() {
    return this.gridDefinition.columnsSettings ? Object.keys(this.gridDefinition.columnsSettings) : [];
  }

  private getColumnsNotResizedByUser() {
    const columnsResizedByUser = this.getColumnIdsResizedByUser();
    return columnsResizedByUser.length > 0
      ? this.gridDefinition.columns.filter((x) => columnsResizedByUser.indexOf(x.columnId) === -1)
      : this.gridDefinition.columns;
  }

  private resizeColumnsAdjustedToText() {
    const columnsNotResizedByUser = this.getColumnsNotResizedByUser();
    const columnsAdjustedToText = columnsNotResizedByUser.filter((x) => x.widthType == 'adjustToText');
    const kendoColumns = this.getKendoColumns(columnsAdjustedToText.map((x) => x.columnId));

    if (kendoColumns.length !== 0) {
      this.kendoGrid.autoFitColumns(kendoColumns);
      kendoColumns.forEach((x) => {
        this.columnWidths[<string>x.cssClass] = x.width;
      });
    }
  }
}
