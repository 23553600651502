<ng-container *ngIf="isWide">
  <button type="button" *ngIf="menuConfiguration?.useFavoriteMenuItems" mat-icon-button (click)="favoriteButtonClick.emit()">
    <ng-container *ngIf="isAddFavoriteEnabled; else notFavorite">
      <mat-icon [matTooltip]="'Menu.ToggleOffAddingToFavorites' | translate"> favorite </mat-icon>
    </ng-container>
    <ng-template #notFavorite>
      <mat-icon [matTooltip]="'Menu.ToggleOnAddingToFavorites' | translate"> favorite_border </mat-icon>
    </ng-template>
  </button>
</ng-container>
