<ng-container *ngIf="formData$ | async as formData">
  <div class="sticky">
    <app-main-context-menu *ngIf="formData.definition && formData.dataItem"></app-main-context-menu>
    <app-main-notification-bar *ngIf="formData.recordInfo"></app-main-notification-bar>
  </div>
  <engine-processes-container
      *ngIf="formData.dataItem"
      [dataItem]="formData.dataItem"
      [recordInfo]="formData?.definition?.recordInfo">
  </engine-processes-container>
  <engine-form
    *ngIf="formData.definition && formData.dataItem"
    [definition]="formData.definition"
    [dataItem]="formData.dataItem"
    [selectedTabIndex]="formData.selectedTabIndex"
    [widgetScripts]="widgetScripts"
    [widgetId]="formData.definition.id"
    [notificationGroups]="notificationGroups$ | async"
    [notificationGroupName]="formData.definition.recordInfo.entityName"
    [isCompactMode]="isCompactMode$ | async"
    (initiated)="onFormInitiated($event, formData.args)"
    (selectedTabChange)="onSelectedTabChange($event)"
    (isDirtyChange)="onIsDirtyChanged($event)"></engine-form>
  <engine-async-widget [delay]="100" [isReady]="true"></engine-async-widget>
</ng-container>
