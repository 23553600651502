import { Component, forwardRef, Inject, Optional, SkipSelf, ViewChild, Input } from '@angular/core';
import { WidgetDirective } from '@core/widgets/directives/widget.directive';
import { NoEventWidgetDirective } from '@core/widgets/directives/no-event-widget.directive';
import { IScriptRunnerService, SCRIPT_RUNNER_SERVICE } from '@core/widgets/models/iscript-runner.service';
import { select, Store } from '@ngrx/store';
import { IViewState } from '../../store/state';
import {
  ColumnResizeEvent,
  LookupColumnClickEvent,
  RowDoubleClickEvent,
  StateChangeEvent,
  ViewTab,
} from '../../models/view.model';
import {
  LoadEntityView,
  LoadGridSettingsFromLocalStorage,
  RestoreGridSettings,
  SaveGridColumnSettings,
  SaveGridToolbarSettings,
} from '../../store/actions';
import { ContextMenuDto, EntityViewDto, ViewTargets } from '../../../../core/services/api-clients';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { getEntityView, getGridSettings } from '../../store/selectors';
import { GridSettings } from '../engine-view/services/engine-view-settings.service';
import {
  EngineViewStateService,
  ENGINE_VIEW_STATE_SERVICE,
  UrlState,
} from '../engine-view/services/engine-view-state.service';
import { EngineViewComponent } from '../engine-view/engine-view.component';
import { IEngineODataState } from 'src/engine-sdk/contract/odata/iodata-state.model';
import { RouterActions } from '@core/router/store';
import { LoadSubgridContextMenu, LoadSubgridGridColumnContextMenu } from '@core/context-menu/store/actions';
import { getSubgridContextMenu, getSubgridColumnContextMenu } from '@core/context-menu/store/selectors';
import { FormActions } from '@core/engine-forms/store';
import { NavigateByRouteData } from '@core/navigation/store/actions';
import {
  ENGINE_DATA_CONTEXT_PROVIDER,
  IEngineDataContext,
  IEngineDataContextProvider,
} from 'src/engine-sdk/contract/engine-data-context';
import { getValueByAttributePath } from '../../../../shared/utils';
import { NavigationSelectors } from '@core/navigation/store';
import { ContextMenuComponent } from '@core/context-menu/context-menu/context-menu.component';
import { ViewActions } from '../../store';

export interface SubgridParams {
  id?: string;
  viewId?: string;
  pageSize?: number;
  displayRows?: number;
}

export interface EntityViewWithTabs {
  entityView: EntityViewDto;
  viewTabs: ViewTab[];
}

@Component({
  selector: 'app-subgrid-view',
  templateUrl: './subgrid-view.component.html',
  styleUrls: ['./subgrid-view.component.scss'],
  providers: [
    {
      provide: WidgetDirective,
      useExisting: forwardRef(() => SubgridViewComponent),
    },
    {
      provide: ENGINE_VIEW_STATE_SERVICE,
      useClass: EngineViewStateService,
    },
    {
      provide: ENGINE_DATA_CONTEXT_PROVIDER,
      useExisting: forwardRef(() => SubgridViewComponent),
    },
  ],
})
export class SubgridViewComponent extends NoEventWidgetDirective implements IEngineDataContextProvider {
  private _subgridParams: SubgridParams;

  get subgridParams() {
    return this._subgridParams;
  }
  @Input() set subgridParams(value: SubgridParams) {
    if (this._subgridParams != value) {
      const isChanged = this._subgridParams?.id != value?.id || this._subgridParams?.viewId != value?.viewId;
      this._subgridParams = value;

      if (value && isChanged) {
        this.initialize();
      }
    }
  }

  @Input() set systemQuery(query: IEngineODataState) {
    this.stateService.setSystemQuery(query);
  }

  @Input() isReadOnly: boolean = true;
  @Input() isDataFetchingEnabled = true;

  entityViewWithTabs$: Observable<EntityViewWithTabs>;
  gridContextMenu$: Observable<ContextMenuDto>;
  gridColumnContextMenu$: Observable<ContextMenuDto>;
  urlState$: Observable<UrlState>;
  settings$: Observable<GridSettings>;
  isToolbarCollapsed$ = new BehaviorSubject(true);

  @ViewChild(EngineViewComponent, { static: false }) engineViewComponent: EngineViewComponent;
  @ViewChild(ContextMenuComponent, { static: false }) contextMenuComponent: ContextMenuComponent;

  constructor(
    @Optional() @SkipSelf() parentWidget: WidgetDirective,
    @Inject(SCRIPT_RUNNER_SERVICE) scriptRunnerService: IScriptRunnerService,
    @Inject(ENGINE_VIEW_STATE_SERVICE) public stateService: EngineViewStateService,
    @Optional()
    @SkipSelf()
    @Inject(ENGINE_DATA_CONTEXT_PROVIDER)
    private _engineDataContextProvider: IEngineDataContextProvider,
    private _store: Store<IViewState>,
  ) {
    super(parentWidget, scriptRunnerService);
  }

  // TODO-MP: we can remove subgridId from dataContext but it's breaking change from the point of sdk
  //          so we have to inform customizators first
  getDataContext(): IEngineDataContext {
    const parentDataContext = this._engineDataContextProvider?.getDataContext() ?? {};
    return { ...parentDataContext, subgridId: this.subgridParams.id };
  }

  // handlers

  onRowDoubleClick(event: RowDoubleClickEvent) {
    this._store.dispatch(
      new NavigateByRouteData({
        routeData: {
          entityId: event.entityId,
          formId: event.defaultFormId,
          recordId: event.dataItem.Id,
        },
        popCount: 0,
      }),
    );
  }

  onLookupColumnClick(event: LookupColumnClickEvent) {
    this._store.dispatch(
      new NavigateByRouteData({
        routeData: {
          entityId: event.column.relatedEntity.entityId,
          formId: event.column.relatedEntity.defaultFormId,
          recordId: this.getRecordIdByAttributeName(event.dataItem, event.attributeName),
        },
        popCount: 0,
      }),
    );
  }

  onColumnResize(event: ColumnResizeEvent) {
    this._store.dispatch(
      new SaveGridColumnSettings({
        viewOrSubgridId: this.getUniqueId(),
        settings: event,
      }),
    );
  }

  onStateChange(event: StateChangeEvent) {
    this._store.dispatch(
      new RouterActions.SaveViewQuery({
        viewId: this.subgridParams.id,
        stateQuery: event.urlState,
      }),
    );
  }

  onResize(width: number) {
    this.isToolbarCollapsed$.next(width < 1050);
  }

  // Toolbar buttons handlers

  onGlobalSearchChange(globalSearch: string) {
    this.engineViewComponent.stateService.setGlobalSearch(globalSearch);
    this.engineViewComponent.refreshData();
    this.onStateChange({
      viewId: this.engineViewComponent.getViewId(),
      urlState: this.engineViewComponent.stateService.getViewState().urlState,
    });
  }

  onGlobalSearchVisibilityChange(value: boolean) {
    this.resetQuery();

    this._store.dispatch(
      new SaveGridToolbarSettings({
        viewOrSubgridId: this.getUniqueId(),
        settings: { isGlobalSearchEnabled: value, areFiltersEnabled: value ? false : undefined },
      }),
    );
  }

  onFiltersVisibilityChange(value: boolean) {
    this.resetQuery();

    this._store.dispatch(
      new SaveGridToolbarSettings({
        viewOrSubgridId: this.getUniqueId(),
        settings: { areFiltersEnabled: value, isGlobalSearchEnabled: value ? false : undefined },
      }),
    );
  }

  onViewTabsVisibilityChange(viewTabs: ViewTab[]) {
    const selectedViewTab = viewTabs.find((x) => x.isVisible);
    if (selectedViewTab.viewId !== this.subgridParams.viewId) {
      this.subgridParams = {
        ...this.subgridParams,
        viewId: selectedViewTab.viewId,
      };

      this._store.dispatch(
        new FormActions.SaveSubGridSelectedView({
          subgridId: this.subgridParams.id,
          viewId: this.subgridParams.viewId,
        }),
      );
    }
  }

  onRefreshData() {
    this.engineViewComponent.refreshData();
  }

  onRestoreSettings() {
    this._store.dispatch(new RestoreGridSettings({ viewOrSubgridId: this.getUniqueId() }));
  }

  onNavigateToSettings() {
    this._store.dispatch(new RouterActions.NavigateToViewSettings({ viewId: this.engineViewComponent.getViewId() }));
  }

  onPageSizeChange(pageSize: number) {
    this._store.dispatch(
      new ViewActions.SetUserViewPageSize({
        viewId: this.engineViewComponent.getViewId(),
        pageSize: pageSize,
      }),
    );
  }

  // private methods

  initialize() {
    this._store.dispatch(
      new LoadEntityView({
        viewId: this.subgridParams.viewId,
        viewTarget: ViewTargets.Subgrid,
        overwrittenPageSize: this.subgridParams.pageSize,
      }),
    );
    this._store.dispatch(
      new LoadSubgridContextMenu({ subgridId: this.subgridParams.id, viewId: this.subgridParams.viewId }),
    );
    this._store.dispatch(
      new LoadSubgridGridColumnContextMenu({ subgridId: this.subgridParams.id, viewId: this.subgridParams.viewId }),
    );

    this._store.dispatch(new LoadGridSettingsFromLocalStorage({ viewOrSubgridId: this.getUniqueId() }));

    this.entityViewWithTabs$ = this._store.pipe(
      select(getEntityView(this.subgridParams.viewId)),
      map((entityView) => {
        if (entityView == null) return null;

        const viewTabs = entityView.simpleViews.filter(x => (x.viewTargets & ViewTargets.Subgrid) == ViewTargets.Subgrid).map((x) => {
          return <ViewTab>{
            viewId: x.id,
            label: x.label,
            isVisible: this.subgridParams.viewId === x.id,
          };
        });

        if (viewTabs.find((x) => x.isVisible) == null) {
          viewTabs[0].isVisible = true;
        }

        return <EntityViewWithTabs>{
          entityView: entityView,
          viewTabs: viewTabs,
        };
      }),
    );

    this.gridContextMenu$ = this._store.select(getSubgridContextMenu(this.subgridParams.id));
    this.gridColumnContextMenu$ = this._store.select(getSubgridColumnContextMenu(this.subgridParams.id));
    this.urlState$ = this._store.select(NavigationSelectors.getSubGridQueryParams(this.subgridParams.id));
    this.settings$ = this._store.select(getGridSettings(this.getUniqueId()));
  }

  private getRecordIdByAttributeName(dataItem, attributeName) {
    const path = attributeName.split('.').slice(0, -1).join('.');
    return getValueByAttributePath(dataItem, path).Id;
  }

  private getUniqueId() {
    return `${this.subgridParams.id}${this.subgridParams.viewId}`;
  }

  private resetQuery() {
    if (this.engineViewComponent.stateService.resetQuery()) {
      this.engineViewComponent.refreshData();
      this.onStateChange({
        viewId: this.engineViewComponent.getViewId(),
        urlState: this.engineViewComponent.stateService.getViewState().urlState,
      });
    }
  }
}
