import { Injectable } from '@angular/core';
import { UserProfileDto } from '@core/services/api-clients';

export const DEFAULT_COLOR: string = '#bbbbbb';

@Injectable({
  providedIn: 'root',
})
export class UserColorService {
  getUserColor(user: UserProfileDto): string {
    let color = user.userId ? `#${user.userId.substring(0, 6)}` : DEFAULT_COLOR;
    if (this.getBrightness(color) > 200) {
      color = DEFAULT_COLOR;
    }
    return color;
  }

  private getBrightness(color): number {
    const hex = color.replace('#', '');
    const c_r = parseInt(hex.substr(0, 2), 16);
    const c_g = parseInt(hex.substr(2, 2), 16);
    const c_b = parseInt(hex.substr(4, 2), 16);
    const brightness = 0.2126 * c_r + 0.7152 * c_g + 0.0722 * c_b;
    return brightness;
  }
}
