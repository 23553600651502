import { ChangeDetectionStrategy, Component, forwardRef, Inject, Injector, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { HyperlinkBehavior } from '@shared/hyperlink/hyperlink.model';
import { BaseFormControlDirective } from '../../directives/base-form-control.directive';
import { CULTURE_SERVICE, ICultureService } from '../../models/iculture-service.model';

@Component({
  selector: 'app-hyperlink-control',
  templateUrl: './hyperlink-control.component.html',
  styleUrls: ['./hyperlink-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => HyperlinkControlComponent),
      multi: true,
    },
    {
      provide: BaseFormControlDirective,
      useExisting: HyperlinkControlComponent,
    },
  ],
})
export class HyperlinkControlComponent extends BaseFormControlDirective {
  @Input() linkBehavior: HyperlinkBehavior;

  @Input() textAlignStyle: any;

  constructor(@Inject(CULTURE_SERVICE) cultureService: ICultureService, injector: Injector) {
    super(cultureService, injector);
  }
}
