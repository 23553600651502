import { IUserInteractionContext, UserInteractionEventType } from './iuser-interaction-context.model';

export class UserInteractionContexts {
  static readonly NOT_EMPTY_CONTEXT: string = '$ANY';
  static readonly EMPTY_CONTEXT: string = '$EMPTY';

  static isFormStateChanged(formName?: string, entityName?: string): IUserInteractionContext {
    return {
      formName: formName ?? UserInteractionContexts.NOT_EMPTY_CONTEXT,
      entityName: entityName ?? UserInteractionContexts.NOT_EMPTY_CONTEXT,
      eventType: UserInteractionEventType.formStateChanged,
    };
  }
  static isFormLoaded(formName?: string, entityName?: string): IUserInteractionContext {
    return {
      formName: formName ?? UserInteractionContexts.NOT_EMPTY_CONTEXT,
      entityName: entityName ?? UserInteractionContexts.NOT_EMPTY_CONTEXT,
      eventType: UserInteractionEventType.formLoaded,
    };
  }
  static isViewItemSelected(viewName?: string, viewEntityName?: string): IUserInteractionContext {
    return {
      viewEntityName: viewEntityName ?? UserInteractionContexts.NOT_EMPTY_CONTEXT,
      viewName: viewName ?? UserInteractionContexts.NOT_EMPTY_CONTEXT,
      subgridId: UserInteractionContexts.EMPTY_CONTEXT,
      eventType: UserInteractionEventType.viewItemSelected,
    };
  }
  static isSubgridItemSelected(
    subgridId?: string,
    viewName?: string,
    viewEntityName?: string,
  ): IUserInteractionContext {
    return {
      ...UserInteractionContexts.isViewItemSelected(viewName, viewEntityName),
      subgridId: subgridId ?? UserInteractionContexts.NOT_EMPTY_CONTEXT,
    };
  }
}
