import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of, EMPTY } from 'rxjs';
import { withLatestFrom, switchMap, catchError, map, mergeMap } from 'rxjs/operators';
import { ToolbarType, ContextMenuClient } from '@core/services/api-clients';
import {
  LoadMainContextMenu,
  LoadMainContextMenuSuccess,
  LoadGridColumnContextMenu,
  LoadGridColumnContextMenuSuccess,
  LoadSubgridGridColumnContextMenu,
  LoadSubgridGridColumnContextMenuSuccess,
  LoadSubgridContextMenu,
  LoadSubgridContextMenuSuccess,
  LoadDialogFormContextMenu,
  LoadDialogFormContextMenuSuccess,
  ContextMenusActionTypes,
} from './actions';
import { HandleError } from '@core/errors/store/actions';
import { NavigationSelectors } from '@core/navigation/store';

@Injectable()
export class ContextMenusEffects {
  loadMainContextMenu$ = createEffect(() =>
    this._actions$.pipe(
      ofType<LoadMainContextMenu>(ContextMenusActionTypes.LoadMainContextMenu),
      withLatestFrom(this._store.select(NavigationSelectors.getCurrentNavigationPath)),
      switchMap(([_, path]) =>
        this._contextMenuClient.getContextMenu(path.split('_').slice(-1).pop(), ToolbarType.Main).pipe(
          catchError((error) => {
            this._store.dispatch(new HandleError({ error }));
            return EMPTY;
          }),
        ),
      ),
      switchMap((mainContextMenu) => {
        if (mainContextMenu) return of(new LoadMainContextMenuSuccess({ mainContextMenu }));
        return EMPTY;
      }),
    ),
  );

  loadGridColumnContextMenu$ = createEffect(() =>
    this._actions$.pipe(
      ofType<LoadGridColumnContextMenu>(ContextMenusActionTypes.LoadGridColumnContextMenu),
      withLatestFrom(
        this._store.select(NavigationSelectors.getCurrentNavigationPath),
        this._store.select(NavigationSelectors.getViewIdFromUrl),
      ),
      switchMap(([_, path, viewId]) =>
        this._contextMenuClient.getContextMenu(path.split('_').slice(-1).pop(), ToolbarType.GridColumn).pipe(
          map((contextMenu) => {
            return { contextMenu, viewId };
          }),
          catchError((error) => {
            this._store.dispatch(new HandleError({ error }));
            return EMPTY;
          }),
        ),
      ),
      switchMap((payload) => {
        if (payload)
          return of(
            new LoadGridColumnContextMenuSuccess({
              contextMenu: payload.contextMenu,
              viewId: payload.viewId,
            }),
          );
        return EMPTY;
      }),
    ),
  );

  loadSubGridColumnContextMenu$ = createEffect(() =>
    this._actions$.pipe(
      ofType<LoadSubgridGridColumnContextMenu>(ContextMenusActionTypes.LoadSubgridGridColumnContextMenu),
      mergeMap((action) =>
        this._contextMenuClient.getViewContextMenu(action.payload.viewId, ToolbarType.GridColumn).pipe(
          map((contextMenu) => ({
            view: action.payload,
            context: contextMenu,
          })),
          catchError((error) => {
            this._store.dispatch(new HandleError({ error }));
            return EMPTY;
          }),
        ),
      ),
      switchMap((payload) => {
        if (payload)
          return of(
            new LoadSubgridGridColumnContextMenuSuccess({
              viewId: payload.view.viewId,
              subgridId: payload.view.subgridId,
              contextMenu: payload.context,
            }),
          );

        return EMPTY;
      }),
    ),
  );

  loadSubgridContextMenu$ = createEffect(() =>
    this._actions$.pipe(
      ofType<LoadSubgridContextMenu>(ContextMenusActionTypes.LoadSubgridContextMenu),
      mergeMap((action) =>
        this._contextMenuClient.getViewContextMenu(action.payload.viewId, ToolbarType.SubGrid).pipe(
          map((contextMenu) => ({
            view: action.payload,
            context: contextMenu,
          })),
          catchError((error) => {
            this._store.dispatch(new HandleError({ error }));
            return EMPTY;
          }),
        ),
      ),
      switchMap((payload) => {
        if (payload)
          return of(
            new LoadSubgridContextMenuSuccess({
              viewId: payload.view.viewId,
              subgridId: payload.view.subgridId,
              contextMenu: payload.context,
            }),
          );

        return EMPTY;
      }),
    ),
  );

  loadDialogFormContextMenu$ = createEffect(() =>
    this._actions$.pipe(
      ofType<LoadDialogFormContextMenu>(ContextMenusActionTypes.LoadDialogFormContextMenu),
      mergeMap((action) =>
        this._contextMenuClient.getDialogContextMenu(action.payload.formId, action.payload.recordId).pipe(
          map((contextMenu) => ({
            dialogId: action.payload.dialogId,
            context: contextMenu,
          })),
          catchError((error) => {
            this._store.dispatch(new HandleError({ error }));
            return EMPTY;
          }),
        ),
      ),
      switchMap((payload) => {
        if (payload)
          return of(
            new LoadDialogFormContextMenuSuccess({
              dialogId: payload.dialogId,
              dialogFormContextMenu: payload.context,
            }),
          );

        return EMPTY;
      }),
    ),
  );

  constructor(private _actions$: Actions, private _store: Store, private _contextMenuClient: ContextMenuClient) {}
}
