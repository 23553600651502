import { Directive, Injector, Input, Inject, InjectFlags, ChangeDetectorRef, HostBinding, ElementRef } from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormControl,
  FormControlDirective,
  FormControlName,
  FormGroupDirective,
  NgControl,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, pairwise, startWith, withLatestFrom } from 'rxjs/operators';
import { IControlTouchedChangeEvent } from '../models/icontrol-touched-change-event.model copy';
import { IControlValueChangeEvent } from '../models/icontrol-value-change-event.model';
import { CULTURE_SERVICE, ICultureService } from '../models/iculture-service.model';
import { PositionableDirective } from './positionable.directive';
import { DisplayMode } from '@core/services/api-clients';
import { LodashService } from '@core/services/lodash.service';

@Directive({
  selector: '[baseReactiveControl]',
})
export class BaseFormControlDirective extends PositionableDirective implements ControlValueAccessor {
  private _touched$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _focusout$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _formControl: UntypedFormControl;
  private _label: string;
  protected _validators: { [validatorId: string]: ValidatorFn } = {};
  protected _fnChange = (_: any) => { };
  protected _fnTouched = () => { };
  protected _el: ElementRef

  @Input() id: string | undefined;
  @Input() name: string | undefined;
  @Input() displayMode: DisplayMode;
  @Input() set label(value: string) {
    if (this._label != value) {
      this._label = value;
    }
  }
  @Input() set isRequired(v: boolean) {
    v ? this.addValidator('required', Validators.required) : this.removeValidator('required');
  }

  set formControl(_: UntypedFormControl) {
    throw new Error('Cannot override form control!');
  }

  get formControl(): UntypedFormControl {
    return this._formControl;
  }
  get label() {
    return this._label;
  }
  get isRequired() {
    return this.hasValidator('required');
  }
  get cultureSettings() {
    return this._cultureService.getCultureSettings();
  }

  @HostBinding('id')
  get getEngineFormControlId(): string {
    return `ReactiveControl_${this.id}`;
  }
  @HostBinding('attr.name')
  get getEngineFormControlName(): string {
    return `ReactiveControl_${this.name}`;
  }

  @HostBinding('class') get getDisplayModeClass() {
    if (!this.displayMode) return {};

    return {
      ['display-mode-' + DisplayMode[this.displayMode].toLowerCase()]: true,
    };
  }

  constructor(@Inject(CULTURE_SERVICE) protected _cultureService: ICultureService, protected _injector: Injector) {
    super(_injector.get(ChangeDetectorRef));
    this._el = _injector.get(ElementRef);
    this.initialDisplayStyle = 'flex';
    this.initiateFormControl();
  }

  onBlur(): void {
    this.formControl.markAsTouched();
    this._touched$.next(true);
    this._focusout$.next(true);
  }

  // #region ControlValueAccessor
  writeValue(value: any): void {
    if (this.formControl.value !== value) {
      this.formControl.setValue(value);
    }
  }

  registerOnChange(fn: any): void {
    this._fnChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._fnTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled == this.formControl.disabled) return;
    isDisabled ? this.formControl.disable() : this.formControl.enable();
  }
  // #endregion

  controlValueChanges(): Observable<IControlValueChangeEvent> {
    return this.formControl.valueChanges.pipe(
      distinctUntilChanged((x, y) => LodashService.areEqual(x, y)),
      startWith(undefined), // it's okey
      pairwise(),
      map(([prev, next]) => {
        return { oldValue: prev, newValue: next } as IControlValueChangeEvent;
      }),
      debounceTime(100),
    );
  }

  controlTouchedChanges(): Observable<IControlTouchedChangeEvent> {
    return this._touched$.pipe(
      map(() => this.formControl.touched),
      distinctUntilChanged(),
      startWith(false),
      pairwise(),
      map(([prev, next]) => {
        return { oldValue: prev, newValue: next } as IControlTouchedChangeEvent;
      }),
    );
  }

  focusout(): Observable<any> {
    return this._focusout$.pipe(
      withLatestFrom(this.controlValueChanges()),
      filter(([f, v]) => f && v.oldValue != v.newValue),
      debounceTime(100)
    );
  }

  addValidator(validatorId: string, validator: ValidatorFn): void {
    this._validators[validatorId] = validator;
    this.setValidators();
  }

  removeValidator(validatorId: string): void {
    this._validators[validatorId] = undefined;
    this.setValidators();
  }

  hasValidator(validatorId: string): boolean {
    return !!this._validators[validatorId];
  }

  hasErrors(): boolean {
    return this.formControl.errors && Object.keys(this.formControl.errors).length > 0;
  }

  hasError(errorId: string): boolean {
    return this.hasErrors() && this.formControl.errors[errorId] != undefined;
  }

  tryFocus() {
    const element: HTMLElement = this._el.nativeElement;
    const inputElement: HTMLInputElement = element.querySelector("input");
    if (inputElement) {
      inputElement.focus();
    }
  }

  private initiateFormControl() {
    this._formControl = new UntypedFormControl(undefined);
    this.overrideFormControlIfProvided();
    this.setValidators();
  }

  private overrideFormControlIfProvided() {
    const ngControl = this._injector.get(NgControl, null, InjectFlags.SkipSelf);
    if (!ngControl) return;
    if (ngControl instanceof FormControlName) {
      this._formControl = this._injector.get(FormGroupDirective).getControl(ngControl);
    } else {
      this._formControl = (ngControl as FormControlDirective).form as UntypedFormControl;
    }
  }

  private setValidators(): void {
    if (!this.formControl) return;
    this.formControl.setValidators(Object.values(this._validators).filter((v) => !!v));
    this.formControl.updateValueAndValidity({ emitEvent: true });
  }
}
