<div mat-dialog-content>
  {{ data.message }}
</div>

<div mat-dialog-actions align="end">
  <button type="button" mat-button (click)="cancel()">
    {{ 'Dialog.CancelButton' | translate }}
  </button>
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial>
    {{ 'Dialog.OkButton' | translate }}
  </button>
</div>
