import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ErrorOrigin, ErrorType } from 'src/engine-sdk';

@Injectable()
export class HttpErrorsInterceptor implements HttpInterceptor {
  static AccessControlAllowOrigin: string = 'Access-Control-Allow-Origin';

  // http resources in which case the error dialog should not be displayed
  // e.g. user's photo url
  private readonly httpsNotToHandle: string[] = ['https://graph.microsoft.com/v1.0/me/photo'];

  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let handler = next.handle(request);
    if (!this.httpsNotToHandle.some((x) => request.url.startsWith(x))) {
      handler = handler.pipe(
        catchError((error: HttpErrorResponse) => {
          return this.isBlobError(error)
            ? this.parseErrorBlob(error).pipe(switchMap((e) => throwError(this.createError(e))))
            : throwError(this.createError(error));
        }),
      );
    }

    return handler;
  }

  private createError(errorResponse: HttpErrorResponse) {
    const isEngineServerError = errorResponse.url && errorResponse.url.startsWith(environment.urls.BaseUrl);
    let error = {
      origin: ErrorOrigin.Server,
      payload: {},
    };
    let hasCorsHeaders = errorResponse.headers.has(HttpErrorsInterceptor.AccessControlAllowOrigin);
    if (isEngineServerError) {
      if (errorResponse && errorResponse.error && errorResponse.error.type) {
        switch (errorResponse.error.type) {
          case ErrorType.System:
          case ErrorType.Permission:
          case ErrorType.PluginExecution:
          case ErrorType.PluginValidation:
          case ErrorType.RequestValidation:
            error.payload = errorResponse.error;
            break;
          default:
            error.payload = {
              code: errorResponse.status,
              type: ErrorType.System,
              message: errorResponse.message,
            };
        }
      } else {
        error.payload = {
          code: errorResponse.status,
          type: ErrorType.System,
          message: errorResponse.message,
        };
      }
    } else if (!errorResponse.url && !hasCorsHeaders) {
      error.payload = {
        code: 500,
        type: ErrorType.System,
        message: 'Server connection issues. Check if your server is running and has properly configured CORS policy.',
      };
    } else {
      error.payload = {
        code: errorResponse.status,
        type: ErrorType.Unknown,
        message: errorResponse.message,
      };
    }
    return error;
  }

  private isBlobError(err: any) {
    return err instanceof HttpErrorResponse && err.error instanceof Blob && err.error.type === 'application/json';
  }

  private parseErrorBlob(err: HttpErrorResponse): Observable<any> {
    const reader: FileReader = new FileReader();
    const obs = new Observable((observer: any) => {
      reader.onloadend = (e) => {
        const newError = {
          ...err,
          error: JSON.parse(reader.result as string),
        };

        observer.next(newError);
        observer.complete();
      };
    });
    reader.readAsText(err.error);

    return obs;
  }
}
