import { Component, Input, Output, EventEmitter, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { MenuItemDto } from '@core/services/api-clients';

@Component({
  selector: 'app-menu-popup',
  templateUrl: './menu-popup.component.html',
  styleUrls: ['./menu-popup.component.scss'],
})
export class MenuPopupComponent implements OnInit, OnDestroy {
  @Input() menuItems: MenuItemDto[];
  @Input() groupName: string = '';
  @Input() selectedMenuItemId: string;

  @Output() menuItemClick = new EventEmitter<MenuItemDto>();
  @Output() menuItemAddedToFavorite = new EventEmitter<MenuItemDto>();
  @Output() menuItemRemovedFromFavorite = new EventEmitter<MenuItemDto>();
  @Output() isOver = new EventEmitter<any>();

  private subscriptions = new Subscription();

  constructor(private ref: ElementRef) {}

  ngOnInit(): void {
    const moveOut$ = fromEvent(this.ref.nativeElement, 'mouseout');
    const moveIn$ = fromEvent(this.ref.nativeElement, 'mouseover');

    this.subscriptions.add(
      moveOut$.subscribe(() => {
        this.isOver.emit(false);
      }),
    );
    this.subscriptions.add(
      moveIn$.subscribe(() => {
        this.isOver.emit(true);
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onMenuItemClick(menuItem: MenuItemDto) {
    this.menuItemClick.emit(menuItem);
  }

  addMenuItemToFavorite(menuItem: MenuItemDto) {
    this.menuItemAddedToFavorite.emit(menuItem);
  }

  removeMenuItemFromFavorite(menuItem: MenuItemDto) {
    this.menuItemRemovedFromFavorite.emit(menuItem);
  }
}
