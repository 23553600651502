import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

export interface ErrorAction {
  title: string;
  handler: () => void;
}

export interface ErrorInfo {
  code: string;
  message: string;
  details: string;
  actions?: ErrorAction[];
}

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorPageComponent {
  @Input() error: ErrorInfo;
}
