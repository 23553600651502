import { Pipe, PipeTransform } from '@angular/core';
import { GridColumn } from '../models/grid-definition.model';

@Pipe({ name: 'lookup' })
export class LookupPipe implements PipeTransform {
  transform(dataItem: any, column: GridColumn, fieldName?: string): string {
    if (!dataItem) return null;
    fieldName = fieldName ?? column.primaryField;
    const fields = fieldName.split('.');
    let item = dataItem;
    fields.forEach((element) => {
      if (!item) return null;
      item = item[element];
    });
    return item;
  }
}
