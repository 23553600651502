import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable()
export class HtmlSanitizer {
  private _htmlTagRegex: string = `<([^>]\+)(\?:[^<>]\+)\?>([\\s\\S]\*\?)<\\/\\1>\|<([^>]\+)(\?:[^<>]\+)\?>`;

  constructor(private _sanitizer: DomSanitizer) {}

  sanitize(
    html: string,
    allowedTags: string[] = [],
    bypassDefaultSanitization: boolean = false,
    withWarning: boolean = true,
  ) {
    if (!html) return '';
    let htmlToSanitize = html;
    if (!bypassDefaultSanitization) {
      htmlToSanitize = this._sanitizer.sanitize(SecurityContext.HTML, html);
    }
    const sanitizedHtml = this.removeForbiddenTags(htmlToSanitize, allowedTags);
    if (withWarning && html.length > sanitizedHtml.length) {
      console.warn(`WARNING: Text was sanitized: ${html} \n\n to \n\n ${sanitizedHtml}`);
    }
    return sanitizedHtml;
  }

  private removeForbiddenTags(htmlToSanitize: string, allowedTags: string[]) {
    let sanitizedHtml = '';
    const htmlTagRegexp = new RegExp(this._htmlTagRegex);
    while (htmlTagRegexp.test(htmlToSanitize)) {
      const match = htmlTagRegexp.exec(htmlToSanitize);
      sanitizedHtml = sanitizedHtml + htmlToSanitize.slice(0, match.index);
      const isTagAllowed = allowedTags.includes(match[1]);
      if (isTagAllowed) {
        sanitizedHtml = sanitizedHtml + match[0];
      }
      htmlToSanitize = htmlToSanitize.slice(match.index).replace(htmlTagRegexp, '');
    }
    sanitizedHtml = (sanitizedHtml + htmlToSanitize).trim();
    return sanitizedHtml;
  }
}
