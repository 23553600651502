import { ChangeDetectionStrategy, Component, forwardRef, inject, Inject, Optional, SkipSelf } from '@angular/core';
import { MenuItemDto, SystemClient, UILanguageDto } from '@core/services/api-clients';
import { Store } from '@ngrx/store';
import { tap, map } from 'rxjs/operators';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { catchError } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { NotificationService } from '@core/services/notification.service';
import {
  getFilledUserMenuGroup,
  getLogoutIcon,
  getDarkModeIcon,
  getLanguageIcon,
  getAboutIcon,
  getSelectedMenuItemId,
} from '../store/selectors';
import { SelectMenuItem } from '../store/actions';
import { environment } from '@env';
import { WidgetDirective } from '@core/widgets/directives/widget.directive';
import { NoEventWidgetDirective } from '@core/widgets/directives/no-event-widget.directive';
import { SCRIPT_RUNNER_SERVICE, IScriptRunnerService } from '@core/widgets/models/iscript-runner.service';
import { AuthenticationType } from '@core/auth/models/authentication-type.enum';
import { AuthActions } from '@core/auth/store';
import { SetUserLanguage, RemoveUserData } from '@core/user/store/actions';
import { getUILanguages, getCurrentUser, getUserLanguage } from '@core/user/store/selectors';
import { EngineAboutDialogComponent } from './engine-about-dialog/engine-about-dialog.component';
import { EngineVersionData } from './engine-about-dialog/engine-version-data';
import { EngineTheme, ThemeService } from '@core/layout/services/theme.service';
import { AUTH_SERVICE_TOKEN } from '@core/auth/models/iauth-service.model';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  providers: [
    {
      provide: WidgetDirective,
      useExisting: forwardRef(() => UserMenuComponent),
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserMenuComponent extends NoEventWidgetDirective {
  private _authService = inject(AUTH_SERVICE_TOKEN);

  userMenuGroup$ = this._store.select(getFilledUserMenuGroup);
  selectedMenuItemId$ = this._store.select(getSelectedMenuItemId);
  languages$ = this._store.select(getUILanguages);
  currentUser$ = this._store.select(getCurrentUser);
  userLanguage$ = this._store.select(getUserLanguage);
  logoutIcon$ = this._store.select(getLogoutIcon);
  darkModeIcon$ = this._store.select(getDarkModeIcon);
  languageIcon$ = this._store.select(getLanguageIcon);
  aboutIcon$ = this._store.select(getAboutIcon);
  theme$ = this._themeService.getThemeAsync();

  constructor(
    @Optional() @SkipSelf() parentWidget: WidgetDirective,
    @Inject(SCRIPT_RUNNER_SERVICE) scriptRunnerService: IScriptRunnerService,
    private _store: Store,
    private _dialog: MatDialog,
    private _systemClient: SystemClient,
    private _notificationService: NotificationService,
    private _themeService: ThemeService,
  ) {
    super(parentWidget, scriptRunnerService);
  }

  onMenuItemClick(menuItem: MenuItemDto) {
    this._store.dispatch(new SelectMenuItem({ menuItem }));
  }

  selectLanguage(language: UILanguageDto) {
    this._store.dispatch(new SetUserLanguage({ language: language }));
  }

  changeTheme(theme: EngineTheme) {
    this._themeService.setTheme(theme);
  }

  isLogoutVisible() {
    return (
      this._authService.getAuthenticationType() == AuthenticationType.AzureB2C ||
      this._authService.getAuthenticationType() == AuthenticationType.AzureActiveDirectory
    );
  }

  logout() {
    this._store.dispatch(new AuthActions.Logout());
    this._store.dispatch(new RemoveUserData());
  }

  displayAboutInfo() {
    this._systemClient
      .getEngineVersion()
      .pipe(
        map((v) => {
          return <EngineVersionData>{
            metadataVersion: v.metadataVersion,
            solutionVersion: v.solutionVersion,
            assemblyVersion: v.assemblyVersion,
            frontendVersion: environment.version,
          };
        }),
        tap((version) => {
          this._dialog.open(EngineAboutDialogComponent, { data: version });
        }),
        catchError(() => {
          this._notificationService.error('System version information could not be retrieved.');
          return EMPTY;
        }),
      )
      .subscribe();
  }
}
