import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Inject,
  OnInit,
  Optional,
  SkipSelf,
  ViewChild,
} from '@angular/core';
import { WidgetDirective } from '@core/widgets/directives/widget.directive';
import { NoEventWidgetDirective } from '@core/widgets/directives/no-event-widget.directive';
import { IScriptRunnerService, SCRIPT_RUNNER_SERVICE } from '@core/widgets/models/iscript-runner.service';
import { Store } from '@ngrx/store';
import { filter, take, tap } from 'rxjs/operators';
import { MatLegacyTabChangeEvent as MatTabChangeEvent } from '@angular/material/legacy-tabs';
import {
  EngineViewStateService,
  ENGINE_VIEW_STATE_SERVICE,
} from '@core/engine-views/components/engine-view/services/engine-view-state.service';
import { getCurrentGridSettings, getViewTabs } from '@core/engine-views/store/selectors';
import { EngineViewComponent } from '@core/engine-views/components/engine-view/engine-view.component';
import { IViewState } from '@core/engine-views/store/state';
import {
  ColumnResizeEvent,
  LookupColumnClickEvent,
  RowDoubleClickEvent,
  StateChangeEvent,
  ViewTab,
  ViewTabs,
} from '@core/engine-views/models/view.model';
import { ViewMainSelectorVisibilityDto } from '@core/services/api-clients';
import { RouterActions } from '@core/router/store';
import { getGridColumnContextMenu } from '@core/context-menu/store/selectors';
import { getValueByAttributePath } from '../../shared/utils';
import { NavigationActions, NavigationSelectors } from '@core/navigation/store';
import { ViewActions } from '@core/engine-views/store';

@Component({
  selector: 'app-main-view',
  templateUrl: './main-view.component.html',
  styleUrls: ['./main-view.component.scss'],
  providers: [
    {
      provide: WidgetDirective,
      useExisting: forwardRef(() => MainViewComponent),
    },
    {
      provide: ENGINE_VIEW_STATE_SERVICE,
      useClass: EngineViewStateService,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainViewComponent extends NoEventWidgetDirective implements OnInit {
  viewTabs$ = this._store.select(getViewTabs);
  gridColumnContextMenu$ = this._store.select(getGridColumnContextMenu);
  urlState$ = this._store.select(NavigationSelectors.getCurrentViewQueryParams);
  settings$ = this._store.select(getCurrentGridSettings);

  @ViewChild(EngineViewComponent, { static: false }) engineViewComponent: EngineViewComponent;

  constructor(
    @Optional() @SkipSelf() parentWidget: WidgetDirective,
    @Inject(SCRIPT_RUNNER_SERVICE) scriptRunnerService: IScriptRunnerService,
    @Inject(ENGINE_VIEW_STATE_SERVICE) public stateService: EngineViewStateService,
    private _store: Store<IViewState>,
  ) {
    super(parentWidget, scriptRunnerService);
    this._minWidgetsNumber = 3; // contextMenu + entityView + asyncWidget
  }

  ngOnInit() {
    this.loadEntityView();
  }

  // handlers

  onSelectedViewTabChange(viewTabs: ViewTabs, event: MatTabChangeEvent) {
    const viewTab = viewTabs.visibleViewTabs[event.index];
    const isIndexChanged = event.index != viewTabs.selectedTabIndex;
    if (viewTab && isIndexChanged) {
      this._store.dispatch(
        new NavigationActions.NavigateByRouteData({
          routeData: {
            entityId: viewTabs.entityView.view.entity.id,
            viewId: viewTab.viewId,
          },
          popCount: 1,
        }),
      );
    }
  }

  onRowDoubleClick(event: RowDoubleClickEvent) {
    this._store.dispatch(
      new NavigationActions.NavigateByRouteData({
        routeData: {
          entityId: event.entityId,
          formId: event.defaultFormId,
          recordId: event.dataItem.Id,
        },
        popCount: 0,
      }),
    );
  }

  onLookupColumnClick(event: LookupColumnClickEvent) {
    this._store.dispatch(
      new NavigationActions.NavigateByRouteData({
        routeData: {
          entityId: event.column.relatedEntity.entityId,
          formId: event.column.relatedEntity.defaultFormId,
          recordId: this.getRecordIdByAttributeName(event.dataItem, event.attributeName),
        },
        popCount: 0,
      }),
    );
  }

  onColumnResize(event: ColumnResizeEvent) {
    this._store.dispatch(
      new ViewActions.SaveGridColumnSettings({
        viewOrSubgridId: event.viewId,
        settings: event,
      }),
    );
  }

  onStateChange(event: StateChangeEvent) {
    this._store.dispatch(
      new RouterActions.SaveViewQuery({
        viewId: event.viewId,
        stateQuery: event.urlState,
      }),
    );
  }

  onPageSizeChange(pageSize: number) {
    this._store.dispatch(
      new ViewActions.SetUserViewPageSize({
        viewId: this.engineViewComponent.getViewId(),
        pageSize: pageSize,
      }),
    );
  }

  // Toolbar buttons handlers

  onGlobalSearchChange(globalSearch: string) {
    this.engineViewComponent.stateService.setGlobalSearch(globalSearch);
    this.engineViewComponent.refreshData();
    this.onStateChange({
      viewId: this.engineViewComponent.getViewId(),
      urlState: this.engineViewComponent.stateService.getViewState().urlState,
    });
  }

  onGlobalSearchVisibilityChange(value: boolean) {
    this.resetQuery();

    this._store.dispatch(
      new ViewActions.SaveGridToolbarSettings({
        viewOrSubgridId: this.engineViewComponent.getViewId(),
        settings: { isGlobalSearchEnabled: value, areFiltersEnabled: value ? false : undefined },
      }),
    );
  }

  onFiltersVisibilityChange(value: boolean) {
    this.resetQuery();

    this._store.dispatch(
      new ViewActions.SaveGridToolbarSettings({
        viewOrSubgridId: this.engineViewComponent.getViewId(),
        settings: { areFiltersEnabled: value, isGlobalSearchEnabled: value ? false : undefined },
      }),
    );
  }

  onViewTabsVisibilityChange(viewTabs: ViewTab[]) {
    const viewTab = viewTabs.find((x) => x.viewId == this.engineViewComponent.entityView.view.id);
    if (!viewTab.isVisible) {
      const visibleViewTab = viewTabs.find((x) => x.isVisible);
      this._store.dispatch(
        new NavigationActions.NavigateByRouteData({
          routeData: {
            entityId: this.engineViewComponent.entityView.view.entity.id,
            viewId: visibleViewTab.viewId,
          },
          popCount: 1,
        }),
      );
    }

    this._store.dispatch(
      new ViewActions.SaveTabsVisibilitySettings({
        entityId: this.engineViewComponent.entityView.view.entity.id,
        tabsVisibilitySettings: viewTabs.map((viewTab) =>
          ViewMainSelectorVisibilityDto.fromJS({
            viewId: viewTab.viewId,
            isVisibleOnMainSelector: viewTab.isVisible,
          }),
        ),
      }),
    );
  }

  onRefreshData() {
    this.engineViewComponent.refreshData();
  }

  onRestoreSettings() {
    this._store.dispatch(
      new ViewActions.RestoreGridSettings({ viewOrSubgridId: this.engineViewComponent.getViewId() }),
    );
  }

  onNavigateToSettings() {
    this._store.dispatch(new RouterActions.NavigateToViewSettings({ viewId: this.engineViewComponent.getViewId() }));
  }

  // private methods

  private loadEntityView() {
    this._store
      .select(NavigationSelectors.getEntityIdFromUrl)
      .pipe(
        filter((entityId) => !!entityId),
        take(1),
        tap((entityId) => this._store.dispatch(new ViewActions.LoadTabsVisibilitySettings({ entityId }))),
      )
      .subscribe();

    this._store
      .select(NavigationSelectors.getViewIdFromUrl)
      .pipe(
        filter((viewId) => !!viewId),
        take(1),
        tap((viewId) => this._store.dispatch(new ViewActions.ReloadView({ viewId }))),
      )
      .subscribe();
  }

  private getRecordIdByAttributeName(dataItem, attributeName) {
    const path = attributeName.split('.').slice(0, -1).join('.');
    return getValueByAttributePath(dataItem, path).Id;
  }

  private resetQuery() {
    if (this.engineViewComponent.stateService.resetQuery()) {
      this.engineViewComponent.refreshData();
      this.onStateChange({
        viewId: this.engineViewComponent.getViewId(),
        urlState: this.engineViewComponent.stateService.getViewState().urlState,
      });
    }
  }
}
