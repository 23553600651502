import { IRouterStateUrl } from '@core/router/store/state';
import { NavigationTargetDto } from '@core/services/api-clients';

export const FEATURE_NAME: string = 'navigation-feature';

export interface NavigationStackItem {
  navigationToken: string;
  navigationPath: string;
  navigationTarget: NavigationTargetDto;
  routerState: IRouterStateUrl;
  state?: any;
  isDirty?: boolean;
}

export interface FormOption {
  id: string;
  name: string;
}

export interface INavigationState {
  navigationStack: NavigationStackItem[];
  navigationTargets: { [navigationToken: string]: NavigationTargetDto };
  entityForms: { [entityId: string]: FormOption[] };

  isLongBreadCrumbVisible: boolean;
}

export const initialNavigationsState: INavigationState = {
  navigationStack: [],
  navigationTargets: {},
  entityForms: {},

  isLongBreadCrumbVisible: true,
};
