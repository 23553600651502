<div
  class="user-information-container"
  *ngIf="currentUser$ | async as currentUser"
  (click)="avatarClicked.emit()">
  <app-user-avatar [size]="45" [user]="currentUser"> </app-user-avatar>

  <div class="user-details-container">
    <span class="display-name-text"> {{ currentUser.displayName }}</span>
    <span class="email-text"> {{ currentUser.email }}</span>
  </div>
</div>
