import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { takeUntil, tap } from 'rxjs/operators';
import { ErrorInfo } from '@shared/error-page/error-page.component';
import { getFatalErrors } from '../store/selectors';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-fatal-errors',
  template: '<app-error-page [error]="error"></app-error-page>',
})
export class FatalErrorsComponent implements OnInit {
  private _destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private _store: Store) {}

  error: ErrorInfo;

  ngOnInit() {
    this._store
      .select(getFatalErrors)
      .pipe(
        takeUntil(this._destroy$),
        tap((fatalErrors) => {
          this.error = {
            code: '500',
            message: 'Fatal error(s) occured.',
            details: fatalErrors.map((x) => x.payload.message).join('<br>'),
          };
        }),
      )
      .subscribe(); // TODO-MP: przerób to na async pipe :)
  }

  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
  }
}
