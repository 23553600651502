import { Action } from '@ngrx/store';
import { BlobType, FileParameter } from 'src/core/services/api-clients';

export enum BlobActionTypes {
  UploadBlob = '[Blob] Upload Blob',
  UploadBlobSuccess = '[Blob] Upload Blob Success',
}

export class UploadBlob implements Action {
  readonly type = BlobActionTypes.UploadBlob;
  constructor(
    public payload: {
      file: FileParameter;
      name: string;
      type: BlobType;
      onSuccess?: (newBlobId: string) => void;
      onError?: () => void;
    },
  ) {}
}

export class UploadBlobSuccess implements Action {
  public readonly type = BlobActionTypes.UploadBlobSuccess;
}

export type BlobActions = UploadBlob | UploadBlobSuccess;
