import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ViewFeatureState } from '.';
import { FEATURE_NAME, IViewState } from './state';
import { ViewTab, ViewTabs } from '../models/view.model';
import { ViewTargets } from '../../services/api-clients';

export const viewFeatureSelector = createFeatureSelector<ViewFeatureState>(FEATURE_NAME);
export const viewSelector = createSelector(viewFeatureSelector, (state: ViewFeatureState) => state.view);
export const getViews = createSelector(viewSelector, (state: IViewState) => state.views);
export const getCurrentViewId = createSelector(viewSelector, (state) => state.currentViewId);
export const getGridsSettings = createSelector(viewSelector, (state) => state.gridsSettings);
export const getTabsVisibility = createSelector(viewSelector, (state) => state.tabsVisibility);

export const getViewsIds = createSelector(viewSelector, (state: IViewState) => Object.keys(state.views));
export const getCurrentView = createSelector(getViews, getCurrentViewId, (views, viewId) => views[viewId]);
export const getEntityView = (viewId: string) => createSelector(getViews, (views) => views[viewId]);
export const getCurrentEntityView = createSelector(getCurrentView, (currentView) => currentView);
export const getCurrentViewEntityId = createSelector(
  getCurrentEntityView,
  (currentView) => currentView?.view?.entity?.id,
);

export const getViewTabsVisibility = (entityId: string) =>
  createSelector(getTabsVisibility, (tabsVisibility) => tabsVisibility[entityId]);

export const getCurrentViewTabsVisibility = createSelector(
  getTabsVisibility,
  getCurrentViewEntityId,
  (tabsVisibility, entityId) => tabsVisibility[entityId],
);

/// NOWE SELECTORY PO ZMIANACH MR
export const getViewTabs = createSelector(
  getCurrentEntityView,
  getCurrentViewTabsVisibility,
  (entityView, tabsVisibility) => {
    if (entityView && tabsVisibility) {
      const allViewTabs: ViewTab[] = entityView.simpleViews.filter(tab => (tab.viewTargets & ViewTargets.Overview) == ViewTargets.Overview).map((tab) => {
        const setting = tabsVisibility ? tabsVisibility.find((t) => t.viewId === tab.id) : null;

        return <ViewTab>{
          viewId: tab.id,
          label: tab.label,
          isVisible: !setting || setting.isVisibleOnMainSelector || tab.id === entityView.view.id,
        };
      });

      const visibleViewTabs = allViewTabs.filter((x) => x.isVisible);

      return <ViewTabs>{
        entityView: entityView,
        allViewTabs: allViewTabs,
        visibleViewTabs: visibleViewTabs,
        selectedTabIndex: visibleViewTabs.findIndex((x) => x.viewId == entityView.view.id),
      };
    }

    return null;
  },
);

export const getGridSettings = (viewOrSubgridId: string) =>
  createSelector(getGridsSettings, (gridsSettings) => gridsSettings[viewOrSubgridId]);
export const getCurrentGridSettings = createSelector(
  getGridsSettings,
  getCurrentView,
  (gridsSettings, currentView) => gridsSettings[currentView?.view?.id],
);
