<div
  [ngSwitch]="icon ? 'iconTemplate' : 'shortNameTemplate'"
  class="menu-item"
  [ngClass]="{ 'root-level': isRootLevel }">
  <ng-template [ngSwitchCase]="'iconTemplate'">
    <app-icon [icon]="icon" [size]="isRootLevel ? 'md' : 'sm'"></app-icon>
  </ng-template>
  <ng-template [ngSwitchCase]="'shortNameTemplate'">
    <span class="menu-icon">{{ shortLabel }}</span>
  </ng-template>
  <span *ngIf="isWide" class="menu-item-name">{{ label }}</span>
</div>
