import { ChangeDetectionStrategy, Component, forwardRef, Inject, Injector } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseFormControlDirective } from '../../directives/base-form-control.directive';
import { CULTURE_SERVICE, ICultureService } from '../../models/iculture-service.model';

@Component({
  selector: 'app-html-editor-control',
  templateUrl: './html-editor-control.component.html',
  styleUrls: ['./html-editor-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => HtmlEditorControlComponent),
      multi: true,
    },
    {
      provide: BaseFormControlDirective,
      useExisting: HtmlEditorControlComponent,
    },
  ],
})
export class HtmlEditorControlComponent extends BaseFormControlDirective {
  constructor(@Inject(CULTURE_SERVICE) cultureService: ICultureService, injector: Injector) {
    super(cultureService, injector);
  }
}
