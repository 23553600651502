import { Injectable, InjectionToken, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';
import { IError } from 'src/engine-sdk';

export const ERROR_DISPATCHER = new InjectionToken<IErrorDispatcher>('IErrorDispatcher');
export const ERROR_PROVIDER = new InjectionToken<IErrorProvider>('IErrorProvider');

export interface IErrorDispatcher {
  dispatchError(error: IError);
}

export interface IErrorProvider {
  errorEvent$: Observable<IError>;
}

@Injectable({
  providedIn: 'root',
})
export class ErrorEventService implements IErrorDispatcher, IErrorProvider, OnDestroy {
  private _errorEvent$: Subject<IError> = new Subject<IError>();

  public get errorEvent$(): Observable<IError> {
    return this._errorEvent$.asObservable();
  }

  constructor() {}

  ngOnDestroy(): void {
    this._errorEvent$.complete();
  }

  public dispatchError(error: IError) {
    this._errorEvent$.next(error);
  }
}
