import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Pipe({ name: 'webResourceUri' })
export class WebResourceUriPipe implements PipeTransform {
  constructor(private _sanitizer: DomSanitizer, private _http: HttpClient) {}

  async transform(relativeUri: string, sanitized: boolean = true): Promise<SafeResourceUrl | string> {
    const uri = relativeUri ? `${environment.urls.ApiUrl}/Resource/WebResources/${relativeUri}` : null;
    if (!uri) {
      return null;
    }

    const imageBlob = await this._http.get(uri, { responseType: 'blob' }).toPromise();

    const reader = new FileReader();
    return new Promise((resolve) => {
      reader.onloadend = () =>
        resolve(
          sanitized
            ? this._sanitizer.bypassSecurityTrustResourceUrl(reader.result as string)
            : (reader.result as string),
        );
      reader.readAsDataURL(imageBlob);
    });
  }
}
