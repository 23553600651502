import { KanbanDto } from '@core/services/api-clients';

export const FEATURE_NAME: string = 'kanban-feature';

export interface KanbanData {
  columns: {
    [columnId: string]: KanbanColumnData;
  };
}

export interface KanbanColumnData {
  columnId: string;
  records: any[];
  aggregators: { [aggregatorId: string]: number };
  pageSize: number;
}

export interface IKanbanState {
  kanbans: { [kanbanId: string]: KanbanDto };
  kanbansData: { [kanbanId: string]: KanbanData };
  settingsRefreshFlag: number;
}

export const initialKanbanState: IKanbanState = {
  kanbans: {},
  kanbansData: {},
  settingsRefreshFlag: 0,
};
