<kendo-dropdownlist
  [data]="operators"
  [value]="{ value: 'contains' }"
  [textField]="'text'"
  [valueField]="'value'"
  [disabled]="true">
</kendo-dropdownlist>
<kendo-textbox [(value)]="searchText"> </kendo-textbox>
<div class="actions k-actions-stretched k-actions-horizontal">
  <button
    type="button"
    class="k-button k-button-solid-primary k-button-solid k-button-md k-rounded-md k-button-rectangle"
    (click)="onFilterClicked()"
    [disabled]="isFilterButtonDisabled">
    {{ 'kendo.grid.filterFilterButton' | translate }}
  </button>
  <button
    type="button"
    class="k-button k-button-solid-base k-button-solid k-button-md k-rounded-md k-button-rectangle"
    (click)="onClearClicked()">
    {{ 'kendo.grid.filterClearButton' | translate }}
  </button>
</div>
