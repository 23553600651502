import { ModuleWithProviders, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { reducers } from './store';
import { FEATURE_NAME } from './store/state';
import { LayoutEffects } from './store/effects';
import { ShellComponent } from './components/shell/shell.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { BusyIndicatorInterceptor } from './components/busy-indicator/busy-indicator.interceptor';
import { BusyIndicatorComponent } from './components/busy-indicator/busy-indicator.component';
import { BusyIndicatorService } from './services/busy-indicator.service';
import { TopbarComponent } from './components/topbar/topbar.component';
import { SharedModule } from '@shared/shared.module';
import { LayoutService } from './services/layout.service';
import { ThemeService } from './services/theme.service';

@NgModule({
  declarations: [ShellComponent, BusyIndicatorComponent, TopbarComponent],
  imports: [StoreModule.forFeature(FEATURE_NAME, reducers), EffectsModule.forFeature([LayoutEffects]), SharedModule],
  exports: [ShellComponent, TopbarComponent, BusyIndicatorComponent],
})
export class LayoutModule {
  static forRoot(): ModuleWithProviders<LayoutModule> {
    return {
      ngModule: LayoutModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: BusyIndicatorInterceptor,
          multi: true,
        },
        BusyIndicatorService,
        LayoutService,
        ThemeService,
      ],
    };
  }
}
