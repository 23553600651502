import { Injectable } from '@angular/core';
import { ErrorHandlerLifetime, IErrorHandler } from 'src/engine-sdk';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerProvider {
  private _handlers: IErrorHandler[] = [];

  constructor() {}

  addHandler(handler: IErrorHandler) {
    this._handlers.push(handler);
  }

  getHandlers(): IErrorHandler[] {
    return this._handlers;
  }

  clearScopedHandlers() {
    this._handlers.filter((h) => h.lifetime === ErrorHandlerLifetime.Global);
  }
}
