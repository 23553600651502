import { ActionReducerMap } from '@ngrx/store';
import { layoutReducer } from './reducers';
import { ILayoutState } from './state';
import * as LayoutSelectors from './selectors';
import * as LayoutActions from './actions';

export { LayoutActions, LayoutSelectors };

export interface LayoutFeatureState {
  layout: ILayoutState;
}

export const reducers: ActionReducerMap<LayoutFeatureState> = {
  layout: layoutReducer,
};
