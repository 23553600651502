import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { distinctUntilChanged, filter, takeUntil, tap } from 'rxjs/operators';
import {
  SaveColumnExpansion,
  LoadEntityKanban,
  LoadMore,
  MoveRecord,
  MoveRecordEvent,
  SaveDefaultAggregator,
  RestoreSettings,
  LoadKanbanData,
  FilterRecords,
} from './store/actions';
import { KanbanColumnAggregatorDto, KanbanColumnDto, KanbanDto, RecordDto } from '@core/services/api-clients';
import { NavigationService } from '@core/navigation/services/navigation.service';
import { Subject } from 'rxjs';
import { KanbanSelectors } from './store';
import { NavigationSelectors } from '@core/navigation/store';

@Component({
  selector: 'app-kanban-container',
  templateUrl: './main-kanban.component.html',
  styleUrls: ['./main-kanban.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainKanbanComponent implements OnInit, OnDestroy {
  private _destroy$: Subject<boolean> = new Subject<boolean>();

  kanban$ = this._store.select(KanbanSelectors.getCurrentKanban);
  kanbanData$ = this._store.select(KanbanSelectors.getCurrentKanbanData);
  searchTerm$ = this._store.select(KanbanSelectors.getCurrentSearchTerm);

  constructor(private _store: Store, private _navigationContext: NavigationService) { }

  ngOnInit(): void {
    this.reloadKanbanOnNavigationPathChange();
  }

  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
  }

  private reloadKanbanOnNavigationPathChange() {
    this._store
      .select(NavigationSelectors.getKanbanIdFromUrl)
      .pipe(
        takeUntil(this._destroy$),
        filter((kanbanId) => kanbanId != null),
        distinctUntilChanged(),
        tap((kanbanId) => this._store.dispatch(new LoadEntityKanban({ kanbanId }))),
      )
      .subscribe();
  }

  navigateToEntityForm(e: { entity: RecordDto; formId: string; recordId: string }) {
    this._navigationContext.navigateToForm(e.entity.id, e.formId, e.recordId, { popCount: 0 });
  }

  recordMove(moveRecordEvent: MoveRecordEvent) {
    this._store.dispatch(new MoveRecord({ moveRecordEvent }));
  }

  loadMore(e: { kanban: KanbanDto; kanbanColumn: KanbanColumnDto }) {
    this._store.dispatch(new LoadMore(e));
  }

  saveDefaultAggregator(e: { kanbanColumn: KanbanColumnDto; kanbanColumnAggregator: KanbanColumnAggregatorDto }) {
    this._store.dispatch(new SaveDefaultAggregator(e));
  }

  saveColumnExpansion(e: { column: KanbanColumnDto; isExpanded: boolean }) {
    this._store.dispatch(new SaveColumnExpansion({ kanbanColumn: e.column, isExpanded: e.isExpanded }));
  }

  refreshData(kanban: KanbanDto) {
    this._store.dispatch(new LoadKanbanData({ kanban }));
  }

  restoreSettings(kanban: KanbanDto) {
    this._store.dispatch(new RestoreSettings({ kanban }));
  }

  filterRecords(e: { kanban: KanbanDto; searchTerm: string }) {
    this._store.dispatch(new FilterRecords({ kanban: e.kanban, searchTerm: e.searchTerm }));
  }
}
