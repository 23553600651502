import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormOption } from '@core/navigation/store/state';
import { BreadcrumbItem } from './breadcrumb-item/breadcrumb-item.component';
import { FormSelectorComponent } from './form-selector/form-selector.component';

@Component({
  selector: 'app-long-breadcrumb',
  templateUrl: './long-breadcrumb.component.html',
  styleUrls: ['./long-breadcrumb.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LongBreadcrumbComponent {
  @Input() breadcrumbs: BreadcrumbItem[] = [];
  @Input() forms: FormOption[] = [];
  @Output() homeClicked = new EventEmitter();
  @Output() breadcrumbClicked = new EventEmitter<BreadcrumbItem>();
  @Output() formSelected = new EventEmitter<string>();

  @ViewChild(FormSelectorComponent, { static: false })
  formSelector: FormSelectorComponent;
}
