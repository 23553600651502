import { getCurrentUser, getCurrentUserId } from '@core/user/store/selectors';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FormFeatureState } from '.';
import { FEATURE_NAME, IFormState } from './state';
import { NavigationSelectors } from '@core/navigation/store';

export const formFeatureSelector = createFeatureSelector<FormFeatureState>(FEATURE_NAME);
export const formSelector = createSelector(formFeatureSelector, (state: FormFeatureState) => state.form);

export const getForms = createSelector(formSelector, (state: IFormState) => state.forms);
export const getForm = (formId: string) => createSelector(getForms, (forms) => forms[formId]);
export const getFormFromUrl = createSelector(
  getForms,
  NavigationSelectors.getFormIdFromUrl,
  (forms, formId) => forms[formId],
);

export const getSubGridToolbarSettings = (subgridId: string) =>
  createSelector(getCurrentUser, NavigationSelectors.getSubGridQueryParams(subgridId), (currentUser, viewQuery) => {
    if (viewQuery['filters'] || viewQuery['globalSearch']) {
      return {
        search: !!viewQuery['globalSearch'],
        filter: !!viewQuery['filters'],
      };
    } else if (subgridId && currentUser && localStorage['Toolbar_' + subgridId + currentUser.userId]) {
      return JSON.parse(localStorage['Toolbar_' + subgridId + currentUser.userId]);
    }

    return { search: false, filter: false };
  });

export const getSubGridSelectedView = (subgridId: string) =>
  createSelector(getCurrentUserId, (currentUserId) => {
    const result: string = !!currentUserId ? localStorage['SelectedView_' + subgridId + currentUserId] : null;
    return result;
  });
