import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Aggregation } from '../../models/grid-data.model';

@Component({
  selector: 'app-aggregation-dropdown',
  templateUrl: './aggregation-dropdown.component.html',
  styleUrls: ['./aggregation-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AggregationDropdownComponent {
  @Input() aggregations: Aggregation[];
}
