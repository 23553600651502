import { Action } from '@ngrx/store';

export enum LayoutActionTypes {
  InitLayout = '[Layout] Init Layout',
  InitLayoutSuccess = '[Layout] Init Success',
  OpenRightPanel = '[Layout] Open Right Panel',
  CloseRightPanel = '[Layout] Close Right Panel',
  OpenLeftPanel = '[Layout] Open Left Panel',
  CloseLeftPanel = '[Layout] Close Left Panel',
  ToggleLeftPanel = '[Layout] Toggle Left Panel',
  ToggleIsLeftPanelExpanded = '[Layout] Toggle Is Left Panel Expanded',
  ScreenSizeChanged = '[Layout] Screen Size Changed',
  SaveNewScreenWidth = '[Layout] Save New Screen Width',
}

export class InitLayout implements Action {
  public readonly type = LayoutActionTypes.InitLayout;
}

export class InitLayoutSuccess implements Action {
  public readonly type = LayoutActionTypes.InitLayoutSuccess;
  public constructor(public payload: { isLeftPanelExpanded: boolean }) {}
}

export class OpenRightPanel implements Action {
  public readonly type = LayoutActionTypes.OpenRightPanel;
}

export class CloseRightPanel implements Action {
  public readonly type = LayoutActionTypes.CloseRightPanel;
}

export class OpenLeftPanel implements Action {
  public readonly type = LayoutActionTypes.OpenLeftPanel;
}

export class CloseLeftPanel implements Action {
  public readonly type = LayoutActionTypes.CloseLeftPanel;
}

export class ToggleLeftPanel implements Action {
  public readonly type = LayoutActionTypes.ToggleLeftPanel;
}

export class ToggleIsLeftPanelExpanded implements Action {
  public readonly type = LayoutActionTypes.ToggleIsLeftPanelExpanded;
}

export class ScreenSizeChanged implements Action {
  public readonly type = LayoutActionTypes.ScreenSizeChanged;
  public constructor(public payload: { screenWidth: number }) {}
}

export class SaveNewScreenWidth implements Action {
  public readonly type = LayoutActionTypes.SaveNewScreenWidth;
  public constructor(public payload: { screenWidth: number }) {}
}

export type LayoutActions =
  | InitLayout
  | InitLayoutSuccess
  | OpenRightPanel
  | CloseRightPanel
  | OpenLeftPanel
  | CloseLeftPanel
  | ToggleLeftPanel
  | ToggleIsLeftPanelExpanded
  | ScreenSizeChanged
  | SaveNewScreenWidth;
