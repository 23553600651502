import { ModuleWithProviders, NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { LayoutEffects as NavigationEffects } from './store/effects';
import { FEATURE_NAME } from './store/state';
import { reducers } from './store';
import { NavigationService } from '@core/navigation/services/navigation.service';
import { NavigateByNavigationPathGuard } from './guards/navigate-by-navigation-path.guard';
import { ReplaceUrlToTokenGuard } from './guards/replace-url-to-token.guard';
import { SharedModule } from '@shared/shared.module';
import { ShortBreadcrumbComponent } from './breadcrumb/short-breadcrumb/short-breadcrumb.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { BreadcrumbItemComponent } from './breadcrumb/long-breadcrumb/breadcrumb-item/breadcrumb-item.component';
import { LongBreadcrumbComponent } from './breadcrumb/long-breadcrumb/long-breadcrumb.component';
import { FormSelectorComponent } from './breadcrumb/long-breadcrumb/form-selector/form-selector.component';
import { TestEnvOnlyGuard } from './guards/test-env-only.guard';

@NgModule({
  declarations: [
    BreadcrumbComponent,
    BreadcrumbItemComponent,
    ShortBreadcrumbComponent,
    LongBreadcrumbComponent,
    FormSelectorComponent,
  ],
  imports: [
    StoreModule.forFeature(FEATURE_NAME, reducers),
    EffectsModule.forFeature([NavigationEffects]),
    SharedModule,
  ],
  exports: [BreadcrumbComponent],
})
export class NavigationModule {
  constructor() {}

  static forRoot(): ModuleWithProviders<NavigationModule> {
    return {
      ngModule: NavigationModule,
      providers: [ReplaceUrlToTokenGuard, NavigateByNavigationPathGuard, TestEnvOnlyGuard, NavigationService],
    };
  }
}
