import {
  Component,
  Input,
  Output,
  EventEmitter,
  forwardRef,
  SkipSelf,
  Optional,
  Inject,
  ChangeDetectionStrategy,
} from '@angular/core';
import { ContextMenuDto } from '@core/services/api-clients';
import { WidgetDirective } from '@core/widgets/directives/widget.directive';
import { NoEventWidgetDirective } from '@core/widgets/directives/no-event-widget.directive';
import { SCRIPT_RUNNER_SERVICE, IScriptRunnerService } from '@core/widgets/models/iscript-runner.service';
import { IContextMenuContext } from 'src/engine-sdk';
import { ContextService } from '@core/services/context.service';
import {
  ENGINE_DATA_CONTEXT_PROVIDER,
  IEngineDataContext,
  IEngineDataContextProvider,
} from 'src/engine-sdk/contract/engine-data-context';
import { ContextMenuService } from './context-menu.service';
import { DisplayMode, IContextMenuState } from './models/context-menu-state.model';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Component({
  selector: 'app-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss'],
  providers: [
    {
      provide: WidgetDirective,
      useExisting: forwardRef(() => ContextMenuComponent),
    },
    {
      provide: ENGINE_DATA_CONTEXT_PROVIDER,
      useExisting: forwardRef(() => ContextMenuComponent),
    },
    ContextMenuService,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContextMenuComponent
  extends NoEventWidgetDirective
  implements IContextMenuContext, IEngineDataContextProvider
{
  private _contextMenu: ContextMenuDto;
  private _additionalDataContext: any;
  private _isInitiated$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _contextChanged$: Subject<void> = new Subject();
  private _containerWidth: number = 0;

  @Input() set contextMenu(contextMenu) {
    this._contextMenu = contextMenu;
    this.tryToInit();
  }
  @Input()
  set additionalDataContext(v: any) {
    this._additionalDataContext = v;
    this.tryToInit();
  }
  @Input('disabled')
  set isDisabled(isDisabled: boolean) {
    if (!!this._contextMenuService.isInitiated()) {
      this._contextMenuService.setIsDisabled(isDisabled);
    } else {
      this._contextMenuService.updateInitialState({ isDisabled: isDisabled } as IContextMenuState);
    }
  }
  @Input() set displayMode(mode: DisplayMode) {
    if (!!this._contextMenuService.isInitiated()) {
      this._contextMenuService.setDisplayMode(mode);
    } else {
      this._contextMenuService.updateInitialState({ displayMode: mode } as IContextMenuState);
    }
  }
  @Output() widthChanged = new EventEmitter<number>();

  definition$ = this._contextMenuService.getDefinitionAsync();
  state$ = this._contextMenuService.getStateAsync().pipe(tap(() => this.onContainerWidthChange(this._containerWidth)));

  get contextMenu(): ContextMenuDto {
    return this._contextMenu;
  }
  get dataContext(): any {
    return this.getDataContext();
  }
  get additionalDataContext(): any {
    return this._additionalDataContext;
  }

  constructor(
    @Optional() @SkipSelf() parentWidget: WidgetDirective,
    @Inject(SCRIPT_RUNNER_SERVICE) scriptRunnerService: IScriptRunnerService,
    @Optional()
    @SkipSelf()
    @Inject(ENGINE_DATA_CONTEXT_PROVIDER)
    private _engineDataContextProvider: IEngineDataContextProvider,
    private _contextService: ContextService,
    private _contextMenuService: ContextMenuService,
  ) {
    super(parentWidget, scriptRunnerService);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  tryToInit() {
    if (this.contextMenu && this.additionalDataContext) {
      this._contextMenuService.init(this.contextMenu);
      this._contextService.registerContextMenu(this);
      this._minWidgetsNumber = this.contextMenu.sections.flatMap((s) => s.buttons).length;
      if (!this._isInitiated$.value) {
        this._contextChanged$.next();
      } else {
        this._isInitiated$.next(true);
      }
    }
  }

  onContainerWidthChange(event: number): void {
    if (Number.isInteger(event)) {
      this._containerWidth = event;
      this.widthChanged.emit(event);
    }
  }

  //#region IContextMenuContext
  setButtonVisibility(name: string, value: boolean): void {
    const buttonId = this._contextMenuService.getButtonIdByName(name);
    this._contextMenuService.setButtonIsVisible(buttonId, value);
  }

  getButtonVisibility(name: string): boolean {
    const buttonId = this._contextMenuService.getButtonIdByName(name);
    return this._contextMenuService.getState().buttons[buttonId].isVisible;
  }
  //#endregion

  //#region IEngineDataContextProvider
  getDataContext(): IEngineDataContext {
    const parentDataContext = this._engineDataContextProvider?.getDataContext() ?? {};
    return this.additionalDataContext ? { ...parentDataContext, ...this.additionalDataContext } : parentDataContext;
  }

  protected override isWidgetRefreshed(): Observable<boolean> {
    return this._contextChanged$.asObservable().pipe(map(() => true));
  }

  protected override isWidgetInitiated(): Observable<boolean> {
    return this._isInitiated$.asObservable().pipe(map(() => true));
  }
  //#endregion
}
