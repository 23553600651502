import { Injectable } from '@angular/core';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, first, map, tap } from 'rxjs/operators';
import { HttpClient, HttpContext } from '@angular/common/http';
import { environment } from '@env';
import { PreFetchExecutionEvent } from '@core/execution-context';
import { IAutocompleteOption } from '@shared/reactive-controls/components/autocomplete/autocomplete-control.model';
import { IEngineODataState } from 'src/engine-sdk/contract/odata/iodata-state.model';
import { EngineODataStateParser } from '@core/engine-odata/services/odata-state-parser.service';
import { IGNORE_BUSY_INDICATOR } from '@core/layout/components/busy-indicator/busy-indicator.interceptor';
import { LodashService } from '@core/services/lodash.service';
import { RecordDto } from '../../services/api-clients';

export interface ILookupServiceState {
  value: any;
  searchText: string;
  odataQuery: IEngineODataState;
  targetEntity: RecordDto;
  labelFieldName: string;
}

@Injectable()
export class EngineLookupService {
  private readonly BASE_URL = environment.urls.ODataUrl;
  private _stateBs: BehaviorSubject<ILookupServiceState> = new BehaviorSubject({
    value: undefined,
    searchText: undefined,
    odataQuery: {
      take: 50,
      filter: <CompositeFilterDescriptor>{
        logic: 'and',
        filters: [],
      },
    },
    targetEntity: undefined,
    labelFieldName: undefined,
  });
  private _options = new BehaviorSubject<IAutocompleteOption[]>([]);
  private _preFetch: Subject<PreFetchExecutionEvent> = new Subject();

  state$ = this._stateBs.asObservable().pipe(distinctUntilChanged());
  options$ = this._options.asObservable();
  preFetch$ = this._preFetch.asObservable();

  get currentState(): ILookupServiceState {
    return this._stateBs.value;
  }
  get odataQuery() {
    return this.currentState.odataQuery;
  }

  constructor(private _http: HttpClient) {
    this.state$
      .pipe(
        filter((state) => !!state.targetEntity && !!state.labelFieldName),
        tap((state) => {
          if (this._options.value.find(x => x.value == state.value) == null) {

            this.fetchOptions(state.value, state.searchText);;
          }
        })
      )
      .subscribe();
  }

  init(targetEntity: RecordDto, labelFieldName: string, selectedId: string) {
    if (!targetEntity || !labelFieldName) return;

    this.currentState.targetEntity = targetEntity;
    this.currentState.labelFieldName = labelFieldName;
    this.currentState.value = selectedId;

    this._stateBs.next(selectedId ? { ...this.currentState } : this.currentState);
  }

  selectOption(selectedId: string) {
    if (this.currentState.value == selectedId) return;
    this._stateBs.next({ ...this.currentState, value: selectedId });
  }

  clearOptionSelection() {
    if (this.currentState.value == null) return;

    this.currentState.value = null;
    this._stateBs.next(this.currentState);
  }

  search(searchText: string) {
    this.currentState.searchText = searchText;
    this._stateBs.next(this.currentState);

    this._preFetch.next(
      new PreFetchExecutionEvent(() =>
        this.fetchOptions(this.currentState.value, searchText)
      ));
  }

  addFilter(filter: CompositeFilterDescriptor) {
    this.currentState.odataQuery.filter.filters.push(filter);
    this._stateBs.next(this.currentState);
  }

  setFilter(filter: CompositeFilterDescriptor) {
    this.currentState.odataQuery.filter = filter;
    this._stateBs.next(this.currentState);
  }

  setCustomFilter(filter: string) {
    this.currentState.odataQuery.customFilter = filter;
    this._stateBs.next(this.currentState);
  }

  private fetchOptions(selectedId: string, searchText: string): void {
    if (!this.currentState.targetEntity || !this.currentState.labelFieldName) {
      throw new Error(
        `Invalid lookup definition (targetEntity: ${this.currentState.targetEntity}, labelField: ${this.currentState.labelFieldName} )`,
      );
    }

    const searchState = selectedId
      ? this.getQueryByRecordId(selectedId)
      : this.getQueryBasedOnCurrentFiltersAndSearchTerm(searchText);

    this.query(this.currentState.targetEntity.name, this.currentState.labelFieldName, searchState)
      .pipe(
        first(),
        tap((options) => this._options.next(options)),
      )
      .subscribe();
  }

  private getQueryBasedOnCurrentFiltersAndSearchTerm(searchTerm: string): IEngineODataState {
    const query = LodashService.cloneDeep(this.currentState.odataQuery);
    if (searchTerm) {
      query.filter.filters.push({
        field: this.currentState.labelFieldName,
        operator: 'contains',
        value: searchTerm,
      });
    }
    return query;
  }

  private getQueryByRecordId(recordId: string): IEngineODataState {
    if (!this.isGuid(recordId))
      throw {
        error: 'It is not possible to get the record into lookup because record id is invalid',
      };

    return {
      take: 1,
      skip: 0,
      filter: {
        logic: 'and',
        filters: [
          {
            field: 'Id',
            operator: 'eq',
            value: recordId,
          },
        ],
      },
    };
  }

  private query(entityName: string, showField: string, state: IEngineODataState): Observable<IAutocompleteOption[]> {
    let url = `${this.BASE_URL}/${entityName}?${EngineODataStateParser.toODataString(
      state,
    )}& $select=Id, ${showField}& $orderby=${showField}`;

    url = url.replace(/'([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})'/gi, '$1');

    const context = new HttpContext();
    context.set(IGNORE_BUSY_INDICATOR, true);
    return this._http.get(`${url}`, { context }).pipe(
      map((res) => res['value']),
      map((data) =>
        data.map((item) => {
          return { value: item.Id, label: item[showField] };
        }),
      ),
    );
  }

  private isGuid(string: string): boolean {
    if (string[0] === '{') string = string.substring(1, string.length - 1);

    const regexGuid =
      /^(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}$/gi;
    return regexGuid.test(string);
  }
}
