import { ActionReducerMap } from '@ngrx/store';
import { formReducer } from './reducers';
import { IFormState } from './state';
import * as FormSelectors from './selectors';
import * as FormActions from './actions';

export { FormActions, FormSelectors };

export interface FormFeatureState {
  form: IFormState;
}

export const reducers: ActionReducerMap<FormFeatureState> = {
  form: formReducer,
};
