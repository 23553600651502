import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { Store } from '@ngrx/store';
import { takeUntil, tap, filter, distinctUntilChanged, take } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { LoadMainContextMenu, ResetMainContextMenu } from '../store/actions';
import { getMainContextMenu } from '../store/selectors';
import { NavigationSelectors } from '@core/navigation/store';
import { ContextMenuComponent } from '../context-menu/context-menu.component';

@Component({
  selector: 'app-main-context-menu',
  templateUrl: './main-context-menu.component.html',
  styleUrls: ['./main-context-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainContextMenuComponent implements OnInit, OnDestroy {
  private _destroy$: Subject<boolean> = new Subject<boolean>();

  @ViewChild(ContextMenuComponent) menu: ContextMenuComponent;

  mainContextMenu$ = this._store.select(getMainContextMenu);

  constructor(private _store: Store) {}

  ngOnInit() {
    const navigationPath$ = this._store.select(NavigationSelectors.getCurrentNavigationPath);
    navigationPath$
      .pipe(
        takeUntil(this._destroy$),
        filter((path) => path != null),
        distinctUntilChanged(),
        take(1),
        tap(() => {
          this._store.dispatch(new LoadMainContextMenu());
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._store.dispatch(new ResetMainContextMenu());
    this._destroy$.next(true);
    this._destroy$.complete();
  }
}
