import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ReactiveControlDirective } from '@shared/ui-components/directives/reactive-control.directive';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SelectComponent,
      multi: true,
    },
  ],
})
export class SelectComponent extends ReactiveControlDirective<any | undefined> {
  @Input() placeholder: string = '';
  @Input() options: { value?: any; label?: string }[] = [];
}
