import { LayoutActions, LayoutActionTypes } from './actions';
import { initialLayoutState, ILayoutState } from './state';

export function layoutReducer(state = initialLayoutState, action: LayoutActions): ILayoutState {
  switch (action.type) {
    case LayoutActionTypes.InitLayoutSuccess: {
      return {
        ...state,
        isLeftPanelExpanded: action.payload.isLeftPanelExpanded,
      };
    }

    case LayoutActionTypes.OpenRightPanel: {
      return {
        ...state,
        isRightPanelVisible: true,
      };
    }

    case LayoutActionTypes.CloseRightPanel: {
      return {
        ...state,
        isRightPanelVisible: false,
      };
    }

    case LayoutActionTypes.OpenLeftPanel: {
      return {
        ...state,
        isLeftPanelVisible: true,
      };
    }

    case LayoutActionTypes.CloseLeftPanel: {
      return {
        ...state,
        isLeftPanelVisible: false,
      };
    }

    case LayoutActionTypes.ToggleLeftPanel: {
      return {
        ...state,
        isLeftPanelVisible: !state.isLeftPanelVisible,
      };
    }

    case LayoutActionTypes.ToggleIsLeftPanelExpanded: {
      return {
        ...state,
        isLeftPanelExpanded: !state.isLeftPanelExpanded,
      };
    }

    case LayoutActionTypes.SaveNewScreenWidth: {
      return {
        ...state,
        screenWidth: action.payload.screenWidth,
      };
    }

    default:
      return state;
  }
}
