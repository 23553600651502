import { Pipe, PipeTransform } from '@angular/core';
import { IConfig, NgxMaskPipe } from 'ngx-mask';

@Pipe({ name: 'mask' })
export class MaskPipe implements PipeTransform {
  private maskPipe: NgxMaskPipe = new NgxMaskPipe();

  transform(value: any, mask: string, config?: Partial<IConfig>): string {
    if (mask) {
      return this.maskPipe.transform(value, mask, config);
    }

    return value;
  }
}
