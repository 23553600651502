import { ActionReducerMap } from '@ngrx/store';
import { kanbanReducer } from './reducers';
import { IKanbanState } from './state';
import * as KanbanSelectors from './selectors';
import * as KanbanActions from './actions';

export { KanbanActions, KanbanSelectors };

export interface KanbanFeatureState {
  kanban: IKanbanState;
}

export const reducers: ActionReducerMap<KanbanFeatureState> = {
  kanban: kanbanReducer,
};
