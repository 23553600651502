<mat-form-field>
  <mat-select [formControl]="formControl" [placeholder]="placeholder">
    <mat-option *ngFor="let option of options" [value]="option.value">
      {{ option.label }}
    </mat-option>
  </mat-select>
  <button class="autocomplete-action-button visible-on-edit-mode" matSuffix mat-icon-button>
    <mat-icon>arrow_drop_down</mat-icon>
  </button>
</mat-form-field>
