import { Pipe, PipeTransform } from '@angular/core';
import { GridColumn } from '../models/grid-definition.model';
import { LookupPipe } from './lookup.pipe';

@Pipe({ name: 'iconUrl' })
export class IconUrlPipe implements PipeTransform {
  private _lookupPipe: LookupPipe = new LookupPipe();

  transform(dataItem: any, column: GridColumn): string {
    if (!dataItem || column.iconMode != 'url') return null;

    if (column.iconMode == 'url') {
      const value = this._lookupPipe.transform(dataItem, column);
      return value || column.iconUrl;
    }
  }
}
