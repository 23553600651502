import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { KanbanColumnDto, KanbanControlDto } from '@core/services/api-clients';

@Component({
  selector: 'app-kanban-column-tile',
  templateUrl: './kanban-column-tile.component.html',
  styleUrls: ['./kanban-column-tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KanbanColumnTileComponent {
  @Input() kanbanColumn: KanbanColumnDto;
  @Input() kanbanControls: KanbanControlDto[];
  @Input() record: any;

  @HostBinding('style') get style() {
    return `
      border-color: ${this.kanbanColumn.htmlColor};
    `;
  }
}
