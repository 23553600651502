<button
  type="button"
  *ngFor="let star of stars"
  class="star-icon-button"
  mat-icon-button
  [id]="'star_' + star.value"
  [disabled]="disabled"
  [color]="color"
  (click)="onClick(star)"
  [matTooltip]="star.label"
  matTooltipPosition="above">
  <mat-icon class="star-icon" [class.default-color]="!color">
    {{ getIcon(star.value) }}
  </mat-icon>
</button>
