import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  SaveColumnExpansion,
  KanbanActions,
  KanbanActionTypes,
  LoadEntityKanban,
  LoadEntityKanbanSuccess,
  LoadKanbanData,
  LoadKanbanDataSuccess,
  LoadMore,
  LoadMoreSuccess,
  MoveRecord,
  MoveRecordSuccess,
  RecalculateRecordsStackRank,
  RecalculateRecordsStackRankSuccess,
  RefreshAggregators,
  RefreshAggregatorsSuccess,
  SaveDefaultAggregator,
  RestoreSettings,
  RestoreSettingsSuccess,
  FilterRecords,
  SyncKanbanSettings,
} from './actions';
import { select, Store } from '@ngrx/store';
import { catchError, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { getCurrentKanbanData, getCurrentSearchTerm, getKanbans } from './selectors';
import { EMPTY, forkJoin, of } from 'rxjs';
import { KanbanClient } from '@core/services/api-clients';
import { KanbanDataService, KANBAN_SERVICE_TOKEN } from '@app/main-kanban/services/kanban-data.service';
import { KanbanSettingsService } from '@app/main-kanban/services/kanban-settings.service';
import { AddQueryParams, RemoveQueryParams, RouterActions } from '@core/router/store/actions';
import { getCurrentUserId } from '@core/user/store/selectors';
import { HandleError } from '../../../core/errors/store/actions';

@Injectable()
export class KanbanEffects {
  loadEntityKanban$ = createEffect(() =>
    this._actions$.pipe(
      ofType<LoadEntityKanban>(KanbanActionTypes.LoadEntityKanban),
      map((action) => action.payload.kanbanId),
      withLatestFrom(this._store.select(getKanbans)),
      mergeMap(([kanbanId, kanbans]) => {
        const isKanbanLoaded = kanbans[kanbanId] != null;
        if (isKanbanLoaded) {
          return of(new LoadKanbanData({ kanban: kanbans[kanbanId] }));
        } else {
          return this._kanbanClient.getEntityKanban(kanbanId).pipe(
            mergeMap((kanban) => {
              return [new LoadEntityKanbanSuccess({ kanban }), new LoadKanbanData({ kanban })];
            }),
          );
        }
      }),
    ),
  );

  loadKanbanData$ = createEffect(() =>
    this._actions$.pipe(
      ofType<LoadKanbanData>(KanbanActionTypes.LoadKanbanData),
      map((action) => action.payload),
      withLatestFrom(this._store.select(getCurrentSearchTerm)),
      mergeMap(([payload, searchTerm]) =>
        this._kanbanDataService
          .getKanbanData(
            payload.kanban,
            payload.columnsPageSize,
            payload.newSearchTerm !== undefined ? payload.newSearchTerm : searchTerm,
          )
          .pipe(mergeMap((kanbanData) => of(new LoadKanbanDataSuccess({ kanban: payload.kanban, kanbanData })))),
      ),
    ),
  );

  moveRecord$ = createEffect(() =>
    this._actions$.pipe(
      ofType<MoveRecord>(KanbanActionTypes.MoveRecord),
      map((action) => action.payload.moveRecordEvent),
      withLatestFrom(this._store.select(getCurrentKanbanData)),
      mergeMap(([moveRecordEvent, kanbanData]) => {
        const relatedValue = moveRecordEvent.currentColumn.relatedValue;

        const upStackRank =
          moveRecordEvent.currentIndex > 0
            ? kanbanData.columns[moveRecordEvent.currentColumn.id].records[moveRecordEvent.currentIndex - 1][
            moveRecordEvent.kanban.stackRankAttribute.name
            ]
            : 10;
        const downStackRank =
          moveRecordEvent.currentIndex < kanbanData.columns[moveRecordEvent.currentColumn.id].records.length - 1
            ? kanbanData.columns[moveRecordEvent.currentColumn.id].records[moveRecordEvent.currentIndex + 1][
            moveRecordEvent.kanban.stackRankAttribute.name
            ]
            : 2000000000000;
        const newStackRank =
          kanbanData.columns[moveRecordEvent.currentColumn.id].records.length == 1
            ? moveRecordEvent.record[moveRecordEvent.kanban.stackRankAttribute.name]
            : (upStackRank + downStackRank) / 2;

        return this._kanbanDataService
          .update(moveRecordEvent.kanban, moveRecordEvent.record['Id'], relatedValue, newStackRank)
          .pipe(
            mergeMap((_) => {
              const actions: KanbanActions[] = [new MoveRecordSuccess({ moveRecordEvent, newStackRank })];

              if (moveRecordEvent.record[moveRecordEvent.kanban.relatedAttribute.logicalName] != relatedValue) {
                actions.push(
                  new RefreshAggregators({
                    kanban: moveRecordEvent.kanban,
                    kanbanColumns: [moveRecordEvent.previousColumn, moveRecordEvent.currentColumn],
                  }),
                );
              }

              if (
                newStackRank != moveRecordEvent.record[moveRecordEvent.kanban.stackRankAttribute.name] &&
                Math.abs(newStackRank - moveRecordEvent.record[moveRecordEvent.kanban.stackRankAttribute.name]) <= 10
              ) {
                actions.push(new RecalculateRecordsStackRank({ kanban: moveRecordEvent.kanban }));
              }

              return actions;
            }),
            catchError((error) => {
              moveRecordEvent.onError();
              this._store.dispatch(new HandleError({ error }));

              return EMPTY;
            }),
          );
      }),
    ),
  );

  refreshAggregators$ = createEffect(() =>
    this._actions$.pipe(
      ofType<RefreshAggregators>(KanbanActionTypes.RefreshAggregators),
      map((action) => action.payload),
      withLatestFrom(this._store.select(getCurrentSearchTerm)),
      mergeMap(([payload, searchTerm]) => {
        return forkJoin(
          payload.kanbanColumns.map((column) =>
            this._kanbanDataService
              .getKanbanColumnAggregators(payload.kanban, column, searchTerm)
              .pipe(
                mergeMap((aggregators) =>
                  of({ kanban: payload.kanban, kanbanColumn: column, aggregators: aggregators }),
                ),
              ),
          ),
        ).pipe(mergeMap((results) => of(new RefreshAggregatorsSuccess(results))));
      }),
    ),
  );

  loadMore$ = createEffect(() =>
    this._actions$.pipe(
      ofType<LoadMore>(KanbanActionTypes.LoadMore),
      map((action) => action.payload),
      withLatestFrom(this._store.select(getCurrentKanbanData), this._store.select(getCurrentSearchTerm)),
      mergeMap(([payload, kanbanData, searchTerm]) => {
        return this._kanbanDataService
          .getKanbanColumnRecords(
            payload.kanban,
            payload.kanbanColumn,
            payload.kanbanColumn.pageSize + kanbanData.columns[payload.kanbanColumn.id].pageSize,
            searchTerm,
          )
          .pipe(
            mergeMap((records) =>
              of(new LoadMoreSuccess({ kanban: payload.kanban, kanbanColumn: payload.kanbanColumn, records })),
            ),
          );
      }),
    ),
  );

  recalculateRecordsStackRank$ = createEffect(() =>
    this._actions$.pipe(
      ofType<RecalculateRecordsStackRank>(KanbanActionTypes.RecalculateRecordsStackRank),
      map((action) => action.payload.kanban),
      withLatestFrom(this._store.select(getCurrentKanbanData)),
      mergeMap(([kanban, kanbanData]) => {
        return this._kanbanClient.recalculateRecordsStackRank(kanban.id).pipe(
          mergeMap((_) => {
            let columnsPageSize = {};
            kanban.columns.forEach((c) => (columnsPageSize[c.id] = kanbanData.columns[c.id].pageSize));

            return [
              new RecalculateRecordsStackRankSuccess({ kanban }),
              new LoadKanbanData({ kanban, columnsPageSize }),
            ];
          }),
        );
      }),
    ),
  );

  saveDefaultAggregator$ = createEffect(() =>
    this._actions$.pipe(
      ofType<SaveDefaultAggregator>(KanbanActionTypes.SaveDefaultAggregator),
      map((action) => action.payload),
      withLatestFrom(this._store.pipe(select(getCurrentUserId))),
      tap(([payload, userId]) => {
        KanbanSettingsService.saveDefaultAggregator(payload.kanbanColumn, payload.kanbanColumnAggregator, userId);
      }),
      mergeMap((_) => of(new SyncKanbanSettings())),
    ),
  );

  saveColumnExpansion$ = createEffect(() =>
    this._actions$.pipe(
      ofType<SaveColumnExpansion>(KanbanActionTypes.SaveColumnExpansion),
      map((action) => action.payload),
      withLatestFrom(this._store.pipe(select(getCurrentUserId))),
      tap(([payload, userId]) => {
        KanbanSettingsService.saveColumnExpansion(payload.kanbanColumn, payload.isExpanded, userId);
      }),
      mergeMap((_) => of(new SyncKanbanSettings())),
    ),
  );

  restoreSettings$ = createEffect(() =>
    this._actions$.pipe(
      ofType<RestoreSettings>(KanbanActionTypes.RestoreSettings),
      map((action) => action.payload),
      withLatestFrom(this._store.pipe(select(getCurrentUserId))),
      tap(([payload, userId]) => {
        KanbanSettingsService.restoreSettings(payload.kanban, userId);
      }),
      mergeMap(([payload, _]) => of(new RestoreSettingsSuccess({ kanban: payload.kanban }))),
    ),
  );

  filterRecords$ = createEffect(() =>
    this._actions$.pipe(
      ofType<FilterRecords>(KanbanActionTypes.FilterRecords),
      map((action) => action.payload),
      mergeMap((payload) => {
        const actions: (KanbanActions | RouterActions)[] = [];

        if (payload.searchTerm) {
          actions.push(new AddQueryParams({ queryParams: { searchTerm: payload.searchTerm } }));
        } else {
          actions.push(new RemoveQueryParams({ queryParamsKeys: ['searchTerm'] }));
        }

        actions.push(new LoadKanbanData({ kanban: payload.kanban, newSearchTerm: payload.searchTerm }));
        return actions;
      }),
    ),
  );

  constructor(
    private _actions$: Actions,
    private _store: Store,
    private _kanbanClient: KanbanClient,
    @Inject(KANBAN_SERVICE_TOKEN) private _kanbanDataService: KanbanDataService,
  ) { }
}
