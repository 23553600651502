import { moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { KanbanDto } from '@core/services/api-clients';
import { KanbanActions, KanbanActionTypes } from './actions';
import { IKanbanState, initialKanbanState } from './state';

export function kanbanReducer(state = initialKanbanState, action: KanbanActions): IKanbanState {
  switch (action.type) {
    case KanbanActionTypes.LoadEntityKanbanSuccess: {
      return {
        ...state,
        kanbans: {
          ...state.kanbans,
          [action.payload.kanban.id]: action.payload.kanban,
        },
      };
    }

    case KanbanActionTypes.RestoreSettingsSuccess: {
      return {
        ...state,
        kanbans: {
          ...state.kanbans,
          [action.payload.kanban.id]: KanbanDto.fromJS(state.kanbans[action.payload.kanban.id]),
        },
      };
    }

    case KanbanActionTypes.LoadKanbanDataSuccess: {
      return {
        ...state,
        kanbansData: {
          ...state.kanbansData,
          [action.payload.kanban.id]: action.payload.kanbanData,
        },
      };
    }

    case KanbanActionTypes.MoveRecordSuccess: {
      const kanbanId = action.payload.moveRecordEvent.kanban.id;
      const previousColumnId = action.payload.moveRecordEvent.previousColumn.id;
      const currentColumnId = action.payload.moveRecordEvent.currentColumn.id;
      const previousColumnRecords = [...state.kanbansData[kanbanId].columns[previousColumnId].records];
      const currentColumnRecords = [...state.kanbansData[kanbanId].columns[currentColumnId].records];

      let recordIndex = previousColumnRecords.findIndex((x) => x.Id == action.payload.moveRecordEvent.record.Id);
      previousColumnRecords[recordIndex] = {
        ...previousColumnRecords[recordIndex],
        [action.payload.moveRecordEvent.kanban.relatedAttribute.logicalName]:
          action.payload.moveRecordEvent.currentColumn.relatedValue,
        [action.payload.moveRecordEvent.kanban.stackRankAttribute.name]: action.payload.newStackRank,
      };

      if (previousColumnId === currentColumnId) {
        moveItemInArray(
          previousColumnRecords,
          action.payload.moveRecordEvent.previousIndex,
          action.payload.moveRecordEvent.currentIndex,
        );
      } else {
        transferArrayItem(
          previousColumnRecords,
          currentColumnRecords,
          action.payload.moveRecordEvent.previousIndex,
          action.payload.moveRecordEvent.currentIndex,
        );
      }
      if (previousColumnId === currentColumnId) {
        return {
          ...state,
          kanbansData: {
            ...state.kanbansData,
            [kanbanId]: {
              ...state.kanbansData[kanbanId],
              columns: {
                ...state.kanbansData[kanbanId].columns,
                [previousColumnId]: {
                  ...state.kanbansData[kanbanId].columns[previousColumnId],
                  records: previousColumnRecords,
                },
              },
            },
          },
        };
      } else {
        return {
          ...state,
          kanbansData: {
            ...state.kanbansData,
            [kanbanId]: {
              ...state.kanbansData[kanbanId],
              columns: {
                ...state.kanbansData[kanbanId].columns,
                [previousColumnId]: {
                  ...state.kanbansData[kanbanId].columns[previousColumnId],
                  records: previousColumnRecords,
                  pageSize: state.kanbansData[kanbanId].columns[previousColumnId].pageSize - 1,
                },
                [currentColumnId]: {
                  ...state.kanbansData[kanbanId].columns[currentColumnId],
                  records: currentColumnRecords,
                  pageSize: state.kanbansData[kanbanId].columns[currentColumnId].pageSize + 1,
                },
              },
            },
          },
        };
      }
    }

    case KanbanActionTypes.RefreshAggregatorsSuccess: {
      let newState = { ...state };

      for (const columnAggregators of action.payload) {
        newState = {
          ...newState,
          kanbansData: {
            ...newState.kanbansData,
            [columnAggregators.kanban.id]: {
              ...newState.kanbansData[columnAggregators.kanban.id],
              columns: {
                ...newState.kanbansData[columnAggregators.kanban.id].columns,
                [columnAggregators.kanbanColumn.id]: {
                  ...newState.kanbansData[columnAggregators.kanban.id].columns[columnAggregators.kanbanColumn.id],
                  aggregators:
                    columnAggregators.aggregators == null
                      ? null
                      : {
                          ...newState.kanbansData[columnAggregators.kanban.id].columns[
                            columnAggregators.kanbanColumn.id
                          ].aggregators,
                          ...columnAggregators.aggregators,
                        },
                },
              },
            },
          },
        };
      }

      return newState;
    }

    case KanbanActionTypes.LoadMoreSuccess: {
      return {
        ...state,
        kanbansData: {
          ...state.kanbansData,
          [action.payload.kanban.id]: {
            ...state.kanbansData[action.payload.kanban.id],
            columns: {
              ...state.kanbansData[action.payload.kanban.id].columns,
              [action.payload.kanbanColumn.id]: {
                ...state.kanbansData[action.payload.kanban.id].columns[action.payload.kanbanColumn.id],
                records: [...action.payload.records],
                pageSize:
                  state.kanbansData[action.payload.kanban.id].columns[action.payload.kanbanColumn.id].pageSize +
                  state.kanbans[action.payload.kanban.id].columns.find((x) => x.id == action.payload.kanbanColumn.id)
                    .pageSize,
              },
            },
          },
        },
      };
    }

    case KanbanActionTypes.SyncKanbanSettings: {
      return {
        ...state,
        settingsRefreshFlag: state.settingsRefreshFlag + 1,
      };
    }

    default:
      return state;
  }
}
