import { Action } from '@ngrx/store';
import { FormDto } from '@core/services/api-clients';

export interface IPerformOpertionAndNavigate {
  entityName: string;
  entity: any;
  entityId: string;
  recordId: string;
  formId: string;
  onOperationSuccess: () => void;
  onOperationFailure: (error) => void;
  onNavigationSuccess: () => void;
}

export enum FormActionTypes {
  CreateEntity = '[Form] Create Entity',
  CreateEntityAndNavigateToUpadateForm = '[Form] Create Entity And Navigate To Upadate Form',
  CreateEntityAndNavigateBack = '[Form] Create Entity And Navigate Back',
  CreateEntityAndNavigateToCreateForm = '[Form] Create Entity And Navigate To Create Form',

  UpdateEntity = '[Form] Update Entity',
  UpdateEntityAndNavigateBack = '[Form] Update Entity And Navigate Back',
  UpdateEntityAndNavigateToCreateForm = '[Form] Update Entity And Navigate To Create Form',

  DeleteEntity = '[Form] Delete Entity',
  DeleteEntities = '[Form] Delete Entities',

  LoadCurrentFormDto = '[Form] Load Current Form Dto',
  LoadEntityFormDto = '[Form] Load Entity Form Dto',
  LoadEntityFormDtoSuccess = '[Form] Load Entity Form Dto Success',

  SaveSubGridSelectedView = '[Form] Save SubGrid Selected View',
}
export class CreateEntity implements Action {
  public readonly type = FormActionTypes.CreateEntity;
  constructor(
    public payload: {
      entityName: string;
      entity: {};
      onSuccess: (recordId: string) => void;
      onFailure: (error) => void;
    },
  ) {}
}

export class CreateEntityAndNavigateToUpadateForm implements Action {
  public readonly type = FormActionTypes.CreateEntityAndNavigateToUpadateForm;
  constructor(public payload: IPerformOpertionAndNavigate) {}
}

export class CreateEntityAndNavigateBack implements Action {
  public readonly type = FormActionTypes.CreateEntityAndNavigateBack;
  constructor(public payload: IPerformOpertionAndNavigate) {}
}
export class CreateEntityAndNavigateToCreateForm implements Action {
  public readonly type = FormActionTypes.CreateEntityAndNavigateToCreateForm;
  constructor(public payload: IPerformOpertionAndNavigate) {}
}

export class UpdateEntity implements Action {
  public readonly type = FormActionTypes.UpdateEntity;
  constructor(
    public payload: {
      entityName: string;
      entityId: string;
      entity: {};
      onSuccess: () => void;
      onFailure: (error) => void;
    },
  ) {}
}

export class UpdateEntityAndNavigateBack implements Action {
  public readonly type = FormActionTypes.UpdateEntityAndNavigateBack;
  constructor(public payload: IPerformOpertionAndNavigate) {}
}

export class UpdateEntityAndNavigateToCreateForm implements Action {
  public readonly type = FormActionTypes.UpdateEntityAndNavigateToCreateForm;
  constructor(public payload: IPerformOpertionAndNavigate) {}
}

export class DeleteEntity implements Action {
  public readonly type = FormActionTypes.DeleteEntity;
  constructor(
    public payload: {
      entityName: string;
      entityId: string;
      onSuccess: () => void;
    },
  ) {}
}

export class DeleteEntities implements Action {
  public readonly type = FormActionTypes.DeleteEntities;
  constructor(
    public payload: {
      entityName: string;
      entityIds: string[];
      onSuccess: () => void;
    },
  ) {}
}

export class LoadCurrentFormDto implements Action {
  public readonly type = FormActionTypes.LoadCurrentFormDto;
  constructor(public payload?: { forceReload?: boolean }) {}
}

export class LoadEntityFormDto implements Action {
  public readonly type = FormActionTypes.LoadEntityFormDto;
  constructor(public payload: { formId: string }) {}
}

export class LoadEntityFormDtoSuccess implements Action {
  public readonly type = FormActionTypes.LoadEntityFormDtoSuccess;
  public constructor(public payload: { form: FormDto }) {}
}

export class SaveSubGridSelectedView implements Action {
  public readonly type = FormActionTypes.SaveSubGridSelectedView;
  public constructor(public payload: { subgridId: string; viewId: string }) {}
}

export type FormActions =
  | CreateEntity
  | CreateEntityAndNavigateToUpadateForm
  | CreateEntityAndNavigateBack
  | CreateEntityAndNavigateToCreateForm
  | UpdateEntity
  | UpdateEntityAndNavigateBack
  | UpdateEntityAndNavigateToCreateForm
  | DeleteEntity
  | DeleteEntities
  | LoadCurrentFormDto
  | LoadEntityFormDto
  | LoadEntityFormDtoSuccess
  | SaveSubGridSelectedView;
