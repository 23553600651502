import { ScaleType } from '@swimlane/ngx-charts';

export enum ChartType {
  VerticalBar,
  StackedVerticalBar,
  GroupedVerticalBar,
  NormalizedVerticalBar,
  HorizontalBar,
  GroupedHorizontalBar,
  StackedHorizontalBar,
  NormalizedHorizontalBar,
  Pie,
  AdvancedPie,
  PieGrid,
  Line,
  Area,
  StackedArea,
  NormalizedArea,
  Card,
  Gauge,
}

export interface IChartConfiguration {
  chartType: ChartType;
  data: ChartData;
  showTypeSelector?: boolean;
  view?: [number, number];
  scheme?: ChartPredefinedSchema | ChartColorSettings;
  cardColor?: string;
  showLegend?: boolean;
  legendPosition?: ChartLegendPosition;
  showXAxis?: boolean;
  showXAxisLabel?: boolean;
  xAxisLabel?: string;
  showYAxis?: boolean;
  showYAxisLabel?: boolean;
  yAxisLabel?: string;
  animations?: boolean;
  gradient?: boolean;
  doughnut?: boolean;
  customColors?: IChartCustomColor[] | (() => IChartCustomColor[]);
  xAxisTickFormatting?: (value: any) => string;
}

export type ChartPredefinedSchema =
  | 'vivid'
  | 'natural'
  | 'cool'
  | 'fire'
  | 'solar'
  | 'air'
  | 'aqua'
  | 'flame'
  | 'ocean'
  | 'forest'
  | 'horizon'
  | 'neons'
  | 'picnic'
  | 'night'
  | 'nightLights';

export interface ChartColorSettings {
  name: string;
  selectable: boolean;
  group: ScaleType;
  domain: string[];
}

export type ChartLegendPosition = 'right' | 'below';

export type ChartData = IChartDataSimple[] | IChartDataComplex[];

export interface IChartDataSimple {
  name: string;
  value: number;
}

export interface IChartDataComplex {
  name: string;
  series: IChartDataSimple[];
}

export interface IChartCustomColor {
  name: string;
  value: string;
}
