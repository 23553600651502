import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { FEATURE_NAME } from './store/state';
import { KanbanEffects } from './store/effects';
import { SharedModule } from '@shared/shared.module';
import { KanbanComponent } from './kanban/kanban.component';
import { KanbanColumnComponent } from './kanban/kanban-column/kanban-column.component';
import { KanbanColumnHeaderComponent } from './kanban/kanban-column/kanban-column-header/kanban-column-header.component';
import { KanbanColumnTileComponent } from './kanban/kanban-column/kanban-column-tile/kanban-column-tile.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MainKanbanComponent } from './main-kanban.component';
import { KanbanColumnAggregatorsComponent } from './kanban/kanban-column/kanban-column-header/kanban-column-aggregators/kanban-column-aggregators.component';
import { MatIconModule } from '@angular/material/icon';
import { DefaultKanbanDataService, KANBAN_SERVICE_TOKEN } from './services/kanban-data.service';
import { KanbanToolbarComponent } from './kanban/kanban-toolbar/kanban-toolbar.component';
import { KanbanControlValuePipe } from './pipes/kanban-control-value.pipe';
import { reducers } from './store';
import { NotificationModule } from '@core/notification/notification.module';
import { ContextMenuModule } from '@core/context-menu/context-menu.module';

@NgModule({
  declarations: [
    MainKanbanComponent,
    KanbanComponent,
    KanbanColumnComponent,
    KanbanColumnHeaderComponent,
    KanbanColumnAggregatorsComponent,
    KanbanColumnTileComponent,
    KanbanToolbarComponent,
    KanbanControlValuePipe,
  ],
  imports: [
    StoreModule.forFeature(FEATURE_NAME, reducers),
    EffectsModule.forFeature([KanbanEffects]),
    SharedModule,
    DragDropModule,
    MatIconModule,
    NotificationModule,
    ContextMenuModule,
  ],
  exports: [MainKanbanComponent, KanbanComponent],
  providers: [
    {
      provide: KANBAN_SERVICE_TOKEN,
      useExisting: DefaultKanbanDataService,
    },
  ],
})
export class MainKanbanModule {}
