<div *ngIf="userMenuGroup$ | async as userMenu">
  <app-menu-group-items
    [menuItems]="userMenu.menuItems"
    [isWide]="true"
    [isFavoriteEnabled]="false"
    [isSelectable]="false"
    [selectedMenuItemId]="selectedMenuItemId$ | async"
    (menuItemClick)="onMenuItemClick($event)">
  </app-menu-group-items>
  <mat-divider></mat-divider>
  <mat-nav-list>
    <mat-list-item [matMenuTriggerFor]="themeMenu.menu">
      <app-menu-item
        class="margin-right-10"
        [ignoreWidgetEvents]="true"
        [name]="'UserMenu_Theme'"
        [label]="'UserMenu.Theme' | translate"
        [shortLabel]="'UserMenu.Theme' | translate"
        [isWide]="true"
        [icon]="darkModeIcon$ | async">
      </app-menu-item>
      <app-theme-picker #themeMenu="themeMenu" [selectedTheme]="theme$ | async" (themeSelected)="changeTheme($event)">
      </app-theme-picker>
    </mat-list-item>
    <mat-list-item [matMenuTriggerFor]="languageMenu.menu">
      <app-menu-item
        class="margin-right-10"
        [ignoreWidgetEvents]="true"
        [name]="'UserMenu_SelectLanguage'"
        [label]="'UserMenu.SelectLanguage' | translate"
        [shortLabel]="'UserMenu.SelectLanguageShort' | translate"
        [isWide]="true"
        [icon]="languageIcon$ | async">
      </app-menu-item>
      <app-language-picker
        #languageMenu="languageMenu"
        [selectedLanguage]="userLanguage$ | async"
        [languages]="languages$ | async"
        (languageSelected)="selectLanguage($event)">
      </app-language-picker>
    </mat-list-item>
    <mat-list-item (click)="displayAboutInfo()">
      <app-menu-item
        class="margin-right-10"
        [ignoreWidgetEvents]="true"
        [name]="'UserMenu_About'"
        [label]="'UserMenu.About' | translate"
        [shortLabel]="'UserMenu.AboutShort' | translate"
        [isWide]="true"
        [icon]="aboutIcon$ | async">
      </app-menu-item>
    </mat-list-item>
    <mat-list-item *ngIf="isLogoutVisible()" (click)="logout()">
      <app-menu-item
        class="margin-right-10"
        [ignoreWidgetEvents]="true"
        [name]="'UserMenu_Logout'"
        [label]="'UserMenu.Logout' | translate"
        [shortLabel]="'UserMenu.LogoutShort' | translate"
        [isWide]="true"
        [icon]="logoutIcon$ | async">
      </app-menu-item>
    </mat-list-item>
  </mat-nav-list>
</div>
