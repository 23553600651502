import { Injectable, inject } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class CookiesService {
  private _cookieService = inject(CookieService);

  constructor() {}

  set(name: string, value: string, expires: number | Date = 30): void {
    this._cookieService.set(name, value, expires);
  }

  get(name: string): string {
    return this._cookieService.get(name);
  }

  exists(name: string): boolean {
    return this._cookieService.check(name);
  }
}
