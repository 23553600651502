<ng-container *ngIf="isExpanded">
  <div class="column-header" [style.border-color]="kanbanColumn.htmlColor" [style.background]="kanbanColumn.htmlColor">
    <div class="column-title" [matTooltip]="kanbanColumn.tooltip">
      {{ kanbanColumn.label }}
    </div>
    <button type="button" mat-icon-button class="expand-button" (click)="isExpandedChange.emit(!isExpanded)">
      <mat-icon>{{ isExpanded ? 'chevron_left' : 'chevron_right' }}</mat-icon>
    </button>
  </div>
  <app-kanban-column-aggregators
    [aggregators]="kanbanColumn.aggregators"
    [aggregatorsData]="aggregatorsData"
    (aggregatorSelect)="aggregatorSelect.emit($event)">
  </app-kanban-column-aggregators>
</ng-container>

<div *ngIf="!isExpanded" class="column-header-revert">
  <button type="button" mat-icon-button class="expand-button" (click)="isExpandedChange.emit(!isExpanded)">
    <mat-icon>{{ isExpanded ? 'chevron_left' : 'chevron_right' }}</mat-icon>
  </button>
  <div class="column-title revert" [matTooltip]="kanbanColumn.tooltip">
    {{ kanbanColumn.label }}
  </div>
</div>
