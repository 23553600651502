import { ErrorHandler, Inject, Injectable, Injector, OnDestroy } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { select, Store } from '@ngrx/store';
import { of, Subject } from 'rxjs';
import { catchError, map, mergeMap, take, tap } from 'rxjs/operators';
import { AlertDialogComponent } from '@shared/alert-dialog/alert-dialog.component';
import { ErrorHandlerClient, ErrorHandlerDto } from '@core/services/api-clients';
import { ERROR_DISPATCHER, IErrorDispatcher } from './error-event.service';
import { ErrorHandlerProvider } from './error-handler-provider.service';
import { ErrorPipelineService } from './error-pipeline.service';
import { AddFatalError } from '../store/actions';
import { isApplicationLoaded } from '../store/selectors';
import {
  ErrorHandlerLifetime,
  ErrorOrigin,
  ErrorType,
  IError,
  NotificationScope,
  NotificationType,
} from 'src/engine-sdk';
import { AddNotifications } from '@core/notification/store/actions';

@Injectable({
  providedIn: 'root',
})
export class GlobalErrorHandler implements ErrorHandler, OnDestroy {
  private _destroy$ = new Subject<any>();
  private _counter: number = 0;

  constructor(
    private _injector: Injector,
    private _handlerProvider: ErrorHandlerProvider,
    private _pipelineService: ErrorPipelineService,
    private _client: ErrorHandlerClient,
    private _dialog: MatDialog,
    @Inject(ERROR_DISPATCHER) private _errorDispatcher: IErrorDispatcher,
  ) {}

  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
  }

  public handleError(error: any): void {
    let wrappedError = { ...error, isHandled: !!(<IError>error).isHandled };

    if (wrappedError.origin === undefined && wrappedError.payload === undefined) {
      wrappedError = {
        origin: ErrorOrigin.UserInterface,
        isHandled: false,
        payload: {
          message: error.message,
          stack: error.stack,
          type: ErrorType.Unknown,
        },
      };
    }

    if (!this._counter) {
      this.setErrorCounter();
    }

    this.handle(wrappedError);
    this._errorDispatcher.dispatchError(wrappedError);
  }

  public startListening() {
    this._handlerProvider.addHandler({
      handler: (_, err) => {
        if (err.origin === ErrorOrigin.Server) {
          if (err.payload.type == ErrorType.System) {
            const store = <Store>this._injector.get(Store);
            store
              .pipe(
                select(isApplicationLoaded),
                take(1),
                tap((isApplicationLoaded) => {
                  if (!isApplicationLoaded) {
                    store.dispatch(new AddFatalError({ error: err }));
                  }
                }),
              )
              .subscribe();
          }

          this.dispatchNotifications(err);
        } else {
          this._dialog.open(AlertDialogComponent, {
            data: {
              title: 'User Interface error',
              message: `${err.payload.source}: ${err.payload.message}`,
              messageType: 'error',
              buttons: [{ label: 'Ok', action: (dialog) => dialog.close() }],
            },
          });
        }

        return of({ ...err, isHandled: true });
      },
      lifetime: ErrorHandlerLifetime.Global,
      order: Number.MAX_SAFE_INTEGER,
    });
  }

  private dispatchNotifications(error) {
    const notifications = this.createNotifications(error);
    const store = <Store>this._injector.get(Store);
    if (notifications) {
      store.dispatch(new AddNotifications({ notificationGroups: notifications }));
    }
  }

  private createNotifications(error: { origin: ErrorOrigin; payload: any }) {
    let notificationGroups;
    switch (error.payload.type) {
      case ErrorType.PluginValidation:
        notificationGroups = error.payload.notificationGroups.map((element) => {
          const type = ErrorType[error.payload.type];
          return {
            ...element,
            entityName: error.payload.entityName,
            scope: NotificationScope.Global,
            name: `$global_${type.toLowerCase()}`,
          };
        });
        break;
      case ErrorType.RequestValidation:
        notificationGroups = error.payload.notificationGroups;
        break;
      case ErrorType.System:
      case ErrorType.Permission:
      case ErrorType.PluginExecution:
      case ErrorType.Unknown:
        const type = ErrorType[error.payload.type];
        notificationGroups = [
          {
            scope: NotificationScope.Global,
            message: `${type}`,
            name: `$global_${type.toLowerCase()}`,
            notifications: [
              {
                type: NotificationType.Error,
                message: error.payload.message,
              },
            ],
          },
        ];
        break;
    }
    return notificationGroups;
  }

  private handle(error: IError) {
    this._counter++;

    if (this._counter > 50) {
      throw { error: 'Critical error due to error overflow !' };
    }

    if (!error.isCriticalHandlerError && error.payload.type !== ErrorType.Unknown) {
      of(error)
        .pipe(
          mergeMap((error: IError) =>
            this._client.getErrorHandlers(error.payload.exceptionName || null).pipe(
              catchError((err) => {
                err.isCriticalHandlerError = true;
                let wrappedError = {
                  ...error,
                  isHandled: !!(<IError>error).isHandled,
                  isCriticalHandlerError: true,
                };
                this._pipelineService.createHandlersPipeline(wrappedError).pipe(take(1)).subscribe();

                return of();
              }),
              map((handlers: ErrorHandlerDto[]) => {
                return { handlers: handlers, error: error };
              }),
            ),
          ),
          mergeMap((result) => this._pipelineService.createHandlersPipeline(result.error, result.handlers)),
        )
        .subscribe();
    } else {
      console.error(`User interface error: ${error.payload.message}`, error);
      this._pipelineService.createHandlersPipeline(error).pipe(take(1)).subscribe();
    }
  }

  private setErrorCounter() {
    setTimeout(() => {
      this._counter = 0;
    }, 2000);
  }
}
