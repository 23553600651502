import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

export const ENGINE_METADATA_PROVIDER = new InjectionToken<IEngineMetadataProvider>('IEngineMetadataProvider');

export type FieldType = 'text' | 'number' | 'yesno' | 'date' | 'choice' | 'guid';

export interface IEntityAttribute {
  id: string;
  name: string;
  type: FieldType;
  targetEntityId?: string;
}

export interface IEngineEntity {
  id: string;
  name: string;
  attributes: IEntityAttribute[];
}

export interface IEngineMetadataProvider {
  getEntityMetadataAsync(): Observable<IEngineEntity>;
}
