export enum UserInteractionEventType {
  viewLoaded,
  formLoaded,
  viewItemSelected,
  formStateChanged,
}

export interface IUserInteractionContext {
  [key: string]: any;
  eventType: UserInteractionEventType;
}
