import { Injectable } from '@angular/core';
import { ControlDto, FormDto } from '../../../core/services/api-clients';

@Injectable()
export class FormAttributesProvider {
  constructor() {}

  public getFormAttributes(form: FormDto) {
    const attributes = form.tabs
      .reduce((acc, val) => acc.concat(val.controlGroups.reduce((acc2, val2) => acc2.concat(val2.controls), [])), [])
      .map((c: ControlDto) => c.primaryAttribute);

    return attributes.reduce((acc, value) => {
      return { ...acc, [value]: value };
    }, {});
  }
}
