<mat-form-field>
  <mat-label>{{ label }}</mat-label>
  <input [formControl]="formControl" type="hidden" />
  <mat-chip-grid #chipGrid aria-label="label" [ngStyle]="textAlignStyle">
    <mat-chip-row
      *ngFor="let chip of chips"
      appDoubleClick
      (doubleClick)="emitDoubleClick(chip)"
      (removed)="onRemove(chip)">
      {{ chip.label }}
      <button type="button" *ngIf="formControl.enabled" matChipRemove [attr.aria-label]="'remove ' + chip.label">
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip-row>
  </mat-chip-grid>
  <input
    #autocompleteInput
    placeholder="{{ label }}..."
    [disabled]="!formControl.enabled"
    [matChipInputFor]="chipGrid"
    [matAutocomplete]="auto"
    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
    (matChipInputTokenEnd)="onAdd($event)"
    (keyup)="onFilterChange($event)"/>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onOptionSelected($event)">
    <mat-option *ngFor="let option of options" [value]="option.value">
      {{ option.label }}
    </mat-option>
  </mat-autocomplete>
  <button
    type="button"
    *ngIf="formControl.enabled && !isCreateButtonHidden"
    class="add-button visible-on-edit-mode"
    matSuffix
    mat-icon-button
    (click)="addClick.emit()">
    <mat-icon>add</mat-icon>
  </button>
</mat-form-field>
