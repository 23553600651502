import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-grid-page-size-selector',
  templateUrl: './grid-page-size-selector.component.html',
  styleUrls: ['./grid-page-size-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridPageSizeSelectorComponent {
  private _availablePageSizes: number[];

  @Input() disabled = false;
  @Input() pageSize: number;

  get availablePageSizes() {
    return this._availablePageSizes;
  }

  @Input() set availablePageSizes(pageSizes: number[]) {
    this._availablePageSizes = Array.from(new Set(pageSizes.concat(this.pageSize))).sort((a, b) => a - b);
  }

  @Output() pageSizeChange = new EventEmitter<number>();
}
