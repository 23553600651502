<ng-container *ngIf="entityViewWithTabs$ | async as entityViewWithTabs">
  <div appContainerWidth (containerWidth)="onResize($event)">
    <div class="subgrid-toolbar">
      <div class="subgrid-menu-wrapper" *ngIf="gridContextMenu$ | async as contextMenu">
        <app-context-menu [contextMenu]="contextMenu" [additionalDataContext]="{ isSubgrid: true }"> </app-context-menu>
      </div>

      <div class="subgrid-tools-wrapper">
        <app-engine-view-toolbar
          [isCollapsed]="isToolbarCollapsed$ | async"
          [settings]="settings$ | async"
          [viewTabs]="entityViewWithTabs.viewTabs"
          viewTabsSelectionMode="single"
          [globalSearch]="stateService.getViewState().globalSearch"
          (globalSearchChange)="onGlobalSearchChange($event)"
          (globalSearchVisibilityChange)="onGlobalSearchVisibilityChange($event)"
          (filtersVisibilityChange)="onFiltersVisibilityChange($event)"
          (viewTabsVisibilityChange)="onViewTabsVisibilityChange($event)"
          (refreshData)="onRefreshData()"
          (restoreSettings)="onRestoreSettings()"
          (navigateToSettings)="onNavigateToSettings()">
        </app-engine-view-toolbar>
      </div>
    </div>

    <app-engine-view
      #engineView
      [isReadOnly]="isReadOnly"
      [isDataFetchingEnabled]="isDataFetchingEnabled"
      [urlState]="urlState$ | async"
      [settings]="settings$ | async"
      [entityView]="entityViewWithTabs.entityView"
      [contextMenu]="gridColumnContextMenu$ | async"
      [displayRows]="subgridParams.displayRows"
      (rowDoubleClick)="onRowDoubleClick($event)"
      (lookupColumnClick)="onLookupColumnClick($event)"
      (columnResize)="onColumnResize($event)"
      (stateChange)="onStateChange($event)"
      (pageSizeChange)="onPageSizeChange($event)">
    </app-engine-view>
  </div>
</ng-container>
