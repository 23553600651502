import { Directive, Inject, Injector, Input, Optional, SkipSelf } from '@angular/core';
import { AlignStyle, YesNoControlDto } from '@core/services/api-clients';
import { WidgetDirective } from '@core/widgets/directives/widget.directive';
import { IScriptRunnerService, SCRIPT_RUNNER_SERVICE } from '@core/widgets/models/iscript-runner.service';
import { YesNoControlComponent } from '@shared/reactive-controls/components/yes-no/yes-no-control.component';
import { EngineFormControlDirective } from './engine-form-control.directive';

@Directive({
  selector: 'app-yes-no-control[engineYesNoFormControl]',
  providers: [{ provide: EngineFormControlDirective, useExisting: EngineYesNoFormControlDirective }],
})
export class EngineYesNoFormControlDirective extends EngineFormControlDirective {
  protected get _yesNoBaseControl(): YesNoControlComponent {
    return this._baseControl as YesNoControlComponent;
  }

  @Input() set engineYesNoControlDefinition(definition: YesNoControlDto) {
    this.engineControlDefinition = definition;
    this.textAlignStyle = definition.textAlign;
  }

  @Input() set textAlignStyle(textAlign: AlignStyle) {
    this._yesNoBaseControl.textAlignStyle = this.getTextAlignStyle(textAlign);
  }

  get textAlignStyle(): any {
    return this._yesNoBaseControl.textAlignStyle;
  }

  constructor(
    @Optional() @SkipSelf() parentWidget: WidgetDirective,
    @Inject(SCRIPT_RUNNER_SERVICE) scriptRunnerService: IScriptRunnerService,
    injector: Injector,
  ) {
    super(parentWidget, scriptRunnerService, injector);
  }
}
