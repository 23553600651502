<mat-select [value]="selectedFormId" (valueChange)="onOptionSelected($event)">
  <mat-select-trigger>
    <div class="icon-container">
      {{ selectedFormName }}
      <mat-icon class="icon">expand_more</mat-icon>
    </div>
  </mat-select-trigger>
  <mat-option *ngFor="let form of forms" [value]="form.id">
    {{ form.name }}
  </mat-option>
</mat-select>
