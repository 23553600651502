import { Component, Inject } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { AlertDialogData } from './alert-dialog-data.model';

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss'],
})
export class AlertDialogComponent {
  private static iconMapping = {
    error: 'error',
    success: 'check_circle',
    warning: 'warning',
    info: 'info',
  };

  public fontIcon: string;

  constructor(
    public dialogRef: MatDialogRef<AlertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AlertDialogData,
  ) {
    this.fontIcon = AlertDialogComponent.iconMapping[data.messageType];
  }

  close(): void {
    this.dialogRef.close();
  }

  onButtonClick(button: { action: (dialog) => void }) {
    button.action(this);
  }

  getButtonId(label: string) {
    return 'app-alert-dialog-' + label.toLowerCase().replace(/\s/g, "");
  }
}
