import { APP_INITIALIZER, Injector } from '@angular/core';
import { AUTH_SERVICE_TOKEN, IAuthService } from '../models/iauth-service.model';

const initializeAuthModule = (injector: Injector) => {
  const authService = injector.get<IAuthService>(AUTH_SERVICE_TOKEN);
  return () => {
    // authService.startListening().subscribe();
    authService;
  };
};

export const authInitializerProvider = {
  provide: APP_INITIALIZER,
  useFactory: initializeAuthModule,
  deps: [Injector],
  multi: true,
};
