import { Injectable } from '@angular/core';
import { EngineDateParser } from '@core/engine-culture/services/date-parser.service';
import { DateTimeAdapter, NativeDateTimeAdapter } from '@danielmoncada/angular-datetime-picker';
import moment from 'moment';
import { EngineCultureService } from '../../engine-culture/services/culture.service';

@Injectable()
export class OwlDateTimeAdapter extends DateTimeAdapter<any> {
  constructor(
    private _timeAdapter: NativeDateTimeAdapter,
    private _dateParser: EngineDateParser,
    private _cultureService: EngineCultureService) {
    super();
  }

  getYear(date: Date): number {
    return date ? this._dateParser.parseFromDate(date).year() : 2000;
  }
  getMonth(date: Date): number {
    return date ? this._dateParser.parseFromDate(date).month() : 1;
  }
  getDay(date: Date): number {
    return date ? this._dateParser.parseFromDate(date).day() : 1;
  }
  getDate(date: Date): number {
    return date ? this._dateParser.parseFromDate(date).date() : 1;
  }
  getHours(date: Date): number {
    return date ? this._dateParser.parseFromDate(date).hours() : 0;
  }
  getMinutes(date: Date): number {
    return date ? this._dateParser.parseFromDate(date).minutes() : 0;
  }
  getSeconds(date: Date): number {
    return date ? this._dateParser.parseFromDate(date).seconds() : 0;
  }
  getTime(date: Date): number {
    return date ? date.getTime() : 0;
  }
  getNumDaysInMonth(date: Date): number {
    return date ? this._dateParser.parseFromDate(date).daysInMonth() : 0;
  }
  differenceInCalendarDays(dateLeft: any, dateRight: any): number {
    return this._timeAdapter.differenceInCalendarDays(dateLeft, dateRight);
  }
  getYearName(date: any): string {
    return this._timeAdapter.getYearName(date);
  }
  getMonthNames(style: 'long' | 'short' | 'narrow'): string[] {
    return this._timeAdapter.getMonthNames(style);
  }
  getDayOfWeekNames(style: 'long' | 'short' | 'narrow'): string[] {
    return this._timeAdapter.getDayOfWeekNames(style);
  }
  getDateNames(): string[] {
    return this._timeAdapter.getDateNames();
  }
  toIso8601(date: any): string {
    return this._timeAdapter.toIso8601(date);
  }
  isEqual(dateLeft: any, dateRight: any): boolean {
    return this._timeAdapter.isEqual(dateLeft, dateRight);
  }
  isSameDay(dateLeft: any, dateRight: any): boolean {
    const dayA = this._dateParser.parseFromDate(dateLeft);
    const dayB = this._dateParser.parseFromDate(dateRight);
    return !!dayA && !!dayB && dayA.date() == dayB.date();
  }
  isValid(date: any): boolean {
    if (date != null && date instanceof Date) {
      return this._timeAdapter.isValid(date);
    }

    return true;
  }
  invalid() {
    return this._timeAdapter.invalid();
  }
  isDateInstance(obj: any): boolean {
    return this._timeAdapter.isDateInstance(obj);
  }
  addCalendarYears(date: any, amount: number) {
    return this._timeAdapter.addCalendarYears(date, amount);
  }
  addCalendarMonths(date: any, amount: number) {
    return this._timeAdapter.addCalendarMonths(date, amount);
  }
  addCalendarDays(date: any, amount: number) {
    return this._timeAdapter.addCalendarDays(date, amount);
  }
  setHours(date: any, amount: number) {
    const newDate = this._dateParser.parseFromDate(date);
    newDate.hour(amount);
    return newDate.toDate();
  }
  setMinutes(date: any, amount: number) {
    const newDate = this._dateParser.parseFromDate(date);
    newDate.minute(amount);
    return newDate.toDate();
  }
  setSeconds(date: any, amount: number) {
    const newDate = this._dateParser.parseFromDate(date);
    newDate.second(amount);
    return newDate.toDate();
  }
  createDate(year: number, month: number, date: number, hours?: number, minutes?: number, seconds?: number): any {
    return this._dateParser.createDate(year, month, date, hours ?? 0, minutes ?? 0, seconds ?? 0).toDate();
  }
  clone(date: any) {
    return this._timeAdapter.clone(date);
  }
  now() {
    return this._timeAdapter.now();
  }
  format(date: any, displayFormat: any): string {
    return this._dateParser.toString(date, this._cultureService.cultureSettings.dateTimeParsingPattern);
  }
  parse(value: any, parseFormat: any) {
    if (value) {
      const result = moment(value, this._cultureService.cultureSettings.dateTimeParsingPattern, true)

      if (result.isValid()) {
        return result.toDate();
      }
    }

    return undefined;
  }
}
