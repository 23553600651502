import { FormDto } from '@core/services/api-clients';

export const FEATURE_NAME: string = 'form-feature';

export interface IFormState {
  forms: { [formId: string]: FormDto };
}

export const initialFormState: IFormState = {
  forms: {},
};
