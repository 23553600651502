import { ProcessEntityAssignmentDto, ProcessInstanceHistoryDto, ProcessStateDto } from '@core/services/api-clients';

export const FEATURE_NAME: string = 'process-feature';

export interface IProcessState {
  processEntityAssignments: { [entityId: string]: ProcessEntityAssignmentDto[] };
  processStates: { [id: string]: ProcessStateDto };
  processHistory: { [processAssignment: string]: ProcessInstanceHistoryDto[][] };
}

export const initialProcessState: IProcessState = {
  processEntityAssignments: {},
  processStates: {},
  processHistory: {},
};
