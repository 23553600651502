import { createSelector, createFeatureSelector } from '@ngrx/store';
import { ContextMenusFeatureState } from '.';
import { FEATURE_NAME, IContextMenuState } from './state';
import { NavigationSelectors } from '@core/navigation/store';

export const contextMenusFeatureSelector = createFeatureSelector<ContextMenusFeatureState>(FEATURE_NAME);
export const contextMenusSelector = createSelector(
  contextMenusFeatureSelector,
  (state: ContextMenusFeatureState) => state.contextMenus,
);

export const getMainContextMenu = createSelector(
  contextMenusSelector,
  (state: IContextMenuState) => state.mainContextMenu,
);

export const getGridColumnContextMenu = createSelector(
  contextMenusSelector,
  NavigationSelectors.getViewIdFromUrl,
  (state: IContextMenuState, viewId: string) => state.gridColumnContextMenu[viewId],
);

export const getSubgridContextMenu = (subgridId: string) =>
  createSelector(contextMenusSelector, (state) => state.subgridContextMenu[subgridId]);

export const getDialogFormContextMenu = (dialogId: string) =>
  createSelector(contextMenusSelector, (state) => state.dialogFormContextMenu[dialogId]);

export const getSubgridColumnContextMenu = (subgridId: string) =>
  createSelector(contextMenusSelector, (state) => state.subgridColumnContextMenu[subgridId]);
