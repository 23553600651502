import { ActionReducerMap } from '@ngrx/store';
import { errorReducer } from './reducers';
import { IErrorsState } from './state';
import * as ErrorsSelectors from './selectors';
import * as ErrorsActions from './actions';

export { ErrorsActions, ErrorsSelectors };

export interface ErrorFeatureState {
  errors: IErrorsState;
}

export const reducers: ActionReducerMap<ErrorFeatureState> = {
  errors: errorReducer,
};
