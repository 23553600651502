import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormDefinition } from '@core/engine-forms/components/engine-form/engine-form.model';
import { ScriptRunnerService } from '@core/scripts-running/script-runner.service';
import {
  AlignStyle,
  WidthType,
  FormTabDto,
  FormSectionDto,
  ControlGroupDto,
  ControlDto,
  LookupControlDto,
  OptionSetControlDto,
  OptionSetValueDto,
  MultiselectOptionSetControlDto,
  HyperlinkControlDto,
  LinkBehavior,
  SubGridControlDto,
  ContextMenuDto,
  TextAreaControlDto,
  InlineRelationControlDto,
  EntityWithPermissionsDto,
} from '@core/services/api-clients';
import { ContextService } from '@core/services/context.service';
import { GenericODataServiceProvider } from '@core/engine-odata/services/generic-odata-service-provider.service';
import { GuidService } from '@core/services/guid.service';
import { UserInteractionService } from '@core/services/user-interaction.service';
import { NoEventWidgetDirective } from '@core/widgets/directives/no-event-widget.directive';
import { BehaviorSubject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { UserInteractionContexts } from 'src/engine-sdk/contract/user-interaction';
import { FormContextType } from '../../engine-sdk';

export type ControlTypes =
  | 'yesno'
  | 'text'
  | 'textarea'
  | 'label'
  | 'description'
  | 'number'
  | 'datetime'
  | 'date'
  | 'time'
  | 'optionset'
  | 'multiselectoptionset'
  | 'lookup'
  | 'button'
  | 'subgrid'
  | 'hyperlink'
  | 'inlinerelation'
  | 'querybuilder';

export const ALL_SIMPLE_CONTROLS: ControlTypes[] = [
  'button',
  'date',
  'datetime',
  'lookup',
  'multiselectoptionset',
  'number',
  'optionset',
  'text',
  'textarea',
  'label',
  'description',
  'time',
  'yesno',
  'hyperlink',
  'inlinerelation',
  'querybuilder',
];

@Component({
  selector: 'app-test-form',
  templateUrl: './test-form.component.html',
  styleUrls: ['./test-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TestFormComponent extends NoEventWidgetDirective {
  private _testView: any;

  AlignStyle = AlignStyle;
  WidthType = WidthType;
  formDefinition$: BehaviorSubject<FormDefinition> = new BehaviorSubject(undefined);

  testValue: any = {};
  testContextMenu: ContextMenuDto = {
    useDivider: true,
    sections: [],
  } as ContextMenuDto;

  constructor(
    scriptRunner: ScriptRunnerService,
    userInteractionService: UserInteractionService,
    private _contextService: ContextService,
    odataServiceProvider: GenericODataServiceProvider,
  ) {
    super(null, scriptRunner);
    this._minWidgetsNumber = 2;

    this.testUserInteractionService(userInteractionService);
    this.setUpTestViewAndCreateFormDefinition(odataServiceProvider);
  }

  private testUserInteractionService(userInteractionService: UserInteractionService) {
    userInteractionService
      .startListen([
        UserInteractionContexts.isFormStateChanged(
          UserInteractionContexts.NOT_EMPTY_CONTEXT,
          UserInteractionContexts.EMPTY_CONTEXT,
        ),
        UserInteractionContexts.isFormLoaded(
          UserInteractionContexts.NOT_EMPTY_CONTEXT,
          UserInteractionContexts.EMPTY_CONTEXT,
        ),
        UserInteractionContexts.isSubgridItemSelected(),
      ])
      .pipe(
        takeUntil(this._destroy$),
        tap((x) => console.log('DZIAŁA!', x)),
      )
      .subscribe();
  }

  private setUpTestViewAndCreateFormDefinition(odataServiceProvider: GenericODataServiceProvider) {
    const testView = odataServiceProvider.create('View');
    testView
      .query()
      .Top(1)
      .Filter(`Name eq 'User Default View'`)
      .Expand('Entity')
      .Exec()
      .pipe(
        map((views) => views[0]),
        tap((view) => {
          this._testView = view;
          this.createFormDefinition();
        }),
      )
      .subscribe();
  }

  testSomething(index: number): void {
    const mainForm = this._contextService.getFormContext('DummyForm');

    switch (index) {
      case 1:
        const textInputs = this.formDefinition$.value.tabs
          .flatMap((t) => t.controlGroups)
          .flatMap((g) => g.controls)
          .filter((c) => c.type == 'text');
        const newValue = { ...this.testValue };
        textInputs.forEach((c) => (newValue[c.name] = 'TEST'));
        this.testValue = newValue;
        break;
      case 2:
        mainForm.getAllControls().forEach((c) => (c.isVisible = !c.isVisible));
        mainForm
          .getTabByName('Tab1')
          .getSections()
          .forEach((x) => (x.isVisible = !x.isVisible));
        break;
      case 3:
        mainForm.markAsPristine();

        break;
    }
  }

  private createFormDefinition(): void {
    const formDefinition = {
      id: 'DummyForm',
      name: 'DummyForm',
      contextInfo: { type: FormContextType.Main },
      recordInfo: { entityId: undefined, entityName: undefined, recordId: undefined },
      tabs: [
        {
          title: 'Tab 1',
          name: 'Tab1',
          layoutGrid: {
            id: '1',
            slots: null,
            takenSlots: null,
            type: 'col',
            children: [
              {
                id: '1-1',
                slots: 3,
                takenSlots: 1,
                type: 'row',
                children: [
                  {
                    id: '1-1-1',
                    slots: 3,
                    takenSlots: 1,
                    type: 'col',
                    children: [
                      {
                        id: '1-1-1-1',
                        slots: null,
                        takenSlots: 1,
                        type: 'col',
                        children: [],
                      },
                      {
                        id: '1-1-1-2',
                        slots: null,
                        takenSlots: 1,
                        type: 'col',
                        children: [],
                      },
                      {
                        id: '1-1-1-3',
                        slots: null,
                        takenSlots: 1,
                        type: 'col',
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1-1-2',
                    slots: 3,
                    takenSlots: 1,
                    type: 'col',
                    children: [
                      {
                        id: '1-1-2-1',
                        slots: null,
                        takenSlots: 1,
                        type: 'col',
                        children: [],
                      },
                      {
                        id: '1-1-2-2',
                        slots: null,
                        takenSlots: 1,
                        type: 'col',
                        children: [],
                      },
                      {
                        id: '1-1-2-3',
                        slots: null,
                        takenSlots: 1,
                        type: 'col',
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1-1-3',
                    slots: 3,
                    takenSlots: 1,
                    type: 'col',
                    children: [
                      {
                        id: '1-1-3-1',
                        slots: null,
                        takenSlots: 1,
                        type: 'col',
                        children: [],
                      },
                      {
                        id: '1-1-3-2',
                        slots: null,
                        takenSlots: 1,
                        type: 'col',
                        children: [],
                      },
                      {
                        id: '1-1-3-3',
                        slots: null,
                        takenSlots: 1,
                        type: 'col',
                        children: [],
                      },
                    ],
                  },
                  {
                    id: '1-2-1',
                    slots: 2,
                    takenSlots: 3,
                    type: 'row',
                    children: [
                      {
                        id: '1-2-1-1',
                        slots: null,
                        takenSlots: 1,
                        type: 'col',
                        children: [],
                      },
                      {
                        id: '1-2-1-2',
                        slots: null,
                        takenSlots: 1,
                        type: 'col',
                        children: [],
                      },
                    ],
                  },
                ],
              },
              {
                id: '1-3',
                slots: 1,
                takenSlots: 1,
                type: 'row',
                children: [],
              },
              {
                id: '1-4',
                slots: 1,
                takenSlots: 1,
                type: 'row',
                children: [],
              },
            ],
          } as any,
          isVisible: true,
          formSections: [
            this.getSection('1-1-1-1'),
            this.getSection('1-1-1-2'),
            this.getSection('1-1-1-3'),
            this.getSection('1-1-2-1'),
            this.getSection('1-1-2-2'),
            this.getSection('1-1-2-3'),
            this.getSection('1-1-3-1'),
            this.getSection('1-1-3-2'),
            this.getSection('1-1-3-3'),
            this.getSection('1-2-1'),
            this.getSection('1-2'),
            this.getSection('1-3'),
            this.getSection('1-4'),
          ],
          controlGroups: [
            this.getControlGroup('1-1-1-1', ALL_SIMPLE_CONTROLS, true),
            this.getControlGroup('1-1-1-2', ['text', 'text', 'text']),
            this.getControlGroup('1-1-1-3', ['text', 'text', 'text']),
            this.getControlGroup('1-1-2-1', ALL_SIMPLE_CONTROLS, false, true),
            this.getControlGroup('1-1-2-2', ['text', 'text', 'text']),
            this.getControlGroup('1-1-2-3', ['text', 'text', 'text']),
            this.getControlGroup('1-1-3-1', ALL_SIMPLE_CONTROLS, false, false, false),
            this.getControlGroup('1-1-3-2', ['text', 'text', 'text']),
            this.getControlGroup('1-1-3-3', ['yesno', 'yesno', 'yesno']),
            this.getControlGroupWithPrimaryAttributeSharing('1-2-1-1', ['text', 'text', 'text']),
            this.getControlGroupWithNameSharing('1-2-1-2', ['text', 'text', 'text']),
            this.getControlGroup('1-3', ['subgrid']),
            this.getControlGroup('1-4', ['querybuilder']),
          ],
        } as FormTabDto,
      ],
    };
    this.formDefinition$.next(formDefinition);
  }

  private getSection(
    containerId: string,
    color: string = null,
    isOutlined: boolean = true,
    isVisible: boolean = true,
  ): FormSectionDto {
    const sectionId = GuidService.generateNew();
    return {
      id: sectionId,
      name: `${containerId}_${sectionId}`,
      title: `${containerId}_${sectionId}`,
      subtitle: null,
      isOutlined,
      isVisible,
      icon: {
        iconRelativePath: 'images/engine/icons/Settings.svg?v=637812936273272372',
        fontIconName: 'settings',
      },
      backgroundColor: color,
      containerId,
    } as FormSectionDto;
  }

  private getControlGroup(
    containerId: string,
    controlTypes: ControlTypes[],
    isReadOnly?: boolean,
    isRequired?: boolean,
    isVisible?: boolean,
  ): ControlGroupDto {
    const controls = [];
    for (let i = 0; i < controlTypes.length; i++) {
      const curentType = controlTypes[i];
      switch (curentType) {
        case 'textarea':
          controls.push(this.getTestTextareaControl(i, curentType, isReadOnly, isRequired, isVisible));
          break;
        case 'lookup':
          controls.push(this.getTestLookupControl(i, curentType, isReadOnly, isRequired, isVisible));
          break;
        case 'optionset':
          controls.push(this.getTestSelectControl(i, curentType, isReadOnly, isRequired, isVisible));
          break;
        case 'multiselectoptionset':
          controls.push(this.getTestMultiselectControl(i, curentType, isReadOnly, isRequired, isVisible));
          break;
        case 'hyperlink':
          controls.push(this.getTestHyperlinkControl(i, curentType, isReadOnly, isRequired, isVisible));
          break;
        case 'subgrid':
          controls.push(this.getTestSubgridControl(i, curentType, isReadOnly, isRequired, isVisible));
          break;
        case 'inlinerelation':
          controls.push(this.getTestChipsControl(i, curentType, isReadOnly, isRequired, isVisible));
          break;

        default:
          controls.push(this.getTestControl(i, curentType, isReadOnly, isRequired, isVisible));
          break;
      }
    }

    const controlGroupId = GuidService.generateNew();
    return {
      id: controlGroupId,
      name: controlGroupId,
      tabId: 'TEST_TAB1',
      title: null,
      subtitle: null,
      order: 0,
      containerId,
      controls,
    } as ControlGroupDto;
  }

  private getControlGroupWithPrimaryAttributeSharing(
    containerId: string,
    controlTypes: ControlTypes[],
    isReadOnly?: boolean,
    isRequired?: boolean,
    isVisible?: boolean,
  ): ControlGroupDto {
    const controlGroup1 = this.getControlGroup(containerId, controlTypes, isReadOnly, isRequired, isVisible);
    const controlGroup2 = this.getControlGroup(containerId, controlTypes, isReadOnly, isRequired, isVisible);
    for (let i = 0; i < controlGroup1.controls.length; i++) {
      controlGroup2.controls[i].primaryAttribute = controlGroup1.controls[i].primaryAttribute;
    }
    controlGroup1.controls.push(...controlGroup2.controls);
    return controlGroup1;
  }

  private getControlGroupWithNameSharing(
    containerId: string,
    controlTypes: ControlTypes[],
    isReadOnly?: boolean,
    isRequired?: boolean,
    isVisible?: boolean,
  ): ControlGroupDto {
    const controlGroup1 = this.getControlGroup(containerId, controlTypes, isReadOnly, isRequired, isVisible);
    const controlGroup2 = this.getControlGroup(containerId, controlTypes, isReadOnly, isRequired, isVisible);
    for (let i = 0; i < controlGroup1.controls.length; i++) {
      controlGroup2.controls[i].name = controlGroup1.controls[i].name;
    }
    controlGroup1.controls.push(...controlGroup2.controls);
    return controlGroup1;
  }

  private getTestControl(
    i: number,
    type: ControlTypes,
    isReadOnly?: boolean,
    isRequired?: boolean,
    isVisible?: boolean,
  ): ControlDto {
    const controlId = `${type}_${i}_${GuidService.generateNew()}`;
    let dto = new ControlDto();
    dto.id = controlId;
    dto.name = controlId;
    dto.label = `${type} ${i}`;
    dto.isReadOnly = isReadOnly ?? false;
    dto.isRequired = isRequired ?? false;
    dto.isVisible = isVisible ?? true;
    dto.type = type;
    dto.primaryAttribute = controlId;
    dto.align = AlignStyle.Left;
    dto.textAlign = AlignStyle.Left;
    dto.widthType = WidthType.Percentage;
    dto.width = 100;
    return dto;
  }

  private getTestTextareaControl(
    i: number,
    type: ControlTypes,
    isReadOnly?: boolean,
    isRequired?: boolean,
    isVisible?: boolean,
  ): LookupControlDto {
    const control = this.getTestControl(i, type, isReadOnly, isRequired, isVisible) as TextAreaControlDto;
    control.minRowsNumber = 5;
    control.maxRowsNumber = 10;
    return control;
  }

  private getTestLookupControl(
    i: number,
    type: ControlTypes,
    isReadOnly?: boolean,
    isRequired?: boolean,
    isVisible?: boolean,
  ): LookupControlDto {
    const control = this.getTestControl(i, type, isReadOnly, isRequired, isVisible) as LookupControlDto;
    const entity = this._testView['Entity'];
    control.targetEntity = {
      id: this._testView['EntityId'],
      name: entity['Name'],
      canCreate: true,
      canRead: true,
      canUpdate: true,
    } as EntityWithPermissionsDto;
    control.expanderViewId = this._testView['Id'];
    control.showField = 'Name';
    return control;
  }

  private getTestSelectControl(
    i: number,
    type: ControlTypes,
    isReadOnly?: boolean,
    isRequired?: boolean,
    isVisible?: boolean,
  ): OptionSetControlDto {
    const control = this.getTestControl(i, type, isReadOnly, isRequired, isVisible) as OptionSetControlDto;
    const options: OptionSetValueDto[] = [
      { label: '@label1', name: 'label 1', value: '1' },
      { label: '@label2', name: 'label 2', value: '2' },
      { label: '@label3', name: 'label 3', value: '3' },
    ] as any;
    control.options = options;
    return control;
  }

  private getTestMultiselectControl(
    i: number,
    type: ControlTypes,
    isReadOnly?: boolean,
    isRequired?: boolean,
    isVisible?: boolean,
  ): MultiselectOptionSetControlDto {
    const control = this.getTestSelectControl(
      i,
      type,
      isReadOnly,
      isRequired,
      isVisible,
    ) as MultiselectOptionSetControlDto;
    return control;
  }

  private getTestHyperlinkControl(
    i: number,
    type: ControlTypes,
    isReadOnly?: boolean,
    isRequired?: boolean,
    isVisible?: boolean,
  ): HyperlinkControlDto {
    const control = this.getTestControl(i, type, isReadOnly, isRequired, isVisible) as HyperlinkControlDto;
    control.linkBehavior = LinkBehavior.OpenInNewTab;
    return control;
  }

  private getTestSubgridControl(
    i: number,
    type: ControlTypes,
    isReadOnly?: boolean,
    isRequired?: boolean,
    isVisible?: boolean,
  ): SubGridControlDto {
    const control = this.getTestControl(i, type, isReadOnly, isRequired, isVisible) as SubGridControlDto;
    control.viewId = this._testView['Id'];
    control.pageSize = 10;
    control.displayRows = 5;
    return control;
  }

  private getTestChipsControl(
    i: number,
    type: ControlTypes,
    isReadOnly?: boolean,
    isRequired?: boolean,
    isVisible?: boolean,
  ): InlineRelationControlDto {
    const control = this.getTestControl(i, type, isReadOnly, isRequired, isVisible) as InlineRelationControlDto;
    control.relationId = this._testView['Id'];
    control.sourceEntity = this._testView['Entity'];
    control.sourceAttributeName = this._testView['Name'];
    return control;
  }
}
