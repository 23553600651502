import { SelectExpandDto } from '../core/services/api-clients';

export function concatUrl(baseUrl: string, endPoint: string): string {
  if (baseUrl.endsWith('/')) {
    baseUrl = baseUrl.slice(0, -1);
  }
  if (endPoint.startsWith('/')) {
    endPoint = endPoint.slice(1);
  }
  return `${baseUrl}/${endPoint}`;
}

export function isNumber(value: any): boolean {
  return !isNaN(parseFloat(value)) && !isNaN(value - 0);
}

export function serializeSelectExpand(selectExpand: SelectExpandDto, delimiter = '&') {
  if (selectExpand.select && Object.keys(selectExpand.select).length > 0) {
    let selected = selectExpand.select.filter((x) => x != 'Id').join(',');
    let select = '$select=Id';
    if (selected) select += ',' + selected;
    let expand = '$expand=';
    let expandBody = '';
    for (let key in selectExpand.expand) {
      if (expandBody.length > 0) expandBody += ',';
      let singleExpand = key;
      let nestedExpand = serializeSelectExpand(selectExpand.expand[key], ';');
      expandBody += singleExpand;
      if (nestedExpand) expandBody += `(${nestedExpand})`;
    }
    let result = select;
    if (expandBody.length > 0) result += `${delimiter}${expand}${expandBody}`;
    return result;
  }

  return null;
}

export function getValueByAttributePath(dataItem: any, attributePath: string) {
  let entity = { ...dataItem };
  const properties = attributePath.split('.');

  for (const property of properties) entity = entity[property];

  return entity;
}
