<kendo-grid
  *ngIf="gridDefinition"
  #kendoGrid
  [data]="data"
  [pageSize]="state.take"
  [skip]="state.skip"
  [sort]="state.sort"
  [filter]="state.filter"
  [sortable]="true"
  [pageable]="true"
  [filterable]="gridDefinition.isFilterable ? 'menu' : false"
  [resizable]="true"
  (dataStateChange)="onDataStateChange($event)"
  (cellClick)="onCellClick($event)"
  (columnResize)="resizingService.onColumnResize($event)"
  [selectable]="gridDefinition.selection.settings"
  [kendoGridSelectBy]="gridDefinition.selection.selectBy"
  [selectedKeys]="selectionService.selectedKeys"
  (selectedKeysChange)="selectionService.onSelectedKeysChange($event)"
  appResize
  (windowResized)="queueSyncOfGridSelectionAndColumnWidths()"
  [ngClass]="{'no-locked-column-table': ( isCustomColumnTemplateHidden && !gridDefinition.selection?.settings?.enabled) }">
  <!-- REGION: CHECKBOX -->
  <kendo-grid-checkbox-column
    *ngIf="gridDefinition.selection?.settings?.enabled"
    media="(min-width: 600px)"
    class="checkbox-column"
    headerClass="checkbox-column"
    [width]="DEFAULT_CHECKBOX_COLUMN_WIDTH"
    [showSelectAll]="true"
    [resizable]="false"
    [locked]="resizingService.isFreezable">
    <ng-template kendoGridHeaderTemplate>
      <div class="checkbox-header-container">
        <mat-checkbox
          [checked]="selectionService.areAllSelected"
          [indeterminate]="selectionService.isIndeterminateSelection"
          [matTooltip]="'View.SelectAllRows' | translate"
          (click)="selectionService.onSelectAllClick($event)"
          (change)="selectionService.onSelectAllChange($event)"></mat-checkbox>
      </div>
    </ng-template>

    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
      <mat-checkbox
        class="k-grid-ignore-click"
        [checked]="dataItem.$isSelected"
        (click)="selectionService.onRowCheckboxClick($event, dataItem)"
        (change)="selectionService.onRowCheckboxChange($event, dataItem)"></mat-checkbox>
    </ng-template>
  </kendo-grid-checkbox-column>
  <!-- ENDREGION: CHECKBOX -->
  <!-- REGOIN: CUSTOM COLUMN CONTENT -->
  <kendo-grid-column
    *ngIf="customColumnTemplate"
    media="(min-width: 600px)"
    class="custom-column"
    [width]="resizingService.columnWidths['custom-column'] ?? DEFAULT_CUSTOM_COLUMN_WIDTH"
    [resizable]="false"
    [locked]="resizingService.isFreezable">
    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
      <ng-container
        [ngTemplateOutlet]="customColumnTemplate"
        [ngTemplateOutletContext]="{ dataItem, syncColumnWidths: queueSyncOfGridSelectionAndColumnWidths.bind(this) }">
      </ng-container>
    </ng-template>
  </kendo-grid-column>
  <!-- ENDREGION: CUSTOM COLUMN CONTENT -->
  <!-- REGION: COLUMNS -->
  <kendo-grid-column
    *ngFor="let column of gridDefinition.columns | visibleColumns"
    media="(min-width: 600px)"
    [field]="column.primaryField"
    [title]="column.title"
    [width]="resizingService.columnWidths[column.columnId] ?? DEFAULT_COLUMN_WIDTH"
    [style]="{ 'text-align': column.textAlign }"
    [locked]="resizingService.isFreezable && !!column.isFreezed"
    [class]="column.columnId">
    <!-- REGION: COLUMN HEADER -->
    <ng-template kendoGridHeaderTemplate>
      <div class="k-cell-inner">
        <div
          class="k-cell-inner-column"
          [style]="{ 'line-height': hasColumnAggregations(column.columnId) ? 1 : 'inherit' }">
          <span [matTooltip]="column.tooltip ?? column.title">{{ column.title }}</span>
          <app-aggregation-dropdown
            *ngIf="hasColumnAggregations(column.columnId)"
            [aggregations]="data?.aggregations?.[column.columnId]">
          </app-aggregation-dropdown>
        </div>
      </div>
    </ng-template>
    <!-- ENDREGION: COLUMN HEADER -->
    <!-- REGION: COLUMN FILTERS -->

    <ng-template
      *ngIf="column.type === 'boolean'"
      kendoGridFilterMenuTemplate
      let-filter
      let-kendoColumn="column"
      let-filterService="filterService">
      <kendo-grid-boolean-filter-menu [column]="kendoColumn" [filter]="filter" [filterService]="filterService">
      </kendo-grid-boolean-filter-menu>
    </ng-template>

    <ng-template *ngIf="column.type === 'date' || column.type === 'datetime'"
                 kendoGridFilterMenuTemplate
                 let-filter
                 let-kendoColumn="column"
                 let-filterService="filterService">
      <app-datetime-filter [filter]="filter"
                           [filterService]="filterService"
                           [column]="kendoColumn"
                           [useTimeZone]="column.type === 'datetime'"></app-datetime-filter>
    </ng-template>

    <ng-template
      *ngIf="column.type === 'decimal' || column.type === 'integer'"
      kendoGridFilterMenuTemplate
      let-filter
      let-kendoColumn="column"
      let-filterService="filterService">
      <kendo-grid-numeric-filter-menu [column]="kendoColumn" [filter]="filter" [filterService]="filterService">
      </kendo-grid-numeric-filter-menu>
    </ng-template>

    <ng-template
      *ngIf="column.type === 'time'"
      kendoGridFilterMenuTemplate
      let-filter
      let-kendoColumn="column"
      let-filterService="filterService">
      <app-time-filter
        [column]="kendoColumn"
        [filter]="filter"
        [filterService]="filterService"
        [maxPrecision]="column.timeMaxPrecision">
        <kendo-filter-eq-operator></kendo-filter-eq-operator>
        <kendo-filter-neq-operator></kendo-filter-neq-operator>
        <kendo-filter-gt-operator></kendo-filter-gt-operator>
        <kendo-filter-lt-operator></kendo-filter-lt-operator>
        <kendo-filter-isnull-operator></kendo-filter-isnull-operator>
        <kendo-filter-isnotnull-operator></kendo-filter-isnotnull-operator>
      </app-time-filter>
    </ng-template>

    <ng-template
      *ngIf="column.type === 'guid'"
      kendoGridFilterMenuTemplate
      let-filter
      let-kendoColumn="column"
      let-filterService="filterService">
      <kendo-grid-string-filter-menu [column]="kendoColumn" [filter]="filter" [filterService]="filterService">
        <kendo-filter-eq-operator></kendo-filter-eq-operator>
        <kendo-filter-neq-operator></kendo-filter-neq-operator>
        <kendo-filter-isnull-operator></kendo-filter-isnull-operator>
        <kendo-filter-isnotnull-operator></kendo-filter-isnotnull-operator>
      </kendo-grid-string-filter-menu>
    </ng-template>

    <ng-template
      *ngIf="column.type === 'optionSet'"
      kendoGridFilterMenuTemplate
      let-filter
      let-kendoColumn="column"
      let-filterService="filterService">
      <app-option-set-filter
        [currentFilter]="filter"
        [filterService]="filterService"
        [optionSet]="column.optionSet"
        [field]="kendoColumn.field">
      </app-option-set-filter>
    </ng-template>

    <ng-template
      *ngIf="column.type === 'progress'"
      kendoGridFilterMenuTemplate
      let-filter
      let-kendoColumn="column"
      let-filterService="filterService">
      <kendo-grid-numeric-filter-menu
        *ngIf="!column.optionSet"
        [column]="kendoColumn"
        [filter]="filter"
        [filterService]="filterService">
      </kendo-grid-numeric-filter-menu>
      <app-option-set-filter
        *ngIf="column.optionSet"
        [currentFilter]="filter"
        [filterService]="filterService"
        [optionSet]="column.optionSet"
        [field]="kendoColumn.field">
      </app-option-set-filter>
    </ng-template>

    <ng-template
      *ngIf="column.type === 'rating'"
      kendoGridFilterMenuTemplate
      let-filter
      let-kendoColumn="column"
      let-filterService="filterService">
      <kendo-grid-numeric-filter-menu
        *ngIf="!column.optionSet"
        [column]="kendoColumn"
        [filter]="filter"
        [filterService]="filterService">
      </kendo-grid-numeric-filter-menu>
      <app-option-set-filter
        *ngIf="column.optionSet"
        [currentFilter]="filter"
        [filterService]="filterService"
        [optionSet]="column.optionSet"
        [field]="kendoColumn.field">
      </app-option-set-filter>
    </ng-template>

    <ng-template *ngIf="column.type === 'combinedTexts'" kendoGridFilterMenuTemplate let-filter>
      <app-combined-texts-filter
        [column]="column"
        [currentFilter]="filter"
        (filterButtonClicked)="onCustomFilterSubmit($event)"
        (clearFiltersByFields)="clearByFields($event)"></app-combined-texts-filter>
    </ng-template>

    <!-- ENDREGION: COLUMN FILTERS -->
    <!-- REGION: COLUMN CELL CONTENT -->
    <ng-template kendoGridCellTemplate let-dataItem>
      <app-grid-column-cell
        [column]="column"
        [dataItem]="dataItem"
        (cellClick)="onClickableCellClick($event)"></app-grid-column-cell>
    </ng-template>
    <!-- ENDREGION: COLUMN CELL CONTENT -->
  </kendo-grid-column>

  <kendo-grid-column
    media="(max-width: 600px)"
    class="mobile-column"
    [title]="'View.Items' | translate"
    [width]="resizingService.columnWidths['mobile-column'] ?? DEFAULT_MOBILE_COLUMN_WIDTH"
    [resizable]="false"
    [locked]="false">
    <ng-template kendoGridHeaderTemplate let-column>
      <div class="mobile-cell">
        <div class="mobile-cell-col main">
          <span [matTooltip]="column.tooltip ?? column.title">{{ column.title }}</span>
        </div>
        <div class="mobile-cell-col">
          <mat-checkbox
            [checked]="selectionService.areAllSelected"
            [indeterminate]="selectionService.isIndeterminateSelection"
            [matTooltip]="'View.SelectAllRows' | translate"
            (click)="selectionService.onSelectAllClick($event)"
            (change)="selectionService.onSelectAllChange($event)">
          </mat-checkbox>
        </div>
      </div>
    </ng-template>

    <ng-template kendoGridCellTemplate let-dataItem>
      <div class="mobile-cell">
        <div *ngIf="customColumnTemplate" class="mobile-cell-col">
          <ng-container
            [ngTemplateOutlet]="customColumnTemplate"
            [ngTemplateOutletContext]="{ dataItem, syncColumnWidths: queueSyncOfGridSelectionAndColumnWidths.bind(this) }">
          </ng-container>
        </div>
        <div class="mobile-cell-col main">
          <app-grid-column-cell
            *ngFor="let column of gridDefinition.columns | slice: 0:3"
            [column]="column"
            [dataItem]="dataItem"
            (cellClick)="onClickableCellClick($event)">
          </app-grid-column-cell>
        </div>
        <div class="mobile-cell-col">
          <mat-checkbox
            class="k-grid-ignore-click"
            [checked]="dataItem.$isSelected"
            (click)="selectionService.onRowCheckboxClick($event, dataItem)"
            (change)="selectionService.onRowCheckboxChange($event, dataItem)">
          </mat-checkbox>
        </div>
      </div>
    </ng-template>
  </kendo-grid-column>
  <!-- ENDREGION: COLUMNS -->
  <!-- REGION: PAGING -->
  <ng-template kendoPagerTemplate let-currentPage="currentPage" let-totalPages="totalPages">
    <kendo-pager-prev-buttons class="pager-buttons"></kendo-pager-prev-buttons>
    <kendo-pager-info class="pager-info"></kendo-pager-info>
    <kendo-pager-numeric-buttons [buttonCount]="buttonCount"></kendo-pager-numeric-buttons>
    <kendo-pager-next-buttons class="pager-buttons"></kendo-pager-next-buttons>
    <app-grid-page-size-selector
      [(pageSize)]="state.take"
      [availablePageSizes]="[10, 50, 100, 150, 200]"
      (pageSizeChange)="onPageSizeChange($event)"></app-grid-page-size-selector>
  </ng-template>
  <!-- ENDREGION: PAGING -->
</kendo-grid>
