import { toODataString } from '@progress/kendo-data-query';
import { IEngineODataState } from '../../../engine-sdk/contract/odata/iodata-state.model';
import { GuidService } from '../../services/guid.service';
import { flatten } from '../../../shared/grid/grid.component';

export class EngineODataStateParser {
  static toODataString(state: IEngineODataState, utcDates: boolean = false) {
    if (state) {
      const randomToken = GuidService.generateNew();

      if (state?.filter?.filters?.length > 0)
        for (let filter of flatten(state.filter).filter(x => x['type'] == 'guid'))
          filter.value = `guid-${randomToken}${filter.value}guid-${randomToken}`

      let odataString = toODataString(state, { utcDates });
      odataString = odataString
        .split(`'guid-${randomToken}`).join('')
        .split(`guid-${randomToken}'`).join('')

      if (!state.customFilter) return odataString;

      const filterPartStartIndex = odataString.indexOf('$filter=');
      if (filterPartStartIndex == -1) return `${odataString}&$filter=${state.customFilter}`;

      const filterPartEndIndex = odataString.indexOf('&', filterPartStartIndex);
      const filterPart = odataString.substring(
        filterPartStartIndex,
        filterPartEndIndex != -1 ? filterPartEndIndex : undefined,
      );

      odataString = odataString.replace(
        filterPart,
        `$filter=(${state.customFilter}) and (${filterPart.substring(filterPart.indexOf('=') + 1)})`,
      );
      return odataString;
    }

    return '';
  }
}
