import { Injectable, InjectionToken } from '@angular/core';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { GridComponent } from '../grid.component';
import { GridItem } from '../models/grid-data.model';

export const GRID_SELECTION_SERVICE = new InjectionToken<IGridSelectionService>('IGridSelectionService');

export interface IGridSelectionService {
  selectedKeys: any[];
  areAllSelected: boolean;
  isIndeterminateSelection: boolean;

  registerGrid(grid: GridComponent);
  onSelectAllClick(event: Event);
  onSelectAllChange(event: MatCheckboxChange);
  onRowCheckboxClick(event: Event, gridItem: GridItem);
  onRowCheckboxChange(event: MatCheckboxChange, gridItem: GridItem);
  onSelectedKeysChange(selectedKeys: any[]);

  setSelectedKeys(selectedKeys: any[]);
  syncSelectionState();
}

@Injectable()
export class GridSelectionService implements IGridSelectionService {
  private _grid: GridComponent;

  private get gridDefinition() {
    return this._grid.gridDefinition;
  }

  private get data() {
    return this._grid.data;
  }

  selectedKeys: any[] = [];
  areAllSelected: boolean;
  isIndeterminateSelection: boolean;

  registerGrid(grid: GridComponent) {
    this._grid = grid;
  }

  onSelectAllClick(event: Event) {
    if (this.gridDefinition.selection.settings.mode === 'single') {
      event.preventDefault();
      if (this.selectedKeys.length !== 0) {
        this.selectedKeys = [];
        this.syncSelectionState();
        this._grid.onSelectedKeysChange(this.selectedKeys);
      }
    }
  }

  onSelectAllChange(event: MatCheckboxChange) {
    const set = new Set(this.selectedKeys);
    this.data?.data.forEach((gridItem) =>
      event.checked ? set.add(this.getSelectionKey(gridItem)) : set.delete(this.getSelectionKey(gridItem)),
    );
    this.selectedKeys = Array.from(set);

    this.syncSelectionState();
    this._grid.onSelectedKeysChange(this.selectedKeys);
  }

  onRowCheckboxClick(event: Event, gridItem: GridItem) {
    if (this.gridDefinition.selection.settings.mode === 'single') {
      event.preventDefault();

      this.selectedKeys = [this.getSelectionKey(gridItem)];

      this.syncSelectionState();
      this._grid.onSelectedKeysChange(this.selectedKeys);
    }
  }

  onRowCheckboxChange(event: MatCheckboxChange, gridItem: GridItem) {
    const key = this.getSelectionKey(gridItem);
    if (event.checked) {
      this.selectedKeys.push(key);
    } else {
      this.selectedKeys = this.selectedKeys.filter((x) => x !== key);
    }

    this.syncSelectionState();
    this._grid.onSelectedKeysChange(this.selectedKeys);
  }

  onSelectedKeysChange(selectedKeys: any[]) {
    this.selectedKeys = selectedKeys ?? [];
    this.syncSelectionState();
    this._grid.onSelectedKeysChange(selectedKeys);
  }

  setSelectedKeys(selectedKeys: any[]) {
    this.selectedKeys = selectedKeys ?? [];
    this.syncSelectedKeys();
  }

  syncSelectionState() {
    this.syncSelectedKeys();
    this.areAllSelected = this.data && this.data.data.every((x) => x.$isSelected);
    this.isIndeterminateSelection = this.data && !this.areAllSelected && this.data.data.some((x) => x.$isSelected);
  }

  private syncSelectedKeys() {
    if (this._grid.data && this._grid.data.data.length > 0) {
      const checked = this._grid.data.data.filter((gridItem) => gridItem.$isSelected);
      const unchecked = this._grid.data.data.filter((gridItem) => !gridItem.$isSelected);

      checked
        .filter((gridItem) => !this.selectedKeys.find((key) => key === this.getSelectionKey(gridItem)))
        .forEach((gridItem) => (gridItem.$isSelected = false));
      unchecked
        .filter((gridItem) => this.selectedKeys.find((key) => key === this.getSelectionKey(gridItem)))
        .forEach((gridItem) => (gridItem.$isSelected = true));
    }
  }

  private getSelectionKey(item: GridItem): any {
    return this._grid.gridDefinition?.selection?.selectBy
      ? item[this._grid.gridDefinition.selection.selectBy]
      : item['Id'];
  }
}
