import { Injectable, inject, Inject } from '@angular/core';
import { LocalizationClient } from '@core/services/api-clients';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { filter, first, mergeMap } from 'rxjs/operators';
import { AUTH_SERVICE_TOKEN, IAuthService } from '../../auth/models/iauth-service.model';

@Injectable()
export class EngineTranslateLoader implements TranslateLoader {
  private _localizationClient = inject(LocalizationClient);

  constructor(@Inject(AUTH_SERVICE_TOKEN) private _authService: IAuthService) {
  }

  getTranslation(lang: string): Observable<any> {
    return this._authService.isUserAuthenticated().pipe(
      filter(isUserAuthenticated => isUserAuthenticated),
      first(),
      mergeMap(() => this._localizationClient.infrastructureTranslations(lang))
    );
  }
}
