import { NgModule } from '@angular/core';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { HyperlinkModule } from '@shared/hyperlink/hyperlink.module';
import { ButtonControlComponent } from './components/button/button-control.component';
import { ControlErrorComponent } from './components/control-error/control-error.component';
import { DateControlComponent } from './components/date/date-control.component';
import { DatetimeControlComponent } from './components/datetime/datetime-control.component';
import { HyperlinkControlComponent } from './components/hyperlink/hyperlink-control.component';
import { LabelControlComponent } from './components/label/label-control.component';
import { MultiselectControlComponent } from './components/multiselect/multiselect-control.component';
import { NumberControlComponent } from './components/number/number-control.component';
import { SelectControlComponent } from './components/select/select-control.component';
import { TextAreaControlComponent } from './components/text-area/text-area-control.component';
import { TextControlComponent } from './components/text/text-control.component';
import { TimeControlComponent } from './components/time/time-control.component';
import { YesNoControlComponent } from './components/yes-no/yes-no-control.component';
import { HideableDirective } from './directives/hideable.directive';
import { PositionableDirective } from './directives/positionable.directive';
import { UiComponentsModule } from '@shared/ui-components/ui-components.module';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { IconModule } from '@shared/icon/icon.module';
import { CULTURE_SERVICE } from './models/iculture-service.model';
import { DefaultCultureService } from './services/default-culture.service';
import { DescriptionControlComponent } from './components/description/description-control.component';
import { RichTextComponent } from './components/rich-text/rich-text.component';
import { SanitizedHtmlPipe } from './pipes/sanitized-html.pipe';
import { HtmlSanitizer } from './services/html-sanitizer.service';
import { AutocompleteControlComponent } from './components/autocomplete/autocomplete-control.component';
import { IconControlComponent } from './components/icon/icon-control.component';
import { ChipsControlComponent } from './components/chips/chips-control.component';
import { UtilsModule } from '../utils/utils.module';
import { HtmlEditorControlComponent } from './components/html-editor/html-editor-control.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { GroupedAutocompleteControlComponent } from './components/grouped-autocomplete/grouped-autocomplete-control.component';
import { AutocompleteGroupsSelectorComponent } from './components/grouped-autocomplete/autocomplete-groups-selector/autocomplete-groups-selector.component';

@NgModule({
  declarations: [
    ControlErrorComponent,
    PositionableDirective,
    HideableDirective,
    TextControlComponent,
    LabelControlComponent,
    ButtonControlComponent,
    DatetimeControlComponent,
    DateControlComponent,
    TimeControlComponent,
    NumberControlComponent,
    HyperlinkControlComponent,
    SelectControlComponent,
    MultiselectControlComponent,
    TextAreaControlComponent,
    YesNoControlComponent,
    DescriptionControlComponent,
    RichTextComponent,
    SanitizedHtmlPipe,
    AutocompleteControlComponent,
    IconControlComponent,
    ChipsControlComponent,
    HtmlEditorControlComponent,
    GroupedAutocompleteControlComponent,
    AutocompleteGroupsSelectorComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    UiComponentsModule,
    HyperlinkModule,
    IconModule,
    UtilsModule,
    EditorModule,
  ],
  exports: [
    ControlErrorComponent,
    PositionableDirective,
    HideableDirective,
    TextControlComponent,
    LabelControlComponent,
    ButtonControlComponent,
    DatetimeControlComponent,
    DateControlComponent,
    TimeControlComponent,
    NumberControlComponent,
    HyperlinkControlComponent,
    SelectControlComponent,
    MultiselectControlComponent,
    TextAreaControlComponent,
    YesNoControlComponent,
    DescriptionControlComponent,
    AutocompleteControlComponent,
    IconControlComponent,
    ChipsControlComponent,
    HtmlEditorControlComponent,
    GroupedAutocompleteControlComponent,
  ],
  providers: [{ provide: CULTURE_SERVICE, useClass: DefaultCultureService }, HtmlSanitizer],
})
export class ReactiveControlsModule {}
