import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { EMPTY, of } from 'rxjs';
import { switchMap, map, catchError, withLatestFrom, tap, filter, mergeMap } from 'rxjs/operators';
import {
  AddMenuItemToRecentCommand,
  AddMenuItemToFavoriteCommand,
  RemoveMenuItemFromFavoriteCommand,
  MenuClient,
  MenuAreaDto,
} from '@core/services/api-clients';
import { NotificationService } from '@core/services/notification.service';
import {
  LoadMainMenu,
  MenuActionTypes,
  LoadMainMenuSuccess,
  AddRecentMenuItem,
  AddRecentMenuItemSuccess,
  AddFavoriteMenuItem,
  AddFavoriteMenuItemSuccess,
  RemoveFavoriteMenuItem,
  RemoveFavoriteMenuItemSuccess,
  SelectMenuArea,
  SelectMenuItem,
  SelectMenuGroup,
  SelectMenuItemSuccess,
} from './actions';
import {
  getMenuAreas,
  getMenuGroups,
  getSelectedMenuArea,
  getSelectedMenuAreaId,
  getSelectedMenuGroup,
  getSelectedMenuGroupDefaultMenuItem,
  getSelectedMenuItemId,
  isPopupEnabled,
} from './selectors';
import { Location } from '@angular/common';
import { UserSelectors } from '@core/user/store';
import { NavigationActions } from '@core/navigation/store';
import { HandleError } from '@core/errors/store/actions';
import { NavigateByNavigationPath } from '@core/navigation/store/actions';
import { getCurrentUserId } from '@core/user/store/selectors';
import { EngineTranslationService } from '@core/engine-translations/services/translation.service';
import { getCurrentNavigationPath } from '@core/navigation/store/selectors';

@Injectable()
export class MenuEffects {
  loadMainMenu$ = createEffect(() =>
    this._actions$.pipe(
      ofType<LoadMainMenu>(MenuActionTypes.LoadMainMenu),
      switchMap(() => this._menuClient.getMainMenu()),
      withLatestFrom(this._store.select(UserSelectors.getCurrentUserId)),
      switchMap(([mainMenu, userId]) => {
        const selectedMenuAreaId = localStorage[`SelectedMenuAreaId_${userId}`];
        const selectedMenuGroupId = localStorage[`SelectedMenuGroupId_${userId}`];
        const selectedMenuItemId = localStorage[`SelectedMenuItemId_${userId}`];

        return of(
          new LoadMainMenuSuccess({
            mainMenu: mainMenu,
            selectedMenuAreaId,
            selectedMenuGroupId,
            selectedMenuItemId,
          }),
        );
      }),
    ),
  );

  loadMainMenuSuccess$ = createEffect(() =>
    this._actions$.pipe(
      ofType<LoadMainMenuSuccess>(MenuActionTypes.LoadMainMenuSuccess),
      withLatestFrom(this._store.pipe(select(getCurrentNavigationPath))),
      switchMap(([_, navigationPath]) => {
        if (navigationPath != "" || this._location.path() != "") {
          return EMPTY;
        }
        return of(new NavigationActions.NavigateToRecentOrDefaultTarget());
      }),
    ),
  );

  addRecentMenuItem$ = createEffect(() =>
    this._actions$.pipe(
      ofType<AddRecentMenuItem>(MenuActionTypes.AddRecentMenuItem),
      map((action) => action.payload.menuItem),
      switchMap((menuItem) => {
        let command = new AddMenuItemToRecentCommand();
        command.menuItemId = menuItem.id;
        return this._menuClient.addMenuItemToRecent(command).pipe(
          catchError((error) => {
            this._store.dispatch(new HandleError({ error }));
            return of();
          }),
          switchMap((_) => {
            return of(new AddRecentMenuItemSuccess({ menuItem: menuItem }));
          }),
        );
      }),
    ),
  );

  addFavoriteMenuItem$ = createEffect(() =>
    this._actions$.pipe(
      ofType<AddFavoriteMenuItem>(MenuActionTypes.AddFavoriteMenuItem),
      map((action) => action.payload.menuItem),
      mergeMap((menuItem) => {
        let command = new AddMenuItemToFavoriteCommand();
        command.menuItemId = menuItem.id;
        return this._menuClient.addMenuItemToFavorite(command).pipe(
          catchError((error) => {
            this._store.dispatch(new HandleError({ error }));
            return of();
          }),
          switchMap(() => {
            const message = this._translate.translateInstantly('Menu.ItemAddedToFavorite');
            this._notificationService.success(message);
            return of(new AddFavoriteMenuItemSuccess({ menuItem: menuItem }));
          }),
        );
      }),
    ),
  );

  removeFavoriteMenuItem$ = createEffect(() =>
    this._actions$.pipe(
      ofType<RemoveFavoriteMenuItem>(MenuActionTypes.RemoveFavoriteMenuItem),
      map((action) => action.payload.menuItem),
      mergeMap((menuItem) => {
        let command = new RemoveMenuItemFromFavoriteCommand();
        command.menuItemId = menuItem.id;
        return this._menuClient.removeMenuItemFromFavorite(command).pipe(
          catchError((error) => {
            this._store.dispatch(new HandleError({ error }));
            return of();
          }),
          switchMap(() => {
            const message = this._translate.translateInstantly('Menu.ItemRemovedFromFavorite');
            this._notificationService.success(message);
            return of(new RemoveFavoriteMenuItemSuccess({ menuItem: menuItem }));
          }),
        );
      }),
    ),
  );

  selectMenuArea$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType<SelectMenuArea>(MenuActionTypes.SelectMenuArea),
        withLatestFrom(this._store.pipe(select(getSelectedMenuAreaId)), this._store.pipe(select(getCurrentUserId))),
        tap(([_, menuAreaId, userId]) => {
          localStorage[`SelectedMenuAreaId_${userId}`] = menuAreaId;
        }),
      ),
    { dispatch: false },
  );

  selectMenuGroup$ = createEffect(() =>
    this._actions$.pipe(
      ofType<SelectMenuGroup>(MenuActionTypes.SelectMenuGroup),
      withLatestFrom(
        this._store.pipe(select(getSelectedMenuGroup)),
        this._store.pipe(select(getSelectedMenuGroupDefaultMenuItem)),
        this._store.pipe(select(getSelectedMenuArea)),
        this._store.pipe(select(getMenuAreas)),
        this._store.pipe(select(isPopupEnabled)),
        this._store.pipe(select(getCurrentUserId)),
      ),
      switchMap(([action, menuGroup, defaultMenuItem, menuArea, allMenuAreas, isPopupEnabled, userId]) => {
        localStorage[`SelectedMenuGroupId_${userId}`] = action.payload.menuGroupId;

        const actions: Action[] = [];

        const shouldOpenDefaultMenuItem =
          !action.payload.preventDefaultMenuItemSelection
          && (isPopupEnabled || menuGroup?.menuItemIds.length === 1)
          && !!defaultMenuItem;

        if (shouldOpenDefaultMenuItem) {
          actions.push(new SelectMenuItem({ menuItem: defaultMenuItem }));
        }

        const selectedMenuAreaContainsMenuGroup =
          menuArea?.menuGroupIds && menuArea.menuGroupIds.some((id) => id == action.payload.menuGroupId);

        if (!selectedMenuAreaContainsMenuGroup) {
          const menuArea = Object.values(allMenuAreas as MenuAreaDto[]).find(
            (a) => a.menuGroupIds && a.menuGroupIds.some((id) => id == action.payload.menuGroupId),
          );

          if (menuArea) {
            actions.push(
              new SelectMenuArea({
                menuAreaId: menuArea.id,
              }),
            );
          }
        }

        return actions;
      }),
    ),
  );

  selectMenuItem$ = createEffect(() =>
    this._actions$.pipe(
      ofType<SelectMenuItem>(MenuActionTypes.SelectMenuItem),
      withLatestFrom(
        this._store.pipe(select(getSelectedMenuItemId)),
        this._store.pipe(select(getSelectedMenuGroup)),
        this._store.pipe(select(getMenuGroups)),
        this._store.pipe(select(getCurrentUserId)),
      ),
      filter(([action]) => `/${action.payload.menuItem.navigationToken}` != this._location.path()),
      switchMap(([action, _, menuGroup, allMenuGroups, userId]) => {
        const actions: Action[] = [new SelectMenuItemSuccess({ menuItem: action.payload.menuItem })];
        const selectedMenuGroupContainsMenuItem =
          menuGroup?.menuItemIds && menuGroup.menuItemIds.some((id) => id == action.payload.menuItem.id);

        if (!selectedMenuGroupContainsMenuItem) {
          menuGroup = Object.values(allMenuGroups).find(
            (g) => g.menuItemIds && g.menuItemIds.some((id) => id == action.payload.menuItem.id),
          );
          if (menuGroup) {
            actions.push(
              new SelectMenuGroup({
                menuGroupId: menuGroup.id,
                preventDefaultMenuItemSelection: true,
              }),
            );
          }
        }

        if (action.payload.menuItem.navigationToken) {
          localStorage[`SelectedMenuItemId_${userId}`] = action.payload.menuItem.id;
          actions.push(new AddRecentMenuItem({ menuItem: action.payload.menuItem }));

          if (!action.payload.preventNavigation) {
            actions.push(
              new NavigateByNavigationPath({
                navigationPath: action.payload.menuItem.navigationToken,
              }),
            );
          }
        }

        return actions;
      }),
    ),
  );

  constructor(
    private _actions$: Actions,
    private _notificationService: NotificationService,
    private _menuClient: MenuClient,
    private _translate: EngineTranslationService,
    private _store: Store,
    private _location: Location,
  ) { }
}
