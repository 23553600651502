import { Injectable, NgZone } from '@angular/core';
import { Observable, of, forkJoin, from, AsyncSubject, throwError } from 'rxjs';
import { UIScriptDto } from '@core/services/api-clients';
import { WebResourceUriPipe } from '../../shared/utils/pipes/relative-to-webresource-base64uri.pipe';
import { catchError, mergeMap, take, tap } from 'rxjs/operators';
import { ICancellationTokenProvider } from '../services/context.service';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { ErrorOrigin, ErrorType, IExecutionContext } from 'src/engine-sdk';
import { IScriptRunnerService } from '@core/widgets/models/iscript-runner.service';

@Injectable({
  providedIn: 'root',
})
export class ScriptRunnerService implements IScriptRunnerService {
  private _webResourceUriPipe: WebResourceUriPipe;

  constructor(sanitizer: DomSanitizer, http: HttpClient, private _ngZone: NgZone) {
    this._webResourceUriPipe = new WebResourceUriPipe(sanitizer, http);
  }

  registerScripts(scripts: UIScriptDto[]): Observable<void> {
    if (scripts == null || scripts.length == 0) return of(null);

    const scriptToLoad = Array.from(new Set(scripts.map((script) => script.scriptRelativePath)));
    if (scriptToLoad.length == 0) return of(null);

    const result$ = forkJoin(
      scriptToLoad.map((s) => {
        return this.processScriptLoading(s);
      }),
    );

    return result$.pipe(mergeMap(() => of(null)));
  }

  runScripts(scripts: UIScriptDto[], executionContext: IExecutionContext): void {
    if (scripts == null || scripts.length == 0) return;
    scripts.sort((a, b) => a.order - b.order);
    scripts.forEach((script) => {
      try {
        const cancellationToken = ((<any>executionContext) as ICancellationTokenProvider).getCancellationToken();
        if (cancellationToken.isCancellationRequested()) {
          return;
        }

        this._ngZone.run(() => {
          ScriptRunnerService.getAction(script.functionName, 'ctx')(executionContext);
        });
      } catch (error) {
        throw {
          origin: ErrorOrigin.UserInterface,
          payload: {
            message: error.message,
            type: ErrorType.ScriptExecution,
            source: script.functionName,
          },
        };
      }
    });
  }

  deleteScripts(scripts: UIScriptDto[]) {
    if (scripts == null || scripts.length == 0) return of([]);

    const scriptToUnload = Array.from(new Set(scripts.map((script) => script.scriptRelativePath)));
    if (scriptToUnload.length == 0) return of([]);

    const result$ = forkJoin(
      scriptToUnload.map((s) => {
        return this.processScriptUnloading(s);
      }),
    );

    return result$;
  }

  private processScriptUnloading(scriptName: string): Observable<void> {
    ScriptRunnerService.deleteScriptFromDom(scriptName);
    return of(null);
  }

  private processScriptLoading(scriptName: string): Observable<void> {
    if (ScriptRunnerService.isScriptLoaded(scriptName)) {
      return of(null);
    }

    const loadingContext = ScriptRunnerService.getScriptLoadingContext();
    if (loadingContext[scriptName]) {
      return loadingContext[scriptName];
    }

    loadingContext[scriptName] = new AsyncSubject<void>();

    from(this._webResourceUriPipe.transform(scriptName, false))
      .pipe(
        take(1),
        mergeMap((scriptUrl: string) => ScriptRunnerService.insertScriptToDomIfNotLoaded(scriptName, scriptUrl)),
        tap(() => {
          loadingContext[scriptName].next();
          loadingContext[scriptName].complete();
          delete loadingContext[scriptName];
        }),
        catchError((error) => {
          loadingContext[scriptName].error(error);
          loadingContext[scriptName].complete();
          delete loadingContext[scriptName];

          return throwError(error);
        }),
      )
      .subscribe();

    return loadingContext[scriptName];
  }

  //#region DOM manipulations
  private static isScriptLoaded(scriptName: string): boolean {
    return Boolean(document.querySelector(`script[id="${scriptName}"]`));
  }

  private static insertScriptToDomIfNotLoaded(scriptName: string, scriptUrl: string): Observable<void> {
    if (this.isScriptLoaded(scriptName)) return of(null);

    return this.insertScriptToDom(scriptName, scriptUrl);
  }

  private static insertScriptToDom(scriptName: string, scriptUrl: string): Observable<void> {
    const result$ = new AsyncSubject<void>();

    const ref = window.document.getElementsByTagName('script')[0];
    const script = window.document.createElement('script');

    script.onload = () => {
      result$.next();
      result$.complete();
    };

    script.id = scriptName;
    script.src = scriptUrl;

    ref.parentNode.insertBefore(script, ref);

    return result$;
  }

  private static deleteScriptFromDom(scriptName: string) {
    const script = document.querySelector(`script[id="${scriptName}"]`);
    if (script) {
      script.remove();
    }
  }
  //#endregion

  //#region Helpers
  public static getFunction(functionToRun: string, ...argsNames: string[]) {
    return new Function(...argsNames, ScriptRunnerService.wrapInFunctionNullCheck(functionToRun, ...argsNames));
  }

  private static getAction(functionToRun: string, ...argsNames: string[]) {
    return new Function(...argsNames, ScriptRunnerService.wrapInActionNullCheck(functionToRun, ...argsNames));
  }

  private static wrapInActionNullCheck(functionName: string, ...functionArguments: string[]): string {
    return `if(typeof(${functionName}) == typeof(Function)){
            ${functionName}(${functionArguments.join(', ')})
        }`;
  }

  private static wrapInFunctionNullCheck(functionName: string, ...functionArguments: string[]): string {
    return `if(typeof(${functionName}) == typeof(Function)){
            return ${functionName}(${functionArguments.join(', ')})
        }`;
  }

  private static getScriptLoadingContext(): { [scriptName: string]: AsyncSubject<void> } {
    const w = window as any;

    if (!w.scriptLoading$) {
      w.scriptLoading$ = {};
    }

    return w.scriptLoading$;
  }
  //#endregion
}
