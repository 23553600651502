<ng-container *ngIf="formDefinition$ | async as formDefinition">
  <app-context-menu
    [contextMenu]="testContextMenu"
    [additionalDataContext]="{ isDummy: true, formId: 'DummyForm' }"
    [disabled]="true">
  </app-context-menu>


  <div class="test-toolbar" *ngIf="formDefinition">
    <button type="button" mat-raised-button color="primary" (click)="testSomething(1)">Test something 1</button>
    <button type="button" mat-raised-button color="primary" (click)="testSomething(2)">Test something 2</button>
    <button type="button" mat-raised-button color="primary" (click)="testSomething(3)">Test something 3</button>
  </div>
  <engine-form
    *ngIf="formDefinition"
    [definition]="formDefinition"
    [selectedTabIndex]="0"
    [dataItem]="testValue"
    tempName="Dummy Form"></engine-form>
</ng-container>
