import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OwlIntlService } from './services/owl-intl.service';
import {
  NativeDateTimeAdapter,
  OWL_DATE_TIME_LOCALE,
  OwlDateTimeIntl,
  OwlDateTimeModule,
  OwlNativeDateTimeModule,
} from '@danielmoncada/angular-datetime-picker';
import { EngineCultureService } from '@core/engine-culture/services/culture.service';
import { OWL_DATE_ADAPTER } from '@shared/reactive-controls/components/date/date-control.component';
import { OwlDateTimeAdapter } from './services/owl-date-time-adapter.service';
import { OwlDateAdapter } from './services/owl-date-adapter.service';
import { OWL_DATE_TIME_ADAPTER } from '@shared/reactive-controls/components/datetime/datetime-control.component';

@NgModule({
  declarations: [],
  imports: [CommonModule, OwlDateTimeModule, OwlNativeDateTimeModule],
  providers: [NativeDateTimeAdapter],
})
export class OwlCoreModule {
  static forRoot(): ModuleWithProviders<OwlCoreModule> {
    return {
      ngModule: OwlCoreModule,
      providers: [
        { provide: OWL_DATE_TIME_LOCALE, useValue: 'en-GB' },
        OwlIntlService,
        { provide: OwlDateTimeIntl, useExisting: OwlIntlService },
        OwlDateAdapter,
        {
          provide: OWL_DATE_ADAPTER,
          useExisting: OwlDateAdapter,
        },
        OwlDateTimeAdapter,
        {
          provide: OWL_DATE_TIME_ADAPTER,
          useExisting: OwlDateTimeAdapter,
        },
      ],
    };
  }

  constructor(
    private _cultureService: EngineCultureService,
    private _nativeAdapter: NativeDateTimeAdapter,
    private _customAdapter: OwlDateTimeAdapter,
    private _intlService: OwlIntlService,
  ) {
    this.syncCultureInfo();
  }

  private syncCultureInfo() {
    if (!this._cultureService.cultureSettings) return;
    this._nativeAdapter.setLocale(this._cultureService.cultureSettings.code);
    this._customAdapter.setLocale(this._cultureService.cultureSettings.code);
    // TODO-MP: in future we can update owl translations base on engine labels
    this._intlService.updateTranslations({ ...this._intlService.translations });
  }
}
