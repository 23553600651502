import { DEFAULT_CURRENCY_CODE, LOCALE_ID, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule, DATE_PIPE_DEFAULT_OPTIONS } from '@angular/common';
import { EngineCultureService, ENGINE_LOCALE_ID } from './services/culture.service';
import { EngineDateParser } from './services/date-parser.service';
import { MomentModule } from '@shared/moment/moment.module';
import { MomentService } from '@shared/moment/services/moment.service';
import { CookiesModule } from '@shared/cookies/cookies.module';
import { CookiesService } from '@shared/cookies/cookies.service';

@NgModule({
  declarations: [],
  imports: [CommonModule, MomentModule, CookiesModule],
})
export class EngineCultureModule {
  static forRoot(): ModuleWithProviders<EngineCultureModule> {
    return {
      ngModule: EngineCultureModule,
      providers: [
        EngineCultureService,
        { provide: LOCALE_ID, useValue: ENGINE_LOCALE_ID },
        {
          provide: DATE_PIPE_DEFAULT_OPTIONS,
          useFactory: (cultureService: EngineCultureService, momentService: MomentService) => {
            let timezone = 'UTC';
            try {
              timezone = cultureService.cultureSettings?.ianaTimeZone ?? timezone;
            } catch (_) {}

            const offsetMinutes = momentService.getTimezoneOffset(timezone);
            const offsetHours = offsetMinutes / 60;
            const utcString = `UTC${offsetHours >= 0 ? '+' : ''}${offsetHours}`;

            return { timezone: utcString };
          },
          deps: [EngineCultureService, MomentService, CookiesService],
        },
        {
          provide: DEFAULT_CURRENCY_CODE,
          useFactory: (cultureService: EngineCultureService) => {
            let currency = '$';
            try {
              currency = cultureService.localeData[16] ?? currency;
            } catch (_) {}
            return currency;
          },
          deps: [EngineCultureService, CookiesService],
        },
        EngineDateParser,
      ],
    };
  }
}
