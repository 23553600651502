<div class="filter-wrapper">

  <button kendoButton (click)="onFilterModeChange($event)" class="datetime-filter-change-mode-button">
    {{ (isAdvancedModeEnabled ? 'DateTimeFilter.RangeMode' : 'DateTimeFilter.AdvancedMode') | translate }}
  </button>

  <div>

    <kendo-daterange *ngIf="!isAdvancedModeEnabled">
      <input type="text"
             class="k-textbox date-input k-input k-input-md k-rounded-md k-input-solid"
             [value]="display"
             [placeholder]="('DateTimeFilter.DateRange' | translate)"
             #anchor
             readonly
             (click)="popup.toggle()" />

      <kendo-daterange-popup #popup [animate]="{ type: 'fade', direction: 'down', duration: 100 }" [anchor]="{ nativeElement: anchor }" class="date-range-filter">
        <ng-template kendoDateRangePopupTemplate>
          <kendo-multiviewcalendar [views]="1"
                                   [selectionRange]="range"
                                   (selectionRangeChange)="selectionChange($event)"
                                   kendoDateRangeSelection>
          </kendo-multiviewcalendar>
          <button kendoButton (click)="onAccept(popup)" class="datetime-filter-button">{{'Ok' | translate}}</button>
        </ng-template>
      </kendo-daterange-popup>

    </kendo-daterange>

    <kendo-grid-date-filter-menu *ngIf="isAdvancedModeEnabled" [column]="column" [filter]="filter" [filterService]="filterService">
    </kendo-grid-date-filter-menu>
  </div>

</div>
