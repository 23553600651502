import { getMainMenu } from '@core/menu/store/selectors';
import { getCurrentUser, getUILanguages } from '@core/user/store/selectors';
import { createSelector, createFeatureSelector } from '@ngrx/store';
import { ErrorFeatureState } from '.';
import { FEATURE_NAME } from './state';

export const errorsFeatureSelector = createFeatureSelector<ErrorFeatureState>(FEATURE_NAME);
export const errorsSelector = createSelector(errorsFeatureSelector, (state) => state.errors);

export const isApplicationLoaded = createSelector(
  getCurrentUser,
  getMainMenu,
  getUILanguages,
  (user, menu, languages) => user != null && menu != null && languages != null,
);

export const getFatalErrors = createSelector(errorsSelector, (error) => error.fatalErrors);
export const isFatalError = createSelector(
  getFatalErrors,
  (fatalErrors) => fatalErrors != null && fatalErrors.length > 0,
);
