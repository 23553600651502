import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  SkipSelf,
} from '@angular/core';
import { IRecordInfo } from '@core/models/irecord-info.model';
import { NavigationService } from '@core/navigation/services/navigation.service';
import { ProcessEntityAssignmentDto, ProcessInstanceHistoryDto, ProcessStateDto } from '@core/services/api-clients';
import { ContextService } from '@core/services/context.service';
import { NoEventWidgetDirective } from '@core/widgets/directives/no-event-widget.directive';
import { WidgetDirective } from '@core/widgets/directives/widget.directive';
import { IScriptRunnerService, SCRIPT_RUNNER_SERVICE } from '@core/widgets/models/iscript-runner.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, first, takeUntil, tap } from 'rxjs/operators';
import { ProcessDefinitionService } from './services/process-definition-service';

@Component({
  selector: 'engine-processes-container',
  templateUrl: './engine-main-processes.component.html',
  styleUrls: ['./engine-main-processes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EngineProcessesMainComponent extends NoEventWidgetDirective implements OnInit, OnDestroy {
  private _recordInfo: IRecordInfo;
  private _currentStateId: string;

  @Input() dataItem: any;

  @Input()
  set recordInfo(value: IRecordInfo) {
    if (this._recordInfo != value) {
      let wasChanged = this.recordInfo?.entityId != value?.entityId || this.recordInfo?.recordId != value?.recordId;
      this._recordInfo = value;

      if (wasChanged) {
        this._processService.loadProcessAssignmentsForEntity(value.entityId);
        this.processAssignments$ = this._processService.getProcessAssgnmentForEntity(value.entityId).pipe(
          takeUntil(this._destroy$),
          filter((x) => !!x),
          tap((processAssignments) => {
            this.onProcessAssignmentChange(processAssignments[0]);
          }),
        );
      }
    }
  }

  processAssignments$: Observable<ProcessEntityAssignmentDto[]>;
  processHistory$: Observable<ProcessInstanceHistoryDto[]>;
  currentStateId$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  currentProcessAssignment: ProcessEntityAssignmentDto;
  currentProcessState$: Observable<ProcessStateDto>;

  get recordInfo(): IRecordInfo {
    return this._recordInfo;
  }

  constructor(
    @Optional() @SkipSelf() parentWidget: WidgetDirective,
    @Inject(SCRIPT_RUNNER_SERVICE) scriptRunnerService: IScriptRunnerService,
    private _contextService: ContextService,
    private _navigationContext: NavigationService,
    private _processService: ProcessDefinitionService,
  ) {
    super(parentWidget, scriptRunnerService);
  }

  ngOnInit() {
    this.currentStateId$
      .pipe(
        takeUntil(this._destroy$),
        filter((x) => !!x),
        tap((stateId) => {
          if (stateId) {
            this._processService.loadProcessStateWithTransitions(stateId);
            this._processService.loadProcessHistory(
              this.currentProcessAssignment.processAssignmentId,
              this._recordInfo.recordId,
            );
          }
          this.currentProcessState$ = this._processService.getProcessState(stateId);
          this.processHistory$ = this._processService.getProcessHistory(
            this.currentProcessAssignment?.processAssignmentId,
            this._recordInfo.recordId
          );
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.currentStateId$.complete();
    this._destroy$.next(true);
    this._destroy$.complete();
  }

  onProcessAssignmentChange(processAssignment: ProcessEntityAssignmentDto) {
    if (processAssignment) {
      this.currentProcessAssignment = processAssignment;
      this._currentStateId = this.dataItem[processAssignment.processStateLookupAttributeName];
      this.currentStateId$.next(this._currentStateId);
    }
  }

  executeTransition(event: { currentProcessState: ProcessStateDto; targetStateId: string }) {

    this._processService
      .getProcessStateHistoryItem(
        this.currentProcessAssignment?.processAssignmentId,
        this._recordInfo.recordId,
        event.currentProcessState.id,
      )
      .pipe(
        first(),
        tap((currentStateHistoryItem) => {
          this._processService
            .executeTransition(
              event.targetStateId,
              currentStateHistoryItem.recordId,
              this.currentProcessAssignment.processStateLookupAttributeId,
            )
            .subscribe((response) => {
              if (response) {
                if (response.hasRecordChanged) {
                  this._navigationContext.navigateToForm(response.entityId, response.formId, response.recordId);
                } else {
                  this._contextService
                    .getMainFormContextAsync()
                    .pipe(first())
                    .subscribe((x) => {
                      x.refetchData();
                      x.markAsPristine();
                    });

                  this._currentStateId = event.targetStateId;
                  this.currentStateId$.next(this._currentStateId);
                }
              };
            });

        }),
      )
      .subscribe();
  }

  navigateToProcessRecord(data?: ProcessInstanceHistoryDto) {
    if (data && data.recordId != this._recordInfo?.recordId) {
      this._navigationContext.navigateToForm(data.entityId, data.defaultFormId, data.recordId);
    }
  }

  navigateToCurrentProcessRecord(procesState: ProcessStateDto) {
    this._processService
      .getProcessStateHistoryItem(
        this.currentProcessAssignment?.processAssignmentId,
        this._recordInfo.recordId,
        procesState.id,
      )
      .pipe(
        first(),
        tap((x) => {
          this.navigateToProcessRecord(x);
        }),
      )
      .subscribe();
  }
}
