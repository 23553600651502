import { Pipe, PipeTransform } from '@angular/core';
import { GridColumn } from '../models/grid-definition.model';
import { LookupPipe } from './lookup.pipe';

@Pipe({
  name: 'combinedTexts',
})
export class CombinedTextsPipe implements PipeTransform {
  private _lookupPipe: LookupPipe = new LookupPipe();

  transform(dataItem: any, column: GridColumn): string {
    if (!dataItem) return null;
    const combinedTexts = [
      this._lookupPipe.transform(dataItem, column, column.primaryField),
      this._lookupPipe.transform(dataItem, column, column.secondaryField)
    ].filter((x) => !!x);
    return combinedTexts.join(column.combinedTextsSeparator ?? ', ');
  }
}
