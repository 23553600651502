<mat-menu yPosition="below" [overlapTrigger]="false">
  <button
    type="button"
    mat-menu-item
    [ngClass]="{ 'language-selected': item.code === selectedLanguage.code }"
    *ngFor="let item of languages"
    (click)="selectLanguage(item)">
    <img class="language-flag" src="\assets\flags\{{ item.code.substr(3, 5).toLowerCase() }}.svg" />{{
      item.displayName
    }}
    ({{ item.code }})
  </button>
</mat-menu>
