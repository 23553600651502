import { Inject, Injectable } from '@angular/core';
import {
  ENGINE_NOTIFICATION_SERVICE,
  IEngineNotificationService,
} from '@core/notification/services/engine-nofitication.service';
import { forkJoin } from 'rxjs';
import { first, tap } from 'rxjs/operators';
import { IControlContext, FormControlNotificationTemplates } from '../../../engine-sdk';
import { EngineFormComponent } from '../components/engine-form/engine-form.component';
import { EngineTranslationService, TranslationType } from '@core/engine-translations/services/translation.service';

@Injectable()
export class EngineFormNotificationService {
  private _form: EngineFormComponent;

  constructor(
    @Inject(ENGINE_NOTIFICATION_SERVICE) private _notificationService: IEngineNotificationService,
    private _translationService: EngineTranslationService,
  ) {}

  init(form: EngineFormComponent) {
    this._form = form;
  }

  // TODO-MP: notificationGroupName = entityName? lepsza nazwa?
  addNotification(msgVariable: string, control: IControlContext, additionalMessage = null) {
    this.dispatchTranslatedNotificationAction(
      msgVariable,
      FormControlNotificationTemplates.AttributeValidationIssues,
      { label: control.label, primaryAttribute: control.getAttributes().primaryAttribute },
      (msg, groupMsg) => {
        const errorMessage = additionalMessage ? `${msg} ${additionalMessage}` : msg;
        const group = this.getNotificationGroupForControl(
          control,
          groupMsg,
          errorMessage,
          this._form.notificationGroupName,
        );
        this._notificationService.addNotifications([group]);
      },
    );
  }

  // TODO-MP: notificationGroupName = entityName? lepsza nazwa?
  removeNotification(msgVariable: string, control: IControlContext, additionalMessage = null) {
    this.dispatchTranslatedNotificationAction(
      msgVariable,
      FormControlNotificationTemplates.AttributeValidationIssues,
      { label: control.label, primaryAttribute: control.getAttributes().primaryAttribute },
      (msg, groupMsg) => {
        const errorMessage = additionalMessage ? `${msg} ${additionalMessage}` : msg;
        const group = this.getNotificationGroupForControl(
          control,
          groupMsg,
          errorMessage,
          this._form.notificationGroupName,
        );
        this._notificationService.removeNotifications(group);
      },
    );
  }

  private dispatchTranslatedNotificationAction(
    msgVariable: string,
    groupMsgVariable: string,
    context: any,
    action: (msg, groupMsg) => void,
  ) {
    forkJoin([
      this._translationService.translate(msgVariable, context, TranslationType.Business).pipe(first()),
      this._translationService.translate(groupMsgVariable, context, TranslationType.Business).pipe(first()),
    ])
      .pipe(
        tap(([msg, groupMsg]) => {
          action(msg, groupMsg);
        }),
      )
      .subscribe();
  }

  private getNotificationGroupForControl(control, groupMessage, errorMessage, entityName) {
    const builder = this._notificationService.getNotificationGroupsBuilder(entityName);
    builder.getAttributeNotificationGroup(control.primaryAttribute, groupMessage).addValidationError(errorMessage);
    return builder.build()[0];
  }
}
