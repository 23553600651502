import { NgModule, Optional, SkipSelf } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { API_BASE_URL } from '@core/services/api-clients';
import { ContextService } from '@core/services/context.service';
import { ToolsService } from '@core/services/tools-service';
import { environment } from '../environments/environment';
import { SharedModule } from '@shared/shared.module';
import { ScriptsRunningModule } from './scripts-running/scripts-running.module';
import { AuthModule } from './auth/auth.module';
import { RouterModule } from './router/router.module';
import { ContextMenuModule } from './context-menu/context-menu.module';
import { EngineFormsModule } from './engine-forms/engine-forms.module';
import { ErrorModule } from './errors/error.module';
import { MenuModule } from './menu/menu.module';
import { NavigationModule } from './navigation/navigation.module';
import { UserModule } from './user/user.module';
import { NotificationModule } from './notification/notification.module';
import { BlobModule } from './blob/blob.module';
import { LayoutModule } from './layout/layout.module';
import { EngineViewsModule } from './engine-views/engine-views.module';
import { WidgetsModule } from './widgets/widgets.module';
import { USER_INTERACTION_SERVICE } from 'src/engine-sdk/contract/user-interaction';
import { UserInteractionService } from './services/user-interaction.service';
import { LodashService } from './services/lodash.service';
import { KendoCoreModule } from './kendo-core/kendo-core.module';
import { OwlCoreModule } from './owl-core/owl-core.module';
import { EngineCultureModule } from './engine-culture/engine-culture.module';
import { EngineMetadataModule } from './metadata/engine-metadata.module';
import { EngineODataModule } from './engine-odata/engine-odata.module';
import { EngineProcessesModule } from './engine-processes/engine-processes.module';

export const odataBaseUrl = environment.urls.BaseUrl;

const engineCoreModules = [
  RouterModule.forRoot(),
  ErrorModule.forRoot(),
  EngineCultureModule.forRoot(),
  KendoCoreModule.forRoot(),
  OwlCoreModule.forRoot(),
  EngineODataModule.forRoot(),
  AuthModule.forRoot(),
  NavigationModule.forRoot(),
  NotificationModule.forRoot(),
  UserModule.forRoot(),
  LayoutModule.forRoot(),
  MenuModule.forRoot(),
  ContextMenuModule.forRoot(),
  EngineViewsModule.forRoot(),
  EngineFormsModule.forRoot(),
  WidgetsModule.forRoot(),
  ScriptsRunningModule.forRoot(),
  BlobModule.forRoot(),
  EngineMetadataModule.forRoot(),
  EngineProcessesModule.forRoot()
];

@NgModule({
  declarations: [],
  imports: [
    HttpClientModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({
      maxAge: 50,
      logOnly: environment.production,
    }),
    engineCoreModules,
    SharedModule,
  ],
  exports: [],
  providers: [
    {
      provide: API_BASE_URL,
      useValue: odataBaseUrl,
    },
    ContextService,
    ToolsService,
    LodashService,
    UserInteractionService,
    {
      provide: USER_INTERACTION_SERVICE,
      useExisting: UserInteractionService,
    },
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule has already been loaded. You should only import Core modules in the AppModule only.');
    }
  }
}
