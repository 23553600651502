import { Action } from '@ngrx/store';
import { UserProfileDto, UILanguageDto, CheckCurrentUserPermissionKeyResult } from '@core/services/api-clients';

export enum UserActionTypes {
  LoadUserData = '[User] Load User Data',
  LoadUserDataSuccess = '[User] Load User Data Success',
  LoadUserDataFailure = '[User] Load User Data Failure',

  RemoveUserData = '[User] Remove User Data',

  LoadUILanguages = '[User] Load UILanguages',
  LoadUILanguagesSuccess = '[User] Load UILanguages Success',

  SetUserLanguage = '[User] Set User Language',
  SetUserLanguageSuccess = '[User] Set User Language Success',

  LoadLanguages = '[User] Load Languages',
  LoadLanguagesSuccess = '[User] Load Languages Success',

  CheckCurrentUserPermissionKey = '[User] Check Current User Permission Key',
  CheckCurrentUserPermissionKeySuccess = '[User] Check Current User Permission Key Success',
}

export class LoadUserData implements Action {
  public readonly type = UserActionTypes.LoadUserData;
}

export class LoadUserDataSuccess implements Action {
  public readonly type = UserActionTypes.LoadUserDataSuccess;
  public constructor(public payload: { user: UserProfileDto }) {}
}

export class LoadUserDataFailure implements Action {
  public readonly type = UserActionTypes.LoadUserDataFailure;
}

export class RemoveUserData implements Action {
  public readonly type = UserActionTypes.RemoveUserData;
}

export class LoadUILanguages implements Action {
  public readonly type = UserActionTypes.LoadUILanguages;
}

export class LoadUILanguagesSuccess implements Action {
  public readonly type = UserActionTypes.LoadUILanguagesSuccess;
  public constructor(public payload: { uiLanguages: UILanguageDto[] }) {}
}

export class SetUserLanguage implements Action {
  public readonly type = UserActionTypes.SetUserLanguage;
  public constructor(public payload: { language: UILanguageDto }) {}
}

export class SetUserLanguageSuccess implements Action {
  public readonly type = UserActionTypes.SetUserLanguageSuccess;
  public constructor(public payload: { language: UILanguageDto }) {}
}

export class CheckCurrentUserPermissionKey implements Action {
  public readonly type = UserActionTypes.CheckCurrentUserPermissionKey;
  constructor(public payload: { permissionKeyId: string }) {}
}

export class CheckCurrentUserPermissionKeySuccess implements Action {
  public readonly type = UserActionTypes.CheckCurrentUserPermissionKeySuccess;
  constructor(
    public payload: {
      permissionKeyId: string;
      result: CheckCurrentUserPermissionKeyResult;
    },
  ) {}
}

export type UserActions =
  | LoadUserData
  | LoadUserDataSuccess
  | LoadUserDataFailure
  | RemoveUserData
  | LoadUILanguages
  | LoadUILanguagesSuccess
  | SetUserLanguage
  | SetUserLanguageSuccess
  | CheckCurrentUserPermissionKey
  | CheckCurrentUserPermissionKeySuccess;
