import { Directive, ElementRef, OnDestroy, Input } from '@angular/core';

@Directive({
  selector: '[displayRows]',
})
export class GridDisplayRowsDirective implements OnDestroy {
  private _mutationObserver: MutationObserver;
  private _isConnected = false;
  private _displayRows: number;

  get displayRows() {
    return this._displayRows;
  }
  @Input() set displayRows(value: number) {
    if (this._displayRows != value) {
      this._displayRows = value;

      if (value) {
        this.connect();
      }
    }
  }

  constructor(private _elementRef: ElementRef) {
    this._mutationObserver = new MutationObserver(() => {
      const height = this.getGridHeight();
      if (height > 0) {
        const htmlElement = this._elementRef.nativeElement as HTMLElement;
        htmlElement.setAttribute('style', `display:block; height:${height}px;`);

        // auto disconnect when height set
        this.disconnect();
      }
    });
  }

  ngOnDestroy(): void {
    this.disconnect();
  }

  private connect() {
    if (!this._isConnected) {
      this._mutationObserver.observe(this._elementRef.nativeElement, {
        attributes: true,
        childList: true,
        subtree: true,
      });

      this._isConnected = true;
    }
  }

  private disconnect() {
    if (this._isConnected) {
      this._mutationObserver.disconnect();
      this._isConnected = false;
    }
  }

  private getGridHeight(): number {
    let header = this._elementRef.nativeElement.querySelector('.k-grid-header');
    let footer = this._elementRef.nativeElement.querySelector('kendo-pager');
    let row = this._elementRef.nativeElement.querySelector('tbody tr:first-of-type');

    if (header != null && footer != null && row != null) {
      const headerHeight = header ? header.offsetHeight : 0;
      const footerHeight = footer ? footer.offsetHeight : 0;
      const rowHeight = row ? row.offsetHeight : 0;

      // Kendo set 1px for not reeady rows, so we have to check it
      if (headerHeight > 1 && footerHeight > 1 && rowHeight > 1)
        return headerHeight + this.displayRows * rowHeight + footerHeight;
    }

    return 0;
  }
}
