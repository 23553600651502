import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ProcessStateDto, ProcessStateTransitionDto } from '@core/services/api-clients';

@Component({
  selector: 'engine-process-current-state',
  templateUrl: './engine-process-current-state.component.html',
  styleUrls: ['./engine-process-current-state.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EngineProcessCurrentStateComponent {

  private greyColor: string = "#B3B3BF";
  private blueColor: string = "#00A3D7";
  @Input() processState: ProcessStateDto;
  @Output() executeProcessTransition = new EventEmitter<{
    currentProcessState: ProcessStateDto;
    targetStateId: string;
  }>();
  @Output() navigateToRelatedRecord = new EventEmitter<ProcessStateDto>();

  executeTransition(targetStateId: string) {
    this.executeProcessTransition.emit({ currentProcessState: this.processState, targetStateId: targetStateId });
  }

  getTransitionBackgroundColor(transition: ProcessStateTransitionDto) {
    return transition?.htmlColor ? transition.htmlColor : '#ffffff';
  }

  getStateBorder(processState: ProcessStateTransitionDto, isTransitionButton: boolean = false){
    if (isTransitionButton)
      return `2px solid ${processState?.htmlColor ? processState.htmlColor : this.greyColor};`;
    else
      return `2px solid ${processState?.htmlColor ? processState.htmlColor : this.blueColor};`;
  }

  getStateColor(processState: ProcessStateTransitionDto, isTransitionButton: boolean = false){
    if (isTransitionButton)
      return `${processState?.htmlColor ? processState.htmlColor : this.greyColor};`;
    else
      return `${processState?.htmlColor ? processState.htmlColor : this.blueColor};`;
  }
}
