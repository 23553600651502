import { MsalInterceptorConfiguration, MSAL_INTERCEPTOR_CONFIG } from '@azure/msal-angular';
import { InteractionType } from '@azure/msal-browser';
import { environment } from '@env';

const msalInterceptorConfigFactory = (): MsalInterceptorConfiguration => {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(environment.urls.ApiUrl, [environment.msalModule.appScope]);
  protectedResourceMap.set(environment.urls.ODataUrl, [environment.msalModule.appScope]);
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
};

export const msalInterceptorConfigProvider = {
  provide: MSAL_INTERCEPTOR_CONFIG,
  useFactory: msalInterceptorConfigFactory,
};
