import { ModuleWithProviders, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { reducers } from './store';
import { FEATURE_NAME } from './store/state';
import { ViewEffects } from './store/effects';
import { SharedModule } from '@shared/shared.module';
import { WidgetsModule } from '@core/widgets/widgets.module';
import { EngineViewModule } from './components/engine-view/engine-view.module';
import { SubgridViewComponent } from './components/subgrid-view/subgrid-view.component';
import { RecordsSelectorViewComponent } from './components/records-selector-view/records-selector-view.component';
import { ContextMenuModule } from '@core/context-menu/context-menu.module';

@NgModule({
  declarations: [SubgridViewComponent, RecordsSelectorViewComponent],
  imports: [
    StoreModule.forFeature(FEATURE_NAME, reducers),
    EffectsModule.forFeature([ViewEffects]),
    SharedModule,
    WidgetsModule,
    EngineViewModule,
    ContextMenuModule,
  ],
  exports: [EngineViewModule, SubgridViewComponent, RecordsSelectorViewComponent],
})
export class EngineViewsModule {
  static forRoot(): ModuleWithProviders<EngineViewsModule> {
    return {
      ngModule: EngineViewsModule,
      providers: [],
    };
  }
}
