<div class="page-size-selector-container">
  <mat-form-field class="input-wrapper">
    <mat-select [disabled]="disabled" [(ngModel)]="pageSize" (selectionChange)="pageSizeChange.emit($event.value)">
      <mat-option *ngFor="let availablePageSize of availablePageSizes" [value]="availablePageSize">
        {{ availablePageSize }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <span class="page-size-selector-text">{{ 'View.SizePickerInfo' | translate }}</span>
</div>
