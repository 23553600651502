import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { CustomRouterStateSerializer, FEATURE_NAME } from './store/state';
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { RouterEffects } from './store/effects';
import { Router } from '@angular/router';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(FEATURE_NAME, routerReducer),
    EffectsModule.forFeature([RouterEffects]),
    StoreRouterConnectingModule.forRoot({ serializer: CustomRouterStateSerializer }),
  ],
  providers: [],
})
export class RouterModule {
  static forRoot(): ModuleWithProviders<RouterModule> {
    return {
      ngModule: RouterModule,
    };
  }

  constructor(router: Router) {
    router.routeReuseStrategy.shouldReuseRoute = () => false;
  }
}
