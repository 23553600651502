export enum NotificationType {
  Information,
  Warning,
  Error,
}

export enum NotificationScope {
  Global,
  Attribute,
}

export interface INotification {
  type: NotificationType;
  message: string;
}

export class Notification implements INotification {
  type: NotificationType;
  message: string;

  constructor(initData?) {
    if (initData) {
      this.type = initData['type'];
      this.message = initData['message'];
    }
  }
}

export enum FormControlNotificationTemplates {
  IsRequired = 'IsRequired',
  AttributeValidationIssues = 'AttributeValidationIssues',
  MinValueValidation = 'MinValueValidation',
  MaxValueValidation = 'MaxValueValidation',
  RangeValueValidation = 'RangeValueValidation',
  MinLengthValidation = 'MinLengthValidation',
  MaxLengthValidation = 'MaxLengthValidation',
  RangeLengthValidation = 'RangeLengthValidation',
}
