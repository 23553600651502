<button
  type="button"
  *ngIf="buttonDefinition && buttonState"
  [id]="buttonDefinition.id"
  [name]="buttonDefinition.name"
  [disabled]="buttonState.isDisabled"
  [matTooltip]="buttonState.tooltipText"
  matTooltipPosition="below"
  [matTooltipDisabled]="hasLabel && isLabelVisible"
  [ngClass]="{'highlighted-button': buttonState.isHighlighted}"
  mat-button>
  <app-icon buttonIcon [icon]="getIcon(buttonState)"></app-icon>
  <span *ngIf="hasLabel && isLabelVisible" class="button-label">{{ buttonState.label }}</span>
</button>
