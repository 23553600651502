import { InjectionToken } from '@angular/core';
import { UIScriptDto } from '@core/services/api-clients';
import { Observable } from 'rxjs';
import { IExecutionContext } from 'src/engine-sdk';

export const SCRIPT_RUNNER_SERVICE = new InjectionToken<IScriptRunnerService>('IScriptRunnerService');

export interface IScriptRunnerService {
  registerScripts(scripts: UIScriptDto[]): Observable<void>;
  runScripts(scripts: UIScriptDto[], executionContext: IExecutionContext);
  deleteScripts(scripts: UIScriptDto[]);
}
