import { Injectable, inject } from '@angular/core';
import { EngineCultureService } from '@core/engine-culture/services/culture.service';
import { EngineTranslationService } from '@core/engine-translations/services/translation.service';
import { MessageService } from '@progress/kendo-angular-l10n';

@Injectable()
export class KendoMessageService extends MessageService {
  private _cultureService: EngineCultureService = inject(EngineCultureService);
  private _translationService: EngineTranslationService = inject(EngineTranslationService);

  get(key: string): string {
    return this._translationService.translateInstantly(key);
  }

  constructor() {
    super();
    if (!this._cultureService.cultureSettings) return;
    this.notify(this._cultureService.cultureSettings?.rtl ?? false);
  }
}
