import { IError } from 'src/engine-sdk';

export const FEATURE_NAME: string = 'errors-feature';

export interface IErrorsState {
  fatalErrors: IError[];
}

export const initialErrorsState: IErrorsState = {
  fatalErrors: [],
};
