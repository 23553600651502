import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IconDto, MenuItemDto, MenuGroupDto, UserProfileDto, UserMenuGroupDto } from '@core/services/api-clients';
import { MenuFeatureState } from '.';
import { FEATURE_NAME } from './state';
import { getCurrentUser } from '@core/user/store/selectors';

export interface MenuAreaWithMenuGroups {
  id?: string;
  name?: string;
  shortLabel?: string;
  label?: string;
  icon?: IconDto;
  menuGroupIds?: string[];
  menuGroups: MenuGroupWithMenuItems[];
}

export interface MenuGroupWithMenuItems {
  id?: string;
  name?: string | undefined;
  shortLabel?: string | undefined;
  label?: string | undefined;
  icon?: IconDto | undefined;
  menuItemIds?: string[];
  menuItems: MenuItemDto[];
  isOpen: boolean;
}

export const menuFeatureSelector = createFeatureSelector<MenuFeatureState>(FEATURE_NAME);
export const menuSelector = createSelector(menuFeatureSelector, (state: MenuFeatureState) => state.menu);

export const getMainMenu = createSelector(menuSelector, (state) => state.mainMenu);
export const getSelectedMenuAreaId = createSelector(menuSelector, (state) => state.selectedMenuAreaId);
export const getSelectedMenuGroupId = createSelector(menuSelector, (state) => state.selectedMenuGroupId);
export const getSelectedMenuItemId = createSelector(menuSelector, (state) => state.selectedMenuItemId);
export const isPopupEnabled = createSelector(menuSelector, (state) => state.isPopupEnabled);
export const isFavoriteModeEnabled = createSelector(menuSelector, (state) => state.isFavoriteModeEnabled);

export const getMenuAreas = createSelector(getMainMenu, (mainMenu) => (mainMenu ? mainMenu.menuAreas : null));
export const getMenuGroups = createSelector(getMainMenu, (mainMenu) => (mainMenu ? mainMenu.menuGroups : null));
export const getMenuItems = createSelector(getMainMenu, (mainMenu) => (mainMenu ? mainMenu.menuItems : null));

export const getMenuConfiguration = createSelector(getMainMenu, (mainMenu) =>
  mainMenu ? mainMenu.menuConfiguration : null,
);
export const getRecentMenuGroup = createSelector(getMainMenu, (mainMenu) =>
  mainMenu ? mainMenu.recentMenuGroup : null,
);
export const getFavoriteMenuGroup = createSelector(getMainMenu, (mainMenu) =>
  mainMenu ? mainMenu.favoriteMenuGroup : null,
);
export const getUserMenuGroup = createSelector(getMainMenu, (mainMenu) => (mainMenu ? mainMenu.userMenuGroup : null));

export const areFavoriteItemsUsed = createSelector(
  getMenuConfiguration,
  (configuration) => configuration.useFavoriteMenuItems,
);
export const areRecentItemsUsed = createSelector(
  getMenuConfiguration,
  (configuration) => configuration.useRecentMenuItems,
);
export const areUserItemsUsed = createSelector(getMenuConfiguration, (configuration) => configuration.useUserMenuItems);
export const getRecentItemsNumber = createSelector(
  getMenuConfiguration,
  (configuration) => configuration.recentMenuItemsNumber,
);

export const getSelectedMenuArea = createSelector(
  getMenuAreas,
  getSelectedMenuAreaId,
  (menuAreas, selectedMenuAreaId) =>
    menuAreas && selectedMenuAreaId ? menuAreas.find((a) => a.id == selectedMenuAreaId) ?? null : null,
);
export const getSelectedMenuGroup = createSelector(
  getMenuGroups,
  getSelectedMenuGroupId,
  (menuGroups, selectedMenuGroupId) =>
    menuGroups && selectedMenuGroupId ? menuGroups[selectedMenuGroupId] ?? null : null,
);
export const getSelectedMenuItem = createSelector(
  getMenuItems,
  getSelectedMenuItemId,
  (menuItems, selectedMenuItemId) => (menuItems && selectedMenuItemId ? menuItems[selectedMenuItemId] ?? null : null),
);

export const getSelectedMenuAreaMenuGroups = createSelector(
  getSelectedMenuArea,
  getMenuGroups,
  getMenuItems,
  (selectedMenuArea, menuGroups, menuItems): MenuGroupWithMenuItems[] => {
    const selectedMenuAreaMenuGroups = [];
    if (selectedMenuArea) {
      selectedMenuArea.menuGroupIds.forEach((id) => {
        const menuGroup = {
          ...menuGroups[id],
          menuItems: [],
        } as MenuGroupWithMenuItems;
        menuGroup.menuItems = menuGroup.menuItemIds
          ? menuGroup.menuItemIds.map((i) => menuItems[i]).filter((i) => !!i)
          : [];
        selectedMenuAreaMenuGroups.push(menuGroup);
      });
    }
    return selectedMenuAreaMenuGroups;
  },
);

export const getSelectedMenuGroupDefaultMenuItem = createSelector(
  getSelectedMenuGroup,
  getMenuItems,
  (menuGroup, allMenuItems): MenuItemDto => {
    let defaultMenuItem: MenuItemDto = null;

    if (!!menuGroup?.menuItemIds?.length) {
      const groupMenuItems = menuGroup.menuItemIds.map((id) => allMenuItems[id]);
      const filtered = groupMenuItems.filter((x) => x.isDefault);
      defaultMenuItem = filtered.length > 0 ? filtered[0] : groupMenuItems[0];
    }

    return defaultMenuItem;
  },
);

export const getFilledRecentMenuGroup = createSelector(
  getRecentMenuGroup,
  getMenuItems,
  getCurrentUser,
  (recentMenuGroup: MenuGroupDto, menuItems: { [id: string]: MenuItemDto }, currentUser: UserProfileDto) => {
    if (currentUser == null) return null;

    let filledRecentMenuGroup: MenuGroupWithMenuItems = null;
    if (recentMenuGroup) {
      let recentMenuItems: MenuItemDto[] = [];
      recentMenuGroup.menuItemIds.forEach((x) => recentMenuItems.push(menuItems[x]));
      filledRecentMenuGroup = {
        ...recentMenuGroup,
        menuItems: recentMenuItems,
        isOpen: getMenuGroupState(currentUser.userId, 'Special', recentMenuGroup.id),
      };
    }
    return filledRecentMenuGroup;
  },
);

export const getRecentMenuItem = createSelector(getFilledRecentMenuGroup, (recentMenuGroup) => {
  return recentMenuGroup?.menuItems[0];
});

export const getDefaultMenuItem = createSelector(getMainMenu, (mainMenu) => {
  if (mainMenu) {
    const menuArea = mainMenu.menuAreas.find((x) => x.isDefault) ?? mainMenu.menuAreas[0];
    const menuGroup = mainMenu.menuGroups[menuArea.menuGroupIds[0]];
    const menuItems = menuGroup.menuItemIds.map((x) => mainMenu.menuItems[x]);

    return menuItems.find((x) => x.isDefault) ?? menuItems[0];
  } else {
    return null;
  }
});

export const getFilledFavoriteMenuGroup = createSelector(
  getFavoriteMenuGroup,
  getMenuItems,
  getCurrentUser,
  (favoriteMenuGroup: MenuGroupDto, menuItems: { [id: string]: MenuItemDto }, currentUser: UserProfileDto) => {
    if (currentUser == null) return null;

    let filledFavoriteMenuGroup: MenuGroupWithMenuItems = null;
    if (favoriteMenuGroup) {
      let favoriteMenuItems: MenuItemDto[] = [];
      favoriteMenuGroup.menuItemIds.forEach((x) => favoriteMenuItems.push(menuItems[x]));
      filledFavoriteMenuGroup = {
        ...favoriteMenuGroup,
        menuItems: favoriteMenuItems,
        isOpen: getMenuGroupState(currentUser.userId, 'Special', favoriteMenuGroup.id),
      };
    }
    return filledFavoriteMenuGroup;
  },
);

export const getFilledUserMenuGroup = createSelector(
  getUserMenuGroup,
  getMenuItems,
  (userMenuGroup: UserMenuGroupDto, menuItems: { [id: string]: MenuItemDto }) => {
    let filledUserMenuGroup: MenuGroupWithMenuItems = null;
    if (userMenuGroup) {
      let userMenuItems: MenuItemDto[] = [];
      userMenuGroup.menuItemIds.forEach((x) => userMenuItems.push(menuItems[x]));
      filledUserMenuGroup = {
        ...userMenuGroup,
        menuItems: userMenuItems,
        isOpen: true,
      };
    }
    return filledUserMenuGroup;
  },
);

export const getLogoutIcon = createSelector(
  getUserMenuGroup,
  (userMenuDto: UserMenuGroupDto) => userMenuDto.logoutIcon,
);
export const getDarkModeIcon = createSelector(
  getUserMenuGroup,
  (userMenuDto: UserMenuGroupDto) => userMenuDto.darkModeIcon,
);
export const getLanguageIcon = createSelector(
  getUserMenuGroup,
  (userMenuDto: UserMenuGroupDto) => userMenuDto.languageIcon,
);
export const getAboutIcon = createSelector(getUserMenuGroup, (userMenuDto: UserMenuGroupDto) => userMenuDto.aboutIcon);

const getMenuGroupState = (userId: string, menuAreaId: string, menuGroupId: string): boolean => {
  let value = localStorage[`MenuGroupState_${userId}_${menuAreaId}_${menuGroupId}`];
  return value ? JSON.parse(value) : false;
};
