import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { EMPTY, Observable, of } from 'rxjs';
import { AuthenticationSuccess } from '../store/actions';
import { IAuthService } from '../models/iauth-service.model';
import { AuthSelectors } from '../store';
import { AuthenticationType } from '../models/authentication-type.enum';

@Injectable()
export class BasicAuthService implements IAuthService {
  private _isInitialized: boolean = false;

  constructor(private _store: Store) { }

  init(): void {
    if (this._isInitialized) return;
    this._isInitialized = true;
    this._store.dispatch(new AuthenticationSuccess());
  }

  getToken(): Observable<string> {
    return of('token');
  }

  logout(): Observable<any> {
    return EMPTY;
  }

  isUserAuthenticated(): Observable<boolean> {
    return this._store.select(AuthSelectors.isAuthenticated);
  }

  goToLoginPage(): Observable<any> {
    return EMPTY;
  }

  getAuthenticationType(): AuthenticationType {
    return AuthenticationType.ActiveDirectory;
  }
}
