import { ActionReducerMap } from '@ngrx/store';
import { notificationsReducer } from './reducers';
import { INotificationsState } from './state';
import * as NotificationsSelectors from './selectors';
import * as NotificationsActions from './actions';

export { NotificationsActions, NotificationsSelectors };

export interface NotificationsFeatureState {
  notifications: INotificationsState;
}

export const reducers: ActionReducerMap<NotificationsFeatureState> = {
  notifications: notificationsReducer,
};
