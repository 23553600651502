import { MsalGuardConfiguration, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { InteractionType } from '@azure/msal-browser';
import { environment } from '@env';

const msalGuardConfigFactory = (): MsalGuardConfiguration => {
  const state = environment.msalModule.redirectUri.startsWith(location.origin)
    ? ''
    : location.origin;

  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [environment.msalModule.appScope],
      state: state
    },
  };
};

export const authGuardConfigProvider = {
  provide: MSAL_GUARD_CONFIG,
  useFactory: msalGuardConfigFactory,
};
