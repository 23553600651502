<button type="button" mat-button class="view-selector-mainbutton" [matMenuTriggerFor]="selectorMenu" [disabled]="disabled">
  <mat-icon>bookmarks</mat-icon>
</button>

<mat-menu #selectorMenu="matMenu" class="view-selector-buttons" (closed)="onClose()">
  <button
    type="button" 
    *ngFor="let viewTab of viewTabs"
    mat-menu-item
    [disabled]="visibleViewTabsCount <= 1 && viewTab.isVisible"
    (click)="onViewTabClick($event, viewTab)">
    <mat-icon *ngIf="viewTab.isVisible; else selected">star</mat-icon>
    <ng-template #selected>
      <mat-icon>star_border</mat-icon>
    </ng-template>
    {{ viewTab.label }}
  </button>
</mat-menu>
