import { ICultureService } from '../models/iculture-service.model';
import { ICultureSettings } from '../models/iculture-settings.model';

export class DefaultCultureService implements ICultureService {
  constructor() {}

  getCultureSettings(): ICultureSettings {
    throw new Error('Reactive forms culture service not provided');
  }
}
