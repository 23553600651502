import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  LoadProcessEntityAssignments,
  LoadProcessEntityAssignmenetsSuccess,
  LoadProcessStateWithTransitions,
  LoadProcessStateWithTransitionsSuccess,
  ProcessActionsTypes,
  LoadProcessHistory,
  LoadProcessHistorySuccess,
} from './actions';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { getProcessAssignments, getProcessStates } from './selectors';
import { ProcessesClient } from '@core/services/api-clients';
import { HandleError } from '@core/errors/store/actions';
import { EMPTY, of } from 'rxjs';

@Injectable()
export class ProcessEffects {
  loadProcessAssignment$ = createEffect(() =>
    this._actions$.pipe(
      ofType<LoadProcessEntityAssignments>(ProcessActionsTypes.LoadProcessEntityAssignments),
      map((action) => action.payload),
      withLatestFrom(this._store.select(getProcessAssignments)),
      switchMap(([payload, processAssignments]) => {
        if (processAssignments && processAssignments[payload.entityId]) {
          return EMPTY;
        } else {
          return this._processesClient
            .getProcessAssignmentsForEntity(payload.entityId)
            .pipe(
              map(
                (x) =>
                  new LoadProcessEntityAssignmenetsSuccess({ entityId: payload.entityId, procesEntitytAssignments: x }),
              ),
            );
        }
      }),
    ),
  );

  loadProcessSteteWithTransitions$ = createEffect(() =>
    this._actions$.pipe(
      ofType<LoadProcessStateWithTransitions>(ProcessActionsTypes.LoadProcessStateWithTransitions),
      map((action) => action.payload.sourceStateId),
      withLatestFrom(this._store.select(getProcessStates)),
      mergeMap(([processStateId, processStates]) => {
        if (processStates && processStates[processStateId]) {
          return EMPTY;
        } else {
          return this._processesClient.getProcessStateWithTransitions(processStateId).pipe(
            catchError((error) => {
              this._store.dispatch(new HandleError({ error }));
              return EMPTY;
            }),
            mergeMap((processStateDto) => {
              return of(
                new LoadProcessStateWithTransitionsSuccess({
                  sourceStateId: processStateId,
                  processState: processStateDto,
                }),
              );
            }),
          );
        }
      }),
    ),
  );

  loadProcessHistory$ = createEffect(() =>
    this._actions$.pipe(
      ofType<LoadProcessHistory>(ProcessActionsTypes.LoadProcessHistory),
      map((action) => action.payload),
      switchMap((payload) => {
        return this._processesClient
          .getProcessHistory(payload.processAssignmentId, payload.recordId)
          .pipe(
            map(
              (x) =>
                new LoadProcessHistorySuccess({
                  processAssignmentId: payload.processAssignmentId,
                  processHistoryItems: x,
                }),
            ),
          );
      }),
    ),
  );

  constructor(private _actions$: Actions, private _store: Store, private _processesClient: ProcessesClient) {}
}
