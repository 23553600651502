import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { NotificationGroup, NotificationType } from 'src/engine-sdk';

@Component({
  selector: 'app-notification-bar',
  templateUrl: './notification-bar.component.html',
  styleUrls: ['./notification-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationBarComponent {
  private _iconMapping = {
    [NotificationType.Error]: 'error',
    [NotificationType.Warning]: 'warning',
    [NotificationType.Information]: 'info',
  };

  private _notificationGroupMapping = {
    [NotificationType.Error]: 'notification-group-error',
    [NotificationType.Warning]: 'notification-group-warning',
    [NotificationType.Information]: 'notification-group-info',
  };

  @Input() notificationGroups: NotificationGroup[];
  @Input() maxHeight: string;

  @Output() remove: EventEmitter<NotificationGroup> = new EventEmitter<NotificationGroup>();

  panelOpenState = false;

  constructor() {}

  notificationCount(group) {
    return group.notifications.length;
  }

  getIcon(type) {
    return this._iconMapping[type];
  }

  getGroupType(group) {
    return this._notificationGroupMapping[group.type !== undefined ? group.type : NotificationType.Error];
  }

  onRemoveClick(event, group: NotificationGroup) {
    event.stopPropagation();
    this.remove.emit(group);
  }
}
