import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { ViewTab } from '../../../../models/view.model';

@Component({
  selector: 'app-view-selector',
  templateUrl: './view-selector.component.html',
  styleUrls: ['./view-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewSelectorComponent implements OnChanges {
  private _isChanged = false;

  @Input() viewTabs: ViewTab[];
  @Input() disabled = false;
  @Input() selectionMode: 'single' | 'multi';
  @Output() viewTabsVisibilityChange = new EventEmitter<ViewTab[]>();
  @ViewChild(MatMenuTrigger, { static: true }) trigger: MatMenuTrigger;

  visibleViewTabsCount = 0;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['viewTabs']) {
      this.visibleViewTabsCount = this.viewTabs?.length > 0 ? this.viewTabs.filter((x) => x.isVisible).length : 0;
    }
  }

  onViewTabClick(event: Event, viewTab: ViewTab) {
    if (this.selectionMode === 'multi') {
      viewTab.isVisible = !viewTab.isVisible;
      this.visibleViewTabsCount += viewTab.isVisible ? 1 : -1;
      this._isChanged = true;

      event.stopPropagation();
    } else if (this.selectionMode === 'single') {
      this.viewTabs.forEach((x) => {
        x.isVisible = x.viewId == viewTab.viewId;
      });

      this.visibleViewTabsCount = 1;
      this._isChanged = true;

      this.trigger.closeMenu();
    }
  }

  onClose() {
    if (this._isChanged) {
      this._isChanged = false;
      this.viewTabsVisibilityChange.emit(this.viewTabs);
    }
  }
}
