import { ContextMenuDto } from '@core/services/api-clients';

export const FEATURE_NAME: string = 'context-menu-feature';

export interface IContextMenuState {
  mainContextMenu: ContextMenuDto;
  gridColumnContextMenu: { [viewId: string]: ContextMenuDto };
  subgridContextMenu: { [subgridId: string]: ContextMenuDto };
  subgridColumnContextMenu: { [subgridId: string]: ContextMenuDto };
  dialogFormContextMenu: { [dialogId: string]: ContextMenuDto };
}

export const initialContextMenuState: IContextMenuState = {
  mainContextMenu: null,
  gridColumnContextMenu: {},
  subgridContextMenu: {},
  subgridColumnContextMenu: {},
  dialogFormContextMenu: {},
};
