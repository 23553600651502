import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output, inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ISelectOption } from '@shared/reactive-controls/models/iselect-option.model';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { IEngineEntity } from 'src/engine-sdk/contract/metadata';
import { IFilterDescriptor } from 'src/engine-sdk/contract/odata/iodata-state.model';

@Component({
  selector: 'app-query-filter',
  templateUrl: './query-filter.component.html',
  styleUrls: ['./query-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QueryFilterComponent implements OnDestroy {
  private _destroy$: Subject<boolean> = new Subject<boolean>();
  private _fb: UntypedFormBuilder = inject(UntypedFormBuilder);
  private _entity: IEngineEntity;

  @Input() set entity(v: IEngineEntity) {
    this._entity = v;
    this.fieldsOptions = this.entity
      ? this.entity.attributes.map((f) => {
          return { label: f.name, value: f.id };
        })
      : [];
  }
  @Input() set value(v: IFilterDescriptor) {
    if (!v) return;
    this.formGroup.patchValue(v, { emitEvent: false });
  }
  @Output() filterChange = new EventEmitter<IFilterDescriptor>();

  get entity(): IEngineEntity {
    return this._entity;
  }
  get value(): IFilterDescriptor {
    return this.formGroup.value;
  }
  fieldsOptions: ISelectOption[] = [];
  operators: ISelectOption[] = [
    { label: 'Equals', value: 'eq' },
    { label: 'Not Equals', value: 'ne' },
  ];

  formGroup: UntypedFormGroup;

  constructor() {
    this.formGroup = this._fb.group(
      {
        field: [{ value: undefined, disabled: false }],
        operator: [{ value: undefined, disabled: false }],
        value: [{ value: undefined, disabled: false }],
      },
      { updateOn: 'blur' },
    );
    this.formGroup.valueChanges
      .pipe(
        takeUntil(this._destroy$),
        tap((x) => this.filterChange.emit({ ...this.value })),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
  }
}
