import { Component, Inject } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { EngineVersionData } from './engine-version-data';

@Component({
  selector: 'app-engine-about-dialog',
  templateUrl: './engine-about-dialog.component.html',
  styleUrls: ['./engine-about-dialog.component.scss'],
})
export class EngineAboutDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<EngineAboutDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: EngineVersionData,
  ) {}

  close(): void {
    this.dialogRef.close();
  }
}
