<ng-container *ngIf="sectionDefinition && sectionState && contextMenuState && sectionDefinition.buttons.length > 0">

  <div class="narrow-section" fxShow [fxHide.gt-md]="sectionState.isExpandable">
    <button 
            type="button"
            mat-button
            [matMenuTriggerFor]="menu"
            [disabled]="sectionState.isDisabled"
            [ngClass]="{'highlighted-button': sectionState.isHighlighted}">
      <app-icon [icon]="getIcon()"></app-icon>
      <span *ngIf="!isCompactMode" class="button-label">{{ sectionState.label }}</span>
    </button>

    <mat-menu #menu="matMenu">
      <ng-container *ngFor="let button of sectionDefinition.buttons">
        <div *ngIf="getButtonState(button.id) as buttonState"
             class="context-menu-button"
             mat-menu-item
             [class.hidden]="!buttonState.isVisible">
          <app-context-menu-button [buttonDefinition]="button"
                                   [contextMenuState]="contextMenuState"
                                   [isLabelVisible]="true"
                                   [widgetId]="button.id"
                                   [uiScripts]="button.scripts">
          </app-context-menu-button>
        </div>
      </ng-container>
    </mat-menu>
  </div>

  <div class="wide-section"
       fxHide.lt-lg
       [fxHide]="!sectionState.isExpandable">
    <ng-container *ngFor="let button of sectionDefinition.buttons">
      <app-context-menu-button *ngIf="getButtonState(button.id) as buttonState"
                               class="context-menu-button"
                               [class.hidden]="!buttonState.isVisible"
                               [buttonDefinition]="button"
                               [contextMenuState]="contextMenuState"
                               [isLabelVisible]="!isCompactMode"
                               [widgetId]="button.id"
                               [uiScripts]="button.scripts">
      </app-context-menu-button>
    </ng-container>
  </div>

</ng-container>
