import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { KanbanColumnAggregatorDto, KanbanColumnDto, KanbanDto, RecordDto } from '@core/services/api-clients';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { KanbanData } from '@app/main-kanban/store/state';
import { MoveRecordEvent } from '@app/main-kanban/store/actions';
import { ContextService } from '@core/services/context.service';
import { IKanbanContext } from '../../../engine-sdk/contract/kanban';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-kanban',
  templateUrl: './kanban.component.html',
  styleUrls: ['./kanban.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KanbanComponent implements AfterViewInit, OnDestroy, IKanbanContext {
  private _destroy$: Subject<boolean> = new Subject<boolean>();
  private _kanban: KanbanDto;

  @Input() set kanban(k: KanbanDto) {
    this._kanban = k;
    if (this._kanban) {
      this.kanbanColumnIds = this._kanban.columns.map((x) => x.id);
    }
  }
  @Input() kanbanData: KanbanData;
  @Input() searchTerm: string;

  @Output() tileDoubleClick = new EventEmitter<{ entity: RecordDto; formId: string; recordId: string }>();
  @Output() recordMove = new EventEmitter<MoveRecordEvent>();
  @Output() loadMoreClick = new EventEmitter<{ kanban: KanbanDto; kanbanColumn: KanbanColumnDto }>();
  @Output() aggregatorSelect = new EventEmitter<{
    kanbanColumn: KanbanColumnDto;
    kanbanColumnAggregator: KanbanColumnAggregatorDto;
  }>();
  @Output() columnExpansionChange = new EventEmitter<{ column: KanbanColumnDto; isExpanded: boolean }>();
  @Output() refreshDataClick = new EventEmitter<KanbanDto>();
  @Output() restoreSettingsClick = new EventEmitter<KanbanDto>();
  @Output() searchTermChange = new EventEmitter<{ kanban: KanbanDto; searchTerm: string }>();

  kanbanColumnIds: string[];

  get kanban(): KanbanDto {
    return this._kanban;
  }

  constructor(private _contextService: ContextService, private _change: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this._contextService.registerKanban(this);
  }

  ngOnDestroy(): void {
    this._contextService.unregisterKanban();
    this._destroy$.next(true);
    this._destroy$.complete();
  }

  drop(event: CdkDragDrop<string[]>, kanbanColumn: KanbanColumnDto): void {
    const record = event.previousContainer.data[event.previousIndex];
    const previousColumn = this.kanban.columns.find(
      (x) => x.relatedValue === record[this.kanban.relatedAttribute.logicalName],
    );

    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);

      this.kanbanData.columns[previousColumn.id].pageSize--;
      this.kanbanData.columns[kanbanColumn.id].pageSize++;
    }

    this.recordMove.emit({
      kanban: this.kanban,
      previousColumn: previousColumn,
      previousIndex: event.previousIndex,
      currentColumn: kanbanColumn,
      currentIndex: event.currentIndex,
      record: record,
      onError: () => {
        transferArrayItem(event.container.data, event.previousContainer.data, event.currentIndex, event.previousIndex);

        this.kanbanData.columns[previousColumn.id].pageSize++;
        this.kanbanData.columns[kanbanColumn.id].pageSize--;
        this._change.markForCheck();
      },
    });
  }

  emitTileDoubleClick(record: any) {
    if (this.kanban.formId) {
      this.tileDoubleClick.emit({ entity: this.kanban.entity, formId: this.kanban.formId, recordId: record.Id });
    }
  }

  emitLoadMoreClick(kanbanColumn: KanbanColumnDto) {
    this.loadMoreClick.emit({ kanban: this.kanban, kanbanColumn });
  }

  //#region IKanbanContext

  getKanbanId(): string {
    return this.kanban.id;
  }

  getDefaultForm() {
    return this.kanban.formId;
  }

  getEntityInfo(): { entityId: string; entityName: string } {
    return {
      entityId: this.kanban.entity.id,
      entityName: this.kanban.entity.name,
    };
  }

  //#endregion
}
