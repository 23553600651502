import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor, HttpContextToken } from '@angular/common/http';
import { catchError, finalize } from 'rxjs/operators';
import { BusyIndicatorService } from '../../services/busy-indicator.service';

export const IGNORE_BUSY_INDICATOR = new HttpContextToken<boolean>(() => false);

@Injectable()
export class BusyIndicatorInterceptor implements HttpInterceptor {
  constructor(private _busyIndicatorService: BusyIndicatorService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    if (this.isSvgIconRequest(request) || this.shouldIgnoreBusyIndicator(request)) return next.handle(request);

    this._busyIndicatorService.newOperation();
    return next.handle(request).pipe(
      catchError((err) => {
        throw err;
      }),
      finalize(() => {
        this._busyIndicatorService.operationFinished();
      }),
    );
  }

  isSvgIconRequest(request: HttpRequest<any>): boolean {
    const url = request.url.split('?')[0];
    return url.toLowerCase().endsWith('.svg');
  }

  shouldIgnoreBusyIndicator(request: HttpRequest<any>): boolean {
    return request.context.get(IGNORE_BUSY_INDICATOR);
  }
}
