import { NgModule } from '@angular/core';
import { TestFormComponent } from './test-form.component';
import { EngineFormsModule } from '@core/engine-forms/engine-forms.module';
import { SharedModule } from '@shared/shared.module';
import { ContextMenuModule } from '@core/context-menu/context-menu.module';

@NgModule({
  declarations: [TestFormComponent],
  imports: [SharedModule, EngineFormsModule, ContextMenuModule],
  exports: [TestFormComponent],
})
export class TestFormModule {}
