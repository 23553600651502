import { NgModule } from '@angular/core';
import { QueryBuilderComponent } from './query-builder.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { QueryCompositeFilterComponent } from './query-composite-filter/query-composite-filter.component';
import { QueryFilterComponent } from './query-composite-filter/query-filter/query-filter.component';
import { UiComponentsModule } from '@shared/ui-components/ui-components.module';
import { ReactiveControlsModule } from '@shared/reactive-controls/reactive-controls.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [QueryBuilderComponent, QueryCompositeFilterComponent, QueryFilterComponent],
  imports: [CommonModule, ReactiveFormsModule, TranslateModule.forChild(), UiComponentsModule, ReactiveControlsModule],
  exports: [QueryBuilderComponent],
})
export class QueryBuilderModule {}
