import { Component } from '@angular/core';
import { AuthActions } from '@core/auth/store';
import { Store } from '@ngrx/store';
import { ErrorInfo } from '@shared/error-page/error-page.component';

@Component({
  selector: 'app-not-authorized',
  template: '<app-error-page [error]="error"></app-error-page>',
})
export class NotAuthorizedComponent {
  error: ErrorInfo;

  constructor(private _store: Store) {
    this.error = {
      code: '403',
      message: 'You are not authorized.',
      details: 'You tried to access a page you did not have prior permission for. Contact with your administrator.',
      actions: [
        {
          title: 'Logout',
          handler: this.onLogoutClick.bind(this),
        },
      ],
    };
  }

  onLogoutClick() {
    this._store.dispatch(new AuthActions.Logout());
  }
}
