import {
  Component,
  ChangeDetectionStrategy,
  forwardRef,
  Inject,
  Injector,
  ChangeDetectorRef,
  Input,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseFormControlDirective } from '@shared/reactive-controls/directives/base-form-control.directive';
import { CULTURE_SERVICE, ICultureService } from '@shared/reactive-controls/models/iculture-service.model';
import { HtmlSanitizer } from '@shared/reactive-controls/services/html-sanitizer.service';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-description-control',
  templateUrl: './description-control.component.html',
  styleUrls: ['./description-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DescriptionControlComponent),
      multi: true,
    },
    {
      provide: BaseFormControlDirective,
      useExisting: DescriptionControlComponent,
    },
  ],
})
export class DescriptionControlComponent extends BaseFormControlDirective {
  private _destroy$: Subject<boolean> = new Subject<boolean>();
  private _tagsWhitelist: string[] = [
    'h1',
    'h2',
    'h3',
    'h4',
    'p',
    'b',
    'strong',
    'i',
    'em',
    'mark',
    'small',
    'del',
    'ins',
    'sub',
    'sup',
    'li',
    'ul',
    'br',
    'hr',
    'a',
  ];

  @Input() set text(v: string) {
    this.formControl.setValue(v);
  }

  @Input() textAlignStyle: any;
  
  constructor(
    private _change: ChangeDetectorRef,
    private _htmlSanitizer: HtmlSanitizer,
    @Inject(CULTURE_SERVICE) cultureService: ICultureService,
    injector: Injector,
  ) {
    super(cultureService, injector);

    this.formControl.valueChanges
      .pipe(
        takeUntil(this._destroy$),
        tap(() => this._change.markForCheck()),
      )
      .subscribe();
  }

  getSanitizedValue(value?: string) {
    return this._htmlSanitizer.sanitize(value, this._tagsWhitelist);
  }
}
