<div class="container">
  <div class="icon-title-container">
    <mat-icon [inline]="true">save</mat-icon>
    <span class="title">{{ title }}</span>
  </div>
  <div class="file-container">
    <div class="file-selector">
      <button type="button" mat-button (click)="fileInput.click()">{{ 'FileForImport.SelectFile' | translate }}</button>
      <input hidden (change)="onFileSelected($event)" #fileInput type="file" />
      <span class="file-name">{{ filename }}</span>
    </div>
    <button class="upload-button" mat-button (click)="sendFile()">{{ 'FileForImport.SendFile' | translate }}</button>
  </div>
</div>
