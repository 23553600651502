import { Inject, Injectable, Injector, NgZone, OnDestroy } from '@angular/core';
import { Observable, of, Subject, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { ScriptRunnerService } from '@core/scripts-running/script-runner.service';
import { ErrorHandlerDto, UIScriptDto } from '@core/services/api-clients';
import { ContextService } from '@core/services/context.service';
import { ErrorHandlerProvider } from './error-handler-provider.service';
import { IError, IErrorHandler, IExecutionContext } from 'src/engine-sdk';
import { IScriptRunnerService, SCRIPT_RUNNER_SERVICE } from '@core/widgets/models/iscript-runner.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorPipelineService implements OnDestroy {
  private _destroy$ = new Subject<boolean>();

  constructor(
    @Inject(SCRIPT_RUNNER_SERVICE) private _scriptRunner: IScriptRunnerService,
    private _ngZone: NgZone,
    private _injector: Injector,
  ) {}

  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
  }

  createHandlersPipeline(error: IError, scriptHandlers: ErrorHandlerDto[] = []) {
    return this._scriptRunner.registerScripts(scriptHandlers.map((e) => UIScriptDto.fromJS(e))).pipe(
      catchError((err) => throwError({ ...err, isCriticalHandlerError: true })),
      switchMap((_) => {
        const customHandlers = scriptHandlers.map(
          (h) =>
            <IErrorHandler>{
              order: h.order,
              exceptionName: h.exceptionName,
              handler: ScriptRunnerService.getFunction(h.functionName, 'ctx', 'error'),
            },
        );
        let ctx$: Observable<any> = of({});
        if (!(<any>error).isCriticalHandlerError) {
          ctx$ = this._injector.get(ContextService).createContextForError();
        }

        const handlerProvider = this._injector.get(ErrorHandlerProvider);

        const handlers = handlerProvider
          .getHandlers()
          .concat(customHandlers)
          .filter((h) => !h.exceptionName || error.payload.exceptionName === h.exceptionName)
          .sort((a, b) => a.order - b.order);

        let pipeline$: Observable<{ ctx?: IExecutionContext; error: IError }> = of({
          error: error,
        });
        if (!error.isHandled) {
          pipeline$ = pipeline$.pipe(
            switchMap((e) =>
              ctx$.pipe(
                map((ctx) => {
                  return { ctx: ctx, error: e.error };
                }),
              ),
            ),
          );

          handlers.forEach((h) => {
            pipeline$ = pipeline$.pipe(
              switchMap((payload) => {
                if (!payload.error.isHandled) {
                  return this._ngZone
                    .run<Observable<IError>>(() => h.handler(payload.ctx, payload.error))
                    .pipe(
                      map((e) => {
                        return { ctx: payload.ctx, error: e };
                      }),
                    );
                }
                return of(payload);
              }),
            );
          });
        }
        return pipeline$.pipe(
          tap((_) => this._scriptRunner.deleteScripts(scriptHandlers.map((e) => UIScriptDto.fromJS(e)))),
        );
      }),
    );
  }
}
