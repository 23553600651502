<div style="display: flex; flex-direction: column; gap: 8px;">
  <kendo-dropdownlist [data]="operators"
                      [value]="operator"
                      [textField]="'text'"
                      [valueField]="'value'"
                      (valueChange)="onOperatorsValueChange($event)">
  </kendo-dropdownlist>
  <kendo-textbox [placeholder]=placeholder
                 [value]="value"
                 [disabled]="isValueDisabled"
                 (keypress)="validateKey($event)"
                 (valueChange)="onValueChange($event)">
  </kendo-textbox>
</div>
