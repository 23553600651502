<mat-nav-list>
  <div
    *ngFor="let menuItem of menuItems"
    [matTooltip]="menuItem.label"
    [matTooltipDisabled]="false"
    matTooltipPosition="right"
    class="menu-item"
    [ngClass]="{
      selected: menuItem.id === selectedMenuItemId,
      selectable: isSelectable
    }">
    <div class="color-bar"></div>
    <div *ngIf="isFavoriteEnabled && isWide" class="favorite-buttons">
      <button type="button" 
        *ngIf="isFavorite[menuItem.id]; else notFavorite"
        mat-icon-button
        [matTooltip]="'Menu.RemoveFromFavorites' | translate"
        (click)="removeMenuItemFromFavorite(menuItem)">
        <mat-icon>favorite</mat-icon>
      </button>
      <ng-template #notFavorite>
        <button type="button"
          mat-icon-button
          [matTooltip]="'Menu.AddToFavorites' | translate"
          (click)="addMenuItemToFavorite(menuItem)">
          <mat-icon>favorite_border</mat-icon>
        </button>
      </ng-template>
    </div>
    <mat-list-item menuItem (click)="onMenuItemClick(menuItem)">
      <app-menu-item
        [widgetId]="menuItem.id"
        [uiScripts]="menuItem.uiScripts"
        [name]="menuItem.name"
        [shortLabel]="menuItem.shortLabel"
        [label]="menuItem.label"
        [isWide]="isWide"
        [isRootLevel]="false"
        [icon]="menuItem.icon">
      </app-menu-item>
    </mat-list-item>
  </div>
</mat-nav-list>
