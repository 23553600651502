<mat-menu yPosition="below" [overlapTrigger]="false">
  <button
    type="button"
    mat-menu-item
    [ngClass]="{ 'theme-selected': EngineThemes.Light === selectedTheme }"
    (click)="selectTheme(EngineThemes.Light)">
    <mat-icon>light_mode</mat-icon>
    {{ 'UserMenu.LightTheme' | translate }}
  </button>
  <button
    type="button"
    mat-menu-item
    [ngClass]="{ 'theme-selected': EngineThemes.Dark === selectedTheme }"
    (click)="selectTheme(EngineThemes.Dark)">
    <mat-icon>dark_mode</mat-icon>
    {{ 'UserMenu.DarkTheme' | translate }}
  </button>
  <button
    type="button"
    mat-menu-item
    [ngClass]="{ 'theme-selected': EngineThemes.System === selectedTheme }"
    (click)="selectTheme(EngineThemes.System)">
    <mat-icon>desktop_windows</mat-icon>
    {{ 'UserMenu.SystemTheme' | translate }}
  </button>
</mat-menu>
