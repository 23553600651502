import { NgModule, ModuleWithProviders } from '@angular/core';
import { SCRIPT_RUNNER_SERVICE } from '@core/widgets/models/iscript-runner.service';
import { ScriptRunnerService } from './script-runner.service';

@NgModule({
  imports: [],
  declarations: [],
  exports: [],
})
export class ScriptsRunningModule {
  static forRoot(): ModuleWithProviders<ScriptsRunningModule> {
    return {
      ngModule: ScriptsRunningModule,
      providers: [{ provide: SCRIPT_RUNNER_SERVICE, useClass: ScriptRunnerService }],
    };
  }
}
