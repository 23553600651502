<ng-container *ngIf="data && data.children">
  <app-form-section
    *ngIf="data.formSection"
    [id]="data.formSection.id"
    [section]="data.formSection"
    [isVisible]="data.formSection.isVisible"
    #formSection>
    <app-form-grid-content [data]="data" [isCompactMode]="isCompactMode"></app-form-grid-content>
  </app-form-section>

  <ng-container *ngIf="!data.formSection">
    <app-form-grid-content [data]="data" [isCompactMode]="isCompactMode"></app-form-grid-content>
  </ng-container>
</ng-container>
