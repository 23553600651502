<ng-template #template>
  <ng-container *ngIf="fontIconName; then fontIcon; else svgIcon"></ng-container>
</ng-template>
<ng-template #fontIcon>
  <mat-icon
    *ngIf="fontIconName"
    class="icon mat-icon"
    [ngClass]="{ sm: size == 'sm', md: size == 'md', xl: size == 'xl', custom: size == 'custom' }">
    {{ fontIconName }}
  </mat-icon>
</ng-template>
<ng-template #svgIcon>
  <mat-icon
    *ngIf="svgIconName; else webResource"
    class="icon mat-icon"
    [ngClass]="{ sm: size == 'sm', md: size == 'md', xl: size == 'xl', custom: size == 'custom' }"
    [svgIcon]="svgIconName">
  </mat-icon>
</ng-template>
<ng-template #webResource>
  <img
    *ngIf="url"
    class="icon img-icon"
    [ngClass]="{ sm: size == 'sm', md: size == 'md', xl: size == 'xl', custom: size == 'custom' }"
    [attr.src]="url | webResourceUri | async" />
</ng-template>
