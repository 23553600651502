import { Pipe, PipeTransform } from '@angular/core';
import { IIcon } from '../../icon/icon.model';
import { GridColumn } from '../models/grid-definition.model';

@Pipe({ name: 'icon' })
export class IconPipe implements PipeTransform {
  transform(dataItem: any, column: GridColumn): IIcon {
    if (!dataItem || column.iconMode != 'icon') return null;

    if (column.iconMode == 'icon') {
      let fields = column.primaryField.split('.').slice(0, -1);
      let item = dataItem;
      fields.forEach((element) => {
        if (!item) return null;
        item = item[element];
      });

      const icon = item
        ? <IIcon>{
          fontIconName: item.FontIconName,
          iconRelativePath: item.IconRelativePath
        } : undefined

      return icon || column.icon;
    }
  }
}
