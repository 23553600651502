import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { AuthGuard } from './auth.guard';

@Injectable()
export class AzureADAuthGuard implements AuthGuard {
  constructor(private _guard: MsalGuard) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this._guard.canActivate(route, state);
  }
}
