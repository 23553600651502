import { ActionReducerMap } from '@ngrx/store';
import { navigationsReducer } from './reducers';
import { INavigationState } from './state';
import * as NavigationSelectors from './selectors';
import * as NavigationActions from './actions';

export { NavigationActions, NavigationSelectors };

export interface NavigationsFeatureState {
  navigations: INavigationState;
}

export const reducers: ActionReducerMap<NavigationsFeatureState> = {
  navigations: navigationsReducer,
};
