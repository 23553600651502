import { ContextMenusActions, ContextMenusActionTypes } from './actions';
import { initialContextMenuState, IContextMenuState } from './state';

export function contextMenusReducer(state = initialContextMenuState, action: ContextMenusActions): IContextMenuState {
  switch (action.type) {
    case ContextMenusActionTypes.ResetMainContextMenu: {
      return {
        ...state,
        mainContextMenu: undefined,
      };
    }
    case ContextMenusActionTypes.LoadMainContextMenuSuccess: {
      return {
        ...state,
        mainContextMenu: action.payload.mainContextMenu,
      };
    }

    case ContextMenusActionTypes.LoadGridColumnContextMenuSuccess: {
      let viewId = action.payload.viewId;
      return {
        ...state,
        gridColumnContextMenu: {
          ...state.gridColumnContextMenu,
          [viewId]: action.payload.contextMenu,
        },
      };
    }

    case ContextMenusActionTypes.LoadSubgridContextMenuSuccess: {
      let subgridId = action.payload.subgridId;

      return {
        ...state,
        subgridContextMenu: {
          ...state.subgridContextMenu,
          [subgridId]: action.payload.contextMenu,
        },
      };
    }

    case ContextMenusActionTypes.LoadSubgridGridColumnContextMenuSuccess: {
      let subgridId = action.payload.subgridId;

      return {
        ...state,
        subgridColumnContextMenu: {
          ...state.subgridColumnContextMenu,
          [subgridId]: action.payload.contextMenu,
        },
      };
    }

    case ContextMenusActionTypes.LoadDialogFormContextMenuSuccess: {
      let dialogId = action.payload.dialogId;

      return {
        ...state,
        dialogFormContextMenu: {
          ...state.dialogFormContextMenu,
          [dialogId]: action.payload.dialogFormContextMenu,
        },
      };
    }

    case ContextMenusActionTypes.RemoveDialogFormContextMenu: {
      let dialogId = action.payload.dialogId;
      const { [dialogId]: _, ...dialogContextMenus } = state.dialogFormContextMenu;

      return {
        ...state,
        dialogFormContextMenu: dialogContextMenus,
      };
    }

    default:
      return state;
  }
}
