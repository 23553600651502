import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { take, map } from 'rxjs/operators';
import { LabelService } from './label.service';
import { EngineCultureService } from '@core/engine-culture/services/culture.service';

export enum TranslationType {
  Infrastructural,
  Business,
}
@Injectable()
export class EngineTranslationService {
  constructor(
    private _translate: TranslateService,
    private _labelService: LabelService,
    private _cultureService: EngineCultureService,
  ) {
    this.syncCultureInfo();
  }

  translate(
    value: string,
    context: any = null,
    type: TranslationType = TranslationType.Infrastructural,
  ): Observable<string> {
    let result = of(value);
    switch (type) {
      case TranslationType.Infrastructural:
        result = this._translate.get(value, context);
        break;
      case TranslationType.Business:
        result = this._labelService
          .getLabelByNameAndLanguage(value, this._cultureService.cultureSettings.languageId)
          .pipe(
            take(1),
            map((label) => {
              const labelCopy = { ...label };
              if (!labelCopy) return value;
              if (context) {
                let matchedContexts = labelCopy.Content.matchAll(/{{(.*?)}}/g);
                for (let matchedContext of matchedContexts) {
                  let contextElement = context[`${matchedContext[1]}`];
                  labelCopy.Content = labelCopy.Content.replace(matchedContext[0], contextElement);
                }
              }
              return labelCopy.Content;
            }),
          );
        break;
    }
    return result;
  }

  // keep in mind that only infrastructural translations are prefetched
  translateInstantly(value: string, context: any = null): string {
    return this._translate.instant(value, context);
  }

  private syncCultureInfo() {
    if (!this._cultureService.cultureSettings) return;
    this._translate.use(this._cultureService.cultureSettings.code);
  }
}
