import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { environment } from '@env';
import { AzureADAuthGuard } from '../guards/aad-auth.guard';
import { AuthGuard } from '../guards/auth.guard';
import { AzureB2CAuthGuard } from '../guards/b2c-auth.guard';
import { BasicAuthGuard } from '../guards/basic-auth.guard';
import { AuthenticationType } from '../models/authentication-type.enum';
import { AUTH_SERVICE_TOKEN } from '../models/iauth-service.model';

const authGuardFactory = (injector: Injector): AuthGuard => {
  switch (environment.authenticationType) {
    case AuthenticationType.Anonymous:
    case AuthenticationType.ActiveDirectory:
      return new BasicAuthGuard(injector.get(Router), injector.get(AUTH_SERVICE_TOKEN));
    case AuthenticationType.AzureActiveDirectory:
      return new AzureADAuthGuard(injector.get(MsalGuard));
    case AuthenticationType.AzureB2C:
      return new AzureB2CAuthGuard(injector.get(MsalGuard));
  }
};

export const authGuardProvider = {
  provide: AuthGuard,
  useFactory: authGuardFactory,
  deps: [Injector],
};
