<ng-container *ngIf="definition && formGroup; else noDef">
  <form [formGroup]="formGroup">
    <app-form-tabs
      [tabs]="definition.tabs"
      [selectedTabIndex]="selectedTabIndex"
      [isCompactMode]="isCompactMode"
      (selectedTabChange)="selectedTabChange.emit($event)"></app-form-tabs>
  </form>
  <div *ngIf="showDefinition">
    Definition:
    <code>
      <pre>{{ definition | json }}</pre>
    </code>
  </div>
</ng-container>
<ng-template #noDef>Form definition not provided</ng-template>
