import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ToggleLeftPanel } from '../../store/actions';
import { Store } from '@ngrx/store';
import { getScreenSize } from '../../store/selectors';
import { map } from 'rxjs/operators';
import { ScreenSize } from '../../models/screen-size.enum';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopbarComponent {
  isHamburgerVisible$ = this._store.select(getScreenSize).pipe(map((screenSize) => screenSize <= ScreenSize.MD));

  constructor(private _store: Store) {}

  toggleLeftPanel() {
    this._store.dispatch(new ToggleLeftPanel());
  }
}
