import { Injectable } from '@angular/core';
import { IEngineFormService } from '@core/engine-forms/models/iengine-form-service.model';
import { FormActions } from '@core/engine-forms/store';
import { IRecordInfo } from '@core/models/irecord-info.model';
import { GenericODataServiceProvider } from '@core/engine-odata/services/generic-odata-service-provider.service';
import { Store } from '@ngrx/store';
import { AsyncSubject, BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, delay, first, map, tap, mergeMap } from 'rxjs/operators';
import { EngineFormComponent } from '../engine-form/engine-form.component';
import { EngineDialogFormComponent } from './engine-dialog-form.component';
import { EntityMappingClient, GetPrefilledRecordQuery } from '@core/services/api-clients';
import { IFormArgs } from '@app/main-form/main-form.model';

@Injectable()
export class EngineDialogFormService implements IEngineFormService {
  private _entityValue = new BehaviorSubject<any>(undefined);
  private _dialog: EngineDialogFormComponent;
  private _recordInfo: IRecordInfo;

  entityValue$: Observable<any>;

  getValue(): any {
    return this._entityValue.value;
  }

  constructor(
    private _store: Store,
    private _odataServiceProvider: GenericODataServiceProvider,
    private _entityMappingClient: EntityMappingClient,
  ) {
    this.entityValue$ = this._entityValue.asObservable();
  }

  init(recordInfo: IRecordInfo, dialog: EngineDialogFormComponent) {
    this._dialog = dialog;
    this._recordInfo = recordInfo;
    this.getDataItem(this._recordInfo.entityName, this._recordInfo.recordId)
      .pipe(
        first(),
        map((enityValue) => {
          const entityArgs = this.getPrefillFormArgs(this._dialog.dialogData.args);
          if (entityArgs) {
            enityValue = Object.assign(enityValue, entityArgs);
          }
          return enityValue;
        }),
        mergeMap((enityValue) => this.getPrefilledRecord(enityValue)),
        tap((enityValue) => this._entityValue.next(enityValue)),
      )
      .subscribe();
  }

  getPrefilledRecord(record: any): Observable<any> {
    if (record.Id || !this._recordInfo.entityName) return of(record);

    const getPrefilledRecordQuery = {
      entityName: this._recordInfo.entityName,
      recordValues: record,
    } as GetPrefilledRecordQuery;

    return this._entityMappingClient.getPrefilledRecord(getPrefilledRecordQuery).pipe(
      map((prefilled) => {
        record = Object.assign(record, prefilled);
        return record;
      }),
    );
  }

  save(engineForm: EngineFormComponent): Observable<any> {
    return this.internalSave(engineForm, (recordId) => {
      engineForm.definition.recordInfo.recordId = recordId;
      return engineForm.refetchData().pipe(
        tap(() => {
          engineForm.markAsPristine();
        }),
      );
    });
  }

  saveAndNew(engineForm: EngineFormComponent): Observable<any> {
    return this.internalSave(engineForm, () => {
      engineForm.definition.recordInfo.recordId = undefined;
      return engineForm.refetchData().pipe(
        tap(() => {
          engineForm.markAsPristine();
        }),
      );
    });
  }

  saveAndClose(engineForm: EngineFormComponent): Observable<any> {
    return this.internalSave(engineForm, (recordId) => {
      engineForm.definition.recordInfo.recordId = recordId;
      return engineForm.refetchData().pipe(
        tap(() => {
          this._dialog.close(true);
        }),
      );
    });
  }

  refetch(engineForm: EngineFormComponent): Observable<any> {
    const result$ = new AsyncSubject<any>();

    this.getDataItem(engineForm.definition.recordInfo.entityName, engineForm.definition.recordInfo.recordId)
      .pipe(
        catchError((error) => {
          result$.error(error);
          return of(error);
        }),
        tap((entityValue) => {
          this._entityValue.next(entityValue);
        }),
        delay(100), // delay to avoid hazards
        tap((entityValue) => {
          result$.next(entityValue);
          result$.complete();
        }),
      )
      .subscribe();

    return result$;
  }

  private internalSave(
    engineForm: EngineFormComponent,
    onSuccess?: (recordId: string) => Observable<any>,
  ): Observable<any> {
    const entityValue = engineForm.dataItem;
    this._entityValue.next(entityValue);
    const isCreateMode = !engineForm.definition.recordInfo.recordId;
    const result$ = new AsyncSubject<any>();
    if (isCreateMode) {
      this._store.dispatch(
        new FormActions.CreateEntity({
          entityName: engineForm.definition.recordInfo.entityName,
          entity: entityValue,
          onSuccess: (recordId) => {
            onSuccess(recordId)
              .pipe(
                first(),
                catchError((error) => {
                  result$.error(error);
                  return of(error);
                }),
                tap((_) => {
                  result$.next(true);
                  result$.complete();
                }),
              )
              .subscribe();
          },
          onFailure: (error) => {
            result$.error(error);
          },
        }),
      );
    } else {
      this._store.dispatch(
        new FormActions.UpdateEntity({
          entityName: engineForm.definition.recordInfo.entityName,
          entityId: engineForm.definition.recordInfo.recordId,
          entity: engineForm.getPatchDataItem(),
          onSuccess: () => {
            onSuccess(engineForm.definition.recordInfo.recordId)
              .pipe(
                first(),
                catchError((error) => {
                  result$.error(error);
                  return of(error);
                }),
                tap((_) => {
                  result$.next(true);
                  result$.complete();
                }),
              )
              .subscribe();
          },
          onFailure: (error) => {
            result$.error(error);
          },
        }),
      );
    }
    return result$;
  }

  private getDataItem(entityName: string, recordId: string): Observable<any> {
    if (!recordId) return of({ Id: undefined });
    const service = this._odataServiceProvider.create(entityName);
    return service.get(recordId).pipe(first());
  }

  private getPrefillFormArgs(args: IFormArgs): { [key: string]: any } {
    if (args?.$markAsDirty) return {};
    let entityArgs = { ...args };
    delete entityArgs.$mappings;
    delete entityArgs.$markAsDirty;
    return entityArgs;
  }
}
