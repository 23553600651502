import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Inject,
  Input,
  OnDestroy,
  Optional,
  SkipSelf,
  forwardRef,
} from '@angular/core';
import { ControlDto } from '@core/services/api-clients';
import { Observable, Subject } from 'rxjs';
import { FORM_CONTEXT, IFormContext } from 'src/engine-sdk';
import { FormSectionComponent } from '../form-grid/form-section/form-section.component';
import {
  ENGINE_DATA_CONTEXT_PROVIDER,
  IEngineDataContextProvider,
  IEngineFormControlDataContext,
} from 'src/engine-sdk/contract/engine-data-context';
import { EngineMetadataProvider } from '@core/metadata/services/engine-metadata-provider.service';
import { ENGINE_METADATA_PROVIDER, IEngineEntity } from 'src/engine-sdk/contract/metadata';
import { QUERY_BUILDER_METADATA_PROVIDER } from '@core/engine-odata/components/query-builder/query-builder.component';
import { IEngineODataState } from 'src/engine-sdk/contract/odata/iodata-state.model';

@Component({
  selector: 'engine-query-builder-control',
  templateUrl: './engine-query-builder-control.component.html',
  styleUrls: ['./engine-query-builder-control.component.scss'],
  providers: [
    {
      provide: ENGINE_DATA_CONTEXT_PROVIDER,
      useExisting: forwardRef(() => EngineQueryBuilderControlComponent),
    },
    {
      provide: QUERY_BUILDER_METADATA_PROVIDER,
      useExisting: ENGINE_METADATA_PROVIDER,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EngineQueryBuilderControlComponent implements OnDestroy {
  private _destroy$: Subject<boolean> = new Subject<boolean>();
  private _queryBuilderControl: ControlDto;
  private _isVisible: boolean;

  state: IEngineODataState;

  entity$: Observable<IEngineEntity> = this._dataProvider.getEntityMetadataAsync(
    'DBB839B8-7DDB-4C9C-AF7C-08D98A2805DD',
  );

  @Input() set queryBuilderControl(value: ControlDto) {
    if (this._queryBuilderControl != value) {
      this._queryBuilderControl = value;

      if (this._queryBuilderControl) {
        this.id = this._queryBuilderControl.id;
        this.name = this._queryBuilderControl.name;
        this.label = this._queryBuilderControl.label;
        this.type = this._queryBuilderControl.type;
        this.isVisible = this._queryBuilderControl.isVisible;
        this.isRequired = this._queryBuilderControl.isRequired;
        this.isReadOnly = this._queryBuilderControl.isReadOnly;
      }
    }
  }
  get queryBuilderControl() {
    return this._queryBuilderControl;
  }

  constructor(
    @Optional()
    @SkipSelf()
    @Inject(ENGINE_DATA_CONTEXT_PROVIDER)
    private _engineDataContextProvider: IEngineDataContextProvider,
    protected _formSection: FormSectionComponent,
    @Inject(FORM_CONTEXT) protected _formContext: IFormContext,
    @Inject(ENGINE_METADATA_PROVIDER) private _dataProvider: EngineMetadataProvider,
    private _changeDetector: ChangeDetectorRef,
  ) {
    this._formSection.registerControl(this);
  }

  ngOnDestroy() {
    this._destroy$.next(true);
    this._destroy$.complete();
    this._formSection.unregisterControl(this);
  }

  // IQueryBuilderControlContext

  id: string;
  name: string;
  label: string;
  type: string;
  isReadOnly: boolean;
  isRequired: boolean;

  onEntityChange(value: IEngineEntity) {
    // this.entity = value;
  }

  onStateChanged(state: IEngineODataState) {
    this.state = state;
  }

  get isVisible(): boolean {
    return this._isVisible;
  }
  @Input() set isVisible(value) {
    if (this._isVisible != value) {
      this._isVisible = value;
      this._changeDetector.markForCheck();
    }
  }

  get value(): any {
    return undefined;
  }

  set value(_) {
    throw new Error('Not supported.');
  }

  @HostBinding('id')
  get getEngineControlId(): string {
    return `EngineControl_${this.id}`;
  }
  @HostBinding('attr.name')
  get getEngineControlName(): string {
    return `EngineControl_${this.name}`;
  }

  getAttributes(): { primaryAttribute: string; secondaryAttribute?: string } {
    return { primaryAttribute: null, secondaryAttribute: null };
  }

  setDirty(value: boolean): void {
    throw new Error('Not supported.');
  }

  setTouched(value: boolean): void {
    throw new Error('Not supported.');
  }

  isDirty() {
    return false;
  }

  isTouched() {
    return false;
  }

  setErrors(messages: string[]): void {
    throw new Error('Not supported.');
  }

  hasErrors(): boolean {
    return false;
  }

  hasError(errorId: string): boolean {
    return false;
  }

  getDataContext(): IEngineFormControlDataContext {
    const parentDataContext = this._engineDataContextProvider ? this._engineDataContextProvider.getDataContext() : {};
    return {
      ...parentDataContext,
      controlId: this.id,
      controlName: this.name,
    } as IEngineFormControlDataContext;
  }

  tryFocus() {
  }
  // #endregion
}
