import { NavigationActions, NavigationActionTypes } from './actions';
import { initialNavigationsState, INavigationState } from './state';

export function navigationsReducer(state = initialNavigationsState, action: NavigationActions): INavigationState {
  switch (action.type) {
    case NavigationActionTypes.LoadNavigationTargetsSuccess: {
      let targets = { ...state.navigationTargets };
      action.payload.navigationTargets.forEach((x) => (targets[x.navigationToken] = x));

      return {
        ...state,
        navigationTargets: targets,
      };
    }
    case NavigationActionTypes.LoadAvailableFormsSuccess: {
      const entityForms = { ...state.entityForms };
      entityForms[action.payload.entityId] = action.payload.forms;
      return {
        ...state,
        entityForms,
      };
    }
    case NavigationActionTypes.SaveNavigationState: {
      let item = state.navigationStack[state.navigationStack.length - 1];

      return {
        ...state,
        navigationStack: [...state.navigationStack.slice(0, -1), { ...item, state: action.payload.state }],
      };
    }
    case NavigationActionTypes.PushNavigationStackItem: {
      return {
        ...state,
        navigationStack: [...state.navigationStack, action.payload.item],
      };
    }
    case NavigationActionTypes.PopNavigationStackItems: {
      return {
        ...state,
        navigationStack: [...state.navigationStack.slice(0, -action.payload.popCount)],
      };
    }
    case NavigationActionTypes.ClearNavigationStack: {
      return {
        ...state,
        navigationStack: [],
      };
    }
    case NavigationActionTypes.SetIsDirty: {
      let item = state.navigationStack[state.navigationStack.length - 1];
      if (item.isDirty == action.payload.isDirty) return state;
      return {
        ...state,
        navigationStack: [...state.navigationStack.slice(0, -1), { ...item, isDirty: action.payload.isDirty }],
      };
    }
    case NavigationActionTypes.AddQueryParamsToTopNavigationStackItem: {
      let item = state.navigationStack[state.navigationStack.length - 1];
      const originalArgs = item.routerState.queryParams?.args ? JSON.parse(item.routerState.queryParams.args) : {};
      const additionalArgs = action.payload.queryParams?.args ? JSON.parse(action.payload.queryParams.args) : {};
      const args = JSON.stringify({ ...originalArgs, ...additionalArgs });

      return {
        ...state,
        navigationStack: [
          ...state.navigationStack.slice(0, -1),
          {
            ...item,
            routerState: {
              ...item.routerState,
              queryParams: {
                ...item.routerState.queryParams,
                ...action.payload.queryParams,
                args: Object.keys(args).length ? args : undefined,
              },
            },
          },
        ],
      };
    }
    case NavigationActionTypes.SetQueryParamsInTopNavigationStackItem: {
      let item = state.navigationStack[state.navigationStack.length - 1];
      return {
        ...state,
        navigationStack: [
          ...state.navigationStack.slice(0, -1),
          {
            ...item,
            routerState: {
              ...item.routerState,
              queryParams: { ...action.payload.queryParams },
            },
          },
        ],
      };
    }
    case NavigationActionTypes.ShowLongBreadcrumb: {
      return {
        ...state,
        isLongBreadCrumbVisible: true,
      };
    }
    case NavigationActionTypes.ShowShortBreadcrumb: {
      return {
        ...state,
        isLongBreadCrumbVisible: false,
      };
    }
    default:
      return state;
  }
}
