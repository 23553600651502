import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil, debounceTime, tap } from 'rxjs/operators';
import { KanbanDto } from '@core/services/api-clients';

@Component({
  selector: 'app-kanban-toolbar',
  templateUrl: './kanban-toolbar.component.html',
  styleUrls: ['./kanban-toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KanbanToolbarComponent implements OnDestroy {
  private _destroy$: Subject<boolean> = new Subject<boolean>();
  searchControl = new UntypedFormControl('');

  @Input() kanban: KanbanDto;
  @Input() set searchTerm(value: string) {
    if (this.searchControl.value != value) {
      this.searchControl.setValue(value, { emitEvent: false });
    }
  }

  @Output() refreshDataClick = new EventEmitter<any>();
  @Output() restoreSettingsClick = new EventEmitter<any>();
  @Output() searchTermChange = new EventEmitter<string>();

  ngOnInit(): void {
    this.searchControl.valueChanges
      .pipe(
        takeUntil(this._destroy$),
        debounceTime(300),
        tap((value) => this.searchTermChange.emit(value)),
      )
      .subscribe();
  }
  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
  }
}
