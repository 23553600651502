import { Pipe, PipeTransform } from '@angular/core';
import { KanbanControlDto } from '@core/services/api-clients';

@Pipe({ name: 'kanbanControlValue' })
export class KanbanControlValuePipe implements PipeTransform {
  transform(record: any, kanbanControl: KanbanControlDto): string {
    if (!record) return null;
    let fields = kanbanControl.attributePath.split('.');
    let item = record;
    fields.forEach((element) => {
      if (!item) return null;
      item = item[element];
    });

    return item;
  }
}
