import { NotificationGroup } from 'src/engine-sdk';

export const FEATURE_NAME: string = 'notification-feature';

export interface INotificationsState {
  notificationGroups: { [groupName: string]: NotificationGroup };
}

export const initialNotificationsState: INotificationsState = {
  notificationGroups: {},
};
