import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Inject,
  OnDestroy,
  OnInit,
  Optional,
  SkipSelf,
} from '@angular/core';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { ContextMenuDto, EventType } from '@core/services/api-clients';
import { GuidService } from '@core/services/guid.service';
import { NoEventWidgetDirective } from '@core/widgets/directives/no-event-widget.directive';
import { WidgetDirective } from '@core/widgets/directives/widget.directive';
import { SCRIPT_RUNNER_SERVICE, IScriptRunnerService } from '@core/widgets/models/iscript-runner.service';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { tap, takeUntil, map, filter, first, switchMap } from 'rxjs/operators';
import {
  DIALOG_CONTEXT,
  FormContextType,
  IDialogContext,
  IDialogFormConfig,
  NotificationGroup,
  NotificationScope,
} from '../../../../engine-sdk';
import { ContextService } from '@core/services/context.service';
import { ContextMenusSelectors, ContextMenusActions } from '@core/context-menu/store';
import { ENGINE_FORM_SERVICE } from '@core/engine-forms/models/iengine-form-service.model';
import { FormSelectors, FormActions } from '@core/engine-forms/store';
import { NotificationsSelectors } from '@core/notification/store';
import { FormDefinition } from '../engine-form/engine-form.model';
import { EngineDialogFormService } from './engine-dialog-form.service';
import { ConfirmDialogComponent } from '@shared/confirm-dialog/confirm-dialog.component';
import { IRecordInfo } from '@core/models/irecord-info.model';
import {
  ENGINE_DATA_CONTEXT_PROVIDER,
  IEngineDataContext,
  IEngineDataContextProvider,
} from 'src/engine-sdk/contract/engine-data-context';
import { LodashService } from '@core/services/lodash.service';
import { EngineTranslationService } from '@core/engine-translations/services/translation.service';
import { EngineFormComponent } from '../engine-form/engine-form.component';
import { SavingExecutionEvent } from '../../../execution-context';

@Component({
  selector: 'engine-dialog-form',
  templateUrl: 'engine-dialog-form.component.html',
  styleUrls: ['engine-dialog-form.component.scss'],
  providers: [
    { provide: ENGINE_FORM_SERVICE, useClass: EngineDialogFormService },
    {
      provide: WidgetDirective,
      useExisting: forwardRef(() => EngineDialogFormComponent),
    },
    {
      provide: DIALOG_CONTEXT,
      useExisting: forwardRef(() => EngineDialogFormComponent),
    },
    {
      provide: ENGINE_DATA_CONTEXT_PROVIDER,
      useExisting: forwardRef(() => EngineDialogFormComponent),
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EngineDialogFormComponent
  extends NoEventWidgetDirective
  implements OnInit, OnDestroy, IDialogContext, IEngineDataContextProvider {
  private _recordInfo: IRecordInfo;

  id: string = GuidService.generateNew();
  selectedTabIndex: number;
  contextMenu$: Observable<ContextMenuDto>;
  notificationGroups$: Observable<NotificationGroup[]>;
  formData$: Observable<{ definition: FormDefinition; dataItem: any }>;

  constructor(
    @Optional() @SkipSelf() parentWidget: WidgetDirective,
    @Inject(SCRIPT_RUNNER_SERVICE) scriptRunnerService: IScriptRunnerService,
    @Inject(MAT_DIALOG_DATA) public dialogData: IDialogFormConfig,
    @Inject(ENGINE_FORM_SERVICE) private _formService: EngineDialogFormService,
    @Optional()
    @SkipSelf()
    @Inject(ENGINE_DATA_CONTEXT_PROVIDER)
    private _engineDataContextProvider: IEngineDataContextProvider,
    private _contextService: ContextService,
    private _store: Store,
    private _dialogRef: MatDialogRef<EngineDialogFormComponent>,
    private _translationService: EngineTranslationService,
    private _dialog: MatDialog,
  ) {
    super(undefined, scriptRunnerService);
    this._minWidgetsNumber = 2;
    this._recordInfo = {
      entityId: dialogData.entityId,
      entityName: dialogData.entityName,
      recordId: dialogData.recordId,
    };
    this.selectedTabIndex = dialogData.tabIndex ?? 0;
    this._contextService.registerDialog(this);
    this.loadEntityFormIfNotLoaded();
  }

  ngOnInit(): void {
    this.setUpDialogSettings();
    this._formService.init(this._recordInfo, this);

    this.contextMenu$ = this._store.select(ContextMenusSelectors.getDialogFormContextMenu(this.id)).pipe(
      takeUntil(this._destroy$),
      tap((contextMenu) => {
        if (!contextMenu)
          this._store.dispatch(
            new ContextMenusActions.LoadDialogFormContextMenu({
              formId: this.dialogData.formId,
              recordId: this.dialogData.recordId,
              dialogId: this.id,
            }),
          );
      }),
    );

    const formDefinition$ = this._store.select(FormSelectors.getForm(this.dialogData.formId)).pipe(
      takeUntil(this._destroy$),
      filter((currentForm) => !!currentForm),
      map((currentForm) => {
        const form = LodashService.cloneDeep(currentForm) as FormDefinition;
        form.contextInfo = { type: FormContextType.Dialog, dialogId: this.id };
        form.recordInfo = this._recordInfo;
        return form;
      }),
    );

    this.formData$ = combineLatest([formDefinition$, this._formService.entityValue$]).pipe(
      map(([formDefinition, entityValue]) => {
        return { formDefinition, entityValue };
      }),
      filter(({ formDefinition, entityValue }) => !!formDefinition && !!entityValue),
      map(({ formDefinition, entityValue }) => {
        return {
          definition: formDefinition,
          dataItem: entityValue,
        };
      }),
    );

    this.notificationGroups$ = this._store
      .select(NotificationsSelectors.getNotificationGroups)
      .pipe(
        map((notificationGroups) =>
          Object.values(notificationGroups).filter(
            (x) => x.scope === NotificationScope.Attribute && this._recordInfo.entityName === x.entityName,
          ),
        ),
      );

    this._contextService
      .getDialogFormContextAsync(this.id)
      .pipe(
        takeUntil(this._destroy$),
        filter((x) => !!x),
        switchMap((x: EngineFormComponent) => x.events$.pipe(
          takeUntil(this._destroy$),
          filter((e) => e.eventType === EventType.OnSaving),
          map((e) => e as SavingExecutionEvent),
        )),
        tap((s) => s.saveAsync())
      ).subscribe();;
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this._contextService.unregisterDialog(this);
    this._store.dispatch(new ContextMenusActions.RemoveDialogFormContextMenu({ dialogId: this.id }));
  }

  onFormInitiated(form: EngineFormComponent) {
    if (this.dialogData?.args?.$markAsDirty) {
      if (!this.dialogData?.args) return;
      if (this.dialogData.args.$markAsDirty) {
        Object.keys(this.dialogData.args)
          .filter((arg) => arg != '$markAsDirty' && arg != '$mappings')
          .forEach((arg) => form.setFieldValue(arg, this.dialogData.args[arg]));
      }
    }
  }

  close(isSuccess?: boolean): void {
    const dialogForm = this._contextService.getDialogFormContext(this.id);

    if (isSuccess) {
      this._dialogRef.close(true);
      if (this.dialogData.onSuccess) this.dialogData.onSuccess(this._recordInfo.recordId, this._formService.getValue());
    } else {
      if (dialogForm.isDirty()) {
        const warningMessage = this._translationService.translateInstantly('Navigation.ReturnConfirmation');
        let confirmDialog = this._dialog.open(ConfirmDialogComponent, {
          data: {
            message: warningMessage,
          },
        });

        confirmDialog
          .afterClosed()
          .pipe(
            first(),
            tap((confirmClosing) => {
              if (confirmClosing) {
                this._dialogRef.close(false);
                if (this.dialogData.onCancel) this.dialogData.onCancel();
              }
            }),
          )
          .subscribe();
      } else {
        this._dialogRef.close(false);
        if (this.dialogData.onCancel) this.dialogData.onCancel();
      }
    }
  }

  getDataContext(): IEngineDataContext {
    const parentDataContext = this._engineDataContextProvider?.getDataContext() ?? {};
    return { ...parentDataContext, dialogId: this.id };
  }

  private setUpDialogSettings() {
    this._dialogRef.disableClose = true;
    this._dialogRef.updatePosition(this.dialogData.position);
    this._dialogRef.updateSize(this.dialogData.width, this.dialogData.height);
  }

  private loadEntityFormIfNotLoaded() {
    this._store
      .select(FormSelectors.getForm(this.dialogData.formId))
      .pipe(
        takeUntil(this._destroy$),
        tap((currentForm) => {
          if (!currentForm) {
            this._store.dispatch(new FormActions.LoadEntityFormDto({ formId: this.dialogData.formId }));
          }
        }),
      )
      .subscribe();
  }
}
