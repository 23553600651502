import { createSelector, createFeatureSelector } from '@ngrx/store';
import { NotificationsFeatureState } from '.';
import { FEATURE_NAME } from './state';

export const notificationsFeatureSelector = createFeatureSelector<NotificationsFeatureState>(FEATURE_NAME);
export const notificationsSelector = createSelector(notificationsFeatureSelector, (state) => state.notifications);

export const getNotificationGroups = createSelector(notificationsSelector, (state) => state.notificationGroups);
export const getNotificationGroupsList = createSelector(notificationsSelector, (state) =>
  Object.values(state.notificationGroups),
);
