import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ChartType } from '../chart.model';
import { ChartDataType, IChartTypeSelectorOption } from './chart-type-selector.model';

@Component({
  selector: 'app-chart-type-selector',
  templateUrl: './chart-type-selector.component.html',
  styleUrls: ['./chart-type-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartTypeSelectorComponent {
  private _chartTypesOptionsDictionary: { [value: number]: IChartTypeSelectorOption } = {};

  @Input() selectedType: ChartType;
  @Output() onTypeSelected = new EventEmitter<ChartType>();

  get compatibleOptions(): IChartTypeSelectorOption[] {
    const selectedOption = this._chartTypesOptionsDictionary[this.selectedType];
    return selectedOption
      ? Object.values(this._chartTypesOptionsDictionary).filter((t) => t.dataType == selectedOption.dataType)
      : [];
  }

  constructor() {
    this.initChartTypeOptionsDictionary();
  }

  onChartTypeSelect(option: any) {
    this.onTypeSelected.emit(option.source.value);
  }

  private initChartTypeOptionsDictionary(): void {
    this._chartTypesOptionsDictionary[ChartType.VerticalBar] = {
      label: 'Vertical Bar',
      value: ChartType.VerticalBar,
      dataType: ChartDataType.Complex,
    };
    this._chartTypesOptionsDictionary[ChartType.StackedVerticalBar] = {
      label: 'Stacked Vertical Bar',
      value: ChartType.StackedVerticalBar,
      dataType: ChartDataType.Simple,
    };
    this._chartTypesOptionsDictionary[ChartType.GroupedVerticalBar] = {
      label: 'Grouped Vertical Bar',
      value: ChartType.GroupedVerticalBar,
      dataType: ChartDataType.Simple,
    };
    this._chartTypesOptionsDictionary[ChartType.NormalizedVerticalBar] = {
      label: 'Normalized Vertical Bar',
      value: ChartType.NormalizedVerticalBar,
      dataType: ChartDataType.Simple,
    };
    this._chartTypesOptionsDictionary[ChartType.HorizontalBar] = {
      label: 'Horizontal Bar',
      value: ChartType.HorizontalBar,
      dataType: ChartDataType.Complex,
    };
    this._chartTypesOptionsDictionary[ChartType.GroupedHorizontalBar] = {
      label: 'Grouped HorizontalBar',
      value: ChartType.GroupedHorizontalBar,
      dataType: ChartDataType.Simple,
    };
    this._chartTypesOptionsDictionary[ChartType.StackedHorizontalBar] = {
      label: 'Stacked HorizontalBar',
      value: ChartType.StackedHorizontalBar,
      dataType: ChartDataType.Simple,
    };
    this._chartTypesOptionsDictionary[ChartType.NormalizedHorizontalBar] = {
      label: 'Normalized Horizontal Bar',
      value: ChartType.NormalizedHorizontalBar,
      dataType: ChartDataType.Simple,
    };
    this._chartTypesOptionsDictionary[ChartType.Pie] = {
      label: 'Pie',
      value: ChartType.Pie,
      dataType: ChartDataType.Complex,
    };
    this._chartTypesOptionsDictionary[ChartType.AdvancedPie] = {
      label: 'Advanced Pie',
      value: ChartType.AdvancedPie,
      dataType: ChartDataType.Complex,
    };
    this._chartTypesOptionsDictionary[ChartType.PieGrid] = {
      label: 'Pie Grid',
      value: ChartType.PieGrid,
      dataType: ChartDataType.Complex,
    };
    this._chartTypesOptionsDictionary[ChartType.Line] = {
      label: 'Line',
      value: ChartType.Line,
      dataType: ChartDataType.Simple,
    };
    this._chartTypesOptionsDictionary[ChartType.Area] = {
      label: 'Area',
      value: ChartType.Area,
      dataType: ChartDataType.Simple,
    };
    this._chartTypesOptionsDictionary[ChartType.StackedArea] = {
      label: 'Stacked Area',
      value: ChartType.StackedArea,
      dataType: ChartDataType.Simple,
    };
    this._chartTypesOptionsDictionary[ChartType.NormalizedArea] = {
      label: 'Normalized Area',
      value: ChartType.NormalizedArea,
      dataType: ChartDataType.Simple,
    };
    this._chartTypesOptionsDictionary[ChartType.Card] = {
      label: 'Card',
      value: ChartType.Card,
      dataType: ChartDataType.Complex,
    };
    this._chartTypesOptionsDictionary[ChartType.Gauge] = {
      label: 'Gauge',
      value: ChartType.Gauge,
      dataType: ChartDataType.Complex,
    };
  }
}
