export interface IChartTypeSelectorOption {
  value: number;
  label: string;
  dataType: ChartDataType;
}

export enum ChartDataType {
  Simple,
  Complex,
}
