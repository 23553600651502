import { Component, Input, ChangeDetectionStrategy, HostBinding } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';
import { ControlGroupDto } from '@core/services/api-clients';

@Component({
  selector: 'engine-control-group',
  templateUrl: './engine-control-group.component.html',
  styleUrls: ['./engine-control-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class EngineControlGroupComponent {
  private _controlGroup: ControlGroupDto;
  @Input() set controlGroup(v: ControlGroupDto) {
    this._controlGroup = v;
  }

  @HostBinding('id')
  get id(): string {
    return this.controlGroup?.id;
  }
  @HostBinding('attr.name')
  get name(): string {
    return this.controlGroup?.name;
  }
  get controlGroup(): ControlGroupDto {
    return this._controlGroup;
  }

  getLayoutType(controlGroup: ControlGroupDto): 'column' | 'row' {
    return (controlGroup as any).layoutType ?? 'column';
  }
}
