import { APP_INITIALIZER, ErrorHandler, Injector, ModuleWithProviders, NgModule } from '@angular/core';
import { HttpErrorsInterceptor } from './interceptors/http-errors.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { GlobalErrorHandler } from './services/global-error-handler.service';
import { ErrorPipelineService } from './services/error-pipeline.service';
import { ErrorHandlerProvider } from './services/error-handler-provider.service';
import { ErrorEventService, ERROR_DISPATCHER, ERROR_PROVIDER } from './services/error-event.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { StoreModule } from '@ngrx/store';
import { FEATURE_NAME } from './store/state';
import { reducers } from './store';
import { EffectsModule } from '@ngrx/effects';
import { ErrorEffects } from './store/effects';
import { FatalErrorsComponent } from './fatal-errors/fatal-errors.component';
import { SharedModule } from '@shared/shared.module';

export function init(injector: Injector) {
  return () => (<GlobalErrorHandler>injector.get(ErrorHandler)).startListening();
}

@NgModule({
  declarations: [FatalErrorsComponent],
  imports: [SharedModule, StoreModule.forFeature(FEATURE_NAME, reducers), EffectsModule.forFeature([ErrorEffects])],
  exports: [FatalErrorsComponent],
})
export class ErrorModule {
  static forRoot(): ModuleWithProviders<ErrorModule> {
    return {
      ngModule: ErrorModule,
      providers: [
        {
          provide: ErrorHandler,
          useClass: GlobalErrorHandler,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: HttpErrorsInterceptor,
          multi: true,
        },
        ErrorPipelineService,
        ErrorHandlerProvider,
        ErrorEventService,
        {
          provide: ERROR_DISPATCHER,
          useExisting: ErrorEventService,
        },
        {
          provide: ERROR_PROVIDER,
          useExisting: ErrorEventService,
        },
        {
          provide: APP_INITIALIZER,
          useFactory: init,
          deps: [Injector, ErrorPipelineService, MatDialog, ERROR_DISPATCHER],
          multi: true,
        },
      ],
    };
  }
}
