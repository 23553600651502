<div *ngIf="formControl">
  <mat-form-field class="control-input">
    <input class="autocomplete-value-input" [formControl]="formControl" [id]="id" [name]="name" />
    <input
      class="autocomplete-label-input"
      [formControl]="labelFormControl"
      (blur)="onBlur()"
      (keyup)="onFilterChange($event)"
      (focusin)="onFocusIn($event)"
      matInput
      [placeholder]="label"
      [matAutocomplete]="auto"
      [ngStyle]="textAlignStyle" />
    <mat-autocomplete
      #auto="matAutocomplete"
      [displayWith]="displayFn.bind(this)"
      (optionSelected)="onOptionSelected($event)">
      <app-autocomplete-groups-selector
        [groups]="groups"
        [selectedGroupId]="selectedGroupId"
        (selectedGroupChanged)="onSelectedGroupChanged($event)"></app-autocomplete-groups-selector>
      <mat-option *ngIf="!selectedGroupOptions?.length" class="no-options-option" [value]="{ value: 'NO_RECORDS' }">
      </mat-option>
      <mat-option *ngFor="let option of selectedGroupOptions" [value]="option">
        {{ option.label }}
      </mat-option>
      <mat-option *ngFor="let actionOption of actionOptions" class="action-option" [value]="{ value: actionOption.id }">
        {{ actionOption.label }}
      </mat-option>
    </mat-autocomplete>
    <button
      type="button"
      tabindex="-1"
      class="autocomplete-action-button visible-on-edit-mode"
      *ngIf="!isEmpty"
      matSuffix
      mat-icon-button
      (click)="onClearClick()">
      <mat-icon>close</mat-icon>
    </button>
    <button
      type="button"
      tabindex="-1"
      [disabled]="formControl.disabled"
      class="autocomplete-action-button visible-on-edit-mode"
      matSuffix
      mat-icon-button>
      <mat-icon>arrow_drop_down</mat-icon>
    </button>
    <button
      type="button"
      tabindex="-1"
      class="autocomplete-action-button visible-on-edit-mode"
      *ngIf="isOpenButtonVisible && !isEmpty"
      matSuffix
      mat-icon-button
      (click)="onEditClick($event)">
      <mat-icon>visibility</mat-icon>
    </button>
    <button
      type="button"
      tabindex="-1"
      class="autocomplete-action-button visible-on-edit-mode"
      *ngIf="isUpdateButtonVisible && !isEmpty"
      matSuffix
      mat-icon-button
      (click)="onEditClick($event)">
      <mat-icon>edit</mat-icon>
    </button>
    <button
      type="button"
      tabindex="-1"
      class="autocomplete-action-button visible-on-edit-mode"
      *ngIf="isCreateButtonVisible && isEmpty"
      matSuffix
      mat-icon-button
      (click)="onAddClick()">
      <mat-icon>add</mat-icon>
    </button>
  </mat-form-field>

  <app-control-error [control]="formControl" [offset]="-16"></app-control-error>
</div>
