import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IRatingOption } from './rating.model';

@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RatingComponent {
  @Input() value: number;
  @Input() stars: IRatingOption[];
  @Input() color: 'primary' | 'accent' | 'warn' | undefined;
  @Input() disabled: boolean = true;
  @Output() ratingUpdated = new EventEmitter<IRatingOption>();

  constructor() {}

  onClick(rating: IRatingOption) {
    if (this.disabled) return;
    this.value = rating.value;
    this.ratingUpdated.emit(rating);
  }

  getIcon(value: any) {
    if (this.value >= value) {
      return 'star';
    } else {
      return 'star_border';
    }
  }
}
