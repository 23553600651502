import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Color, LegendPosition } from '@swimlane/ngx-charts';
import { ChartType, IChartConfiguration, ChartData, ChartPredefinedSchema } from './chart.model';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartComponent {
  ChartTypes = ChartType;
  private _defaultScheme: ChartPredefinedSchema = 'vivid';
  private _configuration: IChartConfiguration;

  @Input() data: ChartData;
  @Input()
  set configuration(v: IChartConfiguration) {
    this._configuration = v;
    if (!!v) {
      this._configuration.scheme = v.scheme ?? this._defaultScheme;
      this._configuration.legendPosition = v.legendPosition ?? 'below';
    }
    this.data = this.configuration.data;
  }
  @Input() isDarkTheme: boolean;

  get configuration(): IChartConfiguration {
    return this._configuration;
  }

  constructor() {}

  onSelect(event) {}

  onActivate(data): void {}

  onDeactivate(data): void {}

  onChartTypeSelected(chartType: ChartType): void {
    this.configuration.chartType = chartType;
  }

  getChartStyles(): string {
    return this.isDarkTheme ? 'fill: white' : 'fill: black';
  }

  getLegendPosition(): LegendPosition {
    return this.configuration.legendPosition as LegendPosition;
  }

  getScheme(): string | Color {
    return this.configuration.scheme as string | Color;
  }
}
