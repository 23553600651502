<mat-toolbar class="kanban-toolbar">
  <span class="kanban-label" fxHide.lt-md>
    {{ kanban.label }}
  </span>

  <mat-divider class="divider" [vertical]="true" fxHide.lt-md></mat-divider>

  <mat-icon>search</mat-icon>

  <mat-form-field class="toolbar-search" floatLabel="never">
    <input matInput placeholder="{{ 'Kanban.Search' | translate }}..." [formControl]="searchControl" />
  </mat-form-field>

  <span class="spacer"></span>

  <button
    type="button"
    mat-button
    [matTooltip]="'View.RefreshData' | translate"
    (click)="refreshDataClick.emit($event)"
    matTooltipPosition="below">
    <mat-icon>autorenew</mat-icon>
  </button>

  <button
    type="button"
    mat-button
    [matTooltip]="'View.RestoreSettings' | translate"
    (click)="restoreSettingsClick.emit($event)"
    matTooltipPosition="before">
    <mat-icon>settings_backup_restore</mat-icon>
  </button>
</mat-toolbar>
