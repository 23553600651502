import { Action } from '@ngrx/store';
import { KanbanColumnAggregatorDto, KanbanColumnDto, KanbanDto } from '@core/services/api-clients';
import { KanbanData } from './state';

export enum KanbanActionTypes {
  LoadEntityKanban = '[Kanban] Load Entity Kanban',
  LoadEntityKanbanSuccess = '[Kanban] Load Entity Kanban Success',
  LoadKanbanData = '[Kanban] Load Kanban Data',
  LoadKanbanDataSuccess = '[Kanban] Load Kanban Data Success',
  MoveRecord = '[Kanban] Move Record',
  MoveRecordSuccess = '[Kanban] Move Record Success',
  RefreshAggregators = '[Kanban] Refresh Aggregators',
  RefreshAggregatorsSuccess = '[Kanban] Refresh Aggregators Success ',
  LoadMore = '[Kanban] Load More',
  LoadMoreSuccess = '[Kanban] Load More Success',
  RecalculateRecordsStackRank = '[Kanban] Recalculate Records Stack Rank',
  RecalculateRecordsStackRankSuccess = '[Kanban] Recalculate Records Stack Rank Success',
  SaveDefaultAggregator = '[Kanban] Save Default Aggregator',
  SaveColumnExpansion = '[Kanban] Save Column Expansion',
  RestoreSettings = '[Kanban] Restore Settings',
  RestoreSettingsSuccess = '[Kanban] Restore Settings Success',
  FilterRecords = '[Kanban] Filter Records',
  SyncKanbanSettings = '[Kanban] Sync Kanban Settings',
}

export class LoadEntityKanban implements Action {
  public readonly type = KanbanActionTypes.LoadEntityKanban;
  constructor(public payload: { kanbanId: string }) {}
}

export class LoadEntityKanbanSuccess implements Action {
  public readonly type = KanbanActionTypes.LoadEntityKanbanSuccess;
  public constructor(public payload: { kanban: KanbanDto }) {}
}

export interface ColumnsPageSize {
  [columnId: string]: number;
}

export class LoadKanbanData implements Action {
  public readonly type = KanbanActionTypes.LoadKanbanData;
  public constructor(
    public payload: { kanban: KanbanDto; columnsPageSize?: ColumnsPageSize; newSearchTerm?: string },
  ) {}
}

export class LoadKanbanDataSuccess implements Action {
  public readonly type = KanbanActionTypes.LoadKanbanDataSuccess;
  public constructor(public payload: { kanban: KanbanDto; kanbanData: KanbanData }) {}
}

export interface MoveRecordEvent {
  kanban: KanbanDto;
  previousColumn: KanbanColumnDto;
  previousIndex: number;
  currentColumn: KanbanColumnDto;
  currentIndex: number;
  record: any;
  onError: () => void;
}

export class MoveRecord implements Action {
  public readonly type = KanbanActionTypes.MoveRecord;
  public constructor(public payload: { moveRecordEvent: MoveRecordEvent }) {}
}

export class MoveRecordSuccess implements Action {
  public readonly type = KanbanActionTypes.MoveRecordSuccess;
  public constructor(public payload: { moveRecordEvent: MoveRecordEvent; newStackRank: number }) {}
}

export class RefreshAggregators implements Action {
  public readonly type = KanbanActionTypes.RefreshAggregators;
  public constructor(public payload: { kanban: KanbanDto; kanbanColumns: KanbanColumnDto[] }) {}
}

export class RefreshAggregatorsSuccess implements Action {
  public readonly type = KanbanActionTypes.RefreshAggregatorsSuccess;
  public constructor(
    public payload: {
      kanban: KanbanDto;
      kanbanColumn: KanbanColumnDto;
      aggregators: { [aggregatorId: string]: number };
    }[],
  ) {}
}

export class LoadMore implements Action {
  public readonly type = KanbanActionTypes.LoadMore;
  public constructor(public payload: { kanban: KanbanDto; kanbanColumn: KanbanColumnDto }) {}
}

export class LoadMoreSuccess implements Action {
  public readonly type = KanbanActionTypes.LoadMoreSuccess;
  public constructor(public payload: { kanban: KanbanDto; kanbanColumn: KanbanColumnDto; records: any[] }) {}
}

export class RecalculateRecordsStackRank implements Action {
  public readonly type = KanbanActionTypes.RecalculateRecordsStackRank;
  public constructor(public payload: { kanban: KanbanDto }) {}
}

export class RecalculateRecordsStackRankSuccess implements Action {
  public readonly type = KanbanActionTypes.RecalculateRecordsStackRankSuccess;
  public constructor(public payload: { kanban: KanbanDto }) {}
}

export class SaveDefaultAggregator implements Action {
  public readonly type = KanbanActionTypes.SaveDefaultAggregator;
  public constructor(
    public payload: { kanbanColumn: KanbanColumnDto; kanbanColumnAggregator: KanbanColumnAggregatorDto },
  ) {}
}

export class SaveColumnExpansion implements Action {
  public readonly type = KanbanActionTypes.SaveColumnExpansion;
  public constructor(public payload: { kanbanColumn: KanbanColumnDto; isExpanded: boolean }) {}
}

export class RestoreSettings implements Action {
  public readonly type = KanbanActionTypes.RestoreSettings;
  public constructor(public payload: { kanban: KanbanDto }) {}
}

export class RestoreSettingsSuccess implements Action {
  public readonly type = KanbanActionTypes.RestoreSettingsSuccess;
  public constructor(public payload: { kanban: KanbanDto }) {}
}

export class FilterRecords implements Action {
  public readonly type = KanbanActionTypes.FilterRecords;
  public constructor(public payload: { kanban: KanbanDto; searchTerm: string }) {}
}

export class SyncKanbanSettings implements Action {
  public readonly type = KanbanActionTypes.SyncKanbanSettings;
}

export type KanbanActions =
  | LoadEntityKanban
  | LoadEntityKanbanSuccess
  | LoadKanbanData
  | LoadKanbanDataSuccess
  | MoveRecord
  | MoveRecordSuccess
  | RefreshAggregators
  | RefreshAggregatorsSuccess
  | LoadMore
  | LoadMoreSuccess
  | RecalculateRecordsStackRank
  | RecalculateRecordsStackRankSuccess
  | SaveDefaultAggregator
  | SaveColumnExpansion
  | RestoreSettings
  | RestoreSettingsSuccess
  | FilterRecords
  | SyncKanbanSettings;
