export const FEATURE_NAME: string = 'layout-feature';

export interface ILayoutState {
  isLeftPanelVisible: boolean;
  isLeftPanelExpanded: boolean;
  isRightPanelVisible: boolean;
  screenWidth: number;
}

export const initialLayoutState: ILayoutState = {
  isLeftPanelVisible: true,
  isLeftPanelExpanded: false,
  isRightPanelVisible: false,
  screenWidth: 0,
};
