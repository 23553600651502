import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { environment } from '@env';
import { of } from 'rxjs';

@Injectable()
export class TestEnvOnlyGuard implements CanActivate {
  canActivate() {
    return of(!environment.production);
  }
}
