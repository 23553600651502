import { ChangeDetectorRef, Directive, HostBinding, Input } from '@angular/core';

@Directive({
  selector: '[hideable]',
})
export class HideableDirective {
  private _isVisible: boolean = true;
  @Input() set isVisible(v: boolean) {
    this._isVisible = v;
  }
  get isVisible(): boolean {
    return this._isVisible;
  }

  @Input() initialDisplayStyle: 'initial' | 'block' | 'flex' = 'initial';
  @HostBinding('style.display') get getHideableStyle() {
    return this._isVisible ? this.initialDisplayStyle : 'none';
  }

  constructor(protected _changeDetector: ChangeDetectorRef) {}
}
