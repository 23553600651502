<div class="notification-bar-container" [ngStyle]="{ 'max-height': maxHeight || 'unset' }">
  <mat-accordion>
    <ng-container *ngFor="let group of notificationGroups">
      <mat-expansion-panel hideToggle *ngIf="group.notifications.length">
        <mat-expansion-panel-header collapsedHeight="40px" expandedHeight="40px">
          <div class="header-container">
            <span
              class="title"
              [matBadge]="group.notifications.length"
              matBadgePosition="before"
              matBadgeOverlap="false"
              [ngClass]="getGroupType(group)">
              {{ group.message }}
            </span>
          </div>
          <button type="button" mat-button (click)="onRemoveClick($event, group)">
            <mat-icon>close</mat-icon>
          </button>
        </mat-expansion-panel-header>
        <ul class="list-container">
          <li *ngFor="let item of group.notifications">
            <div class="item-container">
              <mat-icon class="notification-icon mat-icon" [ngClass]="getIcon(item.type)">
                {{ getIcon(item.type) }}
              </mat-icon>
              {{ item.message }}
            </div>
          </li>
        </ul>
      </mat-expansion-panel>
    </ng-container>
  </mat-accordion>
</div>
