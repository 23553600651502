import { InjectionToken } from '@angular/core';

export const ENGINE_DATA_CONTEXT_PROVIDER = new InjectionToken<IEngineDataContextProvider>(
  'IEngineDataContextProvider',
);

export interface IEngineDataContextProvider {
  getDataContext(): IEngineDataContext;
}

export interface IEngineDataContext {
  [key: string]: any;
  dialogId?: string;
}

export interface IEngineFormDataContext extends IEngineDataContext {
  entityId?: string;
  entityName?: string;
  recordId?: string;
  formId: string;
  formName: string;
}

export interface IEngineFormControlDataContext extends IEngineFormDataContext {
  controlId: string;
  controlName: string;
}

export interface IEngineSubgridFormControlDataContext extends IEngineFormControlDataContext {
  subgridId: string; // deprecated
}

export interface IEngineViewDataContext extends IEngineDataContext {
  viewId: string;
  viewName: string;
  viewEntityId: string;
  viewEntityName: string;
}
