import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Optional,
  Output,
  Self,
} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormGroup,
  NgControl,
  Validators,
} from '@angular/forms';
import { MatLegacyFormFieldControl as MatFormFieldControl } from '@angular/material/legacy-form-field';
import moment from 'moment';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';
import { DurationService } from './duration.service';

export type Precision =
  | 'days'
  | 'hours'
  | 'minutes'
  | 'seconds';

@Component({
  selector: 'duration-input',
  templateUrl: 'duration-input.component.html',
  styleUrls: ['duration-input.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: DurationInput
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class.duration-floating]': 'shouldLabelFloat',
    '[id]': 'id'
  },
})
export class DurationInput implements ControlValueAccessor, MatFormFieldControl<string>, OnDestroy {
  private _durationSubject = new BehaviorSubject<number>(undefined);
  private _destroy$: Subject<boolean> = new Subject<boolean>();
  private static nextId = 0;

  private _minPrecision: Precision = 'minutes';
  private _maxPrecision: Precision = 'hours';
  private _interval: number = 1;

  private _required = undefined;
  private _disabled = false;

  id: string = `duration-input-${DurationInput.nextId++}`;
  durationGroup: UntypedFormGroup;
  stateChanges = new Subject<void>();
  focused = false;
  touched = false;

  onChange = (_: any) => { };
  onTouched = () => { };

  @Input() set interval(interval: number) {
    if (!interval) return;

    this._interval = interval;
  }

  @Input() set minPrecision(precision: Precision) {
    if (!precision) return;

    this._minPrecision = precision;
    this.refreshDurationGroup();
  }

  @Input() set maxPrecision(precision: Precision) {
    if (!precision) return;

    this._maxPrecision = precision;
    this.refreshDurationGroup();
  }

  @Input() placeholder: string;
  @Input() name: string;

  @Input()
  set required(value: BooleanInput) {
    this._required = coerceBooleanProperty(value);
  }

  @Input()
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.durationGroup.disable() : this.durationGroup.enable();
  }

  @Input()
  set value(v: string | null) {
    const valueAsSeconds = v ? moment.duration(v).asSeconds() : undefined;
    if (valueAsSeconds !== this._durationSubject.value) {
      this._durationSubject.next(valueAsSeconds);
    }
  }

  @Output() blur = new EventEmitter<any>();

  get interval() {
    return this._interval;
  }

  get minPrecision() {
    return this._minPrecision;
  }

  get maxPrecision() {
    return this._maxPrecision;
  }

  get required(): boolean {
    return this._required ?? this.ngControl?.control?.hasValidator(Validators.required);
  }

  get disabled(): boolean {
    return this._disabled;
  }

  get value(): string | null {
    if (this._durationSubject.getValue() != null) {
      return moment.duration(this._durationSubject.getValue(), 'seconds').toISOString()
    }
    return null;
  }

  constructor(
    formBuilder: UntypedFormBuilder,
    private _elementRef: ElementRef<HTMLElement>,
    private _durationService: DurationService,
    @Optional() @Self() public ngControl: NgControl,
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    this.durationGroup = formBuilder.group({
      days: ['', []],
      hours: ['', []],
      minutes: ['', []],
      seconds: ['', []],
    });

    this._durationSubject.pipe(
      takeUntil(this._destroy$),
      tap(() => {
        this.refreshDurationGroup();
      })
    ).subscribe();

    this.blur.asObservable().pipe(
      takeUntil(this._destroy$),
      debounceTime(50),
      tap(_ => {
        if (this.empty)
          return;

        const value = this.durationGroup.value;

        let daysInSeconds = moment.duration(value.days, 'days').asSeconds();
        let hoursInSeconds = moment.duration(value.hours, 'hours').asSeconds();
        let minutesInSeconds = moment.duration(value.minutes, 'minutes').asSeconds();
        let secondsInSeconds = moment.duration(value.seconds, 'seconds').asSeconds();
        let durationInSeconds = daysInSeconds + hoursInSeconds + minutesInSeconds + secondsInSeconds;

        this.setValueAndTriggerOnChange(durationInSeconds);
      })
    ).subscribe();
  }

  isVisible(value: Precision) {
    return this._durationService.isVisible(value, this.maxPrecision, this.minPrecision);
  }

  add() {
    const value = this._durationSubject.getValue() + (this.interval * this._durationService.precisionAsSeconds(this.minPrecision));
    this.setValueAndTriggerOnChange(value);
  }

  subtract() {
    let minPrecisionAsSeconds = this.interval * this._durationService.precisionAsSeconds(this.minPrecision);
    let durationValue = this._durationSubject.getValue();
    let value = durationValue - minPrecisionAsSeconds <= 0 ? 0 : durationValue - minPrecisionAsSeconds

    this.setValueAndTriggerOnChange(value);
  }

  clear() {
    this.setValueAndTriggerOnChange(null);
  }

  ngOnDestroy() {
    this.stateChanges.complete();
  }

  // #region MatFormFieldControl<string>
  onContainerClick() {
  }

  setDescribedByIds(ids: string[]) {
    const controlElement = this._elementRef.nativeElement.querySelector(
      '.duration-input-container',
    )!;
    controlElement.setAttribute('aria-describedby', ids.join(' '));
  }

  get empty(): boolean {
    const { value: { days, hours, minutes, seconds }
    } = this.durationGroup;

    return days == '' && hours == '' && minutes == '' && seconds == '';
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  get errorState(): boolean {
    return this.ngControl.control.invalid && this.touched;
  }

  onFocusIn() {
    if (!this.focused) {
      this.focused = true;
    }
  }

  onFocusOut(event: FocusEvent) {
    const isFocusedElementInsideDurationInput = this._elementRef.nativeElement.contains(event.relatedTarget as Element);
    if (!isFocusedElementInsideDurationInput) {
      this.touched = true;
      this.focused = false;
      this.onTouched();
    }
  }
  // #endregion


  // #region ControlValueAccessor
  writeValue(value: string | null): void {
    this.value = value;
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  // #endregion

  // #region DurationInput
  private refreshDurationGroup() {
    if (this._durationSubject.getValue() != null) {
      let duration = moment.duration(this._durationSubject.getValue(), 'seconds');
      this.durationGroup.setValue({
        days: this._durationService.getDays(duration, this.maxPrecision),
        hours: this._durationService.getHours(duration, this.maxPrecision),
        minutes: this._durationService.getMinutes(duration, this.maxPrecision),
        seconds: this._durationService.getSeconds(duration, this.maxPrecision)
      }, {
        emitEvent: false
      });
    }
    else {
      this.durationGroup.setValue({
        days: '',
        hours: '',
        minutes: '',
        seconds: ''
      }, {
        emitEvent: false
      });
    }
  }
  // #endregion

  private setValueAndTriggerOnChange(value: number | null): void {
    if (this._durationSubject.value !== value) {
      this._durationSubject.next(value);

      if (!this.disabled)
        this.onChange(this.value);
    }
  }
}
