import { MenuActions, MenuActionTypes } from './actions';
import { initialMenuState, IMenuState } from './state';

export function menuReducer(state = initialMenuState, action: MenuActions): IMenuState {
  switch (action.type) {
    case MenuActionTypes.LoadMainMenuSuccess: {
      return {
        ...state,
        mainMenu: action.payload.mainMenu,
        selectedMenuAreaId: action.payload.selectedMenuAreaId,
        selectedMenuGroupId: action.payload.selectedMenuGroupId,
        selectedMenuItemId: action.payload.selectedMenuItemId,
      };
    }

    case MenuActionTypes.AddRecentMenuItemSuccess: {
      if (!state.mainMenu.menuConfiguration.useRecentMenuItems) return state;

      let menuItemId = action.payload.menuItem.id;
      let recentItemsNumber = state.mainMenu.menuConfiguration.recentMenuItemsNumber;
      let menuItems = state.mainMenu.recentMenuGroup.menuItemIds
        .filter((x) => x != menuItemId)
        .slice(0, recentItemsNumber - 1);
      menuItems.unshift(menuItemId);

      return {
        ...state,
        mainMenu: Object.assign({}, state.mainMenu, {
          recentMenuGroup: Object.assign({}, state.mainMenu.recentMenuGroup, {
            menuItemIds: menuItems,
          }),
        }),
      };
    }

    case MenuActionTypes.AddFavoriteMenuItemSuccess: {
      if (!state.mainMenu.menuConfiguration.useFavoriteMenuItems) return state;

      let menuItemId = action.payload.menuItem.id;
      let menuItems = state.mainMenu.favoriteMenuGroup.menuItemIds.filter((x) => x != menuItemId);
      menuItems.push(menuItemId);

      return {
        ...state,
        mainMenu: Object.assign({}, state.mainMenu, {
          favoriteMenuGroup: Object.assign({}, state.mainMenu.favoriteMenuGroup, {
            menuItemIds: menuItems,
          }),
        }),
      };
    }

    case MenuActionTypes.RemoveFavoriteMenuItemSuccess: {
      if (!state.mainMenu.menuConfiguration.useFavoriteMenuItems) return state;

      let menuItemId = action.payload.menuItem.id;
      let menuItems = state.mainMenu.favoriteMenuGroup.menuItemIds.filter((x) => x != menuItemId);

      return {
        ...state,
        mainMenu: Object.assign({}, state.mainMenu, {
          favoriteMenuGroup: Object.assign({}, state.mainMenu.favoriteMenuGroup, {
            menuItemIds: menuItems,
          }),
        }),
      };
    }

    case MenuActionTypes.SelectMenuArea: {
      return {
        ...state,
        selectedMenuAreaId: action.payload.menuAreaId ?? null,
      };
    }

    case MenuActionTypes.SelectMenuGroup: {
      return {
        ...state,
        selectedMenuGroupId: action.payload.menuGroupId ?? null,
      };
    }

    case MenuActionTypes.SelectMenuItemSuccess: {
      return {
        ...state,
        selectedMenuItemId: action.payload.menuItem?.id,
      };
    }

    case MenuActionTypes.EnableMainMenuPopup: {
      return {
        ...state,
        isPopupEnabled: true,
      };
    }

    case MenuActionTypes.DisableMainMenuPopup: {
      return {
        ...state,
        isPopupEnabled: false,
      };
    }

    case MenuActionTypes.ToggleIsFavoriteMode: {
      return {
        ...state,
        isFavoriteModeEnabled: !state.isFavoriteModeEnabled,
      };
    }

    default:
      return state;
  }
}
