import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { HyperlinkBehavior } from './hyperlink.model';
import { NavigationService } from '@core/navigation/services/navigation.service';

@Directive({
  selector: '[appHyperlink]',
})
export class HyperlinkDirective {
  private _url: string;
  private _linkBehavior: HyperlinkBehavior;

  @Input('appHyperlink') set url(v: string) {
    this._url = v;
  }
  @Input() set linkBehavior(v: HyperlinkBehavior) {
    this._linkBehavior = v;
  }

  @HostListener('click', ['$event']) onHostClick(e: Event) {
    if (this.linkBehavior === HyperlinkBehavior.OpenInSamePage) {
      window.open(this.url, '_self');
    } else if (this.linkBehavior === HyperlinkBehavior.OpenInNewTab) {
      window.open(this.url, '_blank', 'noopener noreferrer');
    } else if (this.linkBehavior === HyperlinkBehavior.OpenInNewWindow) {
      window.open(this.url, null, 'resizable,scrollbars,status,noopener,noreferrer');
    } else if (this.linkBehavior === HyperlinkBehavior.OpenInEngineContext) {
      const guidRegex = '[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}';
      const createFormUrlRegex = new RegExp(`^\/entity\/(${guidRegex})\/form\/(${guidRegex})\\?args\=(.*)$`);
      const updateFormUrlRegex = new RegExp(`^\/entity\/(${guidRegex})\/record\/(${guidRegex})\/form\/(${guidRegex})$`);

      let groups: any[];
      if ((groups = this.url.match(createFormUrlRegex)) != null) {
        this._navigationContext.navigateToCreateForm(groups[1], groups[2], {
          popCount: 0,
          queryParams: {
            args: groups[3],
          }
        });
      } else if ((groups = this.url.match(updateFormUrlRegex)) != null) {
        this._navigationContext.navigateToForm(groups[1], groups[3], groups[2], {
          popCount: 0,
        });
      }
    }

    e.preventDefault();
    e.stopImmediatePropagation();
  }
  get url(): string {
    return this._url;
  }
  get linkBehavior(): HyperlinkBehavior {
    return this._linkBehavior;
  }

  constructor(private element: ElementRef, private _navigationContext: NavigationService) {
    this.clearHrefAndTarget();
  }

  private clearHrefAndTarget() {
    this.element.nativeElement.href = '';
    this.element.nativeElement.target = '';
  }
}
