import {
  Component,
  Input,
  ElementRef,
  ViewChild,
  OnInit,
  OnDestroy,
  EventEmitter,
  Output,
  ChangeDetectionStrategy,
} from '@angular/core';
import { Overlay, OverlayRef, OverlayConfig } from '@angular/cdk/overlay';
import { CdkPortal } from '@angular/cdk/portal';
import { BehaviorSubject, Subscription, combineLatest } from 'rxjs';
import { tap, debounceTime } from 'rxjs/operators';
import { MenuItemDto } from '@core/services/api-clients';
import { MenuGroupWithMenuItems } from '@core/menu/store/selectors';

export class SelectedMenuGroup {
  menuGroupId: string;
  menuAreaId: string;
  isExpandable: boolean;
  isExpanded: boolean;
  isSpecial: boolean;
}

@Component({
  selector: 'app-menu-group',
  templateUrl: './menu-group.component.html',
  styleUrls: ['./menu-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuGroupComponent implements OnInit, OnDestroy {
  @Input() menuGroup: MenuGroupWithMenuItems;
  @Input() selectedMenuItemId: string;
  @Input() isSelected: boolean = false;
  @Input() isWide: boolean = false;
  @Input() isSpecial: boolean = false;
  @Input() isPopupEnabled: boolean = false;
  @Input() isFavoriteEnabled: boolean = false;
  @Input() favorites: { [id: string]: boolean } = null;

  @Output() menuItemClick = new EventEmitter<MenuItemDto>();
  @Output() menuItemAddedToFavorite = new EventEmitter<MenuItemDto>();
  @Output() menuItemRemovedFromFavorite = new EventEmitter<MenuItemDto>();
  @Output() menuGroupClick = new EventEmitter<SelectedMenuGroup>();

  @ViewChild('popupContent', { static: false }) menuPopupContent: CdkPortal;

  get isVisible(): boolean {
    return this.menuGroup && (!!this.menuGroup.menuItems?.length || this.isSpecial);
  }
  get isExpandable(): boolean {
    return this.menuGroup?.menuItems?.length > 1 || this.isSpecial;
  }

  private overlayRef: OverlayRef = null;
  private mouseOverPopup: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private mouseOverSource: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private mouseOverPopup$ = this.mouseOverPopup.asObservable();
  private mouseOverSource$ = this.mouseOverSource.asObservable();
  private subscriptions = new Subscription();

  constructor(private _overlay: Overlay, private ref: ElementRef) {}

  ngOnInit(): void {
    this.subscriptions.add(
      combineLatest([this.mouseOverSource$, this.mouseOverPopup$])
        .pipe(
          debounceTime(300),
          tap(([isOverSource, isOverPopup]) => {
            if (isOverSource && !this.isSelected && this.isExpandable) {
              this.openGroupItemsPopup();
              return;
            }

            if (!isOverSource && !isOverPopup) {
              this.closeGroupItemsPopup();
              return;
            }
          }),
        )
        .subscribe(),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.closeGroupItemsPopup();
  }

  onMenuGroupClick() {
    this.closeGroupItemsPopup();
    this.menuGroupClick.emit({
      menuGroupId: this.menuGroup.id,
      menuAreaId: null,
      isExpandable: this.isExpandable,
      isExpanded: this.isSelected,
      isSpecial: this.isSpecial,
    });
  }

  onMouseOver() {
    if (this.mouseOverSource.value != true) this.mouseOverSource.next(true);
  }

  onMouseOut() {
    if (this.mouseOverSource.value != false) this.mouseOverSource.next(false);
  }

  onMouseOverOverlay(isOver: boolean) {
    if (this.mouseOverPopup.value != isOver) this.mouseOverPopup.next(isOver);
  }

  onMenuItemClick(menuItem: MenuItemDto) {
    this.menuItemClick.emit(menuItem);
  }

  addMenuItemToFavorite(menuItem: MenuItemDto) {
    this.menuItemAddedToFavorite.emit(menuItem);
  }

  removeMenuItemFromFavorite(menuItem: MenuItemDto) {
    this.menuItemRemovedFromFavorite.emit(menuItem);
  }

  private openGroupItemsPopup() {
    if (this.overlayRef || !this.isPopupEnabled) return;
    const overlayConfig = new OverlayConfig({
      positionStrategy: this._overlay
        .position()
        .flexibleConnectedTo(this.ref.nativeElement)
        .withPositions([
          {
            originX: 'end',
            originY: 'top',
            overlayX: 'start',
            overlayY: 'top',
          },
        ]),
      scrollStrategy: this._overlay.scrollStrategies.close(),
    });
    this.overlayRef = this._overlay.create(overlayConfig);
    this.overlayRef.attach(this.menuPopupContent);
  }

  private closeGroupItemsPopup() {
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }
}
