<div class="kanban-column">
  <app-kanban-column-header
    [kanbanColumn]="kanbanColumn"
    [(isExpanded)]="isExpanded"
    [aggregatorsData]="kanbanColumnData?.aggregators"
    (aggregatorSelect)="aggregatorSelect.emit({ kanbanColumn: kanbanColumn, kanbanColumnAggregator: $event })"
    (isExpandedChange)="isExpandedChange.emit({ column: kanbanColumn, isExpanded: $event })">
  </app-kanban-column-header>
  <div
    id="{{ kanbanColumn.id }}"
    class="tiles-container"
    cdkDropList
    [cdkDropListConnectedTo]="kanbanColumnIds"
    [cdkDropListData]="kanbanColumnData?.records"
    (cdkDropListDropped)="onDropTile($event)">
    <ng-container *ngIf="isExpanded">
      <app-kanban-column-tile
        *ngFor="let record of kanbanColumnData?.records | slice: 0:kanbanColumnData?.pageSize"
        [kanbanColumn]="kanbanColumn"
        [kanbanControls]="kanbanControls"
        [record]="record"
        appDoubleClick
        (doubleClick)="tileDoubleClick.emit(record)"
        cdkDrag></app-kanban-column-tile>
      <div
        class="load-more"
        *ngIf="kanbanColumnData?.records.length > kanbanColumnData?.pageSize"
        (click)="loadMore.emit(kanbanColumn)">
        <mat-icon>expand_more</mat-icon> {{ 'Kanban.LoadMore' | translate }}
      </div>
    </ng-container>
  </div>
</div>
