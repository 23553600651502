import { Action } from '@ngrx/store';
import { ContextMenuDto } from '@core/services/api-clients';

export enum ContextMenusActionTypes {
  ResetMainContextMenu = '[ContextMenus] Reset Main Context Menu',
  LoadMainContextMenu = '[ContextMenus] Load Main Context Menu',
  LoadMainContextMenuSuccess = '[ContextMenus] Load Main Context Menu Success',
  LoadGridColumnContextMenu = '[ContextMenus] Load Grid Column Context Menu',
  LoadGridColumnContextMenuSuccess = '[ContextMenus] Load Grid Column Context Menu Success',
  LoadSubgridGridColumnContextMenu = '[ContextMenus] Load Subgrid Grid Column Context Menu',
  LoadSubgridGridColumnContextMenuSuccess = '[ContextMenus] Load Subgrid Grid Column Context Menu Success',
  LoadSubgridContextMenu = '[ContextMenus] Load Subgrid Context Menu',
  LoadSubgridContextMenuSuccess = '[ContextMenus] Load Subgrid Context Menu Success',
  LoadDialogFormContextMenu = '[ContextMenus] Load Dialog Form Context Menu',
  LoadDialogFormContextMenuSuccess = '[ContextMenus] Load Dialog Form Context Menu Success',
  RemoveDialogFormContextMenu = '[ContextMenus] Remove Dialog Form Context Menu',
}

export class ResetMainContextMenu implements Action {
  public readonly type = ContextMenusActionTypes.ResetMainContextMenu;
}

export class LoadMainContextMenu implements Action {
  public readonly type = ContextMenusActionTypes.LoadMainContextMenu;
}

export class LoadMainContextMenuSuccess implements Action {
  public readonly type = ContextMenusActionTypes.LoadMainContextMenuSuccess;
  public constructor(public payload: { mainContextMenu: ContextMenuDto }) {}
}

export class LoadGridColumnContextMenu implements Action {
  public readonly type = ContextMenusActionTypes.LoadGridColumnContextMenu;
}
export class LoadGridColumnContextMenuSuccess implements Action {
  public readonly type = ContextMenusActionTypes.LoadGridColumnContextMenuSuccess;
  public constructor(public payload: { viewId: string; contextMenu: ContextMenuDto }) {}
}

export class LoadSubgridGridColumnContextMenu implements Action {
  public readonly type = ContextMenusActionTypes.LoadSubgridGridColumnContextMenu;
  public constructor(public payload: { viewId: string; subgridId: string }) {}
}
export class LoadSubgridGridColumnContextMenuSuccess implements Action {
  public readonly type = ContextMenusActionTypes.LoadSubgridGridColumnContextMenuSuccess;
  public constructor(
    public payload: {
      viewId: string;
      subgridId: string;
      contextMenu: ContextMenuDto;
    },
  ) {}
}

export class LoadSubgridContextMenu implements Action {
  public readonly type = ContextMenusActionTypes.LoadSubgridContextMenu;
  public constructor(public payload: { viewId: string; subgridId: string }) {}
}

export class LoadSubgridContextMenuSuccess implements Action {
  public readonly type = ContextMenusActionTypes.LoadSubgridContextMenuSuccess;
  public constructor(
    public payload: {
      viewId: string;
      subgridId: string;
      contextMenu: ContextMenuDto;
    },
  ) {}
}

export class LoadDialogFormContextMenu implements Action {
  public readonly type = ContextMenusActionTypes.LoadDialogFormContextMenu;
  public constructor(public payload: { dialogId: string; formId: string; recordId?: string }) {}
}

export class LoadDialogFormContextMenuSuccess implements Action {
  public readonly type = ContextMenusActionTypes.LoadDialogFormContextMenuSuccess;
  public constructor(public payload: { dialogId: string; dialogFormContextMenu: ContextMenuDto }) {}
}

export class RemoveDialogFormContextMenu implements Action {
  public readonly type = ContextMenusActionTypes.RemoveDialogFormContextMenu;
  public constructor(public payload: { dialogId: string }) {}
}

export type ContextMenusActions =
  | ResetMainContextMenu
  | LoadMainContextMenu
  | LoadMainContextMenuSuccess
  | LoadGridColumnContextMenu
  | LoadGridColumnContextMenuSuccess
  | LoadSubgridGridColumnContextMenu
  | LoadSubgridGridColumnContextMenuSuccess
  | LoadSubgridContextMenu
  | LoadSubgridContextMenuSuccess
  | LoadDialogFormContextMenu
  | LoadDialogFormContextMenuSuccess
  | RemoveDialogFormContextMenu;
