<div *ngIf="entityViewWithTabs$ | async as entityViewWithTabs" class="subgrid-container">
  <div class="subgrid-toolbar">
    <div class="subgrid-title">
      <span
        *ngIf="hasCustomFilters; else notFiltered"
        [matTooltip]="'View.ExpanderWithAdditionalFilters' | translate"
        matTooltipPosition="below">
        {{ entityViewWithTabs.entityView.view.label }} *
      </span>
      <ng-template #notFiltered>
        <span>{{ entityViewWithTabs.entityView.view.label }}</span>
      </ng-template>
    </div>

    <div class="subgrid-tools-wrapper">
      <app-engine-view-toolbar
        [isCollapsed]="false"
        [settings]="settings$ | async"
        [viewTabs]="entityViewWithTabs.viewTabs"
        viewTabsSelectionMode="single"
        [globalSearch]="stateService.getViewState().globalSearch"
        (globalSearchChange)="onGlobalSearchChange($event)"
        (globalSearchVisibilityChange)="onGlobalSearchVisibilityChange($event)"
        (filtersVisibilityChange)="onFiltersVisibilityChange($event)"
        (viewTabsVisibilityChange)="onViewTabsVisibilityChange($event)"
        (refreshData)="onRefreshData()"
        (restoreSettings)="onRestoreSettings()"
        (navigateToSettings)="onNavigateToSettings()">
      </app-engine-view-toolbar>
    </div>
  </div>

  <app-engine-view
    #engineView
    [settings]="settings$ | async"
    [entityView]="entityViewWithTabs.entityView"
    [displayRows]="10"
    [selectedKeys]="data.selectedIds"
    [selectionMode]="data.selectionMode"
    (columnResize)="onColumnResize($event)"
    (rowDoubleClick)="onRowDoubleClick()"
    (pageSizeChange)="onPageSizeChange($event)">
  </app-engine-view>
</div>

<div mat-dialog-actions align="end">
  <button mat-button (click)="confirm()">
    {{ 'Dialog.OkButton' | translate }}
  </button>
  <button type="button" mat-button (click)="cancel()">
    {{ 'Dialog.CancelButton' | translate }}
  </button>
</div>
