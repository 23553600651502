import { UserProfileDto, UILanguageDto, CheckCurrentUserPermissionKeyResult } from '@core/services/api-clients';

export const FEATURE_NAME: string = 'user-feature';

export interface IUserState {
  isNotAuthorized: boolean;
  currentUser: UserProfileDto;
  userLanguage: UILanguageDto;
  uiLanguages: UILanguageDto[];
  currentUserPermissionKeyMap: {
    [permissionKeyId: string]: CheckCurrentUserPermissionKeyResult;
  };
}

export const initialUserState: IUserState = {
  isNotAuthorized: false,
  currentUser: null,
  userLanguage: null,
  uiLanguages: [],
  currentUserPermissionKeyMap: {},
};
