import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatLegacyMenu as MatMenu } from '@angular/material/legacy-menu';
import { EngineTheme } from '@core/layout/services/theme.service';

@Component({
  selector: 'app-theme-picker',
  templateUrl: './theme-picker.component.html',
  styleUrls: ['./theme-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'themeMenu',
})
export class ThemePickerComponent {
  @ViewChild(MatMenu, { static: true }) menu: MatMenu;
  @Input() selectedTheme: EngineTheme;
  EngineThemes = EngineTheme;

  @Output() themeSelected = new EventEmitter<EngineTheme>();

  selectTheme(theme) {
    this.themeSelected.emit(theme);
  }
}
