import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '@env';
import { GuidService } from './guid.service';

const ENGINE_ICON_PREFIX = 'Engine';

@Injectable({
  providedIn: 'root',
})
export class IconService {
  private _registeredIcons: { [url: string]: string } = {};

  constructor(private _iconRegistry: MatIconRegistry, private _sanitizer: DomSanitizer) {}

  registerIconByRelativeUrl(relativeUrl: string): string {
    return this.registerIconByUrl(`${environment.urls.ApiUrl}/Resource/WebResources/${relativeUrl}`);
  }

  registerIconByUrl(url: string): string {
    let iconName = this._registeredIcons[url] ?? null;
    if (!iconName) {
      iconName = this.generateIconNameBaseOnUrl(url);
      this.registerIcon(iconName, url);
    }
    return iconName;
  }

  private generateIconNameBaseOnUrl(url: string): string {
    let regex = new RegExp('.*/+(.*).svg');
    let regexResult = regex.exec(url);
    if (!regexResult?.length) {
      console.error(`'${url}' is invalid icon url.`);
    }
    let name = `${ENGINE_ICON_PREFIX}${regexResult[regexResult.length - 1]}`;
    if (Object.values(this._registeredIcons).some((n) => n == name)) {
      let guidName = GuidService.generateNew();
      console.error(`'${name}' is already registered. Guid value generated '${guidName}'.`);
      name = guidName;
    }
    return name;
  }

  private registerIcon(name: string, url: string): void {
    this._iconRegistry.addSvgIcon(name, this._sanitizer.bypassSecurityTrustResourceUrl(url));
    this._registeredIcons[url] = name;
  }
}
