<div class="main-menu-content" [ngClass]="{ expanded: showWideMenu$ | async }">
  <ng-container *ngIf="selectedMenuArea$ | async as selectedMenuArea">
    <app-main-menu-logo
     [isWide]="showWideMenu$ | async"
     [selectedMenuArea]="selectedMenuArea$ | async"
     [menuConfiguration]="menuConfiguration$ | async">
    </app-main-menu-logo>

    <app-main-menu-navigation
      [menuAreaId]="selectedMenuAreaId$ | async"
      [menuGroups]="menuGroups$ | async"
      [widgetScripts]="menuItems$ | async"
      [specialMenuGroups]="specialGroups$ | async"
      [selectedMenuGroupId]="selectedMenuGroupId$ | async"
      [selectedMenuItemId]="selectedMenuItemId$ | async"
      [isWide]="showWideMenu$ | async"
      [isAddFavoriteEnabled]="isFavoriteModeEnabled$ | async"
      [isPopupEnabled]="isMainMenuPopupEnabled$ | async"
      [favorites]="favorites"
      (menuGroupClick)="onMenuGroupClick($event)"
      (menuItemClick)="onMenuItemClick($event)"
      (menuItemAddedToFavorite)="addMenuItemToFavorite($event)"
      (menuItemRemovedFromFavorite)="removeMenuItemFromFavorite($event)">
    </app-main-menu-navigation>

    <app-main-menu-settings
      [isWide]="showWideMenu$ | async"
      [isAddFavoriteEnabled]="isFavoriteModeEnabled$ | async"
      [menuConfiguration]="menuConfiguration$ | async"
      (favoriteButtonClick)="toggleFavoriteMode()">
    </app-main-menu-settings>

    <app-main-menu-area-selector
      [menuAreas]="menuAreas$ | async"
      [selectedMenuArea]="selectedMenuArea$ | async"
      [isWide]="showWideMenu$ | async"
      (areaChanged)="onMenuAreaChange($event)">
    </app-main-menu-area-selector>

    <app-main-menu-expand-button [isWide]="showWideMenu$ | async" (toggleExpansion)="toggleExpansion()">
    </app-main-menu-expand-button>
  </ng-container>
</div>
