import { Injectable } from '@angular/core';
import moment from 'moment-timezone';
import { Moment } from 'moment-timezone';

@Injectable()
export class MomentService {
  constructor() { }

  setDefaultLocaleId(localeId: string) {
    moment.locale(localeId);
  }

  setDefaultTimezone(timezone: string) {
    if (timezone) {
      moment.tz.setDefault(timezone);
    }
  }

  now(): Moment {
    return moment();
  }

  createDate(
    year: number,
    month: number,
    day: number,
    hour: number,
    minute: number,
    second: number,
    timezone?: string,
  ): Moment {
    return moment.tz(
      {
        year: year,
        month: month,
        day: day,
        hour: hour,
        minute: minute,
        second: second,
      },
      timezone,
    );
  }

  parseFromDate(date: Date, timezone?: string): Moment {
    return this.parseFromTime(date ? date.getTime() : undefined, timezone);
  }

  parseFromTime(date: number, timezone?: string): Moment {
    return moment.tz(date, timezone);
  }

  parseFromString(date: string, dateFormat?: string, strict?: boolean, timezone?: string): Moment {
    return moment.tz(date, dateFormat, strict, timezone);
  }

  getTimezoneOffset(timezone: string): number {
    return moment.tz(timezone).utcOffset();
  }
}
