import { MSAL_INSTANCE } from '@azure/msal-angular';
import { IPublicClientApplication, PublicClientApplication, BrowserCacheLocation, LogLevel } from '@azure/msal-browser';
import { environment } from '@env';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export function authDetailedLoggerCallback(logLevel: LogLevel, message: string) {
  if (environment.msalModule.showDetailedAuthLogs) {
    console.log(`[Auth-${LogLevel[logLevel]}] ${message}`);
  }
}

const msalInstanceFactory = (): IPublicClientApplication => {
  const redirectUrl =
    environment.msalModule.redirectUri[0] == '/'
      ? window.location.origin + environment.msalModule.redirectUri
      : environment.msalModule.redirectUri;

  return new PublicClientApplication({
    auth: {
      clientId: environment.msalModule.clientID,
      authority: environment.msalModule.authority,
      redirectUri: redirectUrl,
      knownAuthorities: [environment.msalModule.authority.split('/')[2]],
      postLogoutRedirectUri: `/`,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
    system: {
      loggerOptions: {
        loggerCallback: authDetailedLoggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false,
      },
    },
  });
};

export const msalInstanceProvider = {
  provide: MSAL_INSTANCE,
  useFactory: msalInstanceFactory,
};
