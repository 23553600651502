import { ChangeDetectionStrategy, Component, forwardRef, HostListener, Inject, Injector, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { BaseFormControlDirective } from '../../directives/base-form-control.directive';
import { CULTURE_SERVICE, ICultureService } from '../../models/iculture-service.model';

@Component({
  selector: 'app-number-control',
  templateUrl: './number-control.component.html',
  styleUrls: ['./number-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumberControlComponent),
      multi: true,
    },
    {
      provide: BaseFormControlDirective,
      useExisting: NumberControlComponent,
    },
  ],
})
export class NumberControlComponent extends BaseFormControlDirective {
  static numberRegex = /^$|^[-+]?\d+$/;

  private _minValue: number = null;
  private _maxValue: number = null;
  private _onlyIntegers: boolean = false;
  private _latestValidValue: any = null;

  @HostListener('input', ['$event']) onInputChange(event: InputEvent) {
    if (this.onlyIntegers) {
      const inputElement = event.target as HTMLInputElement;

      const initialValue = inputElement.value + (event.data == '.' ? event.data : '');
      if (!NumberControlComponent.numberRegex.test(initialValue)) {
        inputElement.value = this._latestValidValue;
        this.writeValue(inputElement.value);

        event.preventDefault();
        event.stopPropagation();
      }
    }
  }

  @Input() textAlignStyle: any;

  @Input() set maxValue(value: number | undefined | null) {
    if (Number.isInteger(value)) {
      this._maxValue = value;
      this.addValidator('max', Validators.max(value));
    } else {
      this._maxValue = null;
      this.removeValidator('max');
    }
  }

  @Input() set minValue(value: number | undefined | null) {
    if (Number.isInteger(value)) {
      this._minValue = value;
      this.addValidator('min', Validators.min(value));
    } else {
      this._minValue = value;
      this.removeValidator('min');
    }
  }

  @Input() set onlyIntegers(value: boolean) {
    if (value) {
      this._onlyIntegers = value;
      this.addValidator('onlyIntegers', Validators.pattern(NumberControlComponent.numberRegex));
    } else {
      this._onlyIntegers = value;
      this.removeValidator('onlyIntegers');
    }
  }

  get maxValue() {
    return this._maxValue;
  }

  get minValue() {
    return this._minValue;
  }

  get onlyIntegers() {
    return this._onlyIntegers;
  }

  constructor(@Inject(CULTURE_SERVICE) cultureService: ICultureService, injector: Injector) {
    super(cultureService, injector);
  }

  override writeValue(value: any): void {
    super.writeValue(value);

    if (this.onlyIntegers) {
      if (NumberControlComponent.numberRegex.test(value)) {
        this._latestValidValue = value;
      }
    }
  }
}
