<ng-container *ngIf="entity && value">
  <div class="q-button-group q-right-align">
    <button mat-icon-button (click)="addFilter()">
      <mat-icon>add</mat-icon>
    </button>
    <button mat-icon-button (click)="addCompositeFilter()">
      <mat-icon>add_circle_outline</mat-icon>
    </button>
  </div>
  <mat-radio-group [value]="value.logic" (change)="onLogicChange($event.value)">
    <mat-radio-button *ngFor="let logicOperator of logicOperators" [value]="logicOperator.value">
      {{ logicOperator.label }}
    </mat-radio-button>
  </mat-radio-group>

  <div class="q-tree-container">
    <ul class="q-tree">
      <li class="q-node" *ngFor="let filter of value.filters; index as index">
        <app-query-composite-filter
          class="q-filter"
          *ngIf="isCompositeFilterDescriptor(filter)"
          [entity]="entity"
          [value]="mapToAny(filter)"
          (filterChange)="onInnerFilterChange(index, $event)"></app-query-composite-filter>
        <app-query-filter
          class="q-filter"
          *ngIf="!isCompositeFilterDescriptor(filter)"
          [entity]="entity"
          [value]="mapToAny(filter)"
          (filterChange)="onInnerFilterChange(index, $event)"></app-query-filter>
        <div class="q-button-group q-right-align">
          <button mat-icon-button (click)="removeInnerFilter(index)">
            <mat-icon>remove</mat-icon>
          </button>
        </div>
      </li>
    </ul>
  </div>
</ng-container>
