import { Directive, Inject, Injector, Input, Optional, SkipSelf } from '@angular/core';
import { AlignStyle, DateControlDto } from '@core/services/api-clients';
import { WidgetDirective } from '@core/widgets/directives/widget.directive';
import { IScriptRunnerService, SCRIPT_RUNNER_SERVICE } from '@core/widgets/models/iscript-runner.service';
import moment from 'moment';
import { takeUntil, tap } from 'rxjs/operators';
import { FormControlNotificationTemplates, IControlContext } from '../../../engine-sdk';
import { DateControlComponent } from '../../../shared/reactive-controls/components/date/date-control.component';
import { EngineFormControlDirective } from './engine-form-control.directive';

@Directive({
  selector: 'app-date-control[engineDateFormControl]',
  providers: [{ provide: EngineFormControlDirective, useExisting: EngineDateFormControlDirective }],
})
export class EngineDateFormControlDirective extends EngineFormControlDirective {
  protected get _dateBaseControl(): DateControlComponent {
    return this._baseControl as DateControlComponent;
  }

  @Input() set engineDateControlDefinition(definition: DateControlDto) {
    this.minValue = definition.minValue;
    this.maxValue = definition.maxValue;
    this.engineControlDefinition = definition;
    this.textAlignStyle = definition.textAlign;
  }

  @Input() set minValue(minValue: moment.Moment | Date) {
    this._dateBaseControl.minValue = minValue;
  }

  @Input() set maxValue(maxValue: moment.Moment | Date) {
    this._dateBaseControl.maxValue = maxValue;
  }

  @Input() set textAlignStyle(textAlign: AlignStyle) {
    this._dateBaseControl.textAlignStyle = this.getTextAlignStyle(textAlign);
  }

  get textAlignStyle(): any {
    return this._dateBaseControl.textAlignStyle;
  }

  get minValue(): moment.Moment | Date {
    return this._dateBaseControl.minValue;
  }

  get maxValue(): moment.Moment | Date {
    return this._dateBaseControl.maxValue;
  }

  get minFormattedDateValue() {
    return this.minValue ? moment(this.minValue).format('L') : this.minValue;
  }

  get maxFormattedDateValue() {
    return this.maxValue ? moment(this.maxValue).format('L') : this.maxValue;
  }

  constructor(
    @Optional() @SkipSelf() parentWidget: WidgetDirective,
    @Inject(SCRIPT_RUNNER_SERVICE) scriptRunnerService: IScriptRunnerService,
    injector: Injector,
  ) {
    super(parentWidget, scriptRunnerService, injector);

    this._baseControl
      .controlValueChanges()
      .pipe(
        takeUntil(this._destroy$),
        tap(() => this.refreshDateNotification(false)),
      )
      .subscribe();

    this._baseControl
      .focusout()
      .pipe(
        takeUntil(this._destroy$),
        tap(() => this.refreshDateNotification(true)),
      )
      .subscribe();
  }

  private refreshDateNotification(onFocusOut: boolean) {
    this.refreshControlMinDateNotification(this, onFocusOut);
    this.refreshControlMaxDateNotification(this, onFocusOut);
    this.refreshControlRangeDateNotification(this, onFocusOut);
  }

  private refreshControlMinDateNotification(control: IControlContext, onFocusOut: boolean) {
    if (!control || control.isReadOnly || !control.isVisible) return;
    if (
      control.hasError('date-minimum') &&
      !this._baseControl.hasValidator('dateMaximum') &&
      this._baseControl.formControl.touched
    ) {
      if (onFocusOut) {
        this._formNotificationService.addNotification(
          FormControlNotificationTemplates.MinValueValidation,
          control,
          this.minFormattedDateValue,
        );
      }
    } else {
      this._formNotificationService.removeNotification(
        FormControlNotificationTemplates.MinValueValidation,
        control,
        this.minFormattedDateValue,
      );
    }
  }

  private refreshControlMaxDateNotification(control: IControlContext, onFocusOut: boolean) {
    if (!control || control.isReadOnly || !control.isVisible) return;
    if (
      control.hasError('date-maximum') &&
      !this._baseControl.hasValidator('dateMinimum') &&
      this._baseControl.formControl.touched
    ) {
      if (onFocusOut) {
        this._formNotificationService.addNotification(
          FormControlNotificationTemplates.MaxValueValidation,
          control,
          this.maxFormattedDateValue,
        );
      }
    } else {
      this._formNotificationService.removeNotification(
        FormControlNotificationTemplates.MaxValueValidation,
        control,
        this.maxFormattedDateValue,
      );
    }
  }

  private refreshControlRangeDateNotification(control: IControlContext, onFocusOut: boolean) {
    if (!control || control.isReadOnly || !control.isVisible) return;
    if (
      (control.hasError('date-maximum') || control.hasError('date-minimum')) &&
      this._baseControl.hasValidator('dateMinimum') &&
      this._baseControl.hasValidator('dateMaximum') &&
      this._baseControl.formControl.touched
    ) {
      if (onFocusOut) {
        this._formNotificationService.addNotification(
          FormControlNotificationTemplates.RangeValueValidation,
          control,
          `${this.minFormattedDateValue} - ${this.maxFormattedDateValue}`,
        );
      }
    } else {
      this._formNotificationService.removeNotification(
        FormControlNotificationTemplates.RangeValueValidation,
        control,
        `${this.minFormattedDateValue} - ${this.maxFormattedDateValue}`,
      );
    }
  }

}
