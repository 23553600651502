import { Directive, ElementRef, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { bufferTime, distinctUntilChanged, map, takeUntil, tap } from 'rxjs/operators';

@Directive({
  selector: '[appContainerWidth]',
})
export class ContainerWidthDirective implements OnInit, OnDestroy {
  private _resize: ResizeObserver;
  private _resize$: Subject<any> = new Subject<any>();
  private _unsubscribe$: Subject<any> = new Subject<any>();
  @Output() containerWidth: EventEmitter<number> = new EventEmitter<number>();

  constructor(private _element: ElementRef) {}

  ngOnInit(): void {
    this.setResizeObserver();

    this._resize$
      .pipe(
        takeUntil(this._unsubscribe$),
        bufferTime(250),
        map(() => this._element.nativeElement.parentNode.clientWidth),
        distinctUntilChanged(),
        tap((freeSpace) => {
          this.containerWidth.emit(freeSpace);
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._resize.unobserve(this._element.nativeElement.parentNode);
    this._unsubscribe$.next(null);
    this._unsubscribe$.complete();
  }

  private setResizeObserver() {
    this._resize = new ResizeObserver(() => this._resize$.next(null));
    this._resize.observe(this._element.nativeElement.parentNode);
  }
}
