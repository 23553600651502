import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
@Component({
  selector: 'app-control-error',
  templateUrl: './control-error.component.html',
  styleUrls: ['./control-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ControlErrorComponent implements OnDestroy {
  private _control: UntypedFormControl;
  private _destroy$: Subject<boolean> = new Subject<boolean>();

  @Input() set control(v: UntypedFormControl) {
    this._control = v;
    if (this._control) {
      this._control.statusChanges
        .pipe(
          takeUntil(this._destroy$),
          tap(() => this._change.markForCheck()),
        )
        .subscribe();
    }
  }

  get control(): UntypedFormControl {
    return this._control;
  }

  /**
   * Gives possibility to offset error notification block from neighboring components. Useful in combination with Angular Material inputs.
   * Value should be in pixels.
   */
  @Input() offset: number;

  constructor(private _change: ChangeDetectorRef) {}

  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
  }
}
