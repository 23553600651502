import { MainMenuDto } from '@core/services/api-clients';

export const FEATURE_NAME: string = 'menu-feature';

export interface IMenuState {
  mainMenu: MainMenuDto;
  selectedMenuAreaId: string;
  selectedMenuGroupId: string;
  selectedMenuItemId: string;
  isPopupEnabled: boolean;
  isFavoriteModeEnabled: boolean;
}

export const initialMenuState: IMenuState = {
  mainMenu: null,
  selectedMenuAreaId: null,
  selectedMenuGroupId: null,
  selectedMenuItemId: null,
  isPopupEnabled: true,
  isFavoriteModeEnabled: false,
};
