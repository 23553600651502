<ng-container
  *ngIf="
    isNotAuthorized$ | async;
    then notAuthorized;
    else (isFatalError$ | async) ? fatalError : shell
  "></ng-container>

<ng-template #shell>
  <app-shell>
    <app-topbar topbar>
      <app-breadcrumb></app-breadcrumb>
      <app-user-topbar-section (avatarClicked)="openRightPanel()"></app-user-topbar-section>
    </app-topbar>

    <app-main-menu left-sidenav></app-main-menu>

    <ng-container right-sidenav>
      <app-user-panel>
        <app-user-menu additional-content></app-user-menu>
      </app-user-panel>
    </ng-container>

    <ng-container main-content>
      <router-outlet></router-outlet>
    </ng-container>
  </app-shell>
</ng-template>

<ng-template #notAuthorized>
  <app-not-authorized></app-not-authorized>
</ng-template>

<ng-template #fatalError>
  <app-fatal-errors></app-fatal-errors>
</ng-template>

<app-busy-indicator></app-busy-indicator>
