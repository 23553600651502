import { Action } from '@ngrx/store';
import { IError } from 'src/engine-sdk';

export enum ErrorsActionTypes {
  HandleError = '[Errors] Handle Error',
  AddFatalError = '[Errors] Add Fatal Error',
}

export class HandleError implements Action {
  public readonly type = ErrorsActionTypes.HandleError;
  constructor(public payload: { error: any }) {}
}

export class AddFatalError implements Action {
  public readonly type = ErrorsActionTypes.AddFatalError;
  constructor(public payload: { error: IError }) {}
}

export type ErrorActions = HandleError | AddFatalError;
