import { ChangeDetectionStrategy, Component, Input, OnDestroy } from '@angular/core';
import { NotificationsActions, NotificationsSelectors } from '@core/notification/store';
import { FormTabDto } from '@core/services/api-clients';
import { Store } from '@ngrx/store';
import { Subject, BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { NotificationGroup } from 'src/engine-sdk';

@Component({
  selector: 'app-form-tab-notification-badge',
  templateUrl: './form-tab-notification-badge.component.html',
  styleUrls: ['./form-tab-notification-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormTabNotificationBadgeComponent implements OnDestroy {
  private _destroy$: Subject<any> = new Subject<any>();
  private _formTab: FormTabDto;
  private _formTabAttributes$: BehaviorSubject<string[]>;

  @Input() set formTab(v: FormTabDto) {
    this._formTab = v;
    this._formTabAttributes$.next(this.getTabAttributes(this._formTab));
  }

  notificationCount$: Observable<number>;

  get formTab(): FormTabDto {
    return this._formTab;
  }

  constructor(private _store: Store) {
    this._formTabAttributes$ = new BehaviorSubject([]);
    this.notificationCount$ = combineLatest([
      this._store.select(NotificationsSelectors.getNotificationGroupsList),
      this._formTabAttributes$,
    ]).pipe(
      map(([notificationGroups, formAttributes]) => this.getTabNotificationsCount(formAttributes, notificationGroups)),
    );
  }

  ngOnDestroy(): void {
    this._destroy$.next(null);
    this._destroy$.complete();
  }

  onRemove(group) {
    this._store.dispatch(new NotificationsActions.RemoveNotificationGroup({ notificationGroup: group }));
  }

  private getTabNotificationsCount(attributes: string[], notificationGroups: NotificationGroup[]): number {
    const groups = notificationGroups
      .filter((e) => attributes.includes(e.name))
      .filter((e) => e.notifications.length !== 0);
    const notifications = groups.map((e) => e.notifications);
    const count = notifications.length !== 0 ? notifications.reduce((a, n) => a.concat(n)).length : 0;
    return count;
  }

  private getTabAttributes(tab: FormTabDto) {
    return [
      ...new Set(
        tab.controlGroups
          .map((e) => e.controls)
          .reduce((a, controls) => a.concat(controls))
          .map((e) => e.primaryAttribute),
      ),
    ];
  }
}
