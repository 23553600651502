import { Injectable } from '@angular/core';
import { FormDefinition } from '../components/engine-form/engine-form.model';
import { ControlDto } from '@core/services/api-clients';

@Injectable()
export class EngineFormDefinitionService {
  private _controlIdToControlDefinition: { [controlId: string]: ControlDto } = {};

  init(formDefinition: FormDefinition) {
    formDefinition.tabs.forEach((tab) => {
      tab.controlGroups.forEach((group) => {
        group.controls.forEach((control) => {
          this._controlIdToControlDefinition[control.id] = control;
        });
      });
    });
  }

  getControlDefinition(controlId: string): ControlDto {
    return this._controlIdToControlDefinition[controlId];
  }
}
