import { ValidatorFn, ValidationErrors, AbstractControl } from '@angular/forms';
import moment from 'moment';

export class DateValidators {
  static dateMinimum(date: Date | moment.Moment): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!date) return null;

      if (!this.isDate(control.value)) return null;

      const inputValue = moment(control.value);
      const minimumDate = moment(date);

      return minimumDate <= inputValue
        ? null
        : {
            'date-minimum': {
              'date-minimum': minimumDate.toLocaleString(),
              actual: inputValue.toLocaleString(),
            },
          };
    };
  }

  static dateMaximum(date: Date | moment.Moment): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!date) return null;

      if (!this.isDate(control.value)) return null;

      const inputValue = moment(control.value);
      const maximumDate = moment(date);

      return maximumDate >= inputValue
        ? null
        : {
            'date-maximum': {
              'date-maximum': maximumDate.toLocaleString(),
              actual: inputValue.toLocaleString(),
            },
          };
    };
  }

  private static isDate(value: any): boolean {
    return value instanceof Date || moment.isMoment(value);
  }
}
