import { Action } from '@ngrx/store';
import { NotificationGroup } from 'src/engine-sdk';

export enum NotificationsActionTypes {
  AddNotifications = '[Notifications] Add Notifications',
  SetNotifications = '[Notifications] Set Notifications',
  RemoveAllNotifications = '[Notifications] Remove All Notifications',
  RemoveNotifications = '[Notifications] Remove Notifications',
  RemoveNotificationGroup = '[Notifications] Remove Notification Group',
}

export class AddNotifications implements Action {
  public readonly type = NotificationsActionTypes.AddNotifications;
  constructor(public payload: { notificationGroups: NotificationGroup[] }) {}
}

export class SetNotifications implements Action {
  public readonly type = NotificationsActionTypes.SetNotifications;
  constructor(public payload: { notificationGroups: NotificationGroup[] }) {}
}

export class RemoveAllNotifications implements Action {
  public readonly type = NotificationsActionTypes.RemoveAllNotifications;
}

export class RemoveNotifications implements Action {
  public readonly type = NotificationsActionTypes.RemoveNotifications;
  constructor(public payload: { notificationGroup: NotificationGroup }) {}
}

export class RemoveNotificationGroup implements Action {
  public readonly type = NotificationsActionTypes.RemoveNotificationGroup;
  constructor(public payload: { notificationGroup: NotificationGroup }) {}
}

export type NotificationsActions =
  | AddNotifications
  | SetNotifications
  | RemoveNotifications
  | RemoveAllNotifications
  | RemoveNotificationGroup;
