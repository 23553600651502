import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class BusyIndicatorService {
  private _operationsInProgress = new BehaviorSubject(0);

  operationsInProgress$ = this._operationsInProgress.asObservable();

  newOperation() {
    this._operationsInProgress.next(this._operationsInProgress.getValue() + 1);
  }

  operationFinished() {
    this._operationsInProgress.next(this._operationsInProgress.getValue() - 1);
  }
}
