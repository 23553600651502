import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressBarComponent {
  private _min: number = 0;
  private _max: number = 100;
  private _value: number = 0;

  @Input() color: ThemePalette = 'primary';

  @Input() set min(min: number) {
    if (min != null) this._min = min;
  }

  @Input() set max(max: number) {
    if (max != null) this._max = max;
  }

  @Input() set value(value: number) {
    this._value = value;
  }

  get min() {
    return this._min;
  }

  get max() {
    return this._max - this.min;
  }

  get value() {
    return this._value - this._min;
  }

  constructor() {}
}
