import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Inject,
  Injector,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { BaseFormControlDirective } from '../../directives/base-form-control.directive';
import { CULTURE_SERVICE, ICultureService } from '../../models/iculture-service.model';
import { IAutocompleteOption } from '../autocomplete/autocomplete-control.model';

@Component({
  selector: 'app-chips-control',
  templateUrl: './chips-control.component.html',
  styleUrls: ['./chips-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ChipsControlComponent),
      multi: true,
    },
    {
      provide: BaseFormControlDirective,
      useExisting: ChipsControlComponent,
    },
  ],
})
export class ChipsControlComponent extends BaseFormControlDirective {
  private _chips: IAutocompleteOption[] = [];

  separatorKeysCodes: number[] = [ENTER, COMMA];

  @ViewChild('autocompleteInput') autocompleteInput: ElementRef<HTMLInputElement>;

  @Input() options: IAutocompleteOption[];
  @Input() isCreateButtonHidden: boolean;
  @Input() textAlignStyle: any;

  @Output() doubleClick = new EventEmitter<IAutocompleteOption>();
  @Output() removeClick = new EventEmitter<IAutocompleteOption>();
  @Output() addClick = new EventEmitter<any>();
  @Output() filterChange = new EventEmitter<string>();
  @Output() optionSelect = new EventEmitter<IAutocompleteOption>();

  @Input() set chips(v: IAutocompleteOption[]) {
    this._chips = v || [];
    this.formControl.setValue(this._chips.map((x) => x.value).join(';'));
  }

  get chips() {
    return this._chips;
  }

  constructor(@Inject(CULTURE_SERVICE) cultureService: ICultureService, injector: Injector) {
    super(cultureService, injector);
  }

  add(chip: IAutocompleteOption) {
    this.chips.push(chip);
    this.chips = this.chips;
  }

  remove(chipValue: string) {
    const index = this.chips.map((x) => x.value).indexOf(chipValue);
    if (index >= 0) {
      this.chips.splice(index, 1);
      this.chips = this.chips;
    }
  }

  onAdd(event: MatChipInputEvent): void {
    this.optionSelect.emit({ value: event.value, label: event.value });
    event.chipInput.clear();
    this.autocompleteInput.nativeElement.value = '';
  }

  onRemove(chip: IAutocompleteOption): void {
    this.removeClick.emit(chip);
  }

  onOptionSelected(event: MatAutocompleteSelectedEvent): void {
    const option = event.source.options.find((x) => x.value == event.option.value);
    if (option) {
      this.optionSelect.emit({ value: event.option.value, label: option._text.nativeElement.innerText });
      this.autocompleteInput.nativeElement.value = '';
    }
  }

  onFilterChange(event: any): void {
    if (event.target.value) {
      this.filterChange.emit(event.target.value);
    }
  }

  emitDoubleClick(chip: IAutocompleteOption) {
    this.doubleClick.emit(chip);
  }
}
