import { NgModule } from '@angular/core';
import { MainFormComponent } from './main-form.component';
import { SharedModule } from '@shared/shared.module';
import { WidgetsModule } from '@core/widgets/widgets.module';
import { EngineFormsModule } from '@core/engine-forms/engine-forms.module';
import { ContextMenuModule } from '@core/context-menu/context-menu.module';
import { NotificationModule } from '@core/notification/notification.module';
import { EngineProcessesModule } from '@core/engine-processes/engine-processes.module';

@NgModule({
  declarations: [MainFormComponent],
  imports: [SharedModule, WidgetsModule, EngineFormsModule, NotificationModule, ContextMenuModule, EngineProcessesModule],
  exports: [MainFormComponent],
})
export class MainFormModule { }
