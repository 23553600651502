import { Component, Input } from '@angular/core';
import { IconDto } from '@core/services/api-clients';

@Component({
  selector: 'app-icon-header',
  templateUrl: './icon-header.component.html',
  styleUrls: ['./icon-header.component.scss'],
})
export class IconHeaderComponent {
  @Input() icon: IconDto;
  @Input() title: string;
  @Input() subtitle: string;

  isHeaderVisible() {
    return !!(this.icon || this.title || this.subtitle);
  }
}
