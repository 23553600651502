import { NgModule, ModuleWithProviders } from '@angular/core';
import { AsyncWidgetComponent } from './components/async-widget.component';
import { NoEventWidgetDirective } from './directives/no-event-widget.directive';

@NgModule({
  imports: [],
  declarations: [AsyncWidgetComponent, NoEventWidgetDirective],
  exports: [AsyncWidgetComponent, NoEventWidgetDirective],
})
export class WidgetsModule {
  static forRoot(): ModuleWithProviders<WidgetsModule> {
    return {
      ngModule: WidgetsModule,
      providers: [],
    };
  }
}
