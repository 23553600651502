<ng-container>
  <div *ngIf="user" (mouseover)="isHovered = true" (mouseout)="isHovered = false">
    <img
      *ngIf="user?.avatar?.iconRelativePath; else avatar"
      [class.mat-elevation-z2]="!isHovered"
      [class.mat-elevation-z4]="isHovered"
      [attr.width]="size"
      [attr.height]="size"
      [src]="user?.avatar?.iconRelativePath | webResourceUri | async" />
    <ng-template #avatar>
      <div
        class="user-acronym"
        [class.mat-elevation-z2]="!isHovered"
        [class.mat-elevation-z4]="isHovered"
        [style.width.px]="size"
        [style.height.px]="size"
        [style.font-size.px]="size / 2.5"
        [style.background-color]="getUserColor()">
        {{ getUsernameAcronym() }}
      </div>
    </ng-template>
  </div>
</ng-container>
