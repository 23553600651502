import { ChangeDetectionStrategy, Component, forwardRef, Inject, Injector, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseFormControlDirective } from '../../directives/base-form-control.directive';
import { CULTURE_SERVICE, ICultureService } from '../../models/iculture-service.model';
import { IIcon } from '@shared/icon/icon.model';
import { IconMode, IconSize } from '../../../ui-components/components/img-icon/img-icon.component';

@Component({
  selector: 'app-icon-control',
  templateUrl: './icon-control.component.html',
  styleUrls: ['./icon-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IconControlComponent),
      multi: true,
    },
    {
      provide: BaseFormControlDirective,
      useExisting: IconControlComponent,
    },
  ],
})
export class IconControlComponent extends BaseFormControlDirective {
  @Input() url: string = null;
  @Input() icon: IIcon = null;
  @Input() iconMode: IconMode = 'icon';
  @Input() size: IconSize;

  constructor(@Inject(CULTURE_SERVICE) cultureService: ICultureService, injector: Injector) {
    super(cultureService, injector);
  }
}
