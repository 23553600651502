import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { LayoutActions, LayoutSelectors } from '../store';
import { Observable } from 'rxjs';
import { ScreenSize } from '../models/screen-size.enum';

@Injectable()
export class LayoutService {
  constructor(private _store: Store) {}

  openRightPanel() {
    this._store.dispatch(new LayoutActions.OpenRightPanel());
  }

  closeRightPanel() {
    this._store.dispatch(new LayoutActions.CloseRightPanel());
  }

  screenSizeChanged(): Observable<ScreenSize> {
    return this._store.select(LayoutSelectors.getScreenSize);
  }

  leftPanelSizeChanged(): Observable<boolean> {
    return this._store.select(LayoutSelectors.getIsLeftPanelExpanded);
  }

  leftPanelVisibilityChanged(): Observable<boolean> {
    return this._store.select(LayoutSelectors.getIsLeftPanelVisible);
  }
}
