import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormOption } from '@core/navigation/store/state';
import { BreadcrumbItem } from '../long-breadcrumb/breadcrumb-item/breadcrumb-item.component';

@Component({
  selector: 'app-short-breadcrumb',
  templateUrl: './short-breadcrumb.component.html',
  styleUrls: ['./short-breadcrumb.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShortBreadcrumbComponent {
  @Input() breadcrumbs: BreadcrumbItem[] = [];
  @Input() set forms(forms: FormOption[]) {
    this._forms = forms?.length ? forms : [];
    this.selectedForm = this._forms.length ? this._forms[0] : null;
  }
  @Output() breadcrumbClicked = new EventEmitter<BreadcrumbItem>();
  @Output() formSelected = new EventEmitter<string>();

  get forms(): FormOption[] {
    return this._forms;
  }
  selectedForm: FormOption = null;
  isMenuOpened: boolean = false;

  private _forms: FormOption[] = [];

  onOptionSelected(form: FormOption) {
    if (form && form.id !== this.selectedForm?.id) {
      this.selectedForm = form;
      this.formSelected.emit(form.id);
    }
  }
}
