import { Component, EventEmitter, forwardRef, Inject, Input, Optional, Output, SkipSelf } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { WidgetDirective } from '../directives/widget.directive';
import { delay } from 'rxjs/operators';
import { NoEventWidgetDirective } from '../directives/no-event-widget.directive';
import { SCRIPT_RUNNER_SERVICE, IScriptRunnerService } from '../models/iscript-runner.service';

@Component({
  selector: 'engine-async-widget',
  template: ``,
  providers: [
    {
      provide: WidgetDirective,
      useExisting: forwardRef(() => AsyncWidgetComponent),
    },
  ],
})
export class AsyncWidgetComponent extends NoEventWidgetDirective {
  private _isReady = new BehaviorSubject<boolean>(false);

  @Input() delay: number = 0;

  @Input() set isReady(v: any) {
    if (v) {
      this._isReady.next(true);
      this.ready.emit();
    }
  }
  @Output() ready = new EventEmitter<void>();

  constructor(
    @Optional() @SkipSelf() parentWidget: WidgetDirective,
    @Inject(SCRIPT_RUNNER_SERVICE) scriptRunnerService: IScriptRunnerService,
  ) {
    super(parentWidget, scriptRunnerService);
  }

  protected isWidgetInitiated(): Observable<boolean> {
    return this._isReady.asObservable().pipe(delay(this.delay));
  }
}
