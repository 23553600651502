<ng-container *ngIf="selectedGroup">
  <button
    type="button"
    mat-icon-button
    [disabled]="groups?.length < 2"
    (click)="onPrevClicked()"
    (pointerdown)="$event.preventDefault()">
    <mat-icon>arrow_left</mat-icon>
  </button>
  <div class="group-label mat-caption">{{ selectedGroup?.label ?? '' }}</div>
  <button
    type="button"
    mat-icon-button
    [disabled]="groups?.length < 2"
    (click)="onNextClicked()"
    (pointerdown)="$event.preventDefault()">
    <mat-icon>arrow_right</mat-icon>
  </button>
</ng-container>
