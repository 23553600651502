import * as _ from 'node_modules/lodash/index';

export class LodashService {
  constructor() {}

  static cloneDeep(obj: any): any {
    return _.cloneDeep(obj);
  }

  static groupBy(collection: any[], iteratee: any) {
    return _.groupBy(collection, iteratee);
  }

  static areEqual(objA: any, objB: any) {
    return _.isEqual(objA, objB);
  }
}
