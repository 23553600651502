import { Component, Input, ChangeDetectionStrategy, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { ProcessStateDto } from '@core/services/api-clients';
import { ProcessDefinitionService } from '@core/engine-processes/services/process-definition-service';
import { EngineViewComponent } from '@core/engine-views/components/engine-view/engine-view.component';

@Component({
  selector: 'engine-process-grid-cell',
  templateUrl: './engine-process-grid-cell.component.html',
  styleUrls: ['./engine-process-grid-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EngineProcessGridCellComponent {
  private _stateId: string;

  @Input()
  set stateId(value: string) {
    this._stateId = value;
    if (value) {
      this._processesService.loadProcessStateWithTransitions(value);
      this.currentState$ = this._processesService.getProcessState(value);
    }
  }

  @Input() attributeName: string;
  @Input() recordId: string;

  currentState$: Observable<ProcessStateDto>;

  get stateId(): string {
    return this._stateId;
  }

  constructor(
    private _processesService: ProcessDefinitionService,
    @Optional() private _engineView: EngineViewComponent,
  ) {}

  executeTransition(event: { currentProcessState: ProcessStateDto; targetStateId: string }) {
    let columnAttribute = this._engineView.entityView.view.entity.attributes.find(
      (x) => x.name == this.attributeName.substring(0, this.attributeName.length - 2),
    );
    this._processesService
      .executeTransition(event.targetStateId, this.recordId, columnAttribute.id)
      .subscribe(() => this._engineView.refreshData());
  }
}
