import { PortalModule } from '@angular/cdk/portal';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { WidgetsModule } from '@core/widgets/widgets.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SharedModule } from '@shared/shared.module';
import { MainMenuAreaSelectorComponent } from './main-menu/main-menu-area-selector/main-menu-area-selector.component';
import { MainMenuExpandButtonComponent } from './main-menu/main-menu-expand-button/main-menu-expand-button.component';
import { MainMenuLogoComponent } from './main-menu/main-menu-logo/main-menu-logo.component';
import { MainMenuNavigationComponent } from './main-menu/main-menu-navigation/main-menu-navigation.component';
import { MainMenuSettingsComponent } from './main-menu/main-menu-settings/main-menu-settings.component';
import { MainMenuComponent } from './main-menu/main-menu.component';
import { MenuGroupItemsComponent } from './shared/menu-group-items/menu-group-items.component';
import { MenuGroupComponent } from './shared/menu-group/menu-group.component';
import { MenuItemComponent } from './shared/menu-item/menu-item.component';
import { MenuPopupComponent } from './shared/menu-popup/menu-popup.component';
import { reducers } from './store';
import { MenuEffects } from './store/effects';
import { FEATURE_NAME } from './store/state';
import { EngineAboutDialogComponent } from './user-menu/engine-about-dialog/engine-about-dialog.component';
import { LanguagePickerComponent } from './user-menu/language-picker/language-picker.component';
import { ThemePickerComponent } from './user-menu/theme-picker/theme-picker.component';
import { UserMenuComponent } from './user-menu/user-menu.component';

@NgModule({
  declarations: [
    UserMenuComponent,
    LanguagePickerComponent,
    EngineAboutDialogComponent,
    MainMenuComponent,
    MenuItemComponent,
    MenuGroupComponent,
    MenuGroupItemsComponent,
    MenuPopupComponent,
    MainMenuAreaSelectorComponent,
    MainMenuExpandButtonComponent,
    MainMenuLogoComponent,
    MainMenuNavigationComponent,
    MainMenuSettingsComponent,
    ThemePickerComponent,
  ],
  imports: [
    StoreModule.forFeature(FEATURE_NAME, reducers),
    EffectsModule.forFeature([MenuEffects]),
    PortalModule,
    SharedModule,
    WidgetsModule,
  ],
  exports: [MainMenuComponent, UserMenuComponent],
})
export class MenuModule {
  static forRoot(): ModuleWithProviders<MenuModule> {
    return {
      ngModule: MenuModule,
      providers: [],
    };
  }
}
