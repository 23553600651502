import { Injectable } from '@angular/core';
import { ButtonDto, ContextMenuDto, SectionDto } from '@core/services/api-clients';
import { LodashService } from '@core/services/lodash.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, filter, map } from 'rxjs/operators';
import {
  IContextMenuButtonDefinition,
  IContextMenuDefinition,
  IContextMenuSectionDefinition,
} from './models/context-menu-definition.model';
import {
  DisplayMode,
  IContextMenuButtonState,
  IContextMenuSectionState,
  IContextMenuState,
} from './models/context-menu-state.model';

@Injectable()
export class ContextMenuService {
  private _definition$: BehaviorSubject<IContextMenuDefinition> = new BehaviorSubject(undefined);
  private _initialState = { displayMode: 'normal' } as IContextMenuState;
  private _state$: BehaviorSubject<IContextMenuState> = new BehaviorSubject(undefined);
  private _buttonNameToButtonId = {};

  constructor() {}

  isInitiated(): boolean {
    return !!this._state$.value;
  }

  updateInitialState(initialState: IContextMenuState) {
    this._initialState = { ...this._initialState, ...initialState };
  }

  init(dto: ContextMenuDto) {
    const definition = this.mapToDefinition(dto);
    this._definition$.next(definition);

    let state = this.mapToState(dto);
    state = { ...state, ...this._initialState };
    this._state$.next(state);

    // reset dictionary
    this._buttonNameToButtonId = {};
    Object.values(state.buttons).forEach((b) => (this._buttonNameToButtonId[b.name] = b.id));
  }

  getDefinitionAsync(): Observable<IContextMenuDefinition> {
    return this._definition$.asObservable().pipe(
      filter((state) => !!state),
      map((state) => LodashService.cloneDeep(state)),
      debounceTime(0),
    );
  }

  getDefinition(): IContextMenuDefinition {
    return LodashService.cloneDeep(this._definition$.value);
  }

  getStateAsync(): Observable<IContextMenuState> {
    return this._state$.asObservable().pipe(
      filter((state) => !!state),
      debounceTime(0),
      map((state) => this.mapToFinalState(state)),
    );
  }

  getState(): IContextMenuState {
    return this._state$.value ? this.mapToFinalState(this._state$.value) : undefined;
  }

  getButtonIdByName(name: string): string {
    return this._buttonNameToButtonId[name];
  }

  setIsDisabled(value: boolean): void {
    const newState = { ...this._state$.value };
    if (newState.isVisible == value) return;
    newState.isVisible = value;
    this._state$.next({ ...newState });
  }

  setDisplayMode(mode: DisplayMode): void {
    const newState = { ...this._state$.value };
    if (newState.displayMode == mode) return;
    newState.displayMode = mode;
    this._state$.next({ ...newState });
  }

  setButtonIsVisible(id: string, value: boolean): void {
    const newButtons = { ...this._state$.value.buttons };
    const button = { ...newButtons[id] };
    if (button.isVisible == value) return;
    button.isVisible = value;
    newButtons[id] = button;
    this._state$.next({ ...this._state$.value, buttons: newButtons });
  }

  setButtonIsDisabled(id: string, value: boolean): void {
    const newButtons = { ...this._state$.value.buttons };
    const button = { ...newButtons[id] };
    if (button.isDisabled == value) return;
    button.isDisabled = value;
    newButtons[id] = button;
    this._state$.next({ ...this._state$.value, buttons: newButtons });
  }

  setButtonLabel(id: string, value: string): void {
    const newButtons = { ...this._state$.value.buttons };
    const button = { ...newButtons[id] };
    if (button.label == value) return;
    button.label = value;
    newButtons[id] = button;
    this._state$.next({ ...this._state$.value, buttons: newButtons });
  }

  setButtonIcon(id: string, fontIconName?: string, iconRelativePath?: string): void {
    const newButtons = { ...this._state$.value.buttons };
    const button = { ...newButtons[id] };
    if (button.fontIconName == fontIconName && button.iconRelativePath == iconRelativePath) return;
    button.fontIconName = fontIconName;
    button.iconRelativePath = iconRelativePath;
    newButtons[id] = button;
    this._state$.next({ ...this._state$.value, buttons: newButtons });
  }


  private mapToFinalState(state: IContextMenuState): IContextMenuState {
    const calculatedState = LodashService.cloneDeep(state);
    let isAnySectionVisible = false;
    Object.values(state.sections).forEach((section) => {
      let isAnyButtonVisible = false;
      const sectionButtons = Object.values(state.buttons).filter((b) => b.sectionId == section.id);
      sectionButtons.forEach((button) => {
        isAnyButtonVisible = isAnyButtonVisible || button.isVisible;
        calculatedState.buttons[button.id].isDisabled = state.isDisabled || section.isDisabled || button.isDisabled;
      });

      const isSectionVisible = section.isVisible && isAnyButtonVisible;
      const isSectionDisabled = state.isDisabled || section.isDisabled;
      const isSectionHighlighted = state.isVisible && section.isHighlighted;
      calculatedState.sections[section.id].isVisible = isSectionVisible;
      calculatedState.sections[section.id].isDisabled = isSectionDisabled;
      calculatedState.sections[section.id].isHighlighted = isSectionHighlighted;

      isAnySectionVisible = isAnySectionVisible || isSectionVisible;
    });
    state.isVisible = state.isVisible && isAnySectionVisible;
    return state;
  }

  private mapToState(menu: ContextMenuDto): IContextMenuState {
    const state = {
      isVisible: true,
      isDisabled: false,
      sections: {},
      buttons: {},
    } as IContextMenuState;
    menu.sections.forEach((section) => {
      state.sections[section.id] = this.mapToSectionState(section);
      section.buttons.forEach((b) => {
        state.buttons[b.id] = this.mapToButtonState(b, section.id);
      });
    });
    return state;
  }

  private mapToSectionState(section: SectionDto): IContextMenuSectionState {
    return {
      id: section.id,
      label: section.shortName,
      isVisible: true,
      isDisabled: false,
      isExpandable: section.isExpandable ?? false,
      isHighlighted: section.isHighlighted ?? false,
      fontIconName: section.icon?.fontIconName,
      iconRelativePath: section.icon?.iconRelativePath,
    } as IContextMenuSectionState;
  }

  private mapToButtonState(button: ButtonDto, sectionId: string): IContextMenuButtonState {
    return {
      id: button.id,
      sectionId: sectionId,
      name: button.name,
      label: button.label,
      tooltipText: button.tooltipText,
      isVisible: !button.isHidden,
      isDisabled: false,
      isHighlighted: button.isHighlighted ?? false,
      fontIconName: button.icon?.fontIconName,
      iconRelativePath: button.icon?.iconRelativePath,
    } as IContextMenuButtonState;
  }

  private mapToDefinition(dto: ContextMenuDto): IContextMenuDefinition {
    const definition = {
      useDivider: dto.useDivider,
      sections: [],
    } as IContextMenuDefinition;
    dto.sections.forEach((section) => {
      definition.sections.push(this.mapToSectionDefinition(section));
    });
    return definition;
  }

  private mapToSectionDefinition(section: SectionDto): IContextMenuSectionDefinition {
    const sectionDefinition = { id: section.id, buttons: [] } as IContextMenuSectionDefinition;
    section.buttons.forEach((b) => {
      sectionDefinition.buttons.push(this.mapToButtonDefinition(b, section.id));
    });
    return sectionDefinition;
  }

  private mapToButtonDefinition(b: ButtonDto, sectionId: string): IContextMenuButtonDefinition {
    return {
      id: b.id,
      sectionId: sectionId,
      name: b.name,
      scripts: b.uiScripts,
    } as IContextMenuButtonDefinition;
  }
}
