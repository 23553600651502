import { Pipe, PipeTransform } from "@angular/core";
import { GridColumn } from "../models/grid-definition.model";
import { LookupPipe } from "./lookup.pipe";

@Pipe({
  name: 'optionSetColor',
})
export class OptionSetColorPipe implements PipeTransform {
  private _lookupPipe: LookupPipe = new LookupPipe();

  transform(dataItem: any, column: GridColumn): string {
    const value = this._lookupPipe.transform(dataItem, column);

    if (!value) return;

    const option = column.optionSet.values.find((e) => e.name === value);
    return option && option.color ? option.color : '';
  }
}
