import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MenuConfigurationDto } from '../../../../core/services/api-clients';

@Component({
  selector: 'app-main-menu-settings',
  templateUrl: './main-menu-settings.component.html',
  styleUrls: ['./main-menu-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainMenuSettingsComponent {
  @Input() isWide: boolean = false;
  @Input() isAddFavoriteEnabled: boolean = false;
  @Input() menuConfiguration: MenuConfigurationDto;

  @Output() favoriteButtonClick = new EventEmitter<void>();
}
