import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { ProcessEntityAssignmentDto, ProcessInstanceHistoryDto, ProcessStateDto } from '@core/services/api-clients';
import * as _ from 'lodash';
@Component({
  selector: 'engine-process',
  templateUrl: './engine-process.component.html',
  styleUrls: ['./engine-process.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EngineProcessComponent {
  private _currentProcessState: ProcessStateDto = null;

  @Input()
  processAssignments: ProcessEntityAssignmentDto[];

  @Input()
  public set currentProcessState(value: ProcessStateDto) {
    this._currentProcessState = value;
  }

  @Input()
  public historyStatesData: ProcessInstanceHistoryDto[];

  @Output() selectProcessAssignment = new EventEmitter<ProcessEntityAssignmentDto>();
  @Output() executeProcessTransition = new EventEmitter<{
    currentProcessState: ProcessStateDto;
    targetStateId: string;
  }>();
  @Output() openHistoryItem = new EventEmitter<ProcessInstanceHistoryDto>();
  @Output() openCurrentItem = new EventEmitter<ProcessStateDto>();

  get currentProcessState(): ProcessStateDto {
    return this._currentProcessState;
  }

  onOpenHistoryItem(arg: ProcessInstanceHistoryDto) {
    this.openHistoryItem.emit(arg);
  }

  onProcessAssignmentChange(value: ProcessEntityAssignmentDto) {
    this.selectProcessAssignment.emit(value);
  }
}
