import { GridColumnsSettings } from '@shared/grid/models/grid-definition.model';

export interface GridSettings {
  areFiltersEnabled: boolean;
  isGlobalSearchEnabled: boolean;
  columnsSettings: GridColumnsSettings;
}

export class EngineViewSettingsService {
  static getGridSettings(viewId: string, userId: string): GridSettings {
    const key = EngineViewSettingsService.getGridSettingsKey(viewId, userId);

    if (localStorage[key]) {
      return JSON.parse(localStorage[key]) as GridSettings;
    }

    return {
      areFiltersEnabled: false,
      isGlobalSearchEnabled: false,
      columnsSettings: {},
    };
  }

  static saveGridSettings(viewId: string, userId: string, gridSettings: GridSettings) {
    const key = EngineViewSettingsService.getGridSettingsKey(viewId, userId);
    localStorage[key] = JSON.stringify(gridSettings);
  }

  static clearGridSettings(viewId: string, userId: string) {
    const key = EngineViewSettingsService.getGridSettingsKey(viewId, userId);
    localStorage.removeItem(key);
  }

  private static getGridSettingsKey(viewId: string, userId: string) {
    return `GridSettings_${viewId}_${userId}`;
  }
}
