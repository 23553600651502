import { ModuleWithProviders, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { reducers } from './store';
import { FEATURE_NAME } from './store/state';
import { ContextMenusEffects } from './store/effects';
import { MainContextMenuComponent } from './main-context-menu/main-context-menu.component';
import { SharedModule } from '@shared/shared.module';
import { ContextMenuComponent } from './context-menu/context-menu.component';
import { ContextMenuButtonComponent } from './context-menu/context-menu-button/context-menu-button.component';
import { ContextMenuSectionComponent } from './context-menu/context-menu-section/context-menu-section.component';

@NgModule({
  declarations: [
    MainContextMenuComponent,
    ContextMenuComponent,
    ContextMenuSectionComponent,
    ContextMenuButtonComponent,
  ],
  imports: [
    StoreModule.forFeature(FEATURE_NAME, reducers),
    EffectsModule.forFeature([ContextMenusEffects]),
    SharedModule,
  ],
  exports: [MainContextMenuComponent, ContextMenuComponent],
})
export class ContextMenuModule {
  static forRoot(): ModuleWithProviders<ContextMenuModule> {
    return {
      ngModule: ContextMenuModule,
      providers: [],
    };
  }
}
