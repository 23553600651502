import { Injectable } from '@angular/core';
import { environment } from '@env';
import { Observable, of } from 'rxjs';
import { EngineFormComponent } from '../components/engine-form/engine-form.component';
import { IEngineFormService } from '../models/iengine-form-service.model';

@Injectable()
export class DefaultEngineFormService implements IEngineFormService {
  constructor() {}

  save(engineForm: EngineFormComponent): Observable<any> {
    if (!environment.production) {
      console.warn(
        `"${engineForm.definition.name} ?? 'Unnamed'" form use default EngineFormService. You can override it.`,
      );
    }
    return of();
  }

  saveAndNew(engineForm: EngineFormComponent): Observable<any> {
    if (!environment.production) {
      console.warn(
        `"${engineForm.definition.name} ?? 'Unnamed'" form use default EngineFormService. You can override it.`,
      );
    }
    return of();
  }

  saveAndClose(engineForm: EngineFormComponent): Observable<any> {
    if (!environment.production) {
      console.warn(
        `"${engineForm.definition.name} ?? 'Unnamed'" form use default EngineFormService. You can override it.`,
      );
    }
    return of();
  }

  refetch(engineForm: EngineFormComponent): Observable<any> {
    if (!environment.production) {
      console.warn(
        `"${engineForm.definition.name} ?? 'Unnamed'" form use default EngineFormService. You can override it.`,
      );
    }
    return of();
  }
}
