import {
  ChangeDetectorRef,
  Directive,
  EventEmitter,
  InjectionToken,
  Injector,
  Input,
  Output,
  inject,
} from '@angular/core';
import { ControlContainer, ControlValueAccessor, FormControl, Validators } from '@angular/forms';

export interface IReactiveControl<T> extends ControlValueAccessor {
  readonly formControlName: string;
  readonly isRequired: boolean;
  readonly isValid: boolean;
  readonly isTouched: boolean;
  onBlur(): void;
  onFocus(): void;
  changeValue(value: T): void;
}

export const REACTIVE_CONTROL = new InjectionToken<IReactiveControl<any>>('IReactiveControl');

@Directive({
  selector: '[]',
})
export class ReactiveControlDirective<T> implements ControlValueAccessor {
  private _injector = inject(Injector);
  private _cd = inject(ChangeDetectorRef);
  private _controlContainer: ControlContainer | null;
  private _onChange: (newValue: T) => void = () => {};
  private _onTouched: () => void = () => {};
  private _value: T = null;

  @Input() formControlName: string = '';
  @Input()
  get value(): T {
    return this._value;
  }
  set value(v: T) {
    this._value = v;
    this._onChange(this.value);
  }
  @Input() disabled: boolean = false;
  @Output() change = new EventEmitter<T>();

  get formControl(): FormControl | null | undefined {
    let formControl = this._controlContainer?.control!.get(this.formControlName);
    return formControl as FormControl;
  }
  get isRequired(): boolean {
    return !!this.formControl && this.formControl!.hasValidator(Validators.required);
  }
  get isValid(): boolean {
    return !!this.formControl && this.formControl!.valid;
  }
  get isTouched(): boolean {
    return !!this.formControl && this.formControl!.touched;
  }

  constructor() {
    this._controlContainer = this._injector.get(ControlContainer, null, { skipSelf: true, host: true, optional: true });
  }

  writeValue(obj: T): void {
    this._value = obj;
    this._cd.markForCheck();
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this._cd.markForCheck();
  }

  changeValue(value: T): void {
    if (!this.disabled) {
      this.value = value;
      this._onChange(this.value);
      this.change.emit(value);
    }
  }

  markAsTouched(): void {
    this._onTouched();
  }
}
