import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { KanbanColumnAggregatorDto, KanbanColumnDto, KanbanControlDto } from '@core/services/api-clients';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { KanbanColumnData } from '@app/main-kanban/store/state';

@Component({
  selector: 'app-kanban-column',
  templateUrl: './kanban-column.component.html',
  styleUrls: ['./kanban-column.component.scss'],
  // drag & drop po evencie mi nie działa z tym
  //changeDetection: ChangeDetectionStrategy.OnPush
})
export class KanbanColumnComponent {
  private _kanbanColumn: KanbanColumnDto;

  get kanbanColumn(): KanbanColumnDto {
    return this._kanbanColumn;
  }

  @Input() set kanbanColumn(value: KanbanColumnDto) {
    this._kanbanColumn = value;
    this.isExpanded = this._kanbanColumn ? !this._kanbanColumn.isDefaultMinimalized : false;
  }

  @Input() kanbanControls: KanbanControlDto[];
  @Input() kanbanColumnData: KanbanColumnData;
  @Input() kanbanColumnIds: string[];

  @Output() drop = new EventEmitter<CdkDragDrop<string[]>>();
  @Output() tileDoubleClick = new EventEmitter<any>();
  @Output() loadMore = new EventEmitter<KanbanColumnDto>();
  @Output() aggregatorSelect = new EventEmitter<{
    kanbanColumn: KanbanColumnDto;
    kanbanColumnAggregator: KanbanColumnAggregatorDto;
  }>();
  @Output() isExpandedChange = new EventEmitter<{ column: KanbanColumnDto; isExpanded: boolean }>();

  @HostBinding('style') get style() {
    return `
      border-left-color: ${this.kanbanColumn.htmlColor};
      width: ${this.getWidth()};
      max-width: ${this.getWidth()};
    `;
  }

  isExpanded = true;

  getWidth() {
    return this.isExpanded ? '300px' : '60px';
  }

  onDropTile(event: CdkDragDrop<string[]>) {
    this.drop.emit(event);
    this.isExpanded = true;
  }
}
