<div *ngIf="formControl" class="control-input-with-buttons">
  <mat-form-field class="control-input">
    <input [formControl]="formControl"
           [min]="minValue"
           [max]="maxValue"
           (blur)="onBlur()"
           matInput
           [id]="id"
           [name]="name"
           [placeholder]="label"
           [owlDateTime]="datePicker"
           [owlDateTimeTrigger]="datePicker"
           [ngStyle]="textAlignStyle" />
    <owl-date-time #datePicker
                   pickerType="calendar"
                   [firstDayOfWeek]="firstDayOfWeek"
                   [startAt]="minDate"
                   [endAt]="maxDate"
                   (blur)="onBlur()">
    </owl-date-time>
    <button type="button"
            tabindex="-1"
            *ngIf="!formControl.disabled"
            matSuffix
            mat-icon-button
            (click)="clear()"
            class="action-button visible-on-edit-mode">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>
</div>
<app-control-error [control]="formControl" [offset]="-16"></app-control-error>
