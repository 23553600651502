<app-breadcrumb-item [level]="0" (onClick)="homeClicked.emit()">
  <mat-icon breadcrumb-item-prefix> home </mat-icon>
</app-breadcrumb-item>

<app-breadcrumb-item
  *ngFor="let breadcrumb of breadcrumbs; let i = index"
  [level]="i + 1"
  [item]="breadcrumb"
  (onClick)="breadcrumbClicked.emit($event)">
  {{ breadcrumb.label }}
</app-breadcrumb-item>

<app-breadcrumb-item *ngIf="forms?.length" [level]="breadcrumbs.length + 1">
  <app-form-selector breadcrumb-item-prefix [forms]="forms" (formSelected)="formSelected.emit($event)">
  </app-form-selector>
</app-breadcrumb-item>
