import { Component, ViewChild, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { MatLegacyMenu as MatMenu } from '@angular/material/legacy-menu';
import { UILanguageDto } from '@core/services/api-clients';

@Component({
  selector: 'app-language-picker',
  templateUrl: './language-picker.component.html',
  styleUrls: ['./language-picker.component.scss'],
  exportAs: 'languageMenu',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguagePickerComponent {
  @ViewChild(MatMenu, { static: true }) menu: MatMenu;
  @Input() languages: UILanguageDto[];
  @Input() selectedLanguage: UILanguageDto;

  @Output() languageSelected = new EventEmitter<UILanguageDto>();

  selectLanguage(language) {
    this.languageSelected.emit(language);
  }
}
