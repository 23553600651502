import { UserActions, UserSelectors } from '@core/user/store';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';
import { ICheckCurrentUserPermissionKeyResult, IPermissionContext, IRole } from 'src/engine-sdk';

export class PermissionContext implements IPermissionContext {
  constructor(private _store: Store) {}

  getCurrentUserRoles(): Observable<IRole[]> {
    return this._store.select(UserSelectors.getCurrentUserRoles).pipe(map((x) => x as IRole[]));
  }

  getCurrentUserPermissionKey(): Observable<string> {
    return this._store.select(UserSelectors.getCurrentUserPermissionKey);
  }

  checkCurrentUserPermissionKey(permissionKey: string): Observable<ICheckCurrentUserPermissionKeyResult> {
    this._store.dispatch(new UserActions.CheckCurrentUserPermissionKey({ permissionKeyId: permissionKey }));

    return this._store.select(UserSelectors.checkCurrentUserPermissionKey(permissionKey)).pipe(
      filter((x) => x !== undefined),
      first(),
      map((x) => x as ICheckCurrentUserPermissionKeyResult),
    );
  }
}
