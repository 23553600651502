import { Directive, Inject, Injector, Input, Optional, SkipSelf } from '@angular/core';
import { AlignStyle, HyperlinkControlDto, LinkBehavior } from '@core/services/api-clients';
import { WidgetDirective } from '@core/widgets/directives/widget.directive';
import { SCRIPT_RUNNER_SERVICE, IScriptRunnerService } from '@core/widgets/models/iscript-runner.service';
import { HyperlinkBehavior } from '@shared/hyperlink/hyperlink.model';
import { HyperlinkControlComponent } from '@shared/reactive-controls/components/hyperlink/hyperlink-control.component';
import { EngineFormControlDirective } from './engine-form-control.directive';

@Directive({
  selector: 'app-hyperlink-control[engineHyperlinkFormControl]',
  providers: [{ provide: EngineFormControlDirective, useExisting: EngineHyperlinkFormControlDirective }],
})
export class EngineHyperlinkFormControlDirective extends EngineFormControlDirective {
  protected get _hyperlinkBaseControl(): HyperlinkControlComponent {
    return this._baseControl as HyperlinkControlComponent;
  }

  @Input() set engineHyperlinkControlDefinition(definition: HyperlinkControlDto) {
    this.linkBehavior = definition.linkBehavior;
    this.engineControlDefinition = definition;
    this.textAlignStyle = definition.textAlign;
  }
  @Input() set linkBehavior(linkBehavior: LinkBehavior) {
    this._hyperlinkBaseControl.linkBehavior = this.mapLinkBehavior(linkBehavior);
  }

  @Input() set textAlignStyle(textAlign: AlignStyle) {
    this._hyperlinkBaseControl.textAlignStyle = this.getTextAlignStyle(textAlign);
  }

  get textAlignStyle(): any {
    return this._hyperlinkBaseControl.textAlignStyle;
  }

  constructor(
    @Optional() @SkipSelf() parentWidget: WidgetDirective,
    @Inject(SCRIPT_RUNNER_SERVICE) scriptRunnerService: IScriptRunnerService,
    injector: Injector,
  ) {
    super(parentWidget, scriptRunnerService, injector);
  }

  private mapLinkBehavior(linkBehavior: LinkBehavior): HyperlinkBehavior {
    switch (linkBehavior) {
      case LinkBehavior.OpenInSamePage:
        return HyperlinkBehavior.OpenInSamePage;
      case LinkBehavior.OpenInNewTab:
        return HyperlinkBehavior.OpenInNewTab;
      case LinkBehavior.OpenInNewWindow:
        return HyperlinkBehavior.OpenInNewWindow;
      default:
        return undefined;
    }
  }
}
