<div style="display: flex; flex-direction: column; gap: 8px; width: 100%">

  <app-time-filter-menu-input [operators]="operators"
                              [column]="column"
                              [filter]="firstFilterDescriptor"
                              [filterService]="filterService"
                              [maxPrecision]="maxPrecision"
                              (valueChange)="onFirstValueChange($event)">
  </app-time-filter-menu-input>

  <div style="display: flex; flex-direction: column;">
    <kendo-dropdownlist class="k-filter-and"
                        [data]="logicOperators"
                        [value]="logicOperator"
                        [textField]="'text'"
                        [valueField]="'value'"
                        (valueChange)="onLogicOperatorChange($event)">
    </kendo-dropdownlist>
  </div>

  <app-time-filter-menu-input [operators]="operators"
                              [column]="column"
                              [filter]="secondFilterDescriptor"
                              [filterService]="filterService"
                              [maxPrecision]="maxPrecision"
                              (valueChange)="onSecondValueChange($event)">
  </app-time-filter-menu-input>

</div>
