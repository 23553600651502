<mat-nav-list *ngIf="specialMenuGroups?.length > 0">
  <app-menu-group
    *ngFor="let menuGroup of specialMenuGroups"
    [menuGroup]="menuGroup"
    [selectedMenuItemId]="selectedMenuItemId"
    [favorites]="favorites"
    [isSelected]="menuGroup.id == selectedMenuGroupId"
    [isWide]="isWide"
    [isSpecial]="true"
    [isPopupEnabled]="isPopupEnabled"
    [isFavoriteEnabled]="isAddFavoriteEnabled"
    (menuGroupClick)="menuGroupClick.emit($event)"
    (menuItemClick)="menuItemClick.emit($event)"
    (menuItemAddedToFavorite)="menuItemAddedToFavorite.emit($event)"
    (menuItemRemovedFromFavorite)="menuItemRemovedFromFavorite.emit($event)">
  </app-menu-group>
</mat-nav-list>

<mat-nav-list *ngIf="menuGroups?.length > 0">
  <app-menu-group
    *ngFor="let menuGroup of menuGroups"
    [menuGroup]="menuGroup"
    [selectedMenuItemId]="selectedMenuItemId"
    [favorites]="favorites"
    [isSelected]="menuGroup.id == selectedMenuGroupId"
    [isWide]="isWide"
    [isPopupEnabled]="isPopupEnabled"
    [isFavoriteEnabled]="isAddFavoriteEnabled"
    (menuGroupClick)="menuGroupClick.emit($event)"
    (menuItemClick)="menuItemClick.emit($event)"
    (menuItemAddedToFavorite)="menuItemAddedToFavorite.emit($event)"
    (menuItemRemovedFromFavorite)="menuItemRemovedFromFavorite.emit($event)">
  </app-menu-group>
</mat-nav-list>
