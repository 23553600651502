<mat-select
  #select
  *ngIf="aggregators?.length > 1 && aggregatorsData"
  class="aggregators-selector"
  [value]="aggregators[0]"
  [matTooltip]="select.value.tooltip"
  (selectionChange)="aggregatorSelect.emit(select.value)">
  <mat-option *ngFor="let aggregator of aggregators" [value]="aggregator">
    {{ aggregator.label }}: {{ aggregatorsData[aggregator.id] }}
  </mat-option>
</mat-select>

<div
  *ngIf="aggregators?.length == 1 && aggregatorsData"
  class="aggregators-selector"
  [matTooltip]="aggregators[0].tooltip">
  {{ aggregators[0].label }}: {{ aggregatorsData[aggregators[0].id] }}
</div>
