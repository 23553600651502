import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UserColorService } from '../services/user-color.service';
import { UserProfileDto } from '@core/services/api-clients';

@Component({
  selector: 'app-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserAvatarComponent {
  @Input() user: UserProfileDto = null;
  @Input() size: number;
  isHovered = false;

  constructor(private _colorService: UserColorService) {}

  getUsernameAcronym(): string {
    if (!this.user?.displayName?.length) return '';
    const username = this.user.displayName;
    const splittedUsername = username.split(' ');
    const isOneWord = splittedUsername.length == 1;
    const isOneLetter = isOneWord && splittedUsername[0].length == 1;
    if (isOneLetter) return splittedUsername[0][0] + splittedUsername[0][0];
    if (isOneWord) return splittedUsername[0][0] + splittedUsername[0][1];
    return splittedUsername[0][0] + splittedUsername[1][0];
  }

  getUserColor(): string {
    return this._colorService.getUserColor(this.user);
  }
}
