import { NgModule } from '@angular/core';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { UiComponentsModule } from './ui-components/ui-components.module';
import { CommonModule } from '@angular/common';
import { AlertDialogComponent } from './alert-dialog/alert-dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DividerComponent } from './divider/divider.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { WidgetsModule } from '@core/widgets/widgets.module';
import { IconModule } from './icon/icon.module';
import { UtilsModule } from './utils/utils.module';

const declarationsAndExports = [ConfirmDialogComponent, AlertDialogComponent, DividerComponent, ErrorPageComponent];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    FlexLayoutModule,
    UtilsModule,
    UiComponentsModule,
    IconModule,
    WidgetsModule,
  ],
  declarations: [...declarationsAndExports],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    FlexLayoutModule,
    UtilsModule,
    UiComponentsModule,
    IconModule,
    ...declarationsAndExports,
  ],
})
export class SharedModule {}
