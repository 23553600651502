import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormOption } from '@core/navigation/store/state';

@Component({
  selector: 'app-form-selector',
  templateUrl: './form-selector.component.html',
  styleUrls: ['./form-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormSelectorComponent {
  private _forms: FormOption[] = [];

  @Input() set forms(forms: FormOption[]) {
    this._forms = forms?.length ? forms : [];
    this.selectedFormId = this._forms.length ? this._forms[0].id : null;
    this.selectedFormName = this._forms.length ? this._forms[0].name : null;
  }

  @Output() formSelected: EventEmitter<string> = new EventEmitter();

  selectedFormId: string = null;
  selectedFormName: string = null;
  get forms(): FormOption[] {
    return this._forms;
  }

  onOptionSelected(formId: string) {
    if (formId !== this.selectedFormId) {
      this.selectedFormId = formId;
      this.selectedFormName = this._forms.find(x=>x.id == formId).name;
      this.formSelected.emit(this.selectedFormId);
    }
  }
}
