<div class="code">{{ error.code }}</div>
<div class="info">
  <div class="message">{{ error.message }}</div>
  <div class="details" [innerHTML]="error.details"></div>
  <div class="actions">
    <button type="button" *ngFor="let action of error.actions" mat-button color="primary" (click)="action.handler()">
      {{ action.title }}
    </button>
  </div>
</div>
