<ng-container *ngIf="data.children.length === 0">
  <engine-control-group
    *ngFor="let item of data.controlGroups"
    [id]="item.id"
    [controlGroup]="item"
    [style.flex]="getFlexSpan(1)"></engine-control-group>
</ng-container>
<ng-container *ngIf="data.children.length !== 0">
    <app-form-grid
      [data]="childGrid"
      *ngFor="let childGrid of data.children"
      [isCompactMode]="isCompactMode"
      [parentTotalSlots]="data.slots">
    </app-form-grid>
</ng-container>
<div *ngIf="getUnusedSlotsCount() > 0" [style.flex]="getFlexRestSpan()"></div>
