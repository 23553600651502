import { EntityViewDto, ViewMainSelectorVisibilityDto } from '@core/services/api-clients';
import { GridSettings } from '../components/engine-view/services/engine-view-settings.service';

export const FEATURE_NAME: string = 'view-feature';

export interface IViewState {
  currentViewId: string;
  views: { [viewId: string]: EntityViewDto };
  gridsSettings: { [viewIdOrSubgridId: string]: GridSettings };
  tabsVisibility: { [entityId: string]: ViewMainSelectorVisibilityDto[] };
}

export const initialViewState: IViewState = {
  currentViewId: null,
  views: {},
  gridsSettings: {},
  tabsVisibility: {},
};
