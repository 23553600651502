<ng-container *ngIf="notificationCount$ | async as notificationCount">
  <span
    *ngIf="notificationCount > 0"
    class="title-badge"
    [matBadge]="notificationCount"
    matBadgePosition="before"
    matBadgeOverlap="false"
    matBadgeSize="medium"
    matBadgeColor="warn">
  </span>
</ng-container>
