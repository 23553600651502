<mat-select
  *ngIf="selectedMenuArea && menuAreas?.length > 1"
  class="area-dropdown"
  [ngClass]="{ 'narrow-dropdown': !isWide }"
  [ngModel]="selectedMenuArea"
  (selectionChange)="areaChanged.emit($event.value?.id)">
  <mat-select-trigger>
    <app-menu-item
      [ignoreWidgetEvents]="true"
      [name]="selectedMenuArea.name"
      [shortLabel]="selectedMenuArea.shortLabel"
      [label]="selectedMenuArea.label"
      [isWide]="isWide"
      [icon]="selectedMenuArea.icon">
    </app-menu-item>
  </mat-select-trigger>

  <mat-option *ngFor="let menuArea of menuAreas" [value]="menuArea">
    <app-menu-item
      [ignoreWidgetEvents]="true"
      [name]="menuArea.name"
      [shortLabel]="menuArea.shortLabel"
      [label]="menuArea.label"
      [isWide]="true"
      [icon]="menuArea.icon">
    </app-menu-item>
  </mat-option>
</mat-select>
