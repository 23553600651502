import { AsyncPipe, NgSwitch, NgSwitchCase } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatStepperModule } from '@angular/material/stepper';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SharedModule } from '@shared/shared.module';
import { EngineProcessCurrentStateComponent } from './components/engine-process-current-state/engine-process-current-state.component';
import { EngineProcessGridCellComponent } from './components/engine-process-grid-cell.component/engine-process-grid-cell.component';
import { EngineProcessStateComponent } from './components/engine-process-state/engine-process-state.component';
import { EngineProcessComponent } from './components/engine-process/engine-process.component';
import { EngineProcessesMainComponent } from './engine-main-processes.component';
import { ProcessDefinitionService } from './services/process-definition-service';
import { reducers } from './store';
import { ProcessEffects } from './store/effects';
import { FEATURE_NAME } from './store/state';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [
    EngineProcessesMainComponent,
    EngineProcessComponent,
    EngineProcessGridCellComponent,
    EngineProcessStateComponent,
    EngineProcessCurrentStateComponent,
  ],
  imports: [
    StoreModule.forFeature(FEATURE_NAME, reducers),
    EffectsModule.forFeature([ProcessEffects]),
    NgSwitch,
    NgSwitchCase,
    MatStepperModule,
    FormsModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    AsyncPipe,
    SharedModule,
  ],
  exports: [EngineProcessesMainComponent, EngineProcessGridCellComponent],
  providers: [],
})
export class EngineProcessesModule {
  static forRoot(): ModuleWithProviders<EngineProcessesModule> {
    return {
      ngModule: EngineProcessesModule,
      providers: [ProcessDefinitionService],
    };
  }
}
