import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators';
import {
  IUserInteractionContext,
  IUserInteractionService,
  UserInteractionContexts,
} from 'src/engine-sdk/contract/user-interaction';

export interface IUserInteractionEvent {
  providerId: string;
  context: IUserInteractionContext;
  value: any;
}

@Injectable()
export class UserInteractionService implements IUserInteractionService {
  private _destroy$ = new Subject<boolean>();
  private _userInteractionEvents$: BehaviorSubject<IUserInteractionEvent[]> = new BehaviorSubject([]);
  readonly uiContexts: UserInteractionContexts = UserInteractionContexts;

  startListen(contexts: IUserInteractionContext[]): Observable<any[]> {
    return combineLatest(contexts.map((c) => this.startListenContext(c))).pipe(
      takeUntil(this._destroy$),
      debounceTime(50),
      distinctUntilChanged((a, b) => a.every((x, index) => x.value == b[index].value)),
      map((events) => events.map((e) => e.value)),
    );
  }

  feed(event: IUserInteractionEvent): void {
    this._userInteractionEvents$.next([
      event,
      ...this._userInteractionEvents$.value.filter(
        (e) => !(e.providerId == event.providerId && e.context.eventType == event.context.eventType),
      ),
    ]);
  }

  clearFeed(providerId: string): void {
    this._userInteractionEvents$.next([
      ...this._userInteractionEvents$.value.filter((e) => e.providerId != providerId),
    ]);
    // unsubscribe all listeners when clearFeed clear all interaction events (redirection)
    // TODO-MP: remove it when customizator can unsubscribe himself
    if (!this._userInteractionEvents$.value.length) {
      this._destroy$.next(true);
      this._destroy$.complete();
      this._destroy$ = new Subject<boolean>();
    }
  }

  private startListenContext(context: IUserInteractionContext): Observable<IUserInteractionEvent> {
    return this._userInteractionEvents$.asObservable().pipe(
      map((events) => events.filter((e) => this.isEventInContext(e, context))),
      filter((events) => events.length > 0),
      map((events) => events[0]),
      distinctUntilChanged((a, b) => a.value == b.value),
    );
  }

  private isEventInContext(event: IUserInteractionEvent, context: IUserInteractionContext): boolean {
    let result = true;
    Object.keys(context)
      .filter((key) => key != undefined)
      .forEach((key) => {
        if (!this.isContextKeyValueMeetPattern(event.context[key], context[key])) {
          result = false;
          return;
        }
      });
    return result;
  }

  private isContextKeyValueMeetPattern(eventContextKeyValue: string, contextKeyPattern: string) {
    switch (contextKeyPattern) {
      case UserInteractionContexts.NOT_EMPTY_CONTEXT:
        if (eventContextKeyValue == undefined) {
          return false;
        }
        break;
      case UserInteractionContexts.EMPTY_CONTEXT:
        if (eventContextKeyValue != undefined) {
          return false;
        }
        break;
      default:
        if (eventContextKeyValue != contextKeyPattern) {
          return false;
        }
        break;
    }
    return true;
  }
}
