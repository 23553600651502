import { Injector } from '@angular/core';
import { CancellationToken } from '@core/services/context.service';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { IButtonExecutionContext, IExecutionEvent, IViewContext } from 'src/engine-sdk';
import { EventType } from '../services/api-clients';
import { ExecutionContext, IButtonContext } from './execution-context';

export class ButtonExecutionContext extends ExecutionContext implements IButtonExecutionContext {
  private _buttonEvents: Observable<IExecutionEvent>;
  private _buttonDataContext: any;

  constructor(
    injector: Injector,
    private _buttonContext: IButtonContext,
    buttonEvents: Observable<IExecutionEvent>,
    cancellationToken: CancellationToken,
    buttonDataContext: any,
    executionEvent?: IExecutionEvent,
  ) {
    super(injector, cancellationToken, executionEvent, buttonDataContext.dialogId);
    this._buttonEvents = buttonEvents;
    this._buttonDataContext = buttonDataContext;
  }

  setFontIcon(fontIconName: string): void {
    this._buttonContext.setFontIcon(fontIconName);
  }

  setResourceIcon(iconRelativePath: string): void {
    this._buttonContext.setResourceIcon(iconRelativePath);
  }

  setDisabled(value: boolean) {
    this._buttonContext.setDisabled(value);
  }

  setLabel(value: string) {
    this._buttonContext.setLabel(value);
  }

  setVisibility(value: boolean) {
    this._buttonContext.setVisibility(value);
  }

  getVisibility(): boolean {
    return this._buttonContext.getVisibility();
  }

  buttonClick(): Observable<IExecutionEvent> {
    return this._buttonEvents.pipe(filter((e) => e.eventType === EventType.OnClick));
  }

  getButtonDataContext(): any {
    return this._buttonDataContext;
  }

  override getViewContext(): IViewContext {
    return this._buttonDataContext
      ? this._contextService.createViewContext(this._buttonDataContext)
      : this._contextService.createViewContext();
  }
}
