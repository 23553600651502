<ng-container *ngIf="section">
  <mat-card *ngIf="section.isOutlined" [ngStyle]="{ 'background-color': section.backgroundColor }">
    <ng-container *ngTemplateOutlet="sectionContent; context: { section: this.section }"></ng-container>
  </mat-card>

  <ng-container *ngIf="!section.isOutlined">
    <ng-container *ngTemplateOutlet="sectionContent; context: { section: this.section }"></ng-container>
  </ng-container>
</ng-container>

<ng-template #sectionContent let-sectionDef="section">
  <div class="form-section-content" [ngStyle]="{ 'background-color': sectionDef.backgroundColor }">
    <app-icon-header
      class="form-section-header"
      [icon]="sectionDef.icon"
      [subtitle]="sectionDef.subtitle"
      [title]="sectionDef.title">
    </app-icon-header>
    <ng-content></ng-content>
  </div>
</ng-template>
