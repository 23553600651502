import { moveItemInArray } from '@angular/cdk/drag-drop';
import { KanbanColumnAggregatorDto, KanbanColumnDto, KanbanDto } from '@core/services/api-clients';

export class KanbanSettingsService {
  public static saveDefaultAggregator(
    kanbanColumn: KanbanColumnDto,
    kanbanColumnAggregator: KanbanColumnAggregatorDto,
    userId: string,
  ) {
    localStorage.setItem(this.getDefaultAggregatorKey(kanbanColumn, userId), kanbanColumnAggregator.id);
  }

  public static saveColumnExpansion(kanbanColumn: KanbanColumnDto, isExpanded: boolean, userId: string) {
    localStorage.setItem(this.getColumnExpansionKey(kanbanColumn, userId), isExpanded.toString());
  }

  public static applyUserSettings(kanban: KanbanDto, userId: string) {
    for (let column of kanban.columns) {
      const value = this.getColumnExpansion(column, userId);
      if (value != undefined) {
        column.isDefaultMinimalized = !value;
      }

      if (column.aggregators && column.aggregators.length > 0) {
        const defaultAggregatorIndex = this.getDefaultAggregatorIndex(column, userId);
        if (defaultAggregatorIndex !== -1) {
          moveItemInArray(column.aggregators, defaultAggregatorIndex, 0);
        }
      }
    }
  }

  public static restoreSettings(kanban: KanbanDto, userId: string) {
    for (let column of kanban.columns) {
      localStorage.removeItem(this.getColumnExpansionKey(column, userId));
      localStorage.removeItem(this.getDefaultAggregatorKey(column, userId));
    }
  }

  private static getDefaultAggregatorIndex(kanbanColumn: KanbanColumnDto, userId: string): number {
    const key = this.getDefaultAggregatorKey(kanbanColumn, userId);
    const aggregatorId = localStorage.getItem(key);

    return kanbanColumn.aggregators.findIndex((x) => x.id == aggregatorId);
  }

  private static getColumnExpansion(kanbanColumn: KanbanColumnDto, userId: string): boolean {
    const key = this.getColumnExpansionKey(kanbanColumn, userId);
    const value = localStorage.getItem(key);

    if (value) {
      const isExpanded = localStorage.getItem(key) == 'true' ? true : false;
      return isExpanded;
    } else {
      return undefined;
    }
  }

  private static getDefaultAggregatorKey(kanbanColumn: KanbanColumnDto, userId: string): string {
    return `DefaultAggregator_${kanbanColumn.id}_${userId}`;
  }

  private static getColumnExpansionKey(kanbanColumn: KanbanColumnDto, userId: string): string {
    return `ColumnExpansion_${kanbanColumn.id}_${userId}`;
  }
}
