import {
  NotificationGroup,
  NotificationScope,
  NotificationType,
  Notification,
  INotificationContext,
  INotificationGroupsBuilder,
  INotificationGroupBuilder,
} from 'src/engine-sdk';
import { IEngineNotificationService } from '../services/engine-nofitication.service';

export class NotificationContext implements INotificationContext {
  constructor(private _notificationService: IEngineNotificationService, private _entityName?: string) {}

  getNotificationGroupsBuilder() {
    return this._notificationService.getNotificationGroupsBuilder(this._entityName);
  }

  addNotifications(notifications: NotificationGroup[]) {
    this._notificationService.addNotifications(notifications);
  }

  setNotifications(notifications: NotificationGroup[]) {
    this._notificationService.setNotifications(notifications);
  }

  removeNotifications() {
    this._notificationService.removeAllNotifications();
  }
}

export class NotificationGroupsBuilder implements INotificationGroupsBuilder {
  private _builders: NotificationGroupBuilder[] = [];

  constructor(private _entityName?: string) {}

  build() {
    return this._builders.map((e) => e.notificationGroup);
  }

  getDefaultGlobalNotificationGroup(message?: string): INotificationGroupBuilder {
    return this.getNotificationGroup(NotificationGroup.defaultGlobalGroup, NotificationScope.Global, message);
  }

  getAttributeNotificationGroup(groupName: string, message?: string): INotificationGroupBuilder {
    return this.getNotificationGroup(groupName, NotificationScope.Attribute, message);
  }

  getNotificationGroup(
    groupName: string,
    scope: NotificationScope,
    message?: string,
    type?: NotificationType,
  ): INotificationGroupBuilder {
    let builder = this._builders.find(
      (e) => e.notificationGroup.name == groupName && e.notificationGroup.scope == scope,
    );
    if (!builder) {
      builder = new NotificationGroupBuilder(groupName, scope, message, this._entityName, type);
      this._builders.push(builder);
    }

    return builder;
  }
}

export class NotificationGroupBuilder implements INotificationGroupBuilder {
  public notificationGroup: NotificationGroup;

  constructor(name: string, scope: NotificationScope, message: string, entityName: string, type: NotificationType) {
    this.notificationGroup = new NotificationGroup();
    this.notificationGroup.entityName = entityName;
    this.notificationGroup.name = name;
    this.notificationGroup.message = message;
    this.notificationGroup.scope = scope;
    this.notificationGroup.type = type ? type : NotificationType.Error;
    this.notificationGroup.notifications = [];
  }

  addValidationInfo(message: string): INotificationGroupBuilder {
    return this.addValidationNotification(NotificationType.Information, message);
  }

  addValidationWarning(message: string): INotificationGroupBuilder {
    return this.addValidationNotification(NotificationType.Warning, message);
  }

  addValidationError(message: string): INotificationGroupBuilder {
    return this.addValidationNotification(NotificationType.Error, message);
  }

  private addValidationNotification(type: NotificationType, message: string) {
    this.notificationGroup.notifications.push(new Notification({ type, message }));
    return this;
  }
}
