import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IIcon } from '@shared/icon/icon.model';

export type IconMode = 'icon' | 'url';
export type IconSize = 'sm' | 'md' | 'xl' | 'custom';

@Component({
  selector: 'app-img-icon',
  templateUrl: './img-icon.component.html',
  styleUrls: ['./img-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImgIconComponent {
  @Input() url: string = null;
  @Input() icon: IIcon = null;
  @Input() iconMode: IconMode = 'icon';
  @Input() size: IconSize;
}
