import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { EngineTranslationService } from '@core/engine-translations/services/translation.service';
import { shouldConfirmNavigation } from '@core/navigation/store/selectors';
import {
  ExecuteProcessTransitionCommand,
  ProcessExecutionResult,
  ProcessInstanceHistoryDto,
  ProcessStateDto,
  ProcessesClient,
  ResetProcessCommand,
} from '@core/services/api-clients';
import { Store, select } from '@ngrx/store';
import { ConfirmDialogComponent } from '@shared/confirm-dialog/confirm-dialog.component';
import { EMPTY, Observable } from 'rxjs';
import { first, mergeMap } from 'rxjs/operators';
import { LoadProcessEntityAssignments, LoadProcessHistory, LoadProcessStateWithTransitions } from '../store/actions';
import {
  getProcessAssignmentsForEntity,
  getProcessHistoryForRecord,
  getProcessState,
  getProcessStateHistoryItem,
} from '../store/selectors';

@Injectable()
export class ProcessDefinitionService {
  constructor(
    private _processesClient: ProcessesClient,
    private _store: Store,
    private _translationService: EngineTranslationService,
    private _dialog: MatDialog,
  ) {}

  executeTransition(
    targetStageId: string,
    recordId: string,
    attributeId: string,
  ): Observable<ProcessExecutionResult | undefined> {
    return this._store.pipe(
      select(shouldConfirmNavigation),
      first(),
      mergeMap((shouldConfirmNavigation) => {
        const action = () =>
          this._processesClient.executeProcessTransition(
            ExecuteProcessTransitionCommand.fromJS({
              targetStageId: targetStageId,
              recordId: recordId,
              attributeId: attributeId,
            }),
          );

        return this.confirmIfRequired(shouldConfirmNavigation, action) as Observable<ProcessExecutionResult | undefined>;
      }),
    );
  }

  loadProcessStateWithTransitions(processStateId: string) {
    this._store.dispatch(new LoadProcessStateWithTransitions({ sourceStateId: processStateId }));
  }

  getProcessState(processStateId: string): Observable<ProcessStateDto> {
    return this._store.pipe(select(getProcessState(processStateId)));
  }

  loadProcessAssignmentsForEntity(entityId: string) {
    this._store.dispatch(new LoadProcessEntityAssignments({ entityId: entityId }));
  }

  getProcessAssgnmentForEntity(entityId: string) {
    return this._store.select(getProcessAssignmentsForEntity(entityId));
  }

  loadProcessHistory(processAssignmentId: string, recordId: string) {
    this._store.dispatch(new LoadProcessHistory({ processAssignmentId: processAssignmentId, recordId: recordId }));
  }

  getProcessHistory(
    processAssignmentId: string,
    recordId: string,
  ): Observable<ProcessInstanceHistoryDto[]> {
    return this._store.select(getProcessHistoryForRecord(processAssignmentId, recordId));
  }

  getProcessStateHistoryItem(
    processAssignmentId: string,
    recordId: string,
    stateId: string,
  ): Observable<ProcessInstanceHistoryDto> {
    return this._store.select(getProcessStateHistoryItem(processAssignmentId, recordId, stateId));
  }

  resetProcess(processAssignmentId: string, recordId: string): Observable<ProcessExecutionResult> {
    return this._processesClient.resetProcess(
      ResetProcessCommand.fromJS({
        processAssignmentId: processAssignmentId,
        recordId: recordId,
      }),
    );
  }

  private confirmIfRequired(shouldConfirm: boolean, action: any) {
    if (shouldConfirm) {
      const warningMessage = this._translationService.translateInstantly('Navigation.ReturnConfirmation');
      return this._dialog
        .open(ConfirmDialogComponent, {
          data: {
            message: warningMessage,
          },
        })
        .afterClosed()
        .pipe(
          mergeMap((result) => {
            return result ? action() : EMPTY;
          }),
        );
    } else {
      return action();
    }
  }
}
