import { EntityViewDto } from '@core/services/api-clients';
import { ViewActions, ViewActionTypes } from './actions';
import { initialViewState, IViewState } from './state';

export function viewReducer(state = initialViewState, action: ViewActions): IViewState {
  switch (action.type) {
    case ViewActionTypes.ReloadView: {
      return {
        ...state,
        currentViewId: action.payload.viewId,
      };
    }
    case ViewActionTypes.LoadEntityViewSuccess: {
      let view = action.payload.view;
      let viewId = action.payload.view.view.id;
      return {
        ...state,
        views: {
          ...state.views,
          [viewId]: view,
        },
      };
    }
    case ViewActionTypes.SetUserViewPageSizeSuccess: {
      const viewId = action.payload.viewId;
      const pageSize = action.payload.pageSize;

      return {
        ...state,
        views: {
          ...state.views,
          [viewId]: {
            ...state.views[viewId],
            view: { ...state.views[viewId].view, pageSize: pageSize },
          } as EntityViewDto,
        },
      };
    }
    case ViewActionTypes.SaveGridSettingsToState: {
      let viewId = action.payload.viewOrSubgridId;

      return {
        ...state,
        gridsSettings: {
          ...state.gridsSettings,
          [viewId]: action.payload.settings,
        },
      };
    }
    case ViewActionTypes.SaveTabsVisibilitySettingsToState: {
      let settings = action.payload.tabsVisibilitySettings;
      let entityId = action.payload.entityId;

      return {
        ...state,
        tabsVisibility: {
          ...state.tabsVisibility,
          [entityId]: settings,
        },
      };
    }

    default:
      return state;
  }
}
