import { Observable, combineLatest } from 'rxjs';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { BaseStateService } from '@shared/utils/services/base-state.service';
import { catchError, filter, first, map, tap } from 'rxjs/operators';
import { BaseODataService } from '@core/engine-odata/services/base-odata.service';
import { LodashService } from '@core/services/lodash.service';
import { IEngineEntity, IEntityAttribute } from 'src/engine-sdk/contract/metadata';
import { ODataServiceFactory } from '@shared/odata';

export interface IEngineMetadata {
  entities: { [entityId: string]: IEngineEntity };
}

@Injectable()
export class EngineMetadataProvider extends BaseStateService<IEngineMetadata> {
  entitiesClient: BaseODataService<any>;
  attributesClient: BaseODataService<any>;

  constructor(private _serviceFactory: ODataServiceFactory) {
    super({ entities: {} });
    this.entitiesClient = new BaseODataService(this._serviceFactory, 'Entity');
    this.attributesClient = new BaseODataService(this._serviceFactory, 'Attribute');
  }

  getEntityMetadataAsync(entityId: string): Observable<IEngineEntity> {
    if (!entityId) throw new Error(`getEntityMetadataAsync: entityId can't be null.`);

    const cachedEntity = this.getState().entities[entityId];
    if (cachedEntity) {
      return of(cachedEntity);
    }

    if (!this.isMetadataElementLoading(cachedEntity)) {
      this.loadEntityMetadata(entityId);
    }

    return this.getStateAsync().pipe(
      map((state) => state.entities[entityId]),
      filter((entity) => entity != undefined),
      first(),
    );
  }

  private loadEntityMetadata(entityId: string): void {
    this.setEntityMetadata(entityId, null);
    combineLatest([
      this.entitiesClient.get(entityId),
      this.attributesClient
        .query()
        .Select('Id,Name,Type,IsPrimaryKey,IsNullable')
        .Filter(`EntityId eq ${entityId}`)
        .Exec(),
    ])
      .pipe(
        map(([entity, attributes]) => {
          return {
            id: entity.Id,
            name: entity.Name,
            attributes: attributes.map((attr) => {
              return { id: attr.Id, name: attr.Name, type: attr.Type } as IEntityAttribute;
            }),
          } as IEngineEntity;
        }),
        tap((entity) => this.setEntityMetadata(entityId, entity)),
        catchError((err) => {
          this.setEntityMetadata(entityId, undefined);
          throw err;
        }),
      )
      .subscribe();
  }

  private setEntityMetadata(entityId: string, entityMetadata: IEngineEntity): void {
    const metadataState = LodashService.cloneDeep(this.getState());
    metadataState.entities[entityId] = entityMetadata;
    this.updateState(metadataState);
  }

  private isMetadataElementLoading(element: any) {
    return element === null;
  }
}
