<ng-container *ngIf="processAssignments$ | async as processAssignments">
  <engine-process
    *ngIf="processAssignments.length > 0"
    [processAssignments]="processAssignments"
    [currentProcessState]="currentProcessState$ | async"
    [historyStatesData]="processHistory$ | async"
    (selectProcessAssignment)="onProcessAssignmentChange($event)"
    (executeProcessTransition)="executeTransition($event)"
    (openHistoryItem)="navigateToProcessRecord($event)"
    (openCurrentItem)="navigateToCurrentProcessRecord($event)">
  </engine-process>
</ng-container>
