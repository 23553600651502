import { ActionReducerMap } from '@ngrx/store';
import { menuReducer } from './reducers';
import { IMenuState } from './state';
import * as MenuSelectors from './selectors';
import * as MenuActions from './actions';

export { MenuActions, MenuSelectors };

export interface MenuFeatureState {
  menu: IMenuState;
}

export const reducers: ActionReducerMap<MenuFeatureState> = {
  menu: menuReducer,
};
