<span *ngIf="aggregations && aggregations.length > 0" class="aggregation-container">
  <mat-form-field>
    <mat-select [value]="aggregations[0]" [disabled]="aggregations.length == 1">
      <mat-option *ngFor="let aggregation of aggregations" [value]="aggregation">
        {{ aggregation | aggregationLabel }} =
        {{ aggregation.value | number }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</span>
