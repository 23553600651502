import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewChild } from '@angular/core';
import { Grid } from './form-grid.model';
import { FormSectionComponent } from './form-section/form-section.component';

@Component({
  selector: 'app-form-grid',
  templateUrl: './form-grid.component.html',
  styleUrls: ['./form-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormGridComponent {
  @Input() data: Grid;
  @Input() parentTotalSlots: number;
  @Input() isCompactMode: boolean;

  @HostBinding('id')
  get getGridId(): string {
    return this.data.id;
  }

  @HostBinding('style.flex') get flex() {
    if (this.formSection != null && !this.formSection.isVisible) {
      return '';
    }

    return this.isCompactMode ? '1 100%' : this.getFlexSpan();
  }

  @ViewChild(FormSectionComponent, { static: false })
  formSection: FormSectionComponent;

  private getFlexSpan() {
    const totalSlots = this.parentTotalSlots ?? 1;
    return '1 ' + Math.floor((this.data.takenSlots / totalSlots) * 100) + '%';
  }
}
