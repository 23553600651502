import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { ReplaceUrlToToken } from '../store/actions';

@Injectable()
export class ReplaceUrlToTokenGuard implements CanActivate {
  constructor(private _store: Store) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!route.params['isFinal']) {
      this._store.dispatch(
        new ReplaceUrlToToken({
          routerState: {
            url: this.getUrl(route),
            params: route.params,
            queryParams: route.queryParams,
          },
        }),
      );
    }

    return of(true);
  }

  private getUrl(route: ActivatedRouteSnapshot): string {
    return route.pathFromRoot.map((v) => v.url.map((segment) => segment.toString()).join('/')).join('/');
  }
}
