import { ModuleWithProviders, NgModule } from '@angular/core';
import { ContextMenuModule } from '@core/context-menu/context-menu.module';
import { EngineODataModule } from '@core/engine-odata/engine-odata.module';
import { EngineProcessesModule } from '@core/engine-processes/engine-processes.module';
import { EngineViewsModule } from '@core/engine-views/engine-views.module';
import { NotificationModule } from '@core/notification/notification.module';
import { WidgetsModule } from '@core/widgets/widgets.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ReactiveControlsModule } from '@shared/reactive-controls/reactive-controls.module';
import { SharedModule } from '@shared/shared.module';
import { EngineDialogFormComponent } from './components/engine-dialog-form/engine-dialog-form.component';
import { EngineChartControlComponent } from './components/engine-form/engine-chart-control/engine-chart-control.component';
import { EngineControlGroupComponent } from './components/engine-form/engine-control-group/engine-control-group.component';
import { EngineFormComponent } from './components/engine-form/engine-form.component';
import { EngineQueryBuilderControlComponent } from './components/engine-form/engine-query-builder-control/engine-query-builder-control.component';
import { EngineSubgridControlComponent } from './components/engine-form/engine-subgrid-control/engine-subgrid-control.component';
import { FormGridContentComponent } from './components/engine-form/form-grid/form-grid-content/form-grid-content.component';
import { FormGridComponent } from './components/engine-form/form-grid/form-grid.component';
import { FormSectionComponent } from './components/engine-form/form-grid/form-section/form-section.component';
import { FormTabsComponent } from './components/engine-form/form-tabs/form-tabs.component';
import { IconHeaderComponent } from './components/engine-form/icon-header/icon-header.component';
import { EngineButtonFormControlDirective } from './directives/engine-button-form-control.directive';
import { EngineCombinedLookupsFormControlDirective } from './directives/engine-combined-lookups-form-control.directive';
import { EngineDateFormControlDirective } from './directives/engine-date-form-control.directive';
import { EngineDatetimeFormControlDirective } from './directives/engine-datetime-form-control.directive';
import { EngineDescriptionFormControlDirective } from './directives/engine-description-form-control.directive';
import { EngineFormControlDirective } from './directives/engine-form-control.directive';
import { EngineFormTabDirective } from './directives/engine-form-tab.directive';
import { EngineHtmlEditorFormControlDirective } from './directives/engine-html-editor-control.directive';
import { EngineHyperlinkFormControlDirective } from './directives/engine-hyperlink-form-control.directive';
import { EngineIconFormControlDirective } from './directives/engine-icon-form-control.directive';
import { EngineInlineRelationFormControlDirective } from './directives/engine-inline-relation-control.directive';
import { EngineLabelFormControlDirective } from './directives/engine-label-form-control.directive';
import { EngineLookupFormControlDirective } from './directives/engine-lookup-form-control.directive';
import { EngineMultiselectFormControlDirective } from './directives/engine-multiselect-form-control.directive';
import { EngineNumberFormControlDirective } from './directives/engine-number-form-control.directive';
import { EngineSelectFormControlDirective } from './directives/engine-select-form-control.directive';
import { EngineTextAreaFormControlDirective } from './directives/engine-text-area-form-control.directive';
import { EngineTextFormControlDirective } from './directives/engine-text-form-control.directive';
import { EngineTimeFormControlDirective } from './directives/engine-time-form-control.directive';
import { EngineYesNoFormControlDirective } from './directives/engine-yes-no-form-control.directive';
import { ENGINE_FORM_SERVICE } from './models/iengine-form-service.model';
import { DefaultEngineFormService } from './services/default-engine-form.service';
import { FormValueMapper } from './services/form-value-mapper.service';
import { reducers } from './store';
import { FormEffects } from './store/effects';
import { FEATURE_NAME } from './store/state';

const engineControls = [
  EngineFormControlDirective,
  EngineLookupFormControlDirective,
  EngineSelectFormControlDirective,
  EngineMultiselectFormControlDirective,
  EngineHyperlinkFormControlDirective,
  EngineButtonFormControlDirective,
  EngineTextAreaFormControlDirective,
  EngineSubgridControlComponent,
  EngineNumberFormControlDirective,
  EngineTextFormControlDirective,
  EngineYesNoFormControlDirective,
  EngineDateFormControlDirective,
  EngineDatetimeFormControlDirective,
  EngineTimeFormControlDirective,
  EngineLabelFormControlDirective,
  EngineDescriptionFormControlDirective,
  EngineIconFormControlDirective,
  EngineChartControlComponent,
  EngineInlineRelationFormControlDirective,
  EngineHtmlEditorFormControlDirective,
  EngineCombinedLookupsFormControlDirective,
  EngineQueryBuilderControlComponent,
];

@NgModule({
  declarations: [
    EngineDialogFormComponent,
    EngineFormComponent,
    FormTabsComponent,
    EngineFormTabDirective,
    FormGridComponent,
    FormGridContentComponent,
    FormSectionComponent,
    IconHeaderComponent,
    EngineControlGroupComponent,
    engineControls,
  ],
  imports: [
    StoreModule.forFeature(FEATURE_NAME, reducers),
    EffectsModule.forFeature([FormEffects]),
    SharedModule,
    ReactiveControlsModule,
    WidgetsModule,
    NotificationModule,
    EngineViewsModule,
    ContextMenuModule,
    EngineODataModule,
    EngineProcessesModule
  ],
  exports: [EngineDialogFormComponent, EngineFormComponent],
  providers: [{ provide: ENGINE_FORM_SERVICE, useClass: DefaultEngineFormService }],
})
export class EngineFormsModule {
  static forRoot(): ModuleWithProviders<EngineFormsModule> {
    return {
      ngModule: EngineFormsModule,
      providers: [FormValueMapper],
    };
  }
}
