import { Pipe, PipeTransform } from '@angular/core';
import { GridColumn } from '../models/grid-definition.model';
import { LookupPipe } from './lookup.pipe';

@Pipe({
  name: 'optionSetValue',
})
export class OptionSetValuePipe implements PipeTransform {
  private _lookupPipe: LookupPipe = new LookupPipe();

  transform(dataItem: any, column: GridColumn): number {
    const value = this._lookupPipe.transform(dataItem, column);

    if (!value) {
      return -1;
    }

    const option = column.optionSet.values.find((e) => e.name === value);
    return option.value;
  }
}
