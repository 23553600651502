import { ActionReducerMap } from '@ngrx/store';
import { userReducer } from './reducers';
import { IUserState } from './state';
import * as UserSelectors from './selectors';
import * as UserActions from './actions';

export { UserActions, UserSelectors };

export interface UserFeatureState {
  user: IUserState;
}

export const reducers: ActionReducerMap<UserFeatureState> = {
  user: userReducer,
};
