import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FEATURE_NAME, IKanbanState, KanbanData } from './state';
import { KanbanSettingsService } from '@app/main-kanban/services/kanban-settings.service';
import { KanbanFeatureState } from '.';
import { getTopNavigationStackItem } from '@core/navigation/store/selectors';
import { getCurrentUserId } from '@core/user/store/selectors';
import { NavigationSelectors } from '@core/navigation/store';
import { LodashService } from '@core/services/lodash.service';

export const kanbanFeatureSelector = createFeatureSelector<KanbanFeatureState>(FEATURE_NAME);
export const kanbanSelector = createSelector(kanbanFeatureSelector, (state: KanbanFeatureState) => state.kanban);

export const getKanbans = createSelector(kanbanSelector, (state: IKanbanState) => state.kanbans);
export const getSettingsRefreshFlag = createSelector(kanbanSelector, (state) => state.settingsRefreshFlag);

export const getCurrentKanban = createSelector(
  getKanbans,
  NavigationSelectors.getKanbanIdFromUrl,
  getCurrentUserId,
  getSettingsRefreshFlag,
  (kanbans, kanbanId, userId, flag) => {
    let kanban = kanbans[kanbanId];
    if (kanban) {
      kanban = LodashService.cloneDeep(kanban);
      KanbanSettingsService.applyUserSettings(kanban, userId);
    }

    return kanban;
  },
);

export const getKanbansData = createSelector(kanbanSelector, (state: IKanbanState) => state.kanbansData);
export const getCurrentKanbanData = createSelector(
  getCurrentKanban,
  getKanbansData,
  (kanban, kanbansData) => LodashService.cloneDeep(kanbansData[kanban?.id]) as KanbanData,
);
export const getCurrentSearchTerm = createSelector(getCurrentKanban, getTopNavigationStackItem, (kanban, stackItem) => {
  if (stackItem && kanban && stackItem.navigationTarget.kanbanId == kanban.id) {
    return stackItem.routerState.queryParams?.searchTerm;
  }

  return null;
});
