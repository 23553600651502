import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, Injector, Input, NgZone, ViewChild, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseFormControlDirective } from '@shared/reactive-controls/directives/base-form-control.directive';
import { CULTURE_SERVICE, ICultureService } from '@shared/reactive-controls/models/iculture-service.model';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-text-area-control',
  templateUrl: './text-area-control.component.html',
  styleUrls: ['./text-area-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextAreaControlComponent),
      multi: true,
    },
    {
      provide: BaseFormControlDirective,
      useExisting: TextAreaControlComponent,
    },
  ],
})
export class TextAreaControlComponent extends BaseFormControlDirective implements AfterViewInit {
  private _ngZone: NgZone;

  @Input() minRowsNumber: number;
  @Input() maxRowsNumber: number;
  @Input() textAlignStyle: any;

  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  constructor(@Inject(CULTURE_SERVICE) cultureService: ICultureService, injector: Injector) {
    super(cultureService, injector);
    this._ngZone = injector.get(NgZone);
  }

  ngAfterViewInit() {
    this.triggerResize();
  }

  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable.pipe(take(1)).subscribe(() => {
      this.autosize.resizeToFitContent(true);
    });
  }
}
