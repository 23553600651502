<div class="filter-form-group" *ngIf="entity && value" [formGroup]="formGroup">
  <app-select
    class="field-control"
    formControlName="field"
    [placeholder]="'Field' | translate"
    [options]="fieldsOptions"></app-select>
  <app-select
    class="operator-control"
    formControlName="operator"
    [placeholder]="'Operator' | translate"
    [options]="operators"></app-select>
  <app-input class="value-control" formControlName="value" [placeholder]="'Value' | translate"></app-input>
</div>
