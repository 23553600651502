import { Component, EventEmitter, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { getCurrentUser } from '../store/selectors';

@Component({
  selector: 'app-user-topbar-section',
  templateUrl: './user-topbar-section.component.html',
  styleUrls: ['./user-topbar-section.component.scss'],
})
export class UserTopbarSectionComponent {
  @Output() avatarClicked: EventEmitter<void> = new EventEmitter();

  currentUser$ = this._store.select(getCurrentUser);

  constructor(private _store: Store) {}
}
