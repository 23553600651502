<div
  class="process-state-container"
  [matTooltip]="processState.label"
  matTooltipPosition="below"
  (dblclick)="this.navigateToRelatedRecord.emit(processState)">
  <div
    class="basic-grid-button"
    [ngStyle]="{'border': getStateBorder(processState), 'color': getStateColor(processState)}"
   >
    <app-icon [icon]="processState.icon"></app-icon>
    <span class="state-text">{{ processState.label ?? '' }}</span>
  </div>
</div>
