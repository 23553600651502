import { ErrorHandler, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import { ErrorsActionTypes, HandleError } from './actions';

@Injectable()
export class ErrorEffects {
  handleError$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType<HandleError>(ErrorsActionTypes.HandleError),
        map((action) => this._handler.handleError(action.payload.error)),
      ),
    { dispatch: false },
  );

  constructor(private _actions$: Actions, private _handler: ErrorHandler) {}
}
