import { Params } from '@angular/router';
import { Action } from '@ngrx/store';
import { NavigationTargetDto } from '@core/services/api-clients';
import { FormOption, NavigationStackItem } from './state';
import { IRouterStateUrl } from '@core/router/store/state';

export enum NavigationActionTypes {
  LoadNavigationTargets = '[Navigation] Load Navigation Targets',
  LoadNavigationTargetsSuccess = '[Navigation] Load Navigation Targets Success',

  LoadAvailableForms = '[Navigation] Load Available Forms',
  LoadAvailableFormsSuccess = '[Navigation] Load Available Forms Success',

  ChangeSelectedForm = '[Navigation] Change Selected Form',

  ReplaceUrlToToken = '[Navigation] Replace Url To Token',
  NavigateByNavigationPath = '[Navigation] Navigate By Navigation Path',
  NavigateByRouteData = '[Navigation] Navigate By Route Data',
  NavigateByNavigationTargets = '[Navigation] Navigate By Navigation Targets',
  NavigateToHomePage = '[Navigation] Navigate To Home Page',
  NavigateBack = '[Navigation] Navigate Back',
  NavigateToTopNavigationStackItem = '[Navigation] Navigate To Top Navigation Stack Item',
  NavigateToPrevNavigationStackItem = '[Navigation] Navigate To Prev Navigation Stack Item',
  NavigateToRecentOrDefaultTarget = '[Navigation] Navigate To Recent Or Default Target',

  SaveNavigationState = '[Navigation] Save Navigation State',
  PushNavigationStackItem = '[Navigation] Push Navigation Stack Item',
  PopNavigationStackItems = '[Navigation] Pop Navigation Stack Items',
  ClearNavigationStack = '[Navigation] Clear Navigation Stack',
  AddQueryParamsToTopNavigationStackItem = '[Navigation] Add Query Params To Top Navigation Stack Item',
  SetQueryParamsInTopNavigationStackItem = '[Navigation] Set Query Params In Top Navigation Stack Item',

  SetIsDirty = '[Navigation] Set Is Dirty',
  ShowLongBreadcrumb = '[Navigation] Show Long Breadcrumb',
  ShowShortBreadcrumb = '[Navigation] Show Short Breadcrumb',
}

export class LoadNavigationTargets implements Action {
  public readonly type = NavigationActionTypes.LoadNavigationTargets;
  public constructor(public payload: { path: string }) { }
}

export class LoadNavigationTargetsSuccess implements Action {
  public readonly type = NavigationActionTypes.LoadNavigationTargetsSuccess;
  public constructor(public payload: { navigationTargets: NavigationTargetDto[] }) { }
}

export class LoadAvailableForms implements Action {
  public readonly type = NavigationActionTypes.LoadAvailableForms;
  public constructor(public payload: { entityId: string }) { }
}

export class LoadAvailableFormsSuccess implements Action {
  public readonly type = NavigationActionTypes.LoadAvailableFormsSuccess;
  public constructor(public payload: { entityId: string; forms: FormOption[] }) { }
}

export class ChangeSelectedForm implements Action {
  public readonly type = NavigationActionTypes.ChangeSelectedForm;
  public constructor(public payload: { formId: string }) { }
}

export class ReplaceUrlToToken implements Action {
  public readonly type = NavigationActionTypes.ReplaceUrlToToken;
  public constructor(public payload: { routerState: IRouterStateUrl }) { }
}

export class NavigateByRouteData implements Action {
  public readonly type = NavigationActionTypes.NavigateByRouteData;
  public constructor(
    public payload: {
      routeData: Params;
      preserveState?: any;
      popCount?: number;
      queryParams?: Params;
      onSuccess?: () => void;
    },
  ) { }
}

export class NavigateByNavigationPath implements Action {
  public readonly type = NavigationActionTypes.NavigateByNavigationPath;
  public constructor(
    public payload: {
      navigationPath: string;
      preserveState?: any;
      popCount?: number;
      queryParams?: Params;
    },
  ) { }
}

export class NavigateByNavigationTargets implements Action {
  public readonly type = NavigationActionTypes.NavigateByNavigationTargets;
  public constructor(
    public payload: {
      navigationTargets: NavigationTargetDto[];
      queryParams?: Params;
      onSuccess?: () => void;
    },
  ) { }
}

export class NavigateToHomePage implements Action {
  public readonly type = NavigationActionTypes.NavigateToHomePage;
}

export class NavigateToRecentOrDefaultTarget implements Action {
  public readonly type = NavigationActionTypes.NavigateToRecentOrDefaultTarget;
  public constructor() { };
}

export class NavigateBack implements Action {
  public readonly type = NavigationActionTypes.NavigateBack;
  public constructor(public payload: { queryParams?: Params; isConfirmDisabled?: boolean }) { }
}

export class NavigateToTopNavigationStackItem implements Action {
  public readonly type = NavigationActionTypes.NavigateToTopNavigationStackItem;
}

export class AddQueryParamsToTopNavigationStackItem implements Action {
  public readonly type = NavigationActionTypes.AddQueryParamsToTopNavigationStackItem;
  public constructor(public payload: { queryParams: Params }) { }
}

export class SetQueryParamsInTopNavigationStackItem implements Action {
  public readonly type = NavigationActionTypes.SetQueryParamsInTopNavigationStackItem;
  public constructor(public payload: { queryParams: Params }) { }
}

export class NavigateToPrevNavigationStackItem implements Action {
  public readonly type = NavigationActionTypes.NavigateToPrevNavigationStackItem;
  public constructor(public payload: { popCount: number; queryParams?: Params; isConfirmDisabled?: boolean }) { }
}

export class SaveNavigationState implements Action {
  public readonly type = NavigationActionTypes.SaveNavigationState;
  public constructor(public payload: { state: any }) { }
}

export class PushNavigationStackItem implements Action {
  public readonly type = NavigationActionTypes.PushNavigationStackItem;
  public constructor(public payload: { item: NavigationStackItem }) { }
}

export class PopNavigationStackItems implements Action {
  public readonly type = NavigationActionTypes.PopNavigationStackItems;
  public constructor(public payload: { popCount: number }) { }
}

export class ClearNavigationStack implements Action {
  public readonly type = NavigationActionTypes.ClearNavigationStack;
  public constructor() { }
}

export class SetIsDirty implements Action {
  public readonly type = NavigationActionTypes.SetIsDirty;
  public constructor(public payload: { isDirty: boolean }) { }
}

export class ShowLongBreadcrumb implements Action {
  public readonly type = NavigationActionTypes.ShowLongBreadcrumb;
}
export class ShowShortBreadcrumb implements Action {
  public readonly type = NavigationActionTypes.ShowShortBreadcrumb;
}

export type NavigationActions =
  | LoadNavigationTargets
  | LoadNavigationTargetsSuccess
  | LoadAvailableForms
  | LoadAvailableFormsSuccess
  | ChangeSelectedForm
  | ReplaceUrlToToken
  | NavigateByRouteData
  | NavigateByNavigationPath
  | NavigateByNavigationTargets
  | NavigateToHomePage
  | NavigateBack
  | NavigateToTopNavigationStackItem
  | NavigateToPrevNavigationStackItem
  | SaveNavigationState
  | PushNavigationStackItem
  | ClearNavigationStack
  | PopNavigationStackItems
  | AddQueryParamsToTopNavigationStackItem
  | SetQueryParamsInTopNavigationStackItem
  | SetIsDirty
  | ShowLongBreadcrumb
  | ShowShortBreadcrumb;
