import { ProcessActions, ProcessActionsTypes } from './actions';
import { IProcessState, initialProcessState } from './state';

export function processReducer(state = initialProcessState, action: ProcessActions): IProcessState {
  switch (action.type) {
    case ProcessActionsTypes.LoadProcessEntityAssignmentsSuccess: {
      return {
        ...state,
        processEntityAssignments: {
          ...state.processEntityAssignments,
          [action.payload.entityId]: action.payload.procesEntitytAssignments,
        },
      };
    }
    case ProcessActionsTypes.LoadProcessStateWithTransitionsSuccess: {
      let sourceStateId = action.payload.sourceStateId;
      return {
        ...state,
        processStates: { ...state.processStates, [sourceStateId]: action.payload.processState },
      };
    }
    case ProcessActionsTypes.LoadProcessHistorySuccess: {
      let processesHistory = { ...state.processHistory };
      processesHistory[action.payload.processAssignmentId] = [];
      processesHistory[action.payload.processAssignmentId].push(action.payload.processHistoryItems);

      return {
        ...state,
        processHistory: { ...processesHistory },
      };
    }
    default:
      return state;
  }
}
