import { Component, Inject } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { UploadBlob } from '../store/actions';
import { Store } from '@ngrx/store';
import { FileParameter } from 'src/core/services/api-clients';
import { IUploadDialogConfig } from 'src/engine-sdk';
import { EngineTranslationService } from '@core/engine-translations/services/translation.service';

@Component({
  selector: 'app-upload-dialog',
  templateUrl: './upload-dialog.component.html',
  styleUrls: ['./upload-dialog.component.scss'],
})
export class UploadDialogComponent {
  fileToUpload: FileParameter;
  newBlobId: string = null;
  filename: string;
  title: string = 'Upload file';

  constructor(
    private _store: Store,
    @Inject(MAT_DIALOG_DATA) public data: IUploadDialogConfig,
    public dialogRef: MatDialogRef<UploadDialogComponent>,
    private _translate: EngineTranslationService,
  ) {
    if (this.data.title) {
      this.title = this.data.title;
    } else {
      this.title = this._translate.translateInstantly('FileForImport.FileForImportLabel');
    }
  }

  onFileSelected(event: Event) {
    let file = (event.target as HTMLInputElement).files[0];
    if (file == undefined || file == null) return;
    this.fileToUpload = { data: file, fileName: file.name };
    if (file.name.length > 30) {
      this.filename = file.name.substring(0, 30) + '...' + file.name.slice(file.name.lastIndexOf('.'));
    } else {
      this.filename = file.name;
    }
  }

  sendFile() {
    if (this.fileToUpload !== undefined) {
      let obj = {
        file: this.fileToUpload,
        name: this.fileToUpload.fileName,
        type: this.data.blobType,
        onSuccess: (newBlobId: string) => {
          if (this.data.onSuccess) {
            this.data.onSuccess(newBlobId);
          }
          this.close();
        },
        onError: () => {
          if (this.data.onError) {
            this.data.onError();
          }
          this.close();
        },
      };

      this._store.dispatch(new UploadBlob(obj));
    }
  }

  close(): void {
    this.dialogRef.close();
  }
}
