<div *ngIf="settings" class="list-toolbar-container">
  <mat-form-field *ngIf="!disabled && settings.isGlobalSearchEnabled" class="list-toolbar-search">
    <input
      matInput
      placeholder="{{ 'Search' | translate }}"
      [formControl]="globalSearchControl"
      #globalSearch
      (mousedown)="stopInputPropagation($event)"
      (click)="stopInputPropagation($event)"
      (keydown)="stopInputPropagation($event)" />
  </mat-form-field>

  <ng-container *ngTemplateOutlet="isCollapsed ? smallToolbar : largeToolbar"></ng-container>

  <ng-template #smallToolbar>
    <span class="hamburger-section-menu list-buttons-container">
      <button type="button" mat-button [matMenuTriggerFor]="menu" [disabled]="disabled">
        <img class="menu-icon" />
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <ng-container [ngTemplateOutlet]="buttons"> </ng-container>
      </mat-menu>
    </span>
  </ng-template>

  <ng-template #largeToolbar>
    <span class="list-buttons-container">
      <ng-container [ngTemplateOutlet]="buttons"> </ng-container>
    </span>
  </ng-template>
</div>

<ng-template #buttons>
  <div class="list-toolbar-buttons-container" (click)="$event.stopPropagation()">
    <button
      type="button"
      mat-button
      (click)="changeGlobalSearchVisibility(!settings.isGlobalSearchEnabled)"
      [ngClass]="{
        'list-toolbar-element-unchecked': !settings.isGlobalSearchEnabled,
        'list-toolbar-element-checked': settings.isGlobalSearchEnabled
      }"
      [disabled]="disabled"
      [matTooltip]="'View.ActivateSearch' | translate"
      matTooltipPosition="below">
      <mat-icon *ngIf="!settings.isGlobalSearchEnabled">search</mat-icon>
      <mat-icon *ngIf="settings.isGlobalSearchEnabled">search_off</mat-icon>
    </button>

    <button
      type="button"
      mat-button
      (click)="changeFiltersVisibility(!settings.areFiltersEnabled)"
      [ngClass]="{
        'list-toolbar-element-checked': settings.areFiltersEnabled,
        'list-toolbar-element-unchecked': !settings.areFiltersEnabled
      }"
      [disabled]="disabled"
      [matTooltip]="'View.ActivateFilters' | translate"
      matTooltipPosition="below">
      <mat-icon>filter_alt</mat-icon>
    </button>

    <app-view-selector
      (viewTabsVisibilityChange)="viewTabsVisibilityChange.emit($event)"
      [disabled]="disabled"
      [viewTabs]="viewTabs"
      [selectionMode]="viewTabsSelectionMode"
      [matTooltip]="(viewTabsSelectionMode === 'multi' ? 'View.SelectViews' : 'View.SelectView') | translate"
      matTooltipPosition="below">
    </app-view-selector>

    <button
      type="button"
      mat-button
      (click)="refreshData.emit()"
      [disabled]="disabled"
      [matTooltip]="'View.RefreshData' | translate"
      matTooltipPosition="below">
      <mat-icon>autorenew</mat-icon>
    </button>

    <button
      type="button"
      mat-button
      (click)="onRestoreSettingsClick()"
      [disabled]="disabled"
      [matTooltip]="'View.RestoreSettings' | translate"
      matTooltipPosition="before">
      <mat-icon>settings_backup_restore</mat-icon>
    </button>

    <button
      type="button"
      mat-button
      (click)="navigateToSettings.emit()"
      [disabled]="disabled"
      [matTooltip]="'View.ViewSettings' | translate"
      matTooltipPosition="before">
      <mat-icon>settings</mat-icon>
    </button>
  </div>
</ng-template>
