import { environment } from '@env';

export class UrlProvider {
  get BaseUrl() {
    return this.getBaseUrl();
  }

  get ApiUrl() {
    return this.BaseUrl + '/api';
  }

  get ODataUrl() {
    return this.BaseUrl + '/odata';
  }

  private getBaseUrl() {
    let url = window.location.origin;

    let serverUrlFromConfig = environment.urlMappings.filter((x) => x.client === url)[0];
    if (serverUrlFromConfig) return serverUrlFromConfig.server;

    let regex = /(http[s]?\:\/\/)(([A-Za-z0-9\-]+)(\-[A-Za-z]+)+)\.([A-Za-z0-9\.]+)/gm;
    if (regex.test(url)) {
      return url.replace(regex, '$1$3-server$4.$5');
    }

    regex = /(http[s]?\:\/\/)([A-Za-z0-9\-]+)\.([A-Za-z0-9\.]+)/gm;
    if (regex.test(url)) {
      return url.replace(regex, '$1$2-server.$3');
    }

    throw "Can't determine the server url";
  }
}
