import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { IAuthService } from '../models/iauth-service.model';
import { AuthGuard } from './auth.guard';

@Injectable()
export class BasicAuthGuard implements AuthGuard {
  constructor(private _router: Router, private _authService: IAuthService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this._authService.isUserAuthenticated()) return true;

    this._router.navigate(['/']);
    return false;
  }
}
