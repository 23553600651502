import { ChangeDetectionStrategy, Component, ElementRef, NgZone, Renderer2, ViewChild } from '@angular/core';
import { BusyIndicatorService } from '../../services/busy-indicator.service';
import { map, debounce, tap, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Subject, timer } from 'rxjs';

@Component({
  selector: 'app-busy-indicator',
  templateUrl: './busy-indicator.component.html',
  styleUrls: ['./busy-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BusyIndicatorComponent {
  private _destroy$: Subject<boolean> = new Subject<boolean>();

  @ViewChild('spinnerElement', { static: true }) spinnerElement: ElementRef;

  showIndicator$ = this._busyIndicatorService.operationsInProgress$.pipe(
    distinctUntilChanged(),
    debounce((operations) => (operations > 0 ? timer(0) : timer(200))),
    map((operations) => operations > 0),
    tap((showSpinner) => {
      if (showSpinner) {
        this.showSpinner();
      } else {
        this.hideSpinner();
      }
    }),
  );

  constructor(
    private _busyIndicatorService: BusyIndicatorService,
    private ngZone: NgZone,
    private renderer: Renderer2,
  ) {
    this.showIndicator$.pipe(takeUntil(this._destroy$)).subscribe();
  }

  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
  }

  private hideSpinner(): void {
    this.ngZone.runOutsideAngular(() => {
      this.renderer.setStyle(this.spinnerElement.nativeElement, 'display', 'none');
    });
  }

  private showSpinner(): void {
    this.ngZone.runOutsideAngular(() => {
      this.renderer.setStyle(this.spinnerElement.nativeElement, 'display', 'block');
    });
  }
}
