<ng-container *ngIf="isWide && (menuConfiguration?.logo || selectedMenuArea?.logo)">

  <ng-container *ngIf="selectedMenuArea?.logo?.iconRelativePath; else menuConfigurationLogo">
    <img class="logo" [src]="selectedMenuArea?.logo?.iconRelativePath | webResourceUri | async" />
  </ng-container>

  <ng-template #menuConfigurationLogo>
    <ng-container *ngIf="menuConfiguration?.logo?.iconRelativePath">
      <img class="logo" [src]="menuConfiguration?.logo?.iconRelativePath | webResourceUri | async" />
    </ng-container>
  </ng-template>

</ng-container>

<ng-container *ngIf="!isWide && (menuConfiguration?.miniLogo || selectedMenuArea?.miniLogo)">

  <ng-container *ngIf="selectedMenuArea?.miniLogo?.iconRelativePath; else menuConfigurationMiniLogo">
    <img class="mini-logo" [src]="selectedMenuArea?.miniLogo?.iconRelativePath | webResourceUri | async" />
  </ng-container>

  <ng-template #menuConfigurationMiniLogo>
    <ng-container *ngIf="menuConfiguration?.miniLogo?.iconRelativePath">
      <img class="mini-logo" [src]="menuConfiguration?.miniLogo?.iconRelativePath | webResourceUri | async" />
    </ng-container>
  </ng-template>

</ng-container>
