import { UrlProvider } from './url-provider';
import { config } from 'src/environments/configuration.js';
import { AuthenticationType } from '@core/auth/models/authentication-type.enum';

declare const config: any;

export const environment = {
  version: config.version,
  production: config.production,
  authenticationType: AuthenticationType[config.authenticationType as string],
  msalModule: config.msalModule,
  urlMappings: config.urlMappings,
  urls: new UrlProvider(),
};
