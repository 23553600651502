import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AuthSelectors } from '@core/auth/store';
import { isFatalError } from '@core/errors/store/selectors';
import { ScreenSize } from '@core/layout/models/screen-size.enum';
import { BusyIndicatorService } from '@core/layout/services/busy-indicator.service';
import { LayoutActions } from '@core/layout/store';
import { ToggleLeftPanel, CloseRightPanel, OpenRightPanel } from '@core/layout/store/actions';
import { getScreenSize } from '@core/layout/store/selectors';
import { MenuActions } from '@core/menu/store';
import { DisableMainMenuPopup, EnableMainMenuPopup } from '@core/menu/store/actions';
import { ShowShortBreadcrumb, ShowLongBreadcrumb } from '@core/navigation/store/actions';
import { UserActions, UserSelectors } from '@core/user/store';
import { isUserLoggedIn, isNotAuthorized } from '@core/user/store/selectors';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnDestroy {
  private _destroy$: Subject<boolean> = new Subject<boolean>();
  isIframe = window !== window.parent && !window.opener;
  isUserLoggedIn$ = this._store.select(isUserLoggedIn);
  isNotAuthorized$ = this._store.select(isNotAuthorized);
  isFatalError$ = this._store.select(isFatalError);
  isAuthenticated$ = this._store
    .select(AuthSelectors.isAuthenticated) // TODO: może dwa serwisy ? jeden wewnętrzny a drugi zewnętrzny?
    .pipe(
      takeUntil(this._destroy$),
      tap((isAuthenticated) => {
        if (isAuthenticated) {
          this._store.dispatch(new UserActions.LoadUserData());
          this._store.dispatch(new UserActions.LoadUILanguages());
        }
      }),
    )
    .subscribe();

  currentUserId$ = this._store
    .select(UserSelectors.getCurrentUserId)
    .pipe(
      takeUntil(this._destroy$),
      tap((currentUserId) => {
        if (currentUserId) {
          this._store.dispatch(new MenuActions.LoadMainMenu());
          this._store.dispatch(new LayoutActions.InitLayout());
        }
      }),
    )
    .subscribe();

  screenSize$ = this._store
    .select(getScreenSize)
    .pipe(
      takeUntil(this._destroy$),
      filter((size) => !!size),
      tap((size) => {
        if (size <= ScreenSize.MD) {
          this._store.dispatch(new ShowShortBreadcrumb());
          this._store.dispatch(new DisableMainMenuPopup());
        } else {
          this._store.dispatch(new ShowLongBreadcrumb());
          this._store.dispatch(new EnableMainMenuPopup());
        }
      }),
    )
    .subscribe();

  constructor(private _store: Store, private _busyService: BusyIndicatorService) {
    this.displayBusyIndicatorOnColdStart();
  }

  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
  }

  onMenuBarClick() {
    this._store.dispatch(new ToggleLeftPanel());
  }

  closeUserSidenav() {
    this._store.dispatch(new CloseRightPanel());
  }

  openRightPanel() {
    this._store.dispatch(new OpenRightPanel());
  }

  private displayBusyIndicatorOnColdStart() {
    this._busyService.newOperation();
    setTimeout(() => this._busyService.operationFinished(), 1000);
  }
}
