import { Injectable } from '@angular/core';
import { ODataConfig } from './odata-config.service';
import { Observable } from 'rxjs';
import { ODataServiceFactory, ODataQuery, ODataService } from '@shared/odata';

@Injectable()
export class BaseODataService<TEntity> {
  protected readonly MAX_COUNT = 2147483647;

  protected _service: ODataService<TEntity>;

  constructor(private _serviceFactory: ODataServiceFactory, private _typeName: string) {
    this._service = this._serviceFactory.CreateService<TEntity>(this._typeName, new ODataConfig());
  }

  create(entity: TEntity) {
    return this._service.Post(entity).Exec();
  }

  get(id: string): Observable<TEntity> {
    return this._service.Get(id).Exec();
  }

  getAll(): Observable<TEntity[]> {
    return this._service.Query().MaxPerPage(this.MAX_COUNT).Exec();
  }

  update(id: string, entity: TEntity) {
    return this._service.Patch(entity, id).Exec();
  }

  delete(id: string) {
    return this._service.Delete(id).Exec();
  }

  query(): ODataQuery<TEntity> {
    return this._service.Query();
  }
}
