import { Inject, Injectable, OnDestroy } from '@angular/core';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { Store } from '@ngrx/store';
import { IAuthService } from '../models/iauth-service.model';
import { AuthenticationType } from '../models/authentication-type.enum';
import { MsalAuthBaseService } from './msal-auth-base.service';

@Injectable()
export class AzureADAuthService extends MsalAuthBaseService implements IAuthService, OnDestroy {
  constructor(
    @Inject(MSAL_GUARD_CONFIG) _msalGuardConfig: MsalGuardConfiguration,
    _broadcastService: MsalBroadcastService,
    _msalService: MsalService,
    _store: Store,
  ) {
    super(_msalGuardConfig, _broadcastService, _msalService, _store);
  }

  getAuthenticationType(): AuthenticationType {
    return AuthenticationType.AzureActiveDirectory;
  }
}
