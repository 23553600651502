import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { KanbanColumnAggregatorDto } from '@core/services/api-clients';

@Component({
  selector: 'app-kanban-column-aggregators',
  templateUrl: './kanban-column-aggregators.component.html',
  styleUrls: ['./kanban-column-aggregators.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KanbanColumnAggregatorsComponent {
  selectedAggregator: string;

  @Input() aggregators: KanbanColumnAggregatorDto[] = [];
  @Input() aggregatorsData: { [aggregatorId: string]: number };

  @Output() aggregatorSelect = new EventEmitter<KanbanColumnAggregatorDto>();
}
