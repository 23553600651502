import { Component, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { NavigationTargetDto } from '@core/services/api-clients';
import { BreadcrumbItem } from './long-breadcrumb/breadcrumb-item/breadcrumb-item.component';
import { NavigateToHomePage, ChangeSelectedForm, NavigateToPrevNavigationStackItem } from '../store/actions';
import { isLongBreadcrumbVisible, getCurrentNavigationTargets, getCurrentEntityForms } from '../store/selectors';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbComponent {
  @Output() selectionChange = new EventEmitter<any>();

  isLongBreadcrumbVisible$ = this._store.select(isLongBreadcrumbVisible);
  navigationItems$ = this._store
    .select(getCurrentNavigationTargets)
    .pipe(map((targets) => this.mapNavigationTarget(targets)));
  availableForms$ = this._store.select(getCurrentEntityForms);

  constructor(private _store: Store) {}

  navigateToHomePage() {
    this._store.dispatch(new NavigateToHomePage());
  }

  onBreadcrumbClick(breadcrumb: BreadcrumbItem) {
    if (breadcrumb.popCount) {
      this.navigateToPrevNavigationTarget(breadcrumb.popCount);
    }
  }

  onFormSelected(formId: string) {
    this._store.dispatch(new ChangeSelectedForm({ formId }));
  }

  navigateToPrevNavigationTarget(popCount: number) {
    this._store.dispatch(new NavigateToPrevNavigationStackItem({ popCount }));
  }

  private mapNavigationTarget(navigationTargets: NavigationTargetDto[]): BreadcrumbItem[] {
    const items = navigationTargets
      ? navigationTargets.map((target, index) => {
          return {
            label: this.getNavigationTargetLabel(target),
            type: this.getNavigationTargetType(target),
            popCount: navigationTargets.length - index - 1,
          };
        })
      : [];

    return items;
  }

  private getNavigationTargetLabel(navigationTarget: NavigationTargetDto) {
    if (navigationTarget.recordId) return navigationTarget.recordName ? navigationTarget.recordName : 'Record';

    if (navigationTarget.formId) return navigationTarget.formName ? navigationTarget.formName : 'Form';

    if (navigationTarget.viewId) return navigationTarget.viewName ? navigationTarget.viewName : 'View';

    if (navigationTarget.name) return navigationTarget.name;

    return 'Unknown';
  }

  private getNavigationTargetType(navigationTarget: NavigationTargetDto): 'record' | 'view' | 'form' | 'unknown' {
    if (navigationTarget.recordId) return 'record';

    if (navigationTarget.formId) return 'form';

    if (navigationTarget.viewId) return 'view';

    return 'unknown';
  }
}
