import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { FilterService } from '@progress/kendo-angular-grid';
import { OptionSet } from '../../../models/grid-definition.model';
import { LodashService } from '@core/services/lodash.service';
import { EngineTranslationService } from '@core/engine-translations/services/translation.service';

@Component({
  selector: 'app-option-set-filter',
  templateUrl: './option-set-filter.component.html',
  styleUrls: ['./option-set-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptionSetFilterComponent implements AfterViewInit {
  static readonly EMPTY_NAME = 'View.OptionSetEmptyValue';
  static readonly NOT_EMPTY_NAME = 'View.OptionSetNotEmptyValue';

  private _optionSet: OptionSet;

  get optionSet(): OptionSet {
    return this._optionSet;
  }
  @Input() set optionSet(value: OptionSet) {
    this._optionSet = value;

    if (this._optionSet) {
      this.addEmptyAndNotEmptyValuesToOptionSet(this._optionSet);
    }
  }
  @Input() currentFilter: CompositeFilterDescriptor;
  @Input() filterService: FilterService;
  @Input() field: string;

  value: string;

  constructor(private _translationService: EngineTranslationService, private _changeDetectorRef: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    const currentColumnFilter = <FilterDescriptor>(
      this.currentFilter.filters.find((filter: FilterDescriptor) => filter.field === this.field)
    );

    if (currentColumnFilter) {
      if (currentColumnFilter.operator == 'isnull') this.value = OptionSetFilterComponent.EMPTY_NAME;
      else if (currentColumnFilter.operator == 'isnotnull') this.value = OptionSetFilterComponent.NOT_EMPTY_NAME;
      else this.value = currentColumnFilter.value;

      this._changeDetectorRef.markForCheck();
    }
  }

  onValueChange(value: string): void {
    if (value === null || value === undefined) {
      this.filterService.filter({
        filters: [],
        logic: 'and',
      });
    } else if (value === OptionSetFilterComponent.EMPTY_NAME) {
      this.filterService.filter({
        filters: [{ field: this.field, operator: 'isnull' }],
        logic: 'and',
      });
    } else if (value === OptionSetFilterComponent.NOT_EMPTY_NAME) {
      this.filterService.filter({
        filters: [{ field: this.field, operator: 'isnotnull' }],
        logic: 'and',
      });
    } else {
      this.filterService.filter({
        filters: [this.getValueFilter(value)],
        logic: 'and',
      });
    }
  }

  private getValueFilter(value: string): FilterDescriptor {
    if (this.optionSet.isFlags) {
      return {
        field: this.field,
        operator: 'has',
        value: `${this.optionSet.name}'${value}'`,
      };
    } else {
      return {
        field: this.field,
        operator: 'eq',
        value: value,
      };
    }
  }

  private addEmptyAndNotEmptyValuesToOptionSet(optionSet: OptionSet) {
    const emptyTranslation = this._translationService.translateInstantly(OptionSetFilterComponent.EMPTY_NAME);
    const notEmptyTranslation = this._translationService.translateInstantly(OptionSetFilterComponent.NOT_EMPTY_NAME);
    const finalOptionSet: OptionSet = LodashService.cloneDeep(optionSet);
    finalOptionSet.values.push({
      label: emptyTranslation,
      name: OptionSetFilterComponent.EMPTY_NAME,
      value: -1,
    });
    finalOptionSet.values.push({
      label: notEmptyTranslation,
      name: OptionSetFilterComponent.NOT_EMPTY_NAME,
      value: -2,
    });
    this._optionSet = finalOptionSet;
  }
}
