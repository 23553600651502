import { ModuleWithProviders, NgModule } from '@angular/core';
import { UserPanelComponent } from './user-panel/user-panel.component';
import { SharedModule } from '@shared/shared.module';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { FEATURE_NAME } from './store/state';
import { UserEffects } from './store/effects';
import { reducers } from './store';
import { UserAvatarComponent } from './user-avatar/user-avatar.component';
import { UserTopbarSectionComponent } from './user-topbar-section/user-topbar-section.component';
import { NotAuthorizedComponent } from './not-authorized/not-authorized.component';
import { UserColorService } from './services/user-color.service';

@NgModule({
  declarations: [UserPanelComponent, UserAvatarComponent, UserTopbarSectionComponent, NotAuthorizedComponent],
  imports: [StoreModule.forFeature(FEATURE_NAME, reducers), EffectsModule.forFeature([UserEffects]), SharedModule],
  exports: [UserPanelComponent, UserTopbarSectionComponent, NotAuthorizedComponent],
})
export class UserModule {
  static forRoot(): ModuleWithProviders<UserModule> {
    return {
      ngModule: UserModule,
      providers: [UserColorService],
    };
  }

  constructor() {}
}
