import { Component, forwardRef, Inject, OnDestroy, OnInit, Optional, SkipSelf } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { withLatestFrom, tap, filter, map, takeUntil } from 'rxjs/operators';
import { MainMenuDto, MenuAreaDto, MenuItemDto } from '@core/services/api-clients';
import { SelectedMenuGroup } from '../shared/menu-group/menu-group.component';
import {
  SelectMenuArea,
  AddFavoriteMenuItem,
  RemoveFavoriteMenuItem,
  SelectMenuItem,
  ToggleIsFavoriteMode,
  SelectMenuGroup,
} from '../store/actions';
import {
  getMenuAreas,
  getFilledFavoriteMenuGroup,
  getFilledRecentMenuGroup,
  isPopupEnabled,
  isFavoriteModeEnabled,
  getSelectedMenuArea,
  getSelectedMenuAreaMenuGroups,
  getSelectedMenuGroupId,
  getSelectedMenuItemId,
  getMenuItems,
  getSelectedMenuAreaId,
  getMenuConfiguration,
  getMainMenu,
} from '../store/selectors';
import { WidgetDirective } from '@core/widgets/directives/widget.directive';
import { NoEventWidgetDirective } from '@core/widgets/directives/no-event-widget.directive';
import { IScriptRunnerService, SCRIPT_RUNNER_SERVICE } from '@core/widgets/models/iscript-runner.service';
import { LayoutActions, LayoutSelectors } from '@core/layout/store';
import { getNavigationStack } from '../../navigation/store/selectors';
import { NavigationStackItem } from '../../navigation/store/state';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss'],
  providers: [
    {
      provide: WidgetDirective,
      useExisting: forwardRef(() => MainMenuComponent),
    },
  ],
})
export class MainMenuComponent extends NoEventWidgetDirective implements OnInit, OnDestroy {
  showWideMenu$ = this._store.select(LayoutSelectors.getIsLeftPanelExpanded);
  isMainMenuPopupEnabled$ = this._store.select(isPopupEnabled);
  isFavoriteModeEnabled$ = this._store.select(isFavoriteModeEnabled);

  favoriteGroup$ = this._store.pipe(
    select(getFilledFavoriteMenuGroup),
    tap((favoriteGroup) => {
      if (!!favoriteGroup) {
        this.favorites = {};
        favoriteGroup.menuItems.forEach((item) => {
          this.favorites[item.id] = true;
        });
      }
    }),
  );
  recentGroup$ = this._store.pipe(select(getFilledRecentMenuGroup));
  specialGroups$ = combineLatest([this.favoriteGroup$, this.recentGroup$]).pipe(
    map((specialGroups) => specialGroups.filter((g) => !!g)),
  );

  menuAreas$ = this._store.pipe(select(getMenuAreas));
  menuGroups$ = this._store.pipe(select(getSelectedMenuAreaMenuGroups));
  menuItems$ = this._store.pipe(select(getMenuItems));
  selectedMenuAreaId$ = this._store.pipe(select(getSelectedMenuAreaId));
  selectedMenuArea$ = this._store.pipe(select(getSelectedMenuArea));
  selectedMenuGroupId$ = this._store.pipe(select(getSelectedMenuGroupId));
  selectedMenuItemId$ = this._store.pipe(select(getSelectedMenuItemId));
  menuConfiguration$ = this._store.pipe(select(getMenuConfiguration));
  mainMenu$ = this._store.pipe(select(getMainMenu));
  navigationStack$ = this._store.pipe(select(getNavigationStack));

  favorites: { [id: string]: boolean } = {};

  constructor(
    @Optional() @SkipSelf() parentWidget: WidgetDirective,
    @Inject(SCRIPT_RUNNER_SERVICE) scriptRunnerService: IScriptRunnerService,
    private _store: Store,
  ) {
    super(parentWidget, scriptRunnerService);
    this._minWidgetsNumber = 1;
  }

  ngOnInit(): void {
    this.menuAreas$
      .pipe(
        takeUntil(this._destroy$),
        withLatestFrom(this.selectedMenuArea$),
        filter(([menuAreas, selected]) => !!menuAreas && menuAreas.length > 0 && !selected),
        tap(([menuAreas, _]) => {
          const defaultMenuArea = menuAreas.find((a) => a.isDefault);
          if (defaultMenuArea != null) this._store.dispatch(new SelectMenuArea({ menuAreaId: defaultMenuArea.id }));
          else this._store.dispatch(new SelectMenuArea({ menuAreaId: menuAreas[0].id }));
        }),
      )
      .subscribe();

    this.navigationStack$
      .pipe(
        takeUntil(this._destroy$),
        withLatestFrom(this.selectedMenuArea$, this.selectedMenuItemId$, this.mainMenu$),
        filter(([stack, selectedMenuArea, selectedMenuItemId, mainMenu]) => stack != null && stack.length > 0 && selectedMenuArea != null && selectedMenuItemId != null && mainMenu != null),
        tap(([stack, selectedMenuArea, selectedMenuItemId, mainMenu]) => {
          for (const item of [...stack].reverse()) {
            let menuItems = this.findMenuItemsForNavigationStackItem(item, selectedMenuArea, mainMenu);
            if (menuItems.some(x => x.id == selectedMenuItemId)) {
              return;
            };

            for (const menuArea of mainMenu.menuAreas.filter(a => a.id != selectedMenuArea.id)) {
              let menuItems = this.findMenuItemsForNavigationStackItem(item, menuArea, mainMenu);

              if (menuItems.length > 0) {
                this._store.dispatch(new SelectMenuItem({ menuItem: menuItems[0], preventNavigation: true }));
                return;
              }
            }
          }
        })
      ).subscribe();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this._destroy$.next(true);
    this._destroy$.complete();
  }

  onMenuAreaChange(menuAreaId: string) {
    this._store.dispatch(new SelectMenuArea({ menuAreaId }));
  }

  addMenuItemToFavorite(menuItem: MenuItemDto) {
    this._store.dispatch(new AddFavoriteMenuItem({ menuItem: menuItem }));
  }

  removeMenuItemFromFavorite(menuItem: MenuItemDto) {
    this._store.dispatch(new RemoveFavoriteMenuItem({ menuItem: menuItem }));
  }

  onMenuItemClick(menuItem: MenuItemDto) {
    this._store.dispatch(new SelectMenuItem({ menuItem }));
  }

  onMenuGroupClick(selectedMenuGroup: SelectedMenuGroup) {
    this._store.dispatch(new SelectMenuGroup({ menuGroupId: selectedMenuGroup.menuGroupId }));
  }

  toggleExpansion() {
    this._store.dispatch(new LayoutActions.ToggleIsLeftPanelExpanded());
  }

  toggleFavoriteMode() {
    this._store.dispatch(new ToggleIsFavoriteMode());
  }

  private findMenuItemsForNavigationStackItem(item: NavigationStackItem, menuArea: MenuAreaDto, mainMenu: MainMenuDto): MenuItemDto[] {
    let menuItems: MenuItemDto[] = [];

    for (const menuGroupId of menuArea.menuGroupIds) {
      const menuItemIds = mainMenu.menuGroups[menuGroupId].menuItemIds;
      menuItems.push(...menuItemIds.map(id => mainMenu.menuItems[id]).filter(x => x.navigationToken == item.navigationToken));
    }

    return menuItems;
  }
}
