import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { WidgetsModule } from '@core/widgets/widgets.module';
import { MainViewComponent } from './main-view.component';
import { EngineViewsModule } from '@core/engine-views/engine-views.module';
import { ContextMenuModule } from '@core/context-menu/context-menu.module';
import { NotificationModule } from '@core/notification/notification.module';

@NgModule({
  declarations: [MainViewComponent],
  imports: [SharedModule, WidgetsModule, EngineViewsModule, NotificationModule, ContextMenuModule],
  exports: [MainViewComponent],
})
export class MainViewModule {}
