import { Injectable } from '@angular/core';
import { environment } from '@env';
import { ISystemContext } from 'src/engine-sdk';

@Injectable({
  providedIn: 'root',
})
export class SystemService implements ISystemContext {
  getServerUrl(): string {
    return environment.urls.BaseUrl;
  }
}
