import { Pipe, PipeTransform } from '@angular/core';
import { GridColumn } from '../models/grid-definition.model';
import { DatePipe } from '@angular/common';
import { LookupPipe } from './lookup.pipe';

@Pipe({ name: 'dateTime' })
export class DateTimePipe implements PipeTransform {
  private _lookupPipe: LookupPipe = new LookupPipe();

  constructor(private _datePipe: DatePipe) { }

  transform(dataItem: any, column: GridColumn): string {
    const value = this._lookupPipe.transform(dataItem, column);

    if (!value) return null;
    return this._datePipe.transform(value, this.getDateFormat(column));
  }

  private getDateFormat(column: GridColumn): string {
    const isDateOnlyColumn = column.type == 'date';
    switch (column.dateTimeFormatType) {
      case 'custom':
        return column.dateTimeFormat;
      case 'long':
        return isDateOnlyColumn ? 'longDate' : 'long';
      case 'short':
      default:
        return isDateOnlyColumn ? 'shortDate' : 'short';
    }
  }
}
