<ng-container *ngIf="definition$ | async as definition">
  <ng-container *ngIf="state$ | async as state">
    <div
      *ngIf="definition && state"
      class="context-menu"
      [class.hidden]="!state.isVisible"
      appResize
      (windowResized)="onContainerWidthChange($event)">
      <ng-container *ngFor="let sectionDefinition of definition.sections">
        <app-context-menu-section
          class="context-menu-section"
          [sectionDefinition]="sectionDefinition"
          [contextMenuState]="state"></app-context-menu-section>
      </ng-container>
      <mat-divider *ngIf="definition.useDivider" class="divider" [vertical]="true"></mat-divider>
    </div>
  </ng-container>
</ng-container>
