import { Injectable, InjectionToken } from '@angular/core';
import { Store } from '@ngrx/store';
import { NotificationGroup } from 'src/engine-sdk';
import { NotificationGroupsBuilder } from '../models/notification-context.model';
import { NotificationsActions } from '../store';

export const ENGINE_NOTIFICATION_SERVICE = new InjectionToken<IEngineNotificationService>('IEngineNotificationService');

export interface IEngineNotificationService {
  getNotificationGroupsBuilder(entityName?: string): NotificationGroupsBuilder;
  addNotifications(notifications: NotificationGroup[]): void;
  setNotifications(notifications: NotificationGroup[]): void;
  removeAllNotifications(): void;
  removeNotificationGroup(notification: NotificationGroup): void;
  removeNotifications(notifications: NotificationGroup): void;
}

@Injectable()
export class EngineNotificationService implements IEngineNotificationService {
  constructor(private _store: Store) {}

  getNotificationGroupsBuilder(entityName?: string) {
    return new NotificationGroupsBuilder(entityName);
  }

  addNotifications(notifications: NotificationGroup[]) {
    this._store.dispatch(new NotificationsActions.AddNotifications({ notificationGroups: notifications }));
  }

  setNotifications(notifications: NotificationGroup[]) {
    this._store.dispatch(new NotificationsActions.SetNotifications({ notificationGroups: notifications }));
  }

  removeAllNotifications() {
    this._store.dispatch(new NotificationsActions.RemoveAllNotifications());
  }

  removeNotificationGroup(notification: NotificationGroup) {
    this._store.dispatch(new NotificationsActions.RemoveNotificationGroup({ notificationGroup: notification }));
  }

  removeNotifications(notifications: NotificationGroup) {
    this._store.dispatch(new NotificationsActions.RemoveNotifications({ notificationGroup: notifications }));
  }
}
