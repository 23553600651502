<div class="sticky">
  <app-main-context-menu></app-main-context-menu>
  <app-main-notification-bar></app-main-notification-bar>
</div>
<app-kanban
  [kanban]="kanban$ | async"
  [kanbanData]="kanbanData$ | async"
  [searchTerm]="searchTerm$ | async"
  (recordMove)="recordMove($event)"
  (loadMoreClick)="loadMore($event)"
  (tileDoubleClick)="navigateToEntityForm($event)"
  (aggregatorSelect)="saveDefaultAggregator($event)"
  (columnExpansionChange)="saveColumnExpansion($event)"
  (refreshDataClick)="refreshData($event)"
  (restoreSettingsClick)="restoreSettings($event)"
  (searchTermChange)="filterRecords($event)">
</app-kanban>
