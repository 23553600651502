<div *ngIf="isVisible" class="menu-group-container">
  <div
    class="menu-group"
    [ngClass]="{
      selected: isSelected && !isExpandable,
      expanded: isSelected && isExpandable
    }">
    <div class="color-bar"></div>
    <mat-list-item
      (click)="onMenuGroupClick()"
      (mouseover)="onMouseOver()"
      (mouseout)="onMouseOut()"
      [matTooltip]="menuGroup.label"
      [matTooltipDisabled]="isWide || isExpandable"
      matTooltipPosition="right">
      <app-menu-item
        [widgetId]="menuGroup?.menuItems?.length === 1 ? menuGroup.menuItems[0].id : undefined"
        [uiScripts]="menuGroup?.menuItems?.length === 1 ? menuGroup.menuItems[0].uiScripts : []"
        class="menu-group-item"
        [name]="menuGroup.name"
        [shortLabel]="menuGroup.shortLabel"
        [label]="menuGroup.label"
        [isWide]="isWide"
        [icon]="menuGroup.icon">
      </app-menu-item>
    </mat-list-item>
  </div>
  <div *ngIf="isSelected && isExpandable">
    <app-menu-group-items
      [menuItems]="menuGroup.menuItems"
      [selectedMenuItemId]="selectedMenuItemId"
      [isWide]="isWide"
      [isFavorite]="favorites"
      [isFavoriteEnabled]="isFavoriteEnabled"
      [isSpecial]="isSpecial"
      (menuItemClick)="onMenuItemClick($event)"
      (menuItemAddedToFavorite)="addMenuItemToFavorite($event)"
      (menuItemRemovedFromFavorite)="removeMenuItemFromFavorite($event)">
    </app-menu-group-items>
  </div>

  <ng-template cdk-portal #popupContent="cdkPortal">
    <app-menu-popup
      [menuItems]="menuGroup.menuItems"
      [selectedMenuItemId]="selectedMenuItemId"
      [groupName]="menuGroup.label"
      (menuItemClick)="onMenuItemClick($event)"
      (menuItemAddedToFavorite)="addMenuItemToFavorite($event)"
      (menuItemRemovedFromFavorite)="removeMenuItemFromFavorite($event)"
      (isOver)="onMouseOverOverlay($event)">
    </app-menu-popup>
  </ng-template>
</div>
