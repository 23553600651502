import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { BlobClient, BlobType } from 'src/core/services/api-clients';
import { BlobActionTypes, UploadBlob, UploadBlobSuccess } from './actions';
import { NotificationService } from 'src/core/services/notification.service';
import { HandleError } from '@core/errors/store/actions';
import { EngineTranslationService } from '@core/engine-translations/services/translation.service';

@Injectable()
export class BlobEffects {
  uploadBlob$ = createEffect(() =>
    this._actions$.pipe(
      ofType<UploadBlob>(BlobActionTypes.UploadBlob),
      mergeMap((action) => {
        return this._blobClient.uploadBlob(action.payload.file, action.payload.name, BlobType[action.payload.type]).pipe(
          catchError((error) => {
            if (action.payload.onError) {
              action.payload.onError();
            } else {
              this._store.dispatch(new HandleError({ error }));
            }
            return EMPTY;
          }),
          mergeMap((payload: string) => {
            if (action.payload.onSuccess) {
              action.payload.onSuccess(payload);
              const message = this._translateService.translateInstantly('FileForImport.SuccessfullyUploaded');
              this._notificationService.success(message);
            }
            return of(new UploadBlobSuccess());
          }),
        );
      }),
    ),
  );

  constructor(
    private _actions$: Actions,
    private _store: Store,
    private _blobClient: BlobClient,
    private _notificationService: NotificationService,
    private _translateService: EngineTranslationService,
  ) {}
}
