import { ChangeDetectionStrategy, Component, Input, OnDestroy } from '@angular/core';
import { RemoveNotificationGroup } from '@core/notification/store/actions';
import { getNotificationGroupsList } from '@core/notification/store/selectors';
import { FormTabDto } from '@core/services/api-clients';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { NotificationGroup } from '../../../../engine-sdk';

@Component({
  selector: 'app-form-tab-notification-bar',
  templateUrl: './form-tab-notification-bar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormTabNotificationBarComponent implements OnDestroy {
  private _destroy$: Subject<any> = new Subject<any>();
  private _formTab: FormTabDto;
  private _formTabAttributes$: BehaviorSubject<string[]>;

  @Input() set formTab(v: FormTabDto) {
    this._formTab = v;
    this._formTabAttributes$.next(this.getTabAttributes(this._formTab));
  }

  notificationGroups$: Observable<NotificationGroup[]>;

  get formTab(): FormTabDto {
    return this._formTab;
  }

  constructor(private _store: Store) {
    this._formTabAttributes$ = new BehaviorSubject([]);
    this.notificationGroups$ = combineLatest([
      this._store.select(getNotificationGroupsList),
      this._formTabAttributes$,
    ]).pipe(
      map(([notificationGroups, formAttributes]) => this.getTabNotifications(formAttributes, notificationGroups)),
    );
  }

  ngOnDestroy(): void {
    this._destroy$.next(null);
    this._destroy$.complete();
  }

  onRemove(group) {
    this._store.dispatch(new RemoveNotificationGroup({ notificationGroup: group }));
  }

  private getTabNotifications(attributes: string[], notificationGroups: NotificationGroup[]): NotificationGroup[] {
    return notificationGroups.filter((e) => attributes.includes(e.name)).filter((e) => e.notifications.length !== 0);
  }

  private getTabAttributes(tab: FormTabDto) {
    return [
      ...new Set(
        tab.controlGroups
          .map((e) => e.controls)
          .reduce((a, controls) => a.concat(controls))
          .map((e) => e.primaryAttribute),
      ),
    ];
  }
}
