<div class="process-progress-container" [style.display]="currentProcessState ? 'flex' : 'none'">
  <mat-select
    #select
    class="process-assignment-select"
    *ngIf="processAssignments?.length > 1"
    (selectionChange)="onProcessAssignmentChange(select.value)"
    [value]="processAssignments[0]">
    <mat-option *ngFor="let processAssignment of processAssignments" [value]="processAssignment">
      {{ processAssignment.internalName }}
    </mat-option>
  </mat-select>

  <div id="historyStates" class="history-content" *ngIf="historyStatesData && historyStatesData.length > 0">
    <ng-container *ngFor="let historyState of historyStatesData">
      <engine-process-state
        [isHistoryState]="true"
        [processState]="historyState.processState"
        (navigateToRelatedRecord)="onOpenHistoryItem(historyState)"></engine-process-state>
        <div class="next-line"></div>
    </ng-container>
  </div>
  <div class="current-state-position">
    <engine-process-current-state
      *ngIf="currentProcessState"
      [processState]="currentProcessState"
      (executeProcessTransition)="this.executeProcessTransition.emit($event)"
      (navigateToRelatedRecord)="this.openCurrentItem.emit($event)"></engine-process-current-state>
  </div>
</div>
