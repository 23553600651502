import { Injector } from '@angular/core';
import { MsalGuardConfiguration, MSAL_GUARD_CONFIG, MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { environment } from '@env';
import { Store } from '@ngrx/store';
import { AuthenticationType } from '../models/authentication-type.enum';
import { IAuthService, AUTH_SERVICE_TOKEN } from '../models/iauth-service.model';
import { AzureADAuthService } from '../services/aad-auth.service';
import { AzureB2CAuthService } from '../services/b2c-auth.service';
import { BasicAuthService } from '../services/basic-auth.service';

const authServiceFactory = (injector: Injector): IAuthService => {
  switch (environment.authenticationType) {
    case AuthenticationType.Anonymous:
    case AuthenticationType.ActiveDirectory:
      return new BasicAuthService(injector.get<Store>(Store));
    case AuthenticationType.AzureActiveDirectory:
      return new AzureADAuthService(
        injector.get<MsalGuardConfiguration>(MSAL_GUARD_CONFIG),
        injector.get(MsalBroadcastService),
        injector.get(MsalService),
        injector.get<Store>(Store),
      );
    case AuthenticationType.AzureB2C:
      return new AzureB2CAuthService(
        injector.get<MsalGuardConfiguration>(MSAL_GUARD_CONFIG),
        injector.get(MsalBroadcastService),
        injector.get(MsalService),
        injector.get<Store>(Store),
      );
  }
};

export const authServiceProvider = {
  provide: AUTH_SERVICE_TOKEN,
  useFactory: authServiceFactory,
  deps: [Injector],
};
