import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ISelectOption } from '@shared/reactive-controls/models/iselect-option.model';
import { IEngineEntity } from 'src/engine-sdk/contract/metadata';
import { ICompositeFilterDescriptor, IFilterDescriptor } from 'src/engine-sdk/contract/odata/iodata-state.model';

@Component({
  selector: 'app-query-composite-filter',
  templateUrl: './query-composite-filter.component.html',
  styleUrls: ['./query-composite-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QueryCompositeFilterComponent {
  @Input() entity: IEngineEntity;
  @Input() value: ICompositeFilterDescriptor;

  @Output() filterChange = new EventEmitter<ICompositeFilterDescriptor>();

  logicOperators: ISelectOption[] = [
    { label: 'And', value: 'and' },
    { label: 'Or', value: 'or' },
  ];

  isCompositeFilterDescriptor(filter: IFilterDescriptor | ICompositeFilterDescriptor): boolean {
    return 'filters' in filter;
  }

  // TODO-MP: it's temporary solution; should dissapear in next stage;
  mapToAny(x: any): any {
    return x;
  }

  onLogicChange(logic: 'or' | 'and'): void {
    this.filterChange.emit({
      ...this.value,
      logic: logic,
    });
  }

  addFilter(): void {
    this.filterChange.emit({
      ...this.value,
      filters: [...this.value.filters, { operator: 'eq' } as IFilterDescriptor],
    } as ICompositeFilterDescriptor);
  }

  addCompositeFilter(): void {
    this.filterChange.emit({
      ...this.value,
      filters: [...this.value.filters, { logic: 'and', filters: [] } as ICompositeFilterDescriptor],
    } as ICompositeFilterDescriptor);
  }

  removeInnerFilter(index: number): void {
    const filters = [...this.value.filters];
    filters.splice(index, 1);
    this.filterChange.emit({
      ...this.value,
      filters,
    } as ICompositeFilterDescriptor);
  }

  onInnerFilterChange(index: number, filter: IFilterDescriptor | ICompositeFilterDescriptor): void {
    const filters = [...this.value.filters];
    filters[index] = filter;
    this.filterChange.emit({
      ...this.value,
      filters,
    } as ICompositeFilterDescriptor);
  }
}
