import { ActionReducerMap } from '@ngrx/store';
import { authReducer } from './reducers';
import { IAuthState } from './state';
import * as AuthSelectors from './selectors';
import * as AuthActions from './actions';

export { AuthActions, AuthSelectors };

export interface AuthFeatureState {
  auth: IAuthState;
}

export const reducers: ActionReducerMap<AuthFeatureState> = {
  auth: authReducer,
};
