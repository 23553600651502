import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-main-menu-expand-button',
  templateUrl: './main-menu-expand-button.component.html',
  styleUrls: ['./main-menu-expand-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainMenuExpandButtonComponent {
  @Input() isWide: boolean = false;
  @Output() toggleExpansion = new EventEmitter<void>();
}
