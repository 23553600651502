import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { WidgetsModule } from '../../../../core/widgets/widgets.module';
import { GridModule } from '@shared/grid/grid.module';
import { EngineViewToolbarComponent } from './engine-view-toolbar/engine-view-toolbar.component';
import { ViewSelectorComponent } from './engine-view-toolbar/view-selector/view-selector.component';
import { EngineViewComponent } from './engine-view.component';
import { ContextMenuModule } from '@core/context-menu/context-menu.module';

@NgModule({
  declarations: [EngineViewComponent, EngineViewToolbarComponent, ViewSelectorComponent],
  imports: [SharedModule, GridModule, WidgetsModule, ContextMenuModule],
  exports: [EngineViewComponent, EngineViewToolbarComponent],
})
export class EngineViewModule {}
