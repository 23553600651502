<span *ngIf="column.type == 'datetime' || column.type == 'date'" title="{{ dataItem | dateTime: column }}">
  {{ dataItem | dateTime: column | mask: column.mask }}
</span>

<span *ngIf="column.type == 'boolean'">
  <input type="checkbox" [checked]="dataItem | lookup: column" disabled />
</span>

<span *ngIf="column.type == 'decimal'" title="{{ dataItem | lookup: column | number }}">
  {{ dataItem | lookup: column | number: getNumberFormat() | mask: column.mask }}
</span>

<span *ngIf="column.type == 'integer'" title="{{ dataItem | lookup: column | number }}">
  {{ dataItem | lookup: column | number: '1.0-0' | mask: column.mask }}
</span>

<span *ngIf="column.type == 'optionSet'"
      title="{{ dataItem[column.primaryField] }}"
      [ngStyle]="{ 'background-color': (dataItem | optionSetColor: column) }">
  {{ dataItem | optionSet: column }}
</span>

<span *ngIf="column.type == 'time'" title="{{ dataItem | time: column }}">
  {{ dataItem | time: column | mask: column.mask  }}
</span>

<span *ngIf="column.type == 'hyperlink'" class="hyperlink-column" title="{{ (dataItem | hyperlink: column).url }}">
  <a *ngIf="dataItem | hyperlink: column as hyperlink"
     [appHyperlink]="hyperlink.url"
     [linkBehavior]="column.linkBehavior">
    {{ hyperlink.placeholder }}
  </a>
</span>

<span *ngIf="column.isClickable && (column.type == 'text' || column.type == 'guid' || column.type == null)"
      class="lookup-column"
      title="{{ dataItem | lookup: column }}">
  <span class="lookup-text" (click)="emitCellClick($event)">
    {{ dataItem | lookup: column | mask: column.mask }}
  </span>
</span>

<span *ngIf="!column.isClickable && (column.type == 'text' || column.type == 'guid' || column.type == null)"
      title="{{ dataItem | lookup: column }}">
  {{ dataItem | lookup: column | mask: column.mask }}
</span>

<span *ngIf="column.type == 'progress' && !column.isOptionSet" title="{{ dataItem | lookup: column }}">
  <app-progress-bar [min]="column.progressMinValue"
                    [max]="column.progressMaxValue"
                    [value]="+(dataItem | lookup: column)"></app-progress-bar>
</span>

<span *ngIf="column.type == 'progress' && column.isOptionSet" title="{{ dataItem | lookup: column }}">
  <app-optionset-progress-bar [optionSet]="column.optionSet"
                              [min]="column.progressMinValue"
                              [max]="column.progressMaxValue"
                              [value]="dataItem | lookup: column">
  </app-optionset-progress-bar>
</span>

<span *ngIf="column.type == 'rating' && !column.isOptionSet" title="{{ dataItem | lookup: column }}">
  <app-rating [value]="+(dataItem | lookup: column)" [stars]="column | ratingOptions"></app-rating>
</span>

<span *ngIf="column.type == 'rating' && column.isOptionSet" title="{{ dataItem | optionSetValue: column }}">
  <app-rating [value]="dataItem | optionSetValue: column" [stars]="column | ratingOptions"></app-rating>
</span>

<span *ngIf="column.type == 'icon'" title="{{ dataItem | iconTitle: column }}">
  <app-img-icon [url]="dataItem | iconUrl: column"
                [iconMode]="column.iconMode"
                [icon]="dataItem | icon: column"
                [size]="column.width ? 'custom' : 'md'"
                [style]="{ 'align-items': column.alignItems }">
  </app-img-icon>
</span>

<span *ngIf="!column.isClickable && column.type == 'combinedTexts'" [title]="dataItem | combinedTexts: column">
  {{ dataItem | combinedTexts: column }}
</span>

<span *ngIf="column.isClickable && column.type == 'combinedTexts'"
      [title]="dataItem | combinedTexts: column"
      class="lookup-column">
  <span class="lookup-text" (click)="emitCellClick($event, column.primaryField)">
    {{ dataItem | lookup: column:column.primaryField }}
  </span>
  <span *ngIf="(dataItem | lookup: column:column.primaryField) && (dataItem | lookup: column:column.secondaryField)">
    {{ column.combinedTextsSeparator ?? ', ' }}
  </span>
  <span class="lookup-text" (click)="emitCellClick($event, column.secondaryField)">
    {{ dataItem | lookup: column:column.secondaryField }}
  </span>
</span>

<span *ngIf="column.type == 'process'" title="{{ dataItem[column.primaryField] }}">
  <engine-process-grid-cell [stateId]="dataItem[column.primaryField]"
                            [attributeName]="column.primaryField"
                            [recordId]="dataItem['Id']"></engine-process-grid-cell>
</span>
