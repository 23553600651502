import { Inject, Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { mergeMap } from 'rxjs/operators';
import { AUTH_SERVICE_TOKEN, IAuthService } from '../models/iauth-service.model';
import { AuthActionTypes, Logout } from './actions';

@Injectable()
export class AuthEffects {
  userLogout$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType<Logout>(AuthActionTypes.Logout),
        mergeMap(() => this._authService.logout()),
      ),
    { dispatch: false },
  );

  constructor(private _actions$: Actions, @Inject(AUTH_SERVICE_TOKEN) private _authService: IAuthService) { }
}
