import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output, inject } from '@angular/core';
import { EngineTranslationService } from '@core/engine-translations/services/translation.service';
import { FilterOperator } from '@progress/kendo-angular-grid/common/filter-operator.interface';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { flatten } from '@shared/grid/grid.component';
import { GridColumn } from '@shared/grid/models/grid-definition.model';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-combined-texts-filter',
  templateUrl: './combined-texts-filter.component.html',
  styleUrls: ['./combined-texts-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CombinedTextsFilterComponent implements OnDestroy {
  private _destroy$: Subject<boolean> = new Subject<boolean>();
  private _currentFilter: CompositeFilterDescriptor;
  private _translateService: EngineTranslationService = inject(EngineTranslationService);

  @Input() column: GridColumn;
  @Input() set currentFilter(v: CompositeFilterDescriptor) {
    const isFirstRun = !this._currentFilter;
    this._currentFilter = v;
    if (isFirstRun) {
      if (v?.filters?.length) {
        const x = flatten(v);
        this.searchText = x[0].value;
      }
    }
  }
  @Output() filterButtonClicked: EventEmitter<CompositeFilterDescriptor> = new EventEmitter();
  @Output() clearFiltersByFields: EventEmitter<string[]> = new EventEmitter();

  get currentFilter(): CompositeFilterDescriptor {
    return this._currentFilter;
  }
  get isFilterButtonDisabled(): boolean {
    return !this.searchText?.length;
  }

  searchText: string = '';
  operators: FilterOperator[] = [
    { text: this._translateService.translateInstantly('kendo.grid.filterContainsOperator'), value: 'contains' },
  ];

  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
  }

  onFilterClicked(): void {
    this.currentFilter.filters = [];
    this.filterButtonClicked.emit({
      logic: 'or',
      filters: [
        { field: this.column.primaryField, operator: 'contains', value: this.searchText },
        { field: this.column.secondaryField, operator: 'contains', value: this.searchText },
      ],
    });
  }

  onClearClicked(): void {
    this.currentFilter.filters = [];
    this.clearFiltersByFields.emit([this.column.primaryField, this.column.secondaryField]);
  }
}
