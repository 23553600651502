import { PipeTransform, Pipe } from '@angular/core';
import { Aggregation } from '../models/grid-data.model';

@Pipe({ name: 'aggregationLabel' })
export class AggregationLabelPipe implements PipeTransform {
  transform(aggregation: Aggregation): string {
    switch (aggregation.type) {
      case 'average':
        return 'AVG';
      case 'count':
        return 'COUNT';
      case 'max':
        return 'MAX';
      case 'min':
        return 'MIN';
      case 'sum':
        return 'SUM';
      case 'countDistinct':
        return 'UNIQUE';
    }
  }
}
