import { NotificationScope, NotificationType, INotification } from './notification';

export class NotificationGroup {
  static defaultGlobalMessage = '@GlobalNotificationMessage';
  static defaultAttributeMessage = '@AttributeNotificationMessage';
  static defaultGlobalGroup = '$global';

  scope: NotificationScope;
  message: string;
  name: string;
  notifications: INotification[];
  formId?: string;
  entityName?: string;
  type?: NotificationType;
}
