import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  inject,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject, combineLatest } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';
import { CloseRightPanel, ScreenSizeChanged } from '../../store/actions';
import { LayoutSelectors } from '../../store';
import { ThemeService } from '@core/layout/services/theme.service';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShellComponent implements OnInit, OnDestroy {
  private _destroy$: Subject<boolean> = new Subject<boolean>();
  private _themeService = inject(ThemeService);
  isLeftPanelVisible$ = this._store.select(LayoutSelectors.getIsLeftPanelVisible);
  isLeftPanelExpanded$ = this._store.select(LayoutSelectors.getIsLeftPanelExpanded);
  isRightPanelVisible$ = this._store.select(LayoutSelectors.getIsRightPanelVisible);
  isDarkTheme$ = this._themeService.isDarkThemeAsync();
  screenSize$ = this._store.select(LayoutSelectors.getScreenSize);
  resize$: Subject<number> = new Subject();

  @HostListener('window:resize', ['$event'])
  onWindowResize(event: Event) {
    this.resize$.next(this.getWidthFromEvent(event));
  }

  constructor(private _store: Store, private _change: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.handleScreenResize();
    this.runMatSidenavAutosizeOnShellLayoutChange();
  }

  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
  }

  closeUserSidenav() {
    this._store.dispatch(new CloseRightPanel());
  }

  private handleScreenResize() {
    this.resize$
      .asObservable()
      .pipe(
        takeUntil(this._destroy$),
        debounceTime(500),
        tap((screenWidth) => {
          this._store.dispatch(new ScreenSizeChanged({ screenWidth }));
        }),
      )
      .subscribe();
    this.resize$.next(window.innerWidth);
  }

  private runMatSidenavAutosizeOnShellLayoutChange() {
    const shellLayoutChanged = combineLatest([this.isLeftPanelVisible$, this.isLeftPanelExpanded$, this.screenSize$]);
    shellLayoutChanged
      .pipe(
        takeUntil(this._destroy$),
        debounceTime(100),
        tap(() => {
          this.makeMatSidenavAutosizeRun();
        }),
      )
      .subscribe();
  }

  private makeMatSidenavAutosizeRun() {
    this._change.markForCheck();
  }

  private getWidthFromEvent(event: Event) {
    return (event.target as any).innerWidth;
  }
}
