import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { EngineFormComponent } from '../components/engine-form/engine-form.component';

export const ENGINE_FORM_SERVICE = new InjectionToken<IEngineFormService>('IEngineFormService');

export interface IEngineFormService {
  save(engineForm: EngineFormComponent): Observable<any>;
  saveAndNew(engineForm: EngineFormComponent): Observable<any>;
  saveAndClose(engineForm: EngineFormComponent): Observable<any>;
  refetch(engineForm: EngineFormComponent): Observable<any>;
}
