<ng-container
  *ngIf="{
    navigationItems: navigationItems$ | async,
    isLongBreadcrumbVisible: (isLongBreadcrumbVisible$ | async) ?? true,
    availableForms: (availableForms$ | async) ?? []
  } as observables">
  <div *ngIf="observables.navigationItems">
    <ng-container
      *ngTemplateOutlet="observables.isLongBreadcrumbVisible ? longBreadcrumb : shortBreadcrumb"></ng-container>
  </div>

  <ng-template #shortBreadcrumb>
    <app-short-breadcrumb
      [breadcrumbs]="observables.navigationItems"
      [forms]="observables.availableForms"
      (breadcrumbClicked)="onBreadcrumbClick($event)"
      (formSelected)="onFormSelected($event)">
    </app-short-breadcrumb>
  </ng-template>

  <ng-template #longBreadcrumb>
    <app-long-breadcrumb
      [breadcrumbs]="observables.navigationItems"
      [forms]="observables.availableForms"
      (homeClicked)="navigateToHomePage()"
      (breadcrumbClicked)="onBreadcrumbClick($event)"
      (formSelected)="onFormSelected($event)">
    </app-long-breadcrumb>
  </ng-template>
</ng-container>
