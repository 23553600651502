import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { MessageService } from '@progress/kendo-angular-l10n';
import { KendoMessageService } from './services/kendo-message.service';
import { IntlService } from '@progress/kendo-angular-intl';
import { KENDO_LOCALE_ID, KendoIntlService } from './services/kendo-intl.service';
import { EngineCultureService } from '@core/engine-culture/services/culture.service';
import { CookiesModule } from '@shared/cookies/cookies.module';
import { CookiesService } from '@shared/cookies/cookies.service';

@NgModule({
  declarations: [],
  imports: [CommonModule, CookiesModule],
  providers: [DecimalPipe, DatePipe],
})
export class KendoCoreModule {
  static forRoot(): ModuleWithProviders<KendoCoreModule> {
    return {
      ngModule: KendoCoreModule,
      providers: [
        KendoMessageService,
        { provide: MessageService, useClass: KendoMessageService },
        { provide: IntlService, useClass: KendoIntlService },
        {
          provide: KENDO_LOCALE_ID,
          useFactory: (cultureService: EngineCultureService) => {
            const allowedLocale = ['en', 'pl', 'uk'];
            let localeId = 'en';
            try {
              localeId = cultureService.localeData[0] ?? localeId;
            } catch (_) {}
            return allowedLocale.includes(localeId) ? localeId : 'en';
          },
          deps: [EngineCultureService, CookiesService],
        },
      ],
    };
  }

  constructor() {}
}
