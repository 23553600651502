import { ChangeDetectorRef, Directive, HostBinding, Inject, Injector, Input, Optional, SkipSelf } from '@angular/core';
import { WidgetDirective } from '@core/widgets/directives/widget.directive';
import { SCRIPT_RUNNER_SERVICE, IScriptRunnerService } from '@core/widgets/models/iscript-runner.service';
import { EngineFormControlDirective } from './engine-form-control.directive';
import { IconControlComponent } from '../../../shared/reactive-controls/components/icon/icon-control.component';
import { IconControlDto, IconType, WidthType } from '../../services/api-clients';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { IEngineODataState } from '../../../engine-sdk/contract/odata/iodata-state.model';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpContext } from '@angular/common/http';
import { EngineODataStateParser } from '../../engine-odata/services/odata-state-parser.service';
import { IIcon } from '../../../shared/icon/icon.model';
import { map, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { IGNORE_BUSY_INDICATOR } from '../../layout/components/busy-indicator/busy-indicator.interceptor';
import { IconMode, IconSize } from '../../../shared/ui-components/components/img-icon/img-icon.component';

@Directive({
  selector: 'app-icon-control[engineIconFormControl]',
  providers: [{ provide: EngineFormControlDirective, useExisting: EngineIconFormControlDirective }],
})
export class EngineIconFormControlDirective extends EngineFormControlDirective {
  private _stateBs$: BehaviorSubject<IEngineODataState> = new BehaviorSubject(null);
  private _defaultUrl: string = null;
  private _defaultIcon: IIcon = null;

  private readonly BASE_URL = environment.urls.ODataUrl;

  protected get _iconBaseControl(): IconControlComponent {
    return this._baseControl as IconControlComponent;
  }

  @Input() set engineIconFormControlDefinition(definition: IconControlDto) {
    this.iconMode = this.mapIconType(definition.iconType);
    this.size = definition.width ? 'custom' : null;
    this.url = this._defaultUrl = definition.url;
    this.icon = this._defaultIcon = definition.icon;
    this.engineControlDefinition = definition;
  }

  @Input() set url(url: string) {
    this._iconBaseControl.url = url ? url : this.iconMode == 'url' ? this._defaultUrl : null;
  }

  @Input() set icon(icon: IIcon) {
    this._iconBaseControl.icon = icon ? icon : this.iconMode == 'icon' ? this._defaultIcon : null;
  }

  @Input() set iconMode(iconMode: IconMode) {
    this._iconBaseControl.iconMode = iconMode;
  }

  @Input() set size(size: IconSize) {
    this._iconBaseControl.size = size;
  }

  @HostBinding('style') get getFontSizeStyle() {
    return {
      ...this.getFontSizeBaseOnWidthStyle(),
    };
  }

  get url() {
    return this._iconBaseControl.url;
  }

  get icon() {
    return this._iconBaseControl.icon;
  }

  get iconMode() {
    return this._iconBaseControl.iconMode;
  }

  get size() {
    return this._iconBaseControl.size;
  }

  constructor(
    private _change: ChangeDetectorRef,
    private _http: HttpClient,
    @Optional() @SkipSelf() parentWidget: WidgetDirective,
    @Inject(SCRIPT_RUNNER_SERVICE) scriptRunnerService: IScriptRunnerService,
    injector: Injector,
  ) {
    super(parentWidget, scriptRunnerService, injector);

    this._iconBaseControl.formControl.valueChanges
      .pipe(
        takeUntil(this._destroy$),
        tap((value) => {
          if (this.iconMode == 'icon') {
            let state = value ? this.getQueryByRecordId(value) : null;
            this._stateBs$.next(state);
          } else this.url = value;
        }),
      )
      .subscribe();

    this._stateBs$
      .pipe(
        mergeMap((state) => (state ? this.query(state) : of(null))),
        tap((value) => {
          this.icon = value;
          this._change.markForCheck();
        }),
      )
      .subscribe();
  }

  private mapIconType(iconType?: IconType): IconMode {
    switch (iconType) {
      case IconType.Url:
        return 'url';
      case IconType.Icon:
        return 'icon';
      default:
        return undefined;
    }
  }

  private getFontSizeBaseOnWidthStyle(): any {
    if (this.iconMode == 'url') return;

    let style = {};
    switch (this.widthType) {
      case WidthType.Pixel:
        if (Number.isInteger(this.width)) style = { 'font-size': `${this.width}px` };
        break;
      case WidthType.Percentage:
        if (Number.isInteger(this.width)) style = { 'font-size': `${this.width}%` };
        break;
    }
    return style;
  }

  private getQueryByRecordId(recordId: string): IEngineODataState {
    if (!this.isGuid(recordId))
      throw {
        error: 'It is not possible to get the record into lookup because record id is invalid',
      };

    return {
      take: 1,
      skip: 0,
      filter: {
        logic: 'and',
        filters: [
          {
            field: 'Id',
            operator: 'eq',
            value: recordId,
          },
        ],
      },
    };
  }

  private isGuid(string: string): boolean {
    if (string[0] === '{') string = string.substring(1, string.length - 1);

    const regexGuid =
      /^(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}$/gi;
    return regexGuid.test(string);
  }

  private query(state: IEngineODataState): Observable<IIcon> {
    let url = `${this.BASE_URL}/Icon?${EngineODataStateParser.toODataString(state)}`;

    url = url.replace(/'([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})'/gi, '$1');

    const context = new HttpContext();
    context.set(IGNORE_BUSY_INDICATOR, true);
    return this._http.get(`${url}`, { context }).pipe(
      map((res) => res['value']),
      map((data) =>
        data.length == 1
          ? data.map((item) =>
              item
                ? <IIcon>{
                    fontIconName: item.FontIconName,
                    iconRelativePath: item.IconRelativePath,
                  }
                : undefined,
            )[0]
          : undefined,
      ),
    );
  }
}
