import { Inject, ModuleWithProviders, NgModule } from '@angular/core';
import { MsalBroadcastService, MsalModule, MsalService } from '@azure/msal-angular';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { reducers } from './store';
import { FEATURE_NAME } from './store/state';
import { AuthEffects } from './store/effects';
import { msalInstanceProvider } from './providers/msal-instance.provider';
import { authServiceProvider } from './providers/auth-service.provider';
import { authGuardProvider } from './providers/msal-guard.provider';
import { authGuardConfigProvider } from './providers/msal-guard-config.provider';
import { authInterceptorProvider } from './providers/auth-interceptor.provider';
import { msalInterceptorConfigProvider } from './providers/msal-interceptor-config.provider';
import { authInitializerProvider } from './providers/auth-initializer.provider';
import { AUTH_SERVICE_TOKEN, IAuthService } from './models/iauth-service.model';
import { MomentModule } from '@shared/moment/moment.module';

@NgModule({
  declarations: [],
  imports: [
    MsalModule,
    StoreModule.forFeature(FEATURE_NAME, reducers),
    EffectsModule.forFeature([AuthEffects]),
    MomentModule,
  ],
  exports: [],
})
export class AuthModule {
  static forRoot(): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
        MsalBroadcastService,
        MsalService,
        authGuardConfigProvider,
        msalInterceptorConfigProvider,
        msalInstanceProvider,
        authInterceptorProvider,
        authGuardProvider,
        authServiceProvider,
        authInitializerProvider,
      ],
    };
  }
  constructor(@Inject(AUTH_SERVICE_TOKEN) authService: IAuthService) {
    authService.init();
  }
}
