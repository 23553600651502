import { Directive, HostListener } from '@angular/core';
@Directive({
  selector: '[duration]',
})
export class DurationDirective {
  regexStr = '^[\0-9]*$';

  constructor() {}

  @HostListener('keypress', ['$event']) onKeyPress(event) {
    return new RegExp(this.regexStr).test(event.key);
  }

  @HostListener('paste', ['$event']) blockPaste(event: KeyboardEvent) {
    event.preventDefault();
  }
}
