import { PipeTransform, Pipe } from '@angular/core';
import { GridColumn } from '../models/grid-definition.model';
import { IRatingOption } from '@shared/ui-components/components/rating/rating.model';

@Pipe({ name: 'ratingOptions' })
export class RatingOptionsPipe implements PipeTransform {
  transform(column: GridColumn): IRatingOption[] {
    let ratingOptions = [];
    const isInvalidOptionSetRating = column.isOptionSet && !column.optionSet?.values?.length;
    const isInvalidNumberRating = !column.isOptionSet && (!column.ratingMinValue || !column.ratingMaxValue);
    if (column.type != 'rating' || isInvalidNumberRating || isInvalidOptionSetRating) {
      return ratingOptions;
    }

    if (column.isOptionSet) {
      ratingOptions = column.optionSet.values as IRatingOption[];
    } else {
      ratingOptions = [...Array(column.ratingMaxValue - column.ratingMinValue + 1).keys()]
        .map((x) => x + column.ratingMinValue)
        .map((value) => {
          return { value, label: value.toString() } as IRatingOption;
        });
    }

    return ratingOptions
      .filter((option) => this.hasValidValue(option, column.ratingMinValue, column.ratingMaxValue))
      .sort((a, b) => a.value - b.value);
  }

  private hasValidValue(option: IRatingOption, minValue?: number, maxValue?: number): boolean {
    return (minValue == null || option.value >= minValue) && (maxValue == null || option.value <= maxValue);
  }
}
