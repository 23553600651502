import { Pipe, PipeTransform } from '@angular/core';
import { GridColumn } from '../models/grid-definition.model';

@Pipe({ name: 'iconTitle' })
export class IconTitlePipe implements PipeTransform {
  transform(dataItem: any, column: GridColumn): string {
    if (!dataItem) return null;

    let fields = column.primaryField.split('.');
    let mainField = fields[0];

    return fields.length == 1 ? dataItem[mainField] : dataItem[mainField]?.Name
  }
}
