import { UserActions, UserActionTypes } from './actions';
import { initialUserState, IUserState } from './state';

export function userReducer(state = initialUserState, action: UserActions): IUserState {
  switch (action.type) {
    case UserActionTypes.LoadUserDataSuccess: {
      return {
        ...state,
        currentUser: action.payload.user,
        userLanguage: action.payload.user.language,
        isNotAuthorized: false,
      };
    }

    case UserActionTypes.LoadUserDataFailure: {
      return {
        ...state,
        isNotAuthorized: true,
      };
    }

    case UserActionTypes.RemoveUserData: {
      return {
        ...state,
        currentUser: null,
      };
    }

    case UserActionTypes.LoadUILanguagesSuccess: {
      return {
        ...state,
        uiLanguages: action.payload.uiLanguages,
      };
    }

    case UserActionTypes.SetUserLanguageSuccess: {
      let user;
      if (state.currentUser) {
        user = {
          ...state.currentUser,
          language: {
            ...state.currentUser.language,
            id: action.payload.language?.id,
            code: action.payload.language?.code,
          },
        };
      }

      return {
        ...state,
        userLanguage: action.payload.language,
        currentUser: user,
      };
    }

    case UserActionTypes.CheckCurrentUserPermissionKeySuccess: {
      return {
        ...state,
        currentUserPermissionKeyMap: {
          ...state.currentUserPermissionKeyMap,
          [action.payload.permissionKeyId]: action.payload.result,
        },
      };
    }

    default:
      return state;
  }
}
