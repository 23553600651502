import {
  ChangeDetectorRef,
  Directive,
  HostBinding,
  Inject,
  InjectFlags,
  Injector,
  Input,
  Optional,
  SkipSelf,
} from '@angular/core';
import { OnChangeExecutionEvent } from '@core/execution-context';
import { AlignStyle, ControlDto, DisplayMode, WidthType } from '@core/services/api-clients';
import { ContextService } from '@core/services/context.service';
import { WidgetDirective } from '@core/widgets/directives/widget.directive';
import { IScriptRunnerService, SCRIPT_RUNNER_SERVICE } from '@core/widgets/models/iscript-runner.service';
import { WidgetType } from '@core/widgets/models/widget-type';
import { BaseFormControlDirective } from '@shared/reactive-controls/directives/base-form-control.directive';
import { IControlValueChangeEvent } from '@shared/reactive-controls/models/icontrol-value-change-event.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import {
  ENGINE_DATA_CONTEXT_PROVIDER,
  IEngineDataContextProvider,
  IEngineFormControlDataContext,
} from 'src/engine-sdk/contract/engine-data-context';
import {
  FormControlNotificationTemplates,
  IControlContext,
  IExecutionContext,
  IExecutionEvent,
} from '../../../engine-sdk';
import { EngineFormComponent } from '../components/engine-form/engine-form.component';
import { FormSectionComponent } from '../components/engine-form/form-grid/form-section/form-section.component';
import { EngineFormNotificationService } from '../services/engine-form-notification.service';

@Directive({
  selector: '[engineFormControl]',
})
export class EngineFormControlDirective extends WidgetDirective implements IControlContext {
  private _isInitiated$ = new BehaviorSubject<boolean>(false);
  protected _formNotificationService: EngineFormNotificationService;
  protected _contextService: ContextService;
  protected _baseControl: BaseFormControlDirective;
  protected _formSection: FormSectionComponent;
  protected _form: EngineFormComponent;
  protected _changeDetector: ChangeDetectorRef;
  protected _engineDataContextProvider: IEngineDataContextProvider;

  @Input() set engineControlDefinition(definition: ControlDto) {
    this.id = definition.id;
    this.widgetId = definition.id;
    this.name = definition.name;
    this.label = definition.label;
    this.isReadOnly = definition.isReadOnly;
    this.isRequired = definition.isRequired;
    this.isVisible = definition.isVisible;
    this.width = definition.width;
    this.widthType = definition.widthType;
    this.alignStyle = definition.align;
    this.type = definition.type;
    this.order = definition.order;
    this.primaryAttribute = definition.primaryAttribute;
    this.secondaryAttribute = definition.secondaryAttribute;
    this.uiScripts = definition.uiScripts ?? [];
    this.displayMode = definition.displayMode;
    if (!this._isInitiated$.value) this.initiateControl();
  }
  @Input() set id(v: string) {
    this._baseControl.id = v;
  }
  @Input() set name(v: string) {
    this._baseControl.name = v;
  }
  @Input() set label(v: string) {
    this._baseControl.label = v;
    this.markForCheckIfChangedAfterInitiation();
  }
  @Input() set displayMode(mode: DisplayMode) {
    this._baseControl.displayMode = mode;
    this.markForCheckIfChangedAfterInitiation();
  }
  @Input() set isReadOnly(v: boolean) {
    const oldValue = !this._baseControl.formControl.enabled;
    if (oldValue == v) return;
    this._baseControl.setDisabledState(v);
    this.refreshControlIsRequiredNotification(this, false);
    this.markForCheckIfChangedAfterInitiation();
  }
  @Input() set isRequired(v: boolean) {
    const oldValue = this._baseControl.isRequired;
    if (oldValue == v) return;
    this._baseControl.isRequired = v;
    this.refreshControlIsRequiredNotification(this, false);
  }
  @Input() set isVisible(v: boolean) {
    const oldValue = this._baseControl.isVisible;
    if (oldValue == v) return;
    this._baseControl.isVisible = v;
    this.refreshControlIsRequiredNotification(this, false);
    this.markForCheckIfChangedAfterInitiation();
  }
  @Input() set width(v: number | undefined) {
    this._baseControl.width = v;
    this.markForCheckIfChangedAfterInitiation();
  }
  @Input() set widthType(v: WidthType | undefined) {
    this._baseControl.widthType = v;
    this.markForCheckIfChangedAfterInitiation();
  }
  @Input() set alignStyle(v: AlignStyle | undefined) {
    this._baseControl.alignStyle = v;
    this.markForCheckIfChangedAfterInitiation();
  }
  @Input() type: string;
  @Input() order?: number;
  @Input() primaryAttribute?: string | undefined;
  @Input() secondaryAttribute?: string | undefined;
  set value(v: any) {
    if (v == this.value) return;
    this._baseControl.formControl.patchValue(v);
  }

  get id() {
    return this._baseControl.id;
  }
  get name() {
    return this._baseControl.name;
  }
  get label() {
    return this._baseControl.label;
  }
  get displayMode() {
    return this._baseControl.displayMode;
  }
  get isReadOnly() {
    return this._baseControl.formControl.disabled;
  }
  get isRequired() {
    return this._baseControl.isRequired;
  }
  get isVisible() {
    return this._baseControl.isVisible;
  }
  get width() {
    return this._baseControl.width;
  }
  get widthType() {
    return this._baseControl.widthType;
  }
  get alignStyle() {
    return this._baseControl.alignStyle;
  }
  get value(): any {
    return this._baseControl.formControl.value;
  }
  @HostBinding('id')
  get getEngineControlId(): string {
    return `EngineControl_${this.id}`;
  }
  @HostBinding('attr.name')
  get getEngineControlName(): string {
    return `EngineControl_${this.name}`;
  }
  @HostBinding('attr.primaryAttribute')
  get getEngineControlPrimaryAttribute(): string {
    return this.getAttributes()?.primaryAttribute;
  }

  constructor(
    @Optional() @SkipSelf() parentWidget: WidgetDirective,
    @Inject(SCRIPT_RUNNER_SERVICE) scriptRunnerService: IScriptRunnerService,
    private _injector: Injector,
  ) {
    super(parentWidget, scriptRunnerService);
    this.injectDependencies();

    this._formSection.registerControl(this);
    this._baseControl
      .controlValueChanges()
      .pipe(
        takeUntil(this._destroy$),
        tap((v) => {
          this.onFormControlChange(v);
          this.refreshControlIsRequiredNotification(this, false)
        }),
      )
      .subscribe();

    this._baseControl
      .focusout()
      .pipe(
        takeUntil(this._destroy$),
        tap(() => this.refreshControlIsRequiredNotification(this, true)),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this._formSection.unregisterControl(this);
  }

  // #region IControlContext
  getAttributes(): { primaryAttribute: string; secondaryAttribute?: string } {
    return { primaryAttribute: this.primaryAttribute, secondaryAttribute: this.secondaryAttribute };
  }

  setDirty(value: boolean): void {
    if (value) {
      this._baseControl.formControl.markAsDirty();
    } else {
      this._baseControl.formControl.markAsPristine();
    }
    this.refreshControlIsRequiredNotification(this, false);
    this.markForCheckIfChangedAfterInitiation();
  }

  setTouched(value: boolean): void {
    if (value) {
      this._baseControl.formControl.markAsTouched();
    } else {
      this._baseControl.formControl.markAsUntouched();
    }
    this.refreshControlIsRequiredNotification(this, false);
    this.markForCheckIfChangedAfterInitiation();
  }

  isDirty() {
    return this._baseControl.formControl.dirty;
  }
  isTouched() {
    return this._baseControl.formControl.touched;
  }

  setErrors(messages: string[]): void {
    this._baseControl.formControl.setErrors({ ...this._baseControl.formControl.errors, notifications: messages });
    this.markForCheckIfChangedAfterInitiation();
  }

  hasErrors(): boolean {
    return this._baseControl.hasErrors();
  }

  hasError(errorId: string): boolean {
    return this._baseControl.hasError(errorId);
  }

  getDataContext(): IEngineFormControlDataContext {
    const parentDataContext = this._engineDataContextProvider ? this._engineDataContextProvider.getDataContext() : {};
    return {
      ...parentDataContext,
      controlId: this._baseControl.id,
      controlName: this._baseControl.name,
    } as IEngineFormControlDataContext;
  }

  tryFocus() {
    this._baseControl.tryFocus();
  }

  //#endregion

  // #region WidgetDirective
  override getWidgetType(): WidgetType {
    return WidgetType.FormControl;
  }

  override getExecutionContext(executionEvent: IExecutionEvent): Observable<IExecutionContext> {
    return this._contextService.createControlExecutionContext(
      this,
      this._events$.asObservable(),
      this.getDataContext(),
      executionEvent,
    );
  }

  protected override isWidgetInitiated(): Observable<boolean> {
    return this._isInitiated$.asObservable();
  }

  protected getTextAlignStyle(textAlign: AlignStyle): any {
    let style = {};
    switch (textAlign) {
      case AlignStyle.Left:
        style = { 'text-align': 'left' };
        break;
      case AlignStyle.Right:
        style = { 'text-align': 'right' };
        break;
      case AlignStyle.Center:
        style = { 'text-align': 'center' };
        break;
      default:
        style = { 'text-align': 'left' };
        break;
    }
    return style;
  }
  //#endregion

  private onFormControlChange(event: IControlValueChangeEvent): void {
    const executionEvent = new OnChangeExecutionEvent({
      value: event.newValue,
    });

    this.triggerEvent(this.createEventArgs(executionEvent));
    this._events$.next(executionEvent);
  }

  protected markForCheckIfChangedAfterInitiation() {
    if (this._isInitiated$.value) {
      this._changeDetector.markForCheck();
    }
  }

  private initiateControl() {
    this._isInitiated$.next(true);
    this._changeDetector.markForCheck();
  }

  private refreshControlIsRequiredNotification(control: IControlContext, onFocusOut: boolean) {
    if (!control || control.isReadOnly || !control.isVisible) return;
    if (control.hasError('required') && this._baseControl.formControl.touched) {
      if (onFocusOut) {
        this._formNotificationService.addNotification(FormControlNotificationTemplates.IsRequired, control);
      }
    } else {
      this._formNotificationService.removeNotification(FormControlNotificationTemplates.IsRequired, control);
    }
  }

  private injectDependencies() {
    this._formNotificationService = this._injector.get(EngineFormNotificationService);
    this._contextService = this._injector.get(ContextService);
    this._baseControl = this._injector.get(BaseFormControlDirective);
    this._formSection = this._injector.get(FormSectionComponent);
    this._form = this._injector.get(EngineFormComponent);
    this._changeDetector = this._injector.get(ChangeDetectorRef);
    this._engineDataContextProvider = this._injector.get(ENGINE_DATA_CONTEXT_PROVIDER, undefined, InjectFlags.SkipSelf);
  }
}
