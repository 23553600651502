import { ChangeDetectorRef, Directive, Inject, Injector, Input, OnInit, Optional, SkipSelf } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { RecordsSelectorViewConfig } from '@core/engine-views/components/records-selector-view/records-selector-view-config';
import { RecordsSelectorViewComponent } from '@core/engine-views/components/records-selector-view/records-selector-view.component';
import { NavigationService } from '@core/navigation/services/navigation.service';
import { AlignStyle, CombinedLookupDto, CombinedLookupsControlDto } from '@core/services/api-clients';
import { WidgetDirective } from '@core/widgets/directives/widget.directive';
import { IScriptRunnerService, SCRIPT_RUNNER_SERVICE } from '@core/widgets/models/iscript-runner.service';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { GroupedAutocompleteControlComponent } from '@shared/reactive-controls/components/grouped-autocomplete/grouped-autocomplete-control.component';
import { IAutocompleteGroup } from '@shared/reactive-controls/components/grouped-autocomplete/grouped-autocomplete-control.model';
import { filter, map, takeUntil, tap } from 'rxjs/operators';
import { ILookupControlContext } from '../../../engine-sdk';
import { EngineCombinedLookupsService } from '../services/engine-combined-lookups.service';
import { EngineFormControlDirective } from './engine-form-control.directive';

@Directive({
  selector: 'app-grouped-autocomplete-control[engineCombinedLookupsFormControl]',
  providers: [
    EngineCombinedLookupsService,
    { provide: EngineFormControlDirective, useExisting: EngineCombinedLookupsFormControlDirective },
  ],
})
export class EngineCombinedLookupsFormControlDirective
  extends EngineFormControlDirective
  implements ILookupControlContext, OnInit {
  private _lookups: CombinedLookupDto[];
  private _selectedLookupId: string;
  protected get _groupedAutocompleteBaseControl(): GroupedAutocompleteControlComponent {
    return this._baseControl as GroupedAutocompleteControlComponent;
  }

  @Input() set engineCombinedLookupsControlDefinition(definition: CombinedLookupsControlDto) {
    this._lookups = definition.combinedLookups;
    this.engineControlDefinition = definition;
    const groups = definition.combinedLookups.map((x) => {
      return { id: x.attributeName, name: x.targetEntity.name, label: x.targetEntity.name } as IAutocompleteGroup;
    });
    this._groupedAutocompleteBaseControl.setGroups(groups);
    this.textAlignStyle = definition.textAlign;
  }

  override get isReadOnly(): boolean {
    return super.isReadOnly;
  }

  @Input() override set isReadOnly(v: boolean) {
    super.isReadOnly = v;
    this.refreshActionButtonsVisibility();
  }

  @Input() set textAlignStyle(textAlign: AlignStyle) {
    this._groupedAutocompleteBaseControl.textAlignStyle = this.getTextAlignStyle(textAlign);
  }

  get textAlignStyle(): any {
    return this._groupedAutocompleteBaseControl.textAlignStyle;
  }

  set value(v: any) {
    super.value = v;
  }
  get value(): any {
    return super.value;
  }
  get selectedLookup(): CombinedLookupDto {
    return this.getLookupById(this._selectedLookupId);
  }

  constructor(
    @Optional() @SkipSelf() parentWidget: WidgetDirective,
    @Inject(SCRIPT_RUNNER_SERVICE) scriptRunnerService: IScriptRunnerService,
    injector: Injector,
    private _matDialog: MatDialog,
    private _navigationContext: NavigationService,
    private _combinedLookupsService: EngineCombinedLookupsService,
    private _changes: ChangeDetectorRef,
  ) {
    super(parentWidget, scriptRunnerService, injector);
  }

  ngOnInit(): void {
    this.setValueBaseOnFormValue();
    this.setSelectedLookupBaseOnValue();

    this._form.isWidgetLoaded$
      .pipe(
        takeUntil(this._destroy$),
        tap(() => this._combinedLookupsService.init(this._lookups, this.value)),
      )
      .subscribe();

    this._combinedLookupsService.groupedOptions$
      .pipe(
        takeUntil(this._destroy$),
        tap((options) => {
          this._groupedAutocompleteBaseControl.groupedOptions = options;
          this._changes.markForCheck();
        }),
      )
      .subscribe();

    this._groupedAutocompleteBaseControl.groupChanged
      .asObservable()
      .pipe(
        tap((lookupId) => {
          this._selectedLookupId = lookupId;
          this.refreshActionButtonsVisibility();
        }),
      )
      .subscribe();

    this._groupedAutocompleteBaseControl.addClick
      .asObservable()
      .pipe(
        takeUntil(this._destroy$),
        tap((lookupId) => this.onAddClick(lookupId)),
      )
      .subscribe();

    this._groupedAutocompleteBaseControl.editClick
      .asObservable()
      .pipe(
        takeUntil(this._destroy$),
        tap((lookupId) => this.onEditClick(lookupId)),
      )
      .subscribe();

    this._groupedAutocompleteBaseControl.filterChange
      .asObservable()
      .pipe(
        takeUntil(this._destroy$),
        tap((filterText) => {
          this._combinedLookupsService.clearOptionSelection();
          this._combinedLookupsService.search(filterText);
        }),
      )
      .subscribe();

    this._combinedLookupsService.preFetch$
      .pipe(
        takeUntil(this._destroy$),
        tap((preFetchExecutionEvent) => {
          this._events$.next(preFetchExecutionEvent);
          this.triggerEvent(
            this.createEventArgs(preFetchExecutionEvent, preFetchExecutionEvent.fetchAsync.bind(preFetchExecutionEvent)),
          );
        }),
      )
      .subscribe();

    this._form.dataItemChange
      .pipe(
        takeUntil(this._destroy$),
        filter((changeEvent) => {
          return (
            this._lookups?.length > 0 &&
            this._lookups
              .map((l) => this.getLookupGuidFieldName(l.attributeName))
              .some((k) => changeEvent.oldValue[k] != changeEvent.newValue[k])
          );
        }),
        map((changeEvent) => changeEvent.newValue),
        tap(() => {
          this.setValueBaseOnFormValue();
        }),
        tap(() => {
          this.setSelectedLookupBaseOnValue();
        }),
      )
      .subscribe();

    this._baseControl
      .controlValueChanges()
      .pipe(
        takeUntil(this._destroy$),
        map((e) => e.newValue),
        tap((value) => {
          if (value && Object.values(value).some((v) => !!v)) {
            this._combinedLookupsService.selectOption(value);
          } else {
            this._combinedLookupsService.clearOptionSelection();
            this._combinedLookupsService.search('');
          }
        }),
        tap((v) => {
          this._lookups.forEach((x) =>
            this._form.setFieldValue(this.getLookupGuidFieldName(x.attributeName), v ? v[x.attributeName] : null),
          );
        }),
      )
      .subscribe();
  }

  addFilter(filter: CompositeFilterDescriptor): void {
    this._combinedLookupsService.addFilter(filter);
  }

  setFilter(filter: CompositeFilterDescriptor): void {
    this._combinedLookupsService.setFilter(filter);
  }

  setCustomFilter(filter: string): void {
    this._combinedLookupsService.setCustomFilter(filter);
  }

  private onExpandPressed(lookupId: string): void {
    const lookup = this.getLookupById(lookupId);
    if (!lookup.expanderViewId) return;

    const value = this.getValueByLookupId(lookup.attributeName);
    this._matDialog.open(RecordsSelectorViewComponent, {
      width: '70vw',
      panelClass: 'app-expander-dialog',
      autoFocus: false,
      restoreFocus: false,
      data: {
        entityName: lookup.targetEntity.name,
        viewId: lookup.expanderViewId,
        selectedIds: value ? [value] : null,
        onConfirm: (selectedIds: string[]) => {
          this.setDirty(true);
          this.value = this.createNewValue(lookup.attributeName, selectedIds[0]);
        },
        selectionMode: 'single',
        query: this._combinedLookupsService.odataQuery,
      } as RecordsSelectorViewConfig,
    });
  }

  private onEditClick(lookupId: string) {
    const lookup = this.getLookupById(lookupId);
    const value = this.value ? this.value[lookupId] : null;
    if (lookup?.targetEntity?.id && lookup?.formId && value) {
      this._navigationContext.navigateToForm(lookup.targetEntity.id, lookup.formId, value, {
        popCount: 0,
      });
    }
  }

  private onAddClick(lookupId: string) {
    const lookup = this.getLookupById(lookupId);
    if (lookup?.targetEntity?.id && lookup?.formId) {
      this._navigationContext.navigateToCreateForm(lookup.targetEntity.id, lookup.formId, {
        popCount: 0,
        queryParams: {
          args: JSON.stringify({ $mappings: { [this.primaryAttribute]: 'Id' } }),
        },
      });
    }
  }

  private setSelectedLookupBaseOnValue() {
    this._selectedLookupId = this._lookups[0]?.attributeName;
    for (let i = 0; i < this._lookups.length; i++) {
      const lookup = this._lookups[i];
      if (!this.value) break;
      if (!!this.value[lookup.attributeName]) {
        this._selectedLookupId = lookup.attributeName;
        break;
      }
    }
    this._groupedAutocompleteBaseControl.setSelectedGroup(this._selectedLookupId);
    this.refreshActionButtonsVisibility();
  }

  private setValueBaseOnFormValue() {
    const newValue = {};
    this._lookups.forEach(
      (x) => (newValue[x.attributeName] = this._form.dataItem[this.getLookupGuidFieldName(x.attributeName)]),
    );
    this.value = newValue;
  }

  private refreshActionButtonsVisibility() {
    this._groupedAutocompleteBaseControl.setCreateButtonVisibility(
      (this.selectedLookup?.isCreateButtonVisible &&
        !!this.selectedLookup?.formId &&
        this.selectedLookup.targetEntity?.canCreate) ??
      false,
    );
    this._groupedAutocompleteBaseControl.setOpenButtonVisibility(
      (this.selectedLookup?.isOpenButtonVisible &&
        !!this.selectedLookup?.formId &&
        this.selectedLookup.targetEntity?.canRead &&
        !this.selectedLookup.targetEntity?.canUpdate) ??
      false,
    );
    this._groupedAutocompleteBaseControl.setUpdateButtonVisibility(
      (this.selectedLookup?.isOpenButtonVisible &&
        !!this.selectedLookup?.formId &&
        this.selectedLookup.targetEntity?.canUpdate) ??
      false,
    );

    let actionOptions = [];
    if (!!this.selectedLookup?.expanderViewId) {
      actionOptions = [
        {
          id: 'expand',
          label: 'EXPAND',
          handler: this.onExpandPressed.bind(this),
        },
      ];
    }
    this._groupedAutocompleteBaseControl.setActionOptions(actionOptions);
  }

  private getValueByLookupId(lookupId: string): string {
    return this.value ? this.value[lookupId] : null;
  }

  private getLookupById(id: string): CombinedLookupDto {
    return this._lookups.find((l) => l.attributeName == this._selectedLookupId);
  }

  private getLookupGuidFieldName(lookupId: string): string {
    return lookupId ? `${lookupId}Id` : '';
  }

  private createNewValue(lookupId: string, value: string) {
    const newValue = {};
    newValue[lookupId] = value;
    return newValue;
  }
}
