import { ModuleWithProviders, NgModule } from '@angular/core';
import { ODataConfiguration, ODataModule, ODataServiceFactory } from '@shared/odata';
import { GenericODataServiceProvider } from './services/generic-odata-service-provider.service';
import { ODataConfig } from './services/odata-config.service';
import { QueryBuilderModule } from './components/query-builder/query-builder.module';
import { QueryBuilderComponent } from './components/query-builder/query-builder.component';

@NgModule({
  declarations: [],
  imports: [ODataModule, QueryBuilderModule],
  exports: [QueryBuilderComponent],
})
export class EngineODataModule {
  static forRoot(): ModuleWithProviders<EngineODataModule> {
    return {
      ngModule: EngineODataModule,
      providers: [
        {
          provide: ODataConfiguration,
          useClass: ODataConfig,
        },
        ODataServiceFactory,
        GenericODataServiceProvider,
      ],
    };
  }
}
