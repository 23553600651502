import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Inject,
  Input,
  Optional,
  Output,
  SkipSelf,
} from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { MenuItemDto } from '@core/services/api-clients';
import { WidgetDirective } from '@core/widgets/directives/widget.directive';
import { NoEventWidgetDirective } from '@core/widgets/directives/no-event-widget.directive';
import { SCRIPT_RUNNER_SERVICE, IScriptRunnerService } from '@core/widgets/models/iscript-runner.service';
import { SelectedMenuGroup } from '@core/menu/shared/menu-group/menu-group.component';
import { MenuGroupWithMenuItems } from '@core/menu/store/selectors';

@Component({
  selector: 'app-main-menu-navigation',
  templateUrl: './main-menu-navigation.component.html',
  styleUrls: ['./main-menu-navigation.component.scss'],
  providers: [
    {
      provide: WidgetDirective,
      useExisting: forwardRef(() => MainMenuNavigationComponent),
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainMenuNavigationComponent extends NoEventWidgetDirective {
  @Input() set menuAreaId(menuAreaId: string) {
    if (menuAreaId) {
      this.loaded$.next(true);
    }
  }
  @Input() menuGroups: MenuGroupWithMenuItems[] = [];
  @Input() specialMenuGroups: MenuGroupWithMenuItems[] = [];
  @Input() selectedMenuGroupId: string;
  @Input() selectedMenuItemId: string;

  @Input() isWide: boolean = false;
  @Input() isAddFavoriteEnabled: boolean = false;
  @Input() isPopupEnabled: boolean = false;
  @Input() favorites: { [id: string]: boolean } = {};

  @Output() menuGroupClick = new EventEmitter<SelectedMenuGroup>();
  @Output() menuItemClick = new EventEmitter<MenuItemDto>();
  @Output() menuItemAddedToFavorite = new EventEmitter<MenuItemDto>();
  @Output() menuItemRemovedFromFavorite = new EventEmitter<MenuItemDto>();

  private loaded$ = new BehaviorSubject<boolean>(false);

  constructor(
    @Optional() @SkipSelf() parentWidget: WidgetDirective,
    @Inject(SCRIPT_RUNNER_SERVICE) scriptRunnerService: IScriptRunnerService,
  ) {
    super(parentWidget, scriptRunnerService);
  }

  protected isWidgetInitiated(): Observable<boolean> {
    return this.loaded$.asObservable().pipe(filter((x) => !!x));
  }
}
