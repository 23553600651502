import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import {
  UrlUtils, ProtocolUtils
} from "@azure/msal-common";

@Component({
  selector: 'app-redirect',
  template: '',
})
export class B2CRedirectComponent implements OnInit {
  constructor(private authService: MsalService, private router: Router) { }

  ngOnInit(): void {
    this.authService.getLogger().verbose('MsalRedirectComponent activated');
    this.authService.handleRedirectObservable().subscribe((authResult) => {
      const redirectHash: string = (<any>this.authService).redirectHash;
      if (redirectHash) {
        const deserializedResponse = UrlUtils.getDeserializedResponse(redirectHash);
        if (deserializedResponse != null && deserializedResponse.state != null) {

          if (this.isValidUrl(deserializedResponse.state)) {
            if (!location.href.startsWith(deserializedResponse.state)) {
              location.href = deserializedResponse.state;
            }

            return;
          }

          const { userRequestState } = ProtocolUtils.parseRequestState(
            (<any>this.authService.instance).controller.browserCrypto,
            deserializedResponse.state
          );

          if (userRequestState != null) {
            location.href = userRequestState + '/auth' + redirectHash;
          }
        }

        if (authResult == null && this.router.url.startsWith('/auth')) {
          this.router.navigate(['/']);
        }
      }
    });
  }

  isValidUrl(urlString: string) {
    try {
      new URL(urlString);
      return true;
    } catch (err) {
      return false;
    }
  }
}
