<ng-template #customColumnTemplate let-dataItem="dataItem" let-syncColumnWidths="syncColumnWidths">
  <app-context-menu
    class="compactMenu k-grid-ignore-click"
    [disabled]="!isDataFetchingEnabled"
    [contextMenu]="contextMenu"
    [additionalDataContext]="{
      isSubgridColumn: true,
      recordId: dataItem.Id,
      record: dataItem
    }"
    displayMode="compact"
    (widthChanged)="syncColumnWidths()">
  </app-context-menu>
</ng-template>

<app-grid
  [gridDefinition]="gridDefinition"
  [data]="dataProvider | async"
  [state]="gridQuery$ | async"
  [isReadOnly]="isReadOnly"
  [customColumnTemplate]="contextMenu ? customColumnTemplate : undefined"
  [displayRows]="displayRows"
  [selectedKeys]="selectedKeys"
  (stateChange)="onStateChange($event)"
  (doubleClick)="emitRowDoubleClick($event)"
  (clickableCellClick)="emitLookupColumnClick($event)"
  (selectedKeysChange)="onSelectedKeysChange($event)"
  (columnResize)="onColumnResize($event)"
  (pageSizeChange)="pageSizeChange.emit($event)">
</app-grid>
