import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { FormDto } from '@core/services/api-clients';
import { switchMap, map, distinctUntilChanged, filter, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { NotificationGroup, NotificationScope } from '../../../../engine-sdk';
import { FormSelectors } from '@core/engine-forms/store';
import { FormAttributesProvider } from '@core/notification/services/form-attributes-provider.service';
import { RemoveAllNotifications, RemoveNotificationGroup } from '@core/notification/store/actions';
import { getNotificationGroups } from '@core/notification/store/selectors';
import { NavigationService } from '@core/navigation/services/navigation.service';

// TODO-MP: raczej do przepisania i uproszczenia to jest
@Component({
  selector: 'app-main-notification-bar',
  templateUrl: './main-notification-bar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FormAttributesProvider],
})
export class MainNotificationBarComponent implements OnInit, OnDestroy {
  private _destroy$: Subject<any> = new Subject<any>();

  notificationGroups$ = this._store.pipe(
    select(getNotificationGroups),
    switchMap((e) => {
      return this._store.select(FormSelectors.getFormFromUrl).pipe(
        map((form: FormDto) => {
          if (!form) {
            return Object.values(e).filter((x) => this.isGlobalScope(x));
          } else {
            let attributes = this._attributesProvider.getFormAttributes(form);
            return Object.values(e).filter(
              (x) =>
                this.isGlobalScope(x) ||
                this.isNotInFormEntityScope(x, form) ||
                this.isInEntityFormScopeWithoutControl(x, form, attributes),
            );
          }
        }),
      );
    }),
  );

  constructor(
    private _store: Store,
    private _navigationService: NavigationService,
    private _attributesProvider: FormAttributesProvider,
  ) {}

  ngOnInit() {
    this._navigationService
      .getTopNavigationStackItem()
      .pipe(
        takeUntil(this._destroy$),
        filter((navigationData) => !!navigationData),
        distinctUntilChanged((prev, curr) => prev.navigationPath === curr.navigationPath),
        tap(() => this._store.dispatch(new RemoveAllNotifications())),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._destroy$.next(null);
    this._destroy$.complete();
  }

  public onRemove(group) {
    this._store.dispatch(new RemoveNotificationGroup({ notificationGroup: group }));
  }

  private isGlobalScope(group: NotificationGroup): boolean {
    return group.scope === NotificationScope.Global;
  }

  private isNotInFormEntityScope(group: NotificationGroup, form: FormDto) {
    return group.scope === NotificationScope.Attribute && group.entityName !== form.entityName;
  }

  private isInEntityFormScopeWithoutControl(group: NotificationGroup, form: FormDto, attributes: Object) {
    return (
      group.scope === NotificationScope.Attribute &&
      group.entityName === form.entityName &&
      !attributes.hasOwnProperty(group.name)
    );
  }
}
