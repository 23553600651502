import { Injectable } from '@angular/core';
import { UserSelectors } from '@core/user/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { withLatestFrom, tap, switchMap } from 'rxjs/operators';
import { MD_SCREEN_BREAKPOINT } from '../models/screen-size.enum';
import {
  CloseLeftPanel,
  InitLayout,
  InitLayoutSuccess,
  LayoutActionTypes,
  OpenLeftPanel,
  SaveNewScreenWidth,
  ScreenSizeChanged,
  ToggleIsLeftPanelExpanded,
} from './actions';
import { getIsLeftPanelExpanded, getIsLeftPanelVisible, getScreenWidth } from './selectors';

@Injectable()
export class LayoutEffects {
  initLayout$ = createEffect(() =>
    this._actions$.pipe(
      ofType<InitLayout>(LayoutActionTypes.InitLayout),
      withLatestFrom(this._store.select(UserSelectors.getCurrentUserId)),
      switchMap(([_, userId]) =>
        of(
          new InitLayoutSuccess({
            isLeftPanelExpanded:
              localStorage[`isLeftPanelExpanded_${userId}`] == 'true' ||
              localStorage[`isLeftPanelExpanded_${userId}`] == true,
          }),
        ),
      ),
    ),
  );

  toggleIsMainMenuExpanded$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType<ToggleIsLeftPanelExpanded>(LayoutActionTypes.ToggleIsLeftPanelExpanded),
        withLatestFrom(
          this._store.pipe(select(getIsLeftPanelExpanded)),
          this._store.pipe(select(UserSelectors.getCurrentUserId)),
        ),
        tap(([_, isExpanded, userId]) => {
          localStorage[`isLeftPanelExpanded_${userId}`] = isExpanded;
        }),
      ),
    { dispatch: false },
  );

  screenSizeChanged$ = createEffect(
    () =>
      this._actions$.pipe(
        ofType<ScreenSizeChanged>(LayoutActionTypes.ScreenSizeChanged),
        withLatestFrom(this._store.pipe(select(getScreenWidth)), this._store.pipe(select(getIsLeftPanelVisible))),
        tap(([action, prevScreenWidth, isVisible]) => {
          const screenWidth = action.payload.screenWidth;
          if (screenWidth <= MD_SCREEN_BREAKPOINT) {
            if (prevScreenWidth > MD_SCREEN_BREAKPOINT && isVisible) {
              this._store.dispatch(new CloseLeftPanel());
            }
          } else {
            if (prevScreenWidth <= MD_SCREEN_BREAKPOINT && !isVisible) {
              this._store.dispatch(new OpenLeftPanel());
            }
          }
          this._store.dispatch(new SaveNewScreenWidth({ screenWidth }));
        }),
      ),
    { dispatch: false },
  );

  constructor(private _actions$: Actions, private _store: Store) {}
}
