import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IAutocompleteGroup } from '../grouped-autocomplete-control.model';

@Component({
  selector: 'app-autocomplete-groups-selector',
  templateUrl: './autocomplete-groups-selector.component.html',
  styleUrls: ['./autocomplete-groups-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutocompleteGroupsSelectorComponent {
  @Input() selectedGroupId: string | null = null;
  @Input() groups: IAutocompleteGroup[] = [];

  @Output() selectedGroupChanged = new EventEmitter<string>();

  get selectedGroup(): IAutocompleteGroup | null {
    const selectedGroup = this.groups.find((g) => g.id == this.selectedGroupId);
    return this.selectedGroupId && selectedGroup ? selectedGroup : null;
  }
  get minIndex(): number {
    return 0;
  }
  get maxIndex(): number {
    return this.groups.length - 1;
  }

  onNextClicked() {
    const currentIndex = this.groups.findIndex((g) => g.id == this.selectedGroupId);
    let newIndex = currentIndex + 1 > this.maxIndex ? this.minIndex : currentIndex + 1;
    this.selectedGroupId = this.groups[newIndex].id;
    this.selectedGroupChanged.emit(this.selectedGroupId);
  }

  onPrevClicked() {
    const currentIndex = this.groups.findIndex((g) => g.id == this.selectedGroupId);
    let newIndex = currentIndex - 1 < this.minIndex ? this.maxIndex : currentIndex - 1;
    this.selectedGroupId = this.groups[newIndex].id;
    this.selectedGroupChanged.emit(this.selectedGroupId);
  }
}
