<div *ngIf="formControl">
  <editor apiKey="k79zdro6pnz2ik0shaq2980s8xy2292b2bsmvw6scvig1t30"
          [formControl]="formControl"
          [id]="id"
          [init]="{
     height: 500,
     menubar: true,
     removed_menuitems: 'newdocument',
     toolbar:
       'undo redo | formatselect | bold italic backcolor | \
       alignleft aligncenter alignright alignjustify | \
       bullist numlist outdent indent | removeformat | help'
   }"></editor>
  <app-control-error [control]="formControl" [offset]="-16"></app-control-error>
</div>
