<app-context-menu *ngIf="contextMenu$ | async as contextMenu" [contextMenu]="contextMenu" [additionalDataContext]="{}">
</app-context-menu>

<ng-container *ngIf="formData$ | async as formData">
  <engine-form
    *ngIf="formData && formData.definition && formData.dataItem"
    [definition]="formData.definition"
    [dataItem]="formData.dataItem"
    [selectedTabIndex]="selectedTabIndex"
    [widgetScripts]="widgetScripts"
    [widgetId]="formData.definition.id"
    [notificationGroups]="notificationGroups$ | async"
    [notificationGroupName]="formData.definition.recordInfo.entityName"
    (initiated)="onFormInitiated($event)"></engine-form>
  <engine-async-widget [delay]="100" [isReady]="true"></engine-async-widget>
</ng-container>
