import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { EngineDialogFormComponent } from '@core/engine-forms/components/engine-dialog-form/engine-dialog-form.component';
import { AlertDialogComponent } from '@shared/alert-dialog/alert-dialog.component';
import { Observable } from 'rxjs';
import { UploadDialogComponent } from 'src/core/blob/upload-dialog/upload-dialog.component';
import {
  IDialogConfig,
  IDialogFormConfig,
  IRecordSelectorConfig,
  IToolsService,
  IUploadDialogConfig,
} from 'src/engine-sdk';
import { RecordsSelectorViewComponent } from '../engine-views/components/records-selector-view/records-selector-view.component';
import { RecordsSelectorViewConfig } from '@core/engine-views/components/records-selector-view/records-selector-view-config';
import { IEngineODataState } from 'src/engine-sdk/contract/odata/iodata-state.model';
import { EngineTranslationService, TranslationType } from '@core/engine-translations/services/translation.service';

@Injectable({
  providedIn: 'root',
})
export class ToolsService implements IToolsService {
  constructor(private _dialog: MatDialog, private _translationService: EngineTranslationService) {}

  openRecordSelector(config: IRecordSelectorConfig) {
    const { disableClose: disableClose, filters, ...data } = config;
    const query = filters?.filters?.length > 0 ? ({ filter: filters } as IEngineODataState) : null;
    this._dialog.open(RecordsSelectorViewComponent, {
      data: { ...data, query } as RecordsSelectorViewConfig,
      disableClose: disableClose,
      autoFocus: false,
      restoreFocus: false,
    });
  }

  openDialog(config: IDialogConfig) {
    const { disableClose: disableClose, ...data } = config;
    this._dialog.open(AlertDialogComponent, {
      data: data,
      disableClose: disableClose,
      autoFocus: false,
      restoreFocus: false,
    });
  }

  openUploadFileDialog(config: IUploadDialogConfig) {
    this._dialog.open(UploadDialogComponent, {
      data: config,
      autoFocus: false,
      restoreFocus: false,
    });
  }

  openFormDialog(options?: IDialogFormConfig) {
    this._dialog.open(EngineDialogFormComponent, {
      data: options,
      autoFocus: false,
      restoreFocus: false,
    });
  }

  translate(message: string, context?: Object, type: TranslationType = TranslationType.Business): Observable<string> {
    return this._translationService.translate(message, context, type);
  }
}
