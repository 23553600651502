import { Inject, Injectable, InjectionToken } from '@angular/core';
import { CldrIntlService } from '@progress/kendo-angular-intl';

import '@progress/kendo-angular-intl/locales/en/all';
import '@progress/kendo-angular-intl/locales/pl/all';
import '@progress/kendo-angular-intl/locales/uk/all';

export const KENDO_LOCALE_ID = new InjectionToken<string>('KENDO_LOCALE_ID');

@Injectable()
export class KendoIntlService extends CldrIntlService {
  constructor(@Inject(KENDO_LOCALE_ID) kendoLocaleId: string) {
    super(kendoLocaleId);
  }
}
