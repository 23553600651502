import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { of, EMPTY } from 'rxjs';
import { withLatestFrom, map, mergeMap, catchError } from 'rxjs/operators';
import {
  ViewTargets,
  SetUserViewSettingsPageSizeCommand,
  ViewClient,
  UserViewSettingsClient,
  EntityViewDto,
  SetUserViewSettingsMainSelectorVisibilityCommand,
} from '@core/services/api-clients';
import {
  ReloadView,
  ViewActionTypes,
  LoadEntityView,
  LoadEntityViewSuccess,
  SetUserViewPageSize,
  SetUserViewPageSizeSuccess,
  LoadTabsVisibilitySettings,
  SaveTabsVisibilitySettings,
  SaveTabsVisibilitySettingsToState,
  LoadGridSettingsFromLocalStorage,
  SaveGridSettingsToState,
  SaveGridColumnSettings,
  RestoreGridSettings,
  SaveGridToolbarSettings,
} from './actions';
import { getViewTabsVisibility, getViewsIds } from './selectors';
import { EngineViewSettingsService } from '../components/engine-view/services/engine-view-settings.service';
import { LoadGridColumnContextMenu } from '@core/context-menu/store/actions';
import { HandleError } from '@core/errors/store/actions';
import { getCurrentUserId } from '@core/user/store/selectors';

@Injectable()
export class ViewEffects {
  reloadView$ = createEffect(() =>
    this._actions$.pipe(
      ofType<ReloadView>(ViewActionTypes.ReloadView),
      map((action) => action.payload.viewId),
      withLatestFrom(this._store.select(getViewsIds)),
      mergeMap(([viewId, loadedViewIds]) => {
        const actions: Action[] = [];

        const isViewLoaded = loadedViewIds.find((x) => x == viewId) != null;
        if (!isViewLoaded) {
          actions.push(
            new LoadEntityView({
              viewId: viewId,
              viewTarget: ViewTargets.Overview,
            }),
          );
        }

        actions.push(new LoadGridColumnContextMenu());
        actions.push(new LoadGridSettingsFromLocalStorage({ viewOrSubgridId: viewId }));

        return actions;
      }),
    ),
  );

  loadEntityView$ = createEffect(() =>
    this._actions$.pipe(
      ofType<LoadEntityView>(ViewActionTypes.LoadEntityView),
      map((action) => action.payload),
      mergeMap(({ viewId, viewTarget, overwrittenPageSize }) =>
        this._viewClient.getEntityView(viewTarget, viewId).pipe(
          map((entityView) => {
            if (overwrittenPageSize) {
              entityView.view.pageSize = overwrittenPageSize;
            }

            return entityView;
          }),
          catchError((error) => {
            this._store.dispatch(new HandleError({ error }));
            return EMPTY;
          }),
        ),
      ),
      mergeMap((entityView: EntityViewDto) => {
        if (entityView)
          return [
            new LoadEntityViewSuccess({ view: entityView }),
            new LoadTabsVisibilitySettings({ entityId: entityView.view.entity.id }),
          ];

        return EMPTY;
      }),
    ),
  );

  loadGridSettingsFromLocalStorage$ = createEffect(() =>
    this._actions$.pipe(
      ofType<LoadGridSettingsFromLocalStorage>(ViewActionTypes.LoadGridSettingsFromLocalStorage),
      map((action) => action.payload.viewOrSubgridId),
      withLatestFrom(this._store.pipe(select(getCurrentUserId))),
      mergeMap(([viewId, userId]) => {
        const settings = EngineViewSettingsService.getGridSettings(viewId, userId);
        return of(new SaveGridSettingsToState({ viewOrSubgridId: viewId, settings }));
      }),
    ),
  );

  saveGridColumnSettings$ = createEffect(() =>
    this._actions$.pipe(
      ofType<SaveGridColumnSettings>(ViewActionTypes.SaveGridColumnSettings),
      map((x) => x.payload),
      withLatestFrom(this._store.pipe(select(getCurrentUserId))),
      mergeMap(([payload, userId]) => {
        const settings = EngineViewSettingsService.getGridSettings(payload.viewOrSubgridId, userId);
        settings.columnsSettings[payload.settings.columnId] = payload.settings;

        EngineViewSettingsService.saveGridSettings(payload.viewOrSubgridId, userId, settings);
        return of(new SaveGridSettingsToState({ viewOrSubgridId: payload.viewOrSubgridId, settings }));
      }),
    ),
  );

  saveGridToolbarSettings$ = createEffect(() =>
    this._actions$.pipe(
      ofType<SaveGridToolbarSettings>(ViewActionTypes.SaveGridToolbarSettings),
      map((x) => x.payload),
      withLatestFrom(this._store.select(getCurrentUserId)),
      mergeMap(([payload, userId]) => {
        const settings = EngineViewSettingsService.getGridSettings(payload.viewOrSubgridId, userId);

        if (payload.settings.areFiltersEnabled !== undefined) {
          settings.areFiltersEnabled = payload.settings.areFiltersEnabled;
        }

        if (payload.settings.isGlobalSearchEnabled !== undefined) {
          settings.isGlobalSearchEnabled = payload.settings.isGlobalSearchEnabled;
        }

        EngineViewSettingsService.saveGridSettings(payload.viewOrSubgridId, userId, settings);
        return of(new SaveGridSettingsToState({ viewOrSubgridId: payload.viewOrSubgridId, settings }));
      }),
    ),
  );

  restoreGridSettings$ = createEffect(() =>
    this._actions$.pipe(
      ofType<RestoreGridSettings>(ViewActionTypes.RestoreGridSettings),
      map((action) => action.payload.viewOrSubgridId),
      withLatestFrom(this._store.pipe(select(getCurrentUserId))),
      mergeMap(([viewId, userId]) => {
        const settings = EngineViewSettingsService.getGridSettings(viewId, userId);
        settings.isGlobalSearchEnabled = false;
        settings.areFiltersEnabled = false;
        settings.columnsSettings = {};

        EngineViewSettingsService.saveGridSettings(viewId, userId, settings);
        return of(new SaveGridSettingsToState({ viewOrSubgridId: viewId, settings }));
      }),
    ),
  );

  setUserViewPageSize$ = createEffect(() =>
    this._actions$.pipe(
      ofType<SetUserViewPageSize>(ViewActionTypes.SetUserViewPageSize),
      mergeMap((action) =>
        this._userViewSettingsClient
          .setUserViewSettingsPageSize({
            viewId: action.payload.viewId,
            pageSize: action.payload.pageSize,
          } as SetUserViewSettingsPageSizeCommand)
          .pipe(
            map((_) => action.payload),
            catchError((error) => {
              this._store.dispatch(new HandleError({ error }));
              return EMPTY;
            }),
          ),
      ),
      mergeMap((payload) => {
        if (payload)
          return of(
            new SetUserViewPageSizeSuccess({
              viewId: payload.viewId,
              pageSize: payload.pageSize,
            }),
          );

        return EMPTY;
      }),
    ),
  );

  loadTabsVisibilitySettings$ = createEffect(() =>
    this._actions$.pipe(
      ofType<LoadTabsVisibilitySettings>(ViewActionTypes.LoadTabsVisibilitySettings),
      map((action) => action.payload.entityId),
      mergeMap((entityId) => of(entityId).pipe(withLatestFrom(this._store.select(getViewTabsVisibility(entityId))))),
      mergeMap(([entityId, tabsVisibility]) => {
        if (tabsVisibility !== undefined) {
          return EMPTY;
        } else {
          return this._userViewSettingsClient.getUserViewSettingsMainSelectorVisibility(entityId).pipe(
            mergeMap((settings) =>
              of(
                new SaveTabsVisibilitySettingsToState({
                  entityId: entityId,
                  tabsVisibilitySettings: settings,
                }),
              ),
            ),
          );
        }
      }),
    ),
  );

  saveTabsVisibilitySettings$ = createEffect(() =>
    this._actions$.pipe(
      ofType<SaveTabsVisibilitySettings>(ViewActionTypes.SaveTabsVisibilitySettings),
      map((e) => e.payload),
      mergeMap((payload) =>
        this._userViewSettingsClient
          .setUserViewSettingsMainSelectorVisibility(
            SetUserViewSettingsMainSelectorVisibilityCommand.fromJS({
              viewsMainSelectorVisibility: payload.tabsVisibilitySettings,
            }),
          )
          .pipe(
            mergeMap((_) =>
              of(
                new SaveTabsVisibilitySettingsToState({
                  entityId: payload.entityId,
                  tabsVisibilitySettings: payload.tabsVisibilitySettings,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  constructor(
    private _actions$: Actions,
    private _viewClient: ViewClient,
    private _userViewSettingsClient: UserViewSettingsClient,
    private _store: Store,
  ) {}
}
