import { Action } from '@ngrx/store';

export enum AuthActionTypes {
  AuthenticationSuccess = '[Auth] Authentication Success',
  Logout = '[Auth] Logout',
}

export class AuthenticationSuccess implements Action {
  public readonly type = AuthActionTypes.AuthenticationSuccess;
  constructor() {}
}

export class Logout implements Action {
  public readonly type = AuthActionTypes.Logout;
}

export type AuthActions = AuthenticationSuccess | Logout;
