import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  SkipSelf,
  forwardRef,
} from '@angular/core';
import { ControlDto } from '@core/services/api-clients';
import { Subject, combineLatest } from 'rxjs';
import { FORM_CONTEXT, IChartControlContext, IFormContext } from 'src/engine-sdk';
import { FormSectionComponent } from '../form-grid/form-section/form-section.component';
import { ChartData, IChartConfiguration } from '@shared/ui-components/components/chart/chart.model';
import { LayoutService } from '@core/layout/services/layout.service';
import { ThemeService } from '@core/layout/services/theme.service';
import { tap, delay, takeUntil } from 'rxjs/operators';
import {
  ENGINE_DATA_CONTEXT_PROVIDER,
  IEngineDataContextProvider,
  IEngineFormControlDataContext,
} from 'src/engine-sdk/contract/engine-data-context';

@Component({
  selector: 'engine-chart-control',
  templateUrl: './engine-chart-control.component.html',
  styleUrls: ['./engine-chart-control.component.scss'],
  providers: [
    {
      provide: ENGINE_DATA_CONTEXT_PROVIDER,
      useExisting: forwardRef(() => EngineChartControlComponent),
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EngineChartControlComponent implements OnInit, OnDestroy, IChartControlContext {
  private _destroy$: Subject<boolean> = new Subject<boolean>();
  private _chartControl: ControlDto;
  private _isVisible: boolean;

  @Input() set chartControl(value: ControlDto) {
    if (this._chartControl != value) {
      this._chartControl = value;

      if (this._chartControl) {
        this.id = this._chartControl.id;
        this.name = this._chartControl.name;
        this.label = this._chartControl.label;
        this.type = this._chartControl.type;
        this.isVisible = this._chartControl.isVisible;
        this.isRequired = this._chartControl.isRequired;
        this.isReadOnly = this._chartControl.isReadOnly;
      }
    }
  }
  get chartControl() {
    return this._chartControl;
  }

  get isDarkTheme$() {
    return this._theme.isDarkThemeAsync();
  }

  chartConfig: IChartConfiguration;
  data: ChartData;

  constructor(
    @Optional()
    @SkipSelf()
    @Inject(ENGINE_DATA_CONTEXT_PROVIDER)
    private _engineDataContextProvider: IEngineDataContextProvider,
    protected _formSection: FormSectionComponent,
    @Inject(FORM_CONTEXT) protected _formContext: IFormContext,
    private _theme: ThemeService,
    private _layout: LayoutService,
    private _changeDetector: ChangeDetectorRef,
  ) {
    this._formSection.registerControl(this);
  }

  ngOnInit(): void {
    // i had to add this because charts won't resize properly.
    // it's probably related to shell problems caused by resizable left panel
    combineLatest([
      this._layout.screenSizeChanged(),
      this._layout.leftPanelSizeChanged(),
      this._layout.leftPanelVisibilityChanged(),
    ])
      .pipe(
        takeUntil(this._destroy$),
        delay(500),
        tap(() => this.updateData({ ...this.data })),
      )
      .subscribe();
    this._layout
      .screenSizeChanged()
      .pipe(
        delay(5000),
        tap(() => this.updateData({ ...this.data })),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this._destroy$.next(true);
    this._destroy$.complete();
    this._formSection.unregisterControl(this);
  }

  // IChartControlContext

  id: string;
  name: string;
  label: string;
  type: string;
  isReadOnly: boolean;
  isRequired: boolean;

  setConfiguration(configuration: IChartConfiguration): void {
    this.chartConfig = configuration;
    this.data = configuration.data;
    this._changeDetector.markForCheck();
  }

  updateData(data: ChartData): void {
    this.data = data;
    this._changeDetector.markForCheck();
  }

  get isVisible(): boolean {
    return this._isVisible;
  }
  @Input() set isVisible(value) {
    if (this._isVisible != value) {
      this._isVisible = value;
      this._changeDetector.markForCheck();
    }
  }

  get value(): any {
    return undefined;
  }

  set value(_) {
    throw new Error('Not supported.');
  }

  @HostBinding('id')
  get getEngineControlId(): string {
    return `EngineControl_${this.id}`;
  }
  @HostBinding('attr.name')
  get getEngineControlName(): string {
    return `EngineControl_${this.name}`;
  }

  getAttributes(): { primaryAttribute: string; secondaryAttribute?: string } {
    return { primaryAttribute: null, secondaryAttribute: null };
  }

  setDirty(value: boolean): void {
    throw new Error('Not supported.');
  }

  setTouched(value: boolean): void {
    throw new Error('Not supported.');
  }

  isDirty() {
    return false;
  }

  isTouched() {
    return false;
  }

  setErrors(messages: string[]): void {
    throw new Error('Not supported.');
  }

  hasErrors(): boolean {
    return false;
  }

  hasError(errorId: string): boolean {
    return false;
  }

  getDataContext(): IEngineFormControlDataContext {
    const parentDataContext = this._engineDataContextProvider ? this._engineDataContextProvider.getDataContext() : {};
    return {
      ...parentDataContext,
      controlId: this.id,
      controlName: this.name,
    } as IEngineFormControlDataContext;
  }

  tryFocus() {
  }

  // #endregion
}
