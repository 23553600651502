export type Precision = 'days' | 'hours' | 'minutes' | 'seconds';

export class DurationService {
  private _precisionOrder: Precision[] = ['days', 'hours', 'minutes', 'seconds'];

  isVisible(value: Precision, maxPrecision: Precision, minPrecision: Precision): boolean {
    return (
      this._precisionOrder.indexOf(maxPrecision) <= this._precisionOrder.indexOf(value) &&
      this._precisionOrder.indexOf(minPrecision) >= this._precisionOrder.indexOf(value)
    );
  }

  getDays(duration: moment.Duration, maxPrecision: Precision): string {
    switch (maxPrecision) {
      case 'days':
        return Math.floor(duration.asDays()).toString();
      default:
        return null;
    }
  }

  getHours(duration: moment.Duration, maxPrecision: Precision): string {
    let value = null;
    switch (maxPrecision) {
      case 'days':
        value = duration.hours();
        break;
      case 'hours':
        value = Math.floor(duration.asDays()) * 24 + duration.hours();
        break;
      default:
        break;
    }

    return this.formatDurationPart(value);
  }

  getMinutes(duration: moment.Duration, maxPrecision: Precision): string {
    let value = null;
    switch (maxPrecision) {
      case 'days':
      case 'hours':
        value = duration.minutes();
        break;
      case 'minutes':
        value = Math.floor(duration.asDays()) * 24 * 60 + duration.hours() * 60 + duration.minutes();
        break;
      default:
        break;
    }

    return this.formatDurationPart(value);
  }

  getSeconds(duration: moment.Duration, maxPrecision: Precision): string {
    let value = null;
    switch (maxPrecision) {
      case 'days':
      case 'hours':
      case 'minutes':
        value = duration.seconds();
        break;
      case 'seconds':
        value =
          Math.floor(duration.asDays()) * 24 * 60 * 60 +
          duration.hours() * 60 * 60 +
          duration.minutes() * 60 +
          duration.seconds();
        break;
      default:
        break;
    }

    return this.formatDurationPart(value);
  }

  precisionAsSeconds(precision: Precision): number {
    switch (precision) {
      case 'days':
        return 24 * 60 * 60;
      case 'hours':
        return 60 * 60;
      case 'seconds':
        return 1;
      case 'minutes':
      default:
        return 60;
    }
  }

  private formatDurationPart(value: number): string {
    return value != null ? value.toString().padStart(2, '0') : null;
  }
}
