import { ModuleWithProviders, NgModule } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { EngineTranslationService } from './services/translation.service';
import { LabelService } from './services/label.service';
import { EngineTranslateLoader } from './services/engine-translate-loader.service';

@NgModule({
  declarations: [],
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useExisting: EngineTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
})
export class EngineTranslationsModule {
  static forRoot(): ModuleWithProviders<EngineTranslationsModule> {
    return {
      ngModule: EngineTranslationsModule,
      providers: [EngineTranslationService, LabelService, EngineTranslateLoader],
    };
  }
}
