import { Pipe, PipeTransform } from '@angular/core';
import { GridColumn } from '../models/grid-definition.model';
import { LookupPipe } from './lookup.pipe';

export interface Hyperlink {
  url: string,
  placeholder: string
}

@Pipe({ name: 'hyperlink' })
export class HyperlinkPipe implements PipeTransform {
  private _lookupPipe: LookupPipe = new LookupPipe();

  transform(dataItem: any, column: GridColumn): Hyperlink {
    if (!dataItem) return null;

    const primaryValue = this._lookupPipe.transform(dataItem, column, column.primaryField);
    const secondaryValue = this._lookupPipe.transform(dataItem, column, column.secondaryField);

    return {
      url: secondaryValue ?? primaryValue,
      placeholder: primaryValue ?? column.linkPlaceholder
    }
  }
}
