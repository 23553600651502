import { ErrorActions, ErrorsActionTypes } from './actions';
import { initialErrorsState, IErrorsState } from './state';

export function errorReducer(state = initialErrorsState, action: ErrorActions): IErrorsState {
  switch (action.type) {
    case ErrorsActionTypes.AddFatalError: {
      return {
        ...state,
        fatalErrors: [...state.fatalErrors, action.payload.error],
      };
    }

    default:
      return state;
  }
}
