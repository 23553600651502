import { Injectable } from '@angular/core';
import { OwlDateTimeIntl } from '@danielmoncada/angular-datetime-picker';

export interface IOwlTranslations {
  /** A label for the up second button (used by screen readers).  */
  upSecondLabel: string;
  /** A label for the down second button (used by screen readers).  */
  downSecondLabel: string;
  /** A label for the up minute button (used by screen readers).  */
  upMinuteLabel: string;
  /** A label for the down minute button (used by screen readers).  */
  downMinuteLabel: string;
  /** A label for the up hour button (used by screen readers).  */
  upHourLabel: string;
  /** A label for the down hour button (used by screen readers).  */
  downHourLabel: string;
  /** A label for the previous month button (used by screen readers). */
  prevMonthLabel: string;
  /** A label for the next month button (used by screen readers). */
  nextMonthLabel: string;
  /** A label for the previous year button (used by screen readers). */
  prevYearLabel: string;
  /** A label for the next year button (used by screen readers). */
  nextYearLabel: string;
  /** A label for the previous multi-year button (used by screen readers). */
  prevMultiYearLabel: string;
  /** A label for the next multi-year button (used by screen readers). */
  nextMultiYearLabel: string;
  /** A label for the 'switch to month view' button (used by screen readers). */
  switchToMonthViewLabel: string;
  /** A label for the 'switch to year view' button (used by screen readers). */
  switchToMultiYearViewLabel: string;
  /** A label for the cancel button */
  cancelBtnLabel: string;
  /** A label for the set button */
  setBtnLabel: string;
  /** A label for the range 'from' in picker info */
  rangeFromLabel: string;
  /** A label for the range 'to' in picker info */
  rangeToLabel: string;
  /** A label for the hour12 button (AM) */
  hour12AMLabel: string;
  /** A label for the hour12 button (PM) */
  hour12PMLabel: string;
}

export const OWL_DEFAULT_TRANSLATIONS: IOwlTranslations = {
  upSecondLabel: 'Add a second',
  downSecondLabel: 'Minus a second',
  upMinuteLabel: 'Add a minute',
  downMinuteLabel: 'Minus a minute',
  upHourLabel: 'Add a hour',
  downHourLabel: 'Minus a hour',
  prevMonthLabel: 'Previous month',
  nextMonthLabel: 'Next month',
  prevYearLabel: 'Previous year',
  nextYearLabel: 'Next year',
  prevMultiYearLabel: 'Previous 21 years',
  nextMultiYearLabel: 'Next 21 years',
  switchToMonthViewLabel: 'Change to month view',
  switchToMultiYearViewLabel: 'Choose month and year',
  cancelBtnLabel: 'Cancel',
  setBtnLabel: 'Set',
  rangeFromLabel: 'From',
  rangeToLabel: 'To',
  hour12AMLabel: 'AM',
  hour12PMLabel: 'PM',
};

@Injectable()
export class OwlIntlService extends OwlDateTimeIntl {
  get translations(): IOwlTranslations {
    return {
      upSecondLabel: this.upSecondLabel,
      downSecondLabel: this.downSecondLabel,
      upMinuteLabel: this.upMinuteLabel,
      downMinuteLabel: this.downMinuteLabel,
      upHourLabel: this.upHourLabel,
      downHourLabel: this.downHourLabel,
      prevMonthLabel: this.prevMonthLabel,
      nextMonthLabel: this.nextMonthLabel,
      prevYearLabel: this.prevYearLabel,
      nextYearLabel: this.nextYearLabel,
      prevMultiYearLabel: this.prevMultiYearLabel,
      nextMultiYearLabel: this.nextMultiYearLabel,
      switchToMonthViewLabel: this.switchToMonthViewLabel,
      switchToMultiYearViewLabel: this.switchToMultiYearViewLabel,
      cancelBtnLabel: this.cancelBtnLabel,
      setBtnLabel: this.setBtnLabel,
      rangeFromLabel: this.rangeFromLabel,
      rangeToLabel: this.rangeToLabel,
      hour12AMLabel: this.hour12AMLabel,
      hour12PMLabel: this.hour12PMLabel,
    };
  }

  constructor() {
    super();
    this.updateTranslations(OWL_DEFAULT_TRANSLATIONS);
  }

  updateTranslations(translations: IOwlTranslations) {
    this.upSecondLabel = translations.upSecondLabel;
    this.downSecondLabel = translations.downSecondLabel;
    this.upMinuteLabel = translations.upMinuteLabel;
    this.downMinuteLabel = translations.downMinuteLabel;
    this.upHourLabel = translations.upHourLabel;
    this.downHourLabel = translations.downHourLabel;
    this.prevMonthLabel = translations.prevMonthLabel;
    this.nextMonthLabel = translations.nextMonthLabel;
    this.prevYearLabel = translations.prevYearLabel;
    this.nextYearLabel = translations.nextYearLabel;
    this.prevMultiYearLabel = translations.prevMultiYearLabel;
    this.nextMultiYearLabel = translations.nextMultiYearLabel;
    this.switchToMonthViewLabel = translations.switchToMonthViewLabel;
    this.switchToMultiYearViewLabel = translations.switchToMultiYearViewLabel;
    this.cancelBtnLabel = translations.cancelBtnLabel;
    this.setBtnLabel = translations.setBtnLabel;
    this.rangeFromLabel = translations.rangeFromLabel;
    this.rangeToLabel = translations.rangeToLabel;
    this.hour12AMLabel = translations.hour12AMLabel;
    this.hour12PMLabel = translations.hour12PMLabel;
  }
}
