import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { IconService } from '@core/services/icon.service';
import { IIcon } from './icon.model';

export type IconSize = 'sm' | 'md' | 'xl' | 'custom';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent implements OnInit {
  private _icon: IIcon;

  @Input() size: IconSize = 'md';
  @Input() set icon(icon: IIcon) {
    this._icon = icon;
    
    if (icon?.iconRelativePath) {
      let svgRegex = new RegExp('.*/+(.*).svg');
        let svgRegexResult = svgRegex.exec(icon.iconRelativePath);
        if (svgRegexResult?.length) {
          this.svgIconName = this._iconService.registerIconByRelativeUrl(icon.iconRelativePath);
        }
        else {
          this.svgIconName = null;
        }
      this.url = icon.iconRelativePath;
      this.fontIconName = null;
    } else if (icon?.fontIconName) {
      this.fontIconName = icon.fontIconName;
      this.svgIconName = this.url = null;
    }
  }

  @ViewChild('template', { static: true }) template: TemplateRef<unknown>;
  fontIconName: string;
  svgIconName: string;
  url: string;
  get icon(): IIcon {
    return this._icon;
  }

  constructor(private _viewContainerRef: ViewContainerRef, private _iconService: IconService) {}

  ngOnInit() {
    this._viewContainerRef.createEmbeddedView(this.template);
  }
}
