import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainNotificationBarComponent } from './components/main-notification-bar/main-notification-bar.component';
import { SharedModule } from '@shared/shared.module';
import { StoreModule } from '@ngrx/store';
import { FEATURE_NAME } from './store/state';
import { reducers } from './store';
import { NotificationBarComponent } from './components/notification-bar/notification-bar.component';
import { FormTabNotificationBarComponent } from './components/form-tab-notification-bar/form-tab-notification-bar.component';
import { FormTabNotificationBadgeComponent } from './components/form-tab-notification-badge/form-tab-notification-badge.component';
import { EngineNotificationService, ENGINE_NOTIFICATION_SERVICE } from './services/engine-nofitication.service';

@NgModule({
  declarations: [
    NotificationBarComponent,
    MainNotificationBarComponent,
    FormTabNotificationBarComponent,
    FormTabNotificationBadgeComponent,
  ],
  imports: [CommonModule, StoreModule.forFeature(FEATURE_NAME, reducers), SharedModule],
  exports: [MainNotificationBarComponent, FormTabNotificationBarComponent, FormTabNotificationBadgeComponent],
})
export class NotificationModule {
  static forRoot(): ModuleWithProviders<NotificationModule> {
    return {
      ngModule: NotificationModule,
      providers: [{ provide: ENGINE_NOTIFICATION_SERVICE, useClass: EngineNotificationService }],
    };
  }
}
