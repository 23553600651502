import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MenuAreaDto } from '@core/services/api-clients';

@Component({
  selector: 'app-main-menu-area-selector',
  templateUrl: './main-menu-area-selector.component.html',
  styleUrls: ['./main-menu-area-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainMenuAreaSelectorComponent {
  @Input() menuAreas: MenuAreaDto[] = [];
  @Input() selectedMenuArea: MenuAreaDto;
  @Input() isWide: boolean = false;
  @Output() areaChanged = new EventEmitter<string>();
}
