export enum ErrorType {
  RequestValidation,
  PluginValidation,
  PluginExecution,
  Permission,
  System,
  Unknown,
  ScriptExecution,
}

export enum ErrorOrigin {
  Server,
  UserInterface,
}

export enum ErrorHandlerLifetime {
  Global,
  Scoped,
}

export interface IError {
  origin: ErrorOrigin;
  isHandled: boolean;
  payload: any;
  isCriticalHandlerError?: boolean;
}
