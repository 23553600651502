import { Injector } from '@angular/core';
import { CancellationToken } from '@core/services/context.service';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { IPreFetchExecutionEvent, IExecutionEvent, IControlExecutionContext } from 'src/engine-sdk';
import { EventType } from '../services/api-clients';
import { ExecutionContext } from './execution-context';

export class PreFetchExecutionEvent implements IPreFetchExecutionEvent {
  private _postScriptCallback: () => void;

  readonly eventType: EventType;
  isSuppressed: boolean = false;
  data?: any;

  constructor(postScriptCallback: () => void, data?: any) {
    this.eventType = EventType.PreFetch;
    this._postScriptCallback = postScriptCallback;
    this.data = data;
  }

  suppressFetch() {
    this.isSuppressed = true;
  }

  fetchAsync() {
    this._postScriptCallback();
  }
}

export class OnChangeExecutionEvent implements IExecutionEvent {
  readonly eventType: EventType;
  data?: any;

  constructor(data?: any) {
    this.eventType = EventType.OnChanged;
    this.data = data;
  }
}

export class ControlExecutionContext extends ExecutionContext implements IControlExecutionContext {
  private _events: Observable<IExecutionEvent>;
  private _controlDataContext: any;

  constructor(
    injector: Injector,
    events: Observable<IExecutionEvent>,
    cancellationToken: CancellationToken,
    controlDataContext: any,
    executionEvent?: IExecutionEvent,
  ) {
    super(injector, cancellationToken, executionEvent, controlDataContext.dialogId);
    this._events = events;
    this._controlDataContext = controlDataContext;
  }

  getControlDataContext(): { controlId: string } {
    return this._controlDataContext;
  }

  changed(): Observable<IExecutionEvent> {
    return this._events.pipe(filter((e) => e.eventType === EventType.OnChanged));
  }

  preFetch(): Observable<IPreFetchExecutionEvent> {
    return this._events.pipe(
      filter((e) => e.eventType === EventType.PreFetch),
      map((e) => e as IPreFetchExecutionEvent),
    );
  }
}
