<div *ngIf="kanban" class="root">
  <app-kanban-toolbar
    [kanban]="kanban"
    [searchTerm]="searchTerm"
    (refreshDataClick)="refreshDataClick.emit(kanban)"
    (restoreSettingsClick)="restoreSettingsClick.emit(kanban)"
    (searchTermChange)="searchTermChange.emit({ kanban, searchTerm: $event})"></app-kanban-toolbar>
  <div class="board">
    <div class="board-wrapper">
      <div class="board-columns">
        <div cdkDropList cdkDropListOrientation="horizontal" class="board-columns">
          <app-kanban-column
            *ngFor="let column of kanban.columns"
            [kanbanColumn]="column"
            [kanbanControls]="kanban.controls"
            [kanbanColumnData]="kanbanData?.columns[column.id]"
            [kanbanColumnIds]="kanbanColumnIds"
            cdkDragLockAxis="x"
            (drop)="drop($event, column)"
            (tileDoubleClick)="emitTileDoubleClick($event)"
            (loadMore)="emitLoadMoreClick($event)"
            (aggregatorSelect)="aggregatorSelect.emit($event)"
            (isExpandedChange)="columnExpansionChange.emit($event)">
          </app-kanban-column>
        </div>
      </div>
    </div>
  </div>
</div>
