<div class="menu-popup">
  <span class="popup-title">{{ groupName }}</span>
  <mat-divider></mat-divider>
  <app-menu-group-items
    [menuItems]="menuItems"
    [isSelectable]="false"
    [isWide]="true"
    [isFavoriteEnabled]="false"
    [selectedMenuItemId]="selectedMenuItemId"
    (menuItemClick)="onMenuItemClick($event)"
    (menuItemAddedToFavorite)="addMenuItemToFavorite($event)"
    (menuItemRemovedFromFavorite)="removeMenuItemFromFavorite($event)">
  </app-menu-group-items>
</div>
