import { getCurrentUser } from '@core/user/store/selectors';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import { take, filter, map } from 'rxjs/operators';
import { IUserContext, IUserProfile } from 'src/engine-sdk';

export class UserContext implements IUserContext {
  constructor(private _store: Store) {}

  getUserProfile(): Observable<IUserProfile> {
    return this._store.pipe(
      select(getCurrentUser),
      filter((user) => !!user),
      take(1),
      map((x) => x as IUserProfile),
    );
  }
}
