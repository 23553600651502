import { _DisposeViewRepeaterStrategy } from '@angular/cdk/collections';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ThemePalette } from '@angular/material/core';

export interface OptionSet {
  values: OptionSetValue[];
}

export interface OptionSetValue {
  name: string;
  label: string;
  value: number;
}

@Component({
  selector: 'app-optionset-progress-bar',
  templateUrl: './optionset-progress-bar.component.html',
  styleUrls: ['./optionset-progress-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptionSetProgressBarComponent {
  private _dictionary: { [value: number]: number } = {};

  private _min: number = 0;
  private _max: number = 100;
  private _optionSet: OptionSet = null;
  private _value: string = '';
  private _optionSetValue: number = null;

  @Input() color: ThemePalette = 'primary';

  @Input() set min(min: number) {
    if (min != null) this._min = min;

    this.refreshDictionary();
  }

  @Input() set max(max: number) {
    if (max != null) this._max = max;

    this.refreshDictionary();
  }

  @Input() set value(value: string) {
    this._value = value;
    this.refreshOptionSetValue();
  }

  @Input() set optionSet(optionSet: OptionSet) {
    this._optionSet = optionSet;

    this.refreshDictionary();
    this.refreshOptionSetValue();
  }

  get min() {
    let optionSet = this._optionSet.values.sort((a, b) => a.value - b.value);
    let minOptionSetValue = optionSet[0].value;
    return this._min ?? minOptionSetValue;
  }

  get max() {
    let optionSet = this._optionSet.values.sort((a, b) => a.value - b.value);
    let maxOptionSetValue = optionSet[optionSet.length - 1].value;
    return this._max ?? maxOptionSetValue;
  }

  get value() {
    return this._value;
  }

  get optionSet() {
    return this._optionSet;
  }

  get progressValue() {
    return this._dictionary[this._optionSetValue] ?? 0;
  }

  constructor() {}

  private calculateProgress(order: number, max: number) {
    return order != 0 ? Math.round((order / max) * 100) : 0;
  }

  private refreshDictionary() {
    let availableValues = this._optionSet.values
      .filter((x) => x.value >= this.min && x.value <= this.max)
      .sort((a, b) => a.value - b.value);
    let optionsCount = availableValues.length - 1;
    availableValues.forEach(
      (value, index) => (this._dictionary[value.value] = this.calculateProgress(index, optionsCount)),
    );
  }

  private refreshOptionSetValue() {
    this._optionSetValue = this.optionSet ? this.optionSet.values.find((x) => x.name == this.value)?.value : 0;
  }
}
