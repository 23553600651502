import { ContextService } from '@core/services/context.service';
import { IContextMenuContext, IMenuContext } from 'src/engine-sdk';
import { LayoutService } from '@core/layout/services/layout.service';

// TODO-MP: to jest imo w złym miejscu (miesza zależności dwóch modułów)
export class MenuContext implements IMenuContext {
  constructor(private _layoutService: LayoutService, private _contextService: ContextService) {}

  setUserMenuVisibility(isVisible: boolean) {
    if (isVisible) {
      this._layoutService.openRightPanel();
    } else {
      this._layoutService.closeRightPanel();
    }
  }

  getMainContextMenuContext(): IContextMenuContext {
    return this._contextService.getMainContextMenu();
  }
}
