import { Action } from '@ngrx/store';
import { ProcessEntityAssignmentDto, ProcessInstanceHistoryDto, ProcessStateDto } from '@core/services/api-clients';

export enum ProcessActionsTypes {
  LoadProcessEntityAssignments = '[Process] Load Process Entity Assignments',
  LoadProcessEntityAssignmentsSuccess = '[Process] Load Process Entity Assignments Success',
  LoadProcessStateWithTransitions = '[Process] Load Process State With Transitions',
  LoadProcessStateWithTransitionsSuccess = '[Process] Load Process State With Transitions Success',
  LoadProcessHistory = '[Process] Load Process History',
  LoadProcessHistorySuccess = '[Process] Load Process History Success',
}

export class LoadProcessEntityAssignments implements Action {
  public readonly type = ProcessActionsTypes.LoadProcessEntityAssignments;
  public constructor(public payload: { entityId: string }) {}
}

export class LoadProcessEntityAssignmenetsSuccess implements Action {
  public readonly type = ProcessActionsTypes.LoadProcessEntityAssignmentsSuccess;
  public constructor(public payload: { entityId: string; procesEntitytAssignments: ProcessEntityAssignmentDto[] }) {}
}

export class LoadProcessStateWithTransitions implements Action {
  public readonly type = ProcessActionsTypes.LoadProcessStateWithTransitions;
  public constructor(public payload: { sourceStateId: string }) {}
}

export class LoadProcessStateWithTransitionsSuccess implements Action {
  public readonly type = ProcessActionsTypes.LoadProcessStateWithTransitionsSuccess;
  public constructor(public payload: { sourceStateId: string; processState: ProcessStateDto }) {}
}

export class LoadProcessHistory implements Action {
  public readonly type = ProcessActionsTypes.LoadProcessHistory;
  public constructor(public payload: { processAssignmentId: string; recordId: string }) {}
}

export class LoadProcessHistorySuccess implements Action {
  public readonly type = ProcessActionsTypes.LoadProcessHistorySuccess;
  public constructor(
    public payload: { processAssignmentId: string; processHistoryItems: ProcessInstanceHistoryDto[] },
  ) {}
}

export type ProcessActions =
  | LoadProcessEntityAssignments
  | LoadProcessEntityAssignmenetsSuccess
  | LoadProcessStateWithTransitions
  | LoadProcessStateWithTransitionsSuccess
  | LoadProcessHistory
  | LoadProcessHistorySuccess;
