<div class="process-state-container" *ngIf="processState">
  <div
    class="process-state-container"
    [matTooltip]="processState.description"
    matTooltipPosition="below"
    (dblclick)="this.navigateToRelatedRecord.emit(processState)">
    <div
      class="basic-grid-button current-content"
      [ngStyle]="{'border': getStateBorder(processState), 'color': getStateColor(processState)}"
      >
      <app-icon [icon]="processState.icon"></app-icon>
      <span class="state-text no-wrap">{{ processState.label ?? '' }}</span>
    </div>
  </div>
  <div *ngIf="processState?.transitions?.length > 0">
    <div class="next-line"  [ngStyle]="{'color': getStateColor(processState)}"></div>
  </div>

  <ng-container *ngFor="let transition of processState.transitions">
    <button
      mat-button
      [matTooltip]="transition?.label"
      matTooltipPosition="below"
      id="{{ transition.id }}"
      class="basic-grid-button"
      [ngStyle]="{'border': getStateBorder(transition, true), 'color': getStateColor(transition, true)}"
      (click)="executeTransition(transition.targetStateId)">
      <app-icon [icon]="transition.icon"></app-icon>
      <span class="transition-text no-wrap" [style.max-width]="transition.icon ? '80%' : '100%'">{{
        transition?.label
      }}</span>
    </button>
  </ng-container>
</div>
