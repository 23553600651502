import { BehaviorSubject, Observable } from 'rxjs';

export abstract class BaseStateService<T> {
  private _state$: BehaviorSubject<T>;

  constructor(initialValue: T = undefined) {
    this._state$ = new BehaviorSubject(initialValue);
  }

  protected getStateAsync(): Observable<T> {
    return this._state$.asObservable();
  }

  protected getState(): T {
    return this._state$.getValue();
  }

  protected updateState(newState: T): void {
    this._state$.next(newState);
  }
}
