import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { Grid } from '../form-grid.model';

@Component({
  selector: 'app-form-grid-content',
  templateUrl: './form-grid-content.component.html',
  styleUrls: ['./form-grid-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormGridContentComponent {
  @Input() data: Grid;
  @Input() isCompactMode: boolean;

  @HostBinding('style.flex-flow') get getFlexFlow(): string {
    return this.data.type == 'col' ? 'column wrap' : 'row wrap';
  }

  getFlexSpan(takenSlots: number) {
    const totalSlots = this.data.slots ?? 1;
    return '1 ' + Math.floor((takenSlots / totalSlots) * 100) + '%';
  }

  getUnusedSlotsCount() {
    let usedSlots = this.data.children?.length
      ? this.data.children.map((c) => c.takenSlots).reduce((sum, b) => (sum += b))
      : 0;

    return this.data.slots - usedSlots;
  }

  getFlexRestSpan() {
    return `${this.getUnusedSlotsCount()} 1 auto`;
  }
}
