import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input } from '@angular/core';
import { EngineFormTabDirective } from '@core/engine-forms/directives/engine-form-tab.directive';
import { FormSectionDto } from '@core/services/api-clients';
import { HideableDirective } from '@shared/reactive-controls/directives/hideable.directive';
import { IControlContext, IFormSectionContext, ISubGridContext } from '../../../../../../engine-sdk';

@Component({
  selector: 'app-form-section',
  templateUrl: './form-section.component.html',
  styleUrls: ['./form-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormSectionComponent extends HideableDirective implements IFormSectionContext {
  private _controls: IControlContext[] = [];
  private _subgrids: ISubGridContext[] = [];

  @Input() section: FormSectionDto | undefined;

  get isVisible(): boolean {
    return super.isVisible;
  }
  @Input() set isVisible(v: boolean) {
    super.isVisible = v;
    this._changeDetector.markForCheck();
  }

  @HostBinding('id')
  get id(): string {
    return this.section.id;
  }
  @HostBinding('attr.name')
  get name(): string {
    return this.section.name;
  }

  constructor(changeDetector: ChangeDetectorRef, private _formTab: EngineFormTabDirective) {
    super(changeDetector);
    this._formTab.registerSection(this);
  }

  registerControl(control: IControlContext) {
    this._controls.push(control);
  }

  unregisterControl(control: IControlContext) {
    this._controls = this._controls.filter((c) => c != control);
  }

  getControls(): IControlContext[] {
    return this._controls;
  }

  getSubgrids(): ISubGridContext[] {
    return this._subgrids;
  }
}
