import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { BaseFilterCellComponent, ColumnComponent, FilterService } from "@progress/kendo-angular-grid";
import { FilterOperator } from "@progress/kendo-angular-grid/common/filter-operator.interface";
import { FilterComponent } from "@progress/kendo-angular-grid/filtering/filter-component.interface";
import { CompositeFilterDescriptor, FilterDescriptor } from "@progress/kendo-data-query";
import { EngineTranslationService } from "../../../../../core/engine-translations/services/translation.service";
import { Precision } from "../../../../ui-components/components/duration/duration.service";

@Component({
  selector: 'app-time-filter',
  templateUrl: './time-filter.component.html',
  styleUrls: ['./time-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeFilterComponent extends BaseFilterCellComponent implements FilterComponent, OnInit, AfterViewInit {
  static readonly OR_NAME = 'kendo.grid.filterOrLogic';
  static readonly AND_NAME = 'kendo.grid.filterAndLogic';

  @Input() column: ColumnComponent;
  @Input() filter: CompositeFilterDescriptor;
  @Input() filterService: FilterService;
  @Input() maxPrecision: Precision = 'hours';

  firstFilterDescriptor: FilterDescriptor;
  secondFilterDescriptor: FilterDescriptor;
  logicOperators: Array<FilterOperator>;

  logicOperator: FilterOperator;

  constructor(filterService: FilterService, private _changeDetectorRef: ChangeDetectorRef, private _translationService: EngineTranslationService) {
    super(filterService);
  }

  ngOnInit() {
    this.addLogicalOperatorsWithTranslation();
  }

  ngAfterViewInit(): void {
    const currentColumnFilter = <FilterDescriptor[]>(
      this.filter.filters.filter((filter: FilterDescriptor) => filter.field === this.column.field)
    );

    if (this.filter.logic)
      this.logicOperator = this.logicOperators.find(o => o.value == this.filter.logic);

    if (currentColumnFilter) {
      if (currentColumnFilter.length > 0)
        this.firstFilterDescriptor = currentColumnFilter[0];

      if (currentColumnFilter.length > 1)
        this.secondFilterDescriptor = currentColumnFilter[1];

      this._changeDetectorRef.markForCheck();
    }

    this._changeDetectorRef.markForCheck();
  }

  get logic() {
    return this.logicOperator.value == 'and' ? 'and' : 'or';
  }

  onFirstValueChange(value: FilterDescriptor) {
    this.firstFilterDescriptor = value;

    let filters: (CompositeFilterDescriptor | FilterDescriptor)[] = []

    if (value === null || value === undefined) {
      filters = [this.secondFilterDescriptor];
    } else {
      filters = [this.firstFilterDescriptor, this.secondFilterDescriptor];
    }

    this.filterService.filter({
      filters: filters.filter(n => n),
      logic: this.logic,
    });
  }

  onSecondValueChange(value: FilterDescriptor): void {
    this.secondFilterDescriptor = value;

    let filters: (CompositeFilterDescriptor | FilterDescriptor)[] = []

    if (value === null || value === undefined) {
      filters = [this.firstFilterDescriptor];
    } else {
      filters = [this.secondFilterDescriptor, this.firstFilterDescriptor];
    }

    this.filterService.filter({
      filters: filters.filter(n => n),
      logic: this.logic,
    });
  }

  onLogicOperatorChange(value: any) {
    this.logicOperator = value;

    this.filterService.filter({
      filters: [this.secondFilterDescriptor, this.firstFilterDescriptor].filter(n => n),
      logic: this.logic,
    });
  }

  private addLogicalOperatorsWithTranslation() {
    const andTranslation = this._translationService.translateInstantly(TimeFilterComponent.AND_NAME);
    const orTranslation = this._translationService.translateInstantly(TimeFilterComponent.OR_NAME);

    this.logicOperators = [
      { text: andTranslation, value: 'and' },
      { text: orTranslation, value: 'or' }
    ];
  }
}
