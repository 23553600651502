import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { CellClickEvent as KendoCellClickEvent, GridComponent as KendoGridComponent } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { Subject } from 'rxjs';
import { buffer, debounceTime, filter, takeUntil, tap } from 'rxjs/operators';
import { IEngineODataState } from '../../engine-sdk/contract/odata/iodata-state.model';
import { GridData } from './models/grid-data.model';
import { GridDefinition } from './models/grid-definition.model';
import { CellClickEvent, ColumnResizeEvent } from './models/grid-events.model';
import { GRID_RESIZING_SERVICE, GridResizingService, IGridResizingService } from './services/grid-resizing.service';
import { GRID_SELECTION_SERVICE, GridSelectionService, IGridSelectionService } from './services/grid-selection.service';

export const flatten = (filter) => {
  const filters = filter.filters;
  if (filters) {
    return filters.reduce((acc, curr) => acc.concat(curr.filters ? flatten(curr) : [curr]), []);
  }
  return [];
};

@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: GRID_SELECTION_SERVICE,
      useClass: GridSelectionService,
    },
    {
      provide: GRID_RESIZING_SERVICE,
      useClass: GridResizingService,
    },
  ],
})
export class GridComponent implements OnInit, OnChanges, OnDestroy {
  readonly DEFAULT_COLUMN_WIDTH = 200;
  readonly DEFAULT_CHECKBOX_COLUMN_WIDTH = 40;
  readonly DEFAULT_CUSTOM_COLUMN_WIDTH = 90;
  readonly DEFAULT_MOBILE_COLUMN_WIDTH = 600;
  readonly DEFAULT_STATE = { take: 20, skip: 0 };

  private _destroy$: Subject<boolean> = new Subject<boolean>();
  private _syncQueue = new Subject<any>();
  private _cellClicks$ = new Subject<KendoCellClickEvent>();
  private _kendoGrid: KendoGridComponent;
  private _data: GridData;
  private _state: IEngineODataState = this.DEFAULT_STATE;
  public isCustomColumnTemplateHidden: boolean = false;

  @Input() gridDefinition: GridDefinition;
  @Input() set data(v: GridData) {
    this._data = v;
  }
  @Input() set state(v: IEngineODataState) {
    if (!v) {
      this._state = this.DEFAULT_STATE;
    } else {
      this._state = v;
    }
  }
  @Input() isReadOnly = true; // for future usage
  @Input() customColumnTemplate: TemplateRef<any>;
  @Input() set selectedKeys(keys: any[]) {
    this.selectionService.setSelectedKeys(keys);
  }

  @Output() doubleClick = new EventEmitter<CellClickEvent>();
  @Output() stateChange = new EventEmitter<IEngineODataState>();
  @Output() columnResize = new EventEmitter<ColumnResizeEvent>();
  @Output() selectedKeysChange = new EventEmitter<any[]>();
  @Output() clickableCellClick = new EventEmitter<CellClickEvent>();
  @Output() pageSizeChange = new EventEmitter<number>();

  get kendoGrid() {
    return this._kendoGrid;
  }
  @ViewChild('kendoGrid', { static: false }) set kendoGrid(kendoGrid: KendoGridComponent) {
    if (this._kendoGrid != kendoGrid) {
      this._kendoGrid = kendoGrid;

      if (this._kendoGrid) {
        this.syncGridSelectionAndColumnWidths();
      }
    }
  }
  get data(): GridData {
    return this._data;
  }
  get state(): IEngineODataState {
    return this._state;
  }
  // TODO: popraw binding
  get buttonCount(): number {
    if (this.kendoGrid) {
      return Math.max(Math.floor((this.kendoGrid.header.nativeElement.clientWidth - 280) / 96), 1);
    }

    return 1;
  }

  hasColumnAggregations(columnId: string) {
    return this.data?.aggregations?.[columnId]?.length > 0 && this.data?.data?.length > 0;
  }

  constructor(
    @Inject(GRID_SELECTION_SERVICE) public selectionService: IGridSelectionService,
    @Inject(GRID_RESIZING_SERVICE) public resizingService: IGridResizingService,
    private _changeDetector: ChangeDetectorRef,
  ) {
    this.selectionService.registerGrid(this);
    this.resizingService.registerGrid(this);
  }

  ngOnInit() {
    this.initDoubleClickEvents();
    this.initSynchronization();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.syncGridSelectionAndColumnWidths();
  }

  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
  }

  syncGridSelectionAndColumnWidths(isDetectChangesRequired?: boolean) {
    this.selectionService.syncSelectionState();
    this.resizingService.syncColumnWidths();

    if (isDetectChangesRequired) {
      this._changeDetector.markForCheck();
    }
  }

  queueSyncOfGridSelectionAndColumnWidths() {
    this._syncQueue.next(true);
  }

  // handlers

  onCellClick(args: KendoCellClickEvent) {
    this._cellClicks$.next(args);
  }

  onSelectedKeysChange(keys: any[]) {
    this.selectedKeysChange.emit(keys);
  }

  onPageSizeChange(pageSize: number) {
    this.onDataStateChange({ ...this.state, take: pageSize });
    this.pageSizeChange.emit(pageSize);
  }

  onDataStateChange(state: IEngineODataState) {
    this.stateChange.emit(state);
  }

  onCustomFilterSubmit(filter: FilterDescriptor | CompositeFilterDescriptor): void {
    const currentFilters = this.state.filter?.filters ?? [];
    this.kendoGrid.filter = {
      logic: 'and',
      filters: [...currentFilters, filter],
    };
    this.kendoGrid.dataStateChange.emit({
      ...this.state,
      filter: this.kendoGrid.filter,
    } as any);
  }

  clearByFields(fieldNames: string[]) {
    const currentFilters = this.state.filter?.filters ?? [];
    if (currentFilters.length) {
      const clearedFilters = currentFilters.filter((filter: CompositeFilterDescriptor | FilterDescriptor) => {
        const flattenFilter: FilterDescriptor[] = (filter as any).filters?.length ? flatten(filter) : [filter];
        return (
          flattenFilter.length > 0 && !flattenFilter.some((f) => f.field && fieldNames.includes(f.field as string))
        );
      });
      this.kendoGrid.filter = {
        logic: 'and',
        filters: clearedFilters,
      };
      this.kendoGrid.dataStateChange.emit({
        ...this.state,
        filter: this.kendoGrid.filter,
      } as any);
    }
  }

  onClickableCellClick(event: CellClickEvent) {
    this.clickableCellClick.emit(event);
  }

  /// private methods

  private initDoubleClickEvents() {
    this._cellClicks$
      .pipe(
        takeUntil(this._destroy$),
        buffer(this._cellClicks$.pipe(debounceTime(250))),
        filter((list) => list.length > 1),
        tap((clicks) => this.doubleClick.emit({ ...clicks[0], fieldName: clicks[0].column.primaryField })),
      )
      .subscribe();
  }

  private initSynchronization() {
    this._syncQueue
      .pipe(
        takeUntil(this._destroy$),
        debounceTime(50),
        tap(() => {
          this.syncGridSelectionAndColumnWidths(true);
        }),
      )
      .subscribe();
  }
}
