<div>
  <app-autocomplete-control></app-autocomplete-control>
  <!-- <mat-form-field>
    <mat-select [value]="entity" (selectionChange)="onEntityChange($event.value)">
      <mat-option *ngFor="let entity of entities" [value]="entity">
        {{ entity.name }}
      </mat-option>
    </mat-select>
    <button type="button" matSuffix mat-icon-button class="autocomplete-action-button">
      <mat-icon>arrow_drop_down</mat-icon>
    </button>
  </mat-form-field> -->
  <app-query-builder [entityId]="'DBB839B8-7DDB-4C9C-AF7C-08D98A2805DD'" (stateChanged)="onStateChanged($event)">
  </app-query-builder>
  <div class="q-json-container">
    <pre>{{ state | json }}</pre>
  </div>
</div>
