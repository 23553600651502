import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Inject,
  Injector,
  Input,
  Output,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { IIcon } from '@shared/icon/icon.model';
import { BaseFormControlDirective } from '../../directives/base-form-control.directive';
import { CULTURE_SERVICE, ICultureService } from '../../models/iculture-service.model';

@Component({
  selector: 'app-button-control',
  templateUrl: './button-control.component.html',
  styleUrls: ['./button-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ButtonControlComponent),
      multi: true,
    },
    {
      provide: BaseFormControlDirective,
      useExisting: ButtonControlComponent,
    },
  ],
})
export class ButtonControlComponent extends BaseFormControlDirective {
  @Input() tooltipText: string = null;
  @Input() icon: IIcon = null;
  @Input() dataContext: any = {};
  @Input() textAlignStyle: any;
  @Output() buttonClick = new EventEmitter<any>();

  constructor(@Inject(CULTURE_SERVICE) cultureService: ICultureService, injector: Injector) {
    super(cultureService, injector);
  }

  onClick() {
    this.buttonClick.emit(this.dataContext);
  }
}
