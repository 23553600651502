import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { MenuItemDto } from '@core/services/api-clients';

@Component({
  selector: 'app-menu-group-items',
  templateUrl: './menu-group-items.component.html',
  styleUrls: ['./menu-group-items.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuGroupItemsComponent {
  @Input() menuItems: MenuItemDto[];
  @Input() isWide: boolean = false;
  @Input() isFavoriteEnabled: boolean = false;
  @Input() isFavorite: { [id: string]: boolean } = null;
  @Input() selectedMenuItemId: string;
  @Input() isSpecial: boolean = false;
  @Input() isSelectable: boolean = true;
  @Output() menuItemClick = new EventEmitter<MenuItemDto>();
  @Output() menuItemAddedToFavorite = new EventEmitter<MenuItemDto>();
  @Output() menuItemRemovedFromFavorite = new EventEmitter<MenuItemDto>();

  onMenuItemClick(menuItem: MenuItemDto) {
    this.menuItemClick.emit(menuItem);
  }

  addMenuItemToFavorite(menuItem: MenuItemDto) {
    this.menuItemAddedToFavorite.emit(menuItem);
  }

  removeMenuItemFromFavorite(menuItem: MenuItemDto) {
    this.menuItemRemovedFromFavorite.emit(menuItem);
  }
}
